import React, { Component } from "react";
import { arrayOf, string } from "prop-types";
import { Field } from "formik";

import * as i18nUtils from "util/i18n";

import PeriodAmountField from "pages/_components/fields/PeriodAmountField";

class ShowCurrency extends Component {
    static propTypes = {
        capFrequencyList: arrayOf(string).isRequired,
        masterCurrency: string.isRequired,
        form: string.isRequired,
    };

    render() {
        const { masterCurrency, capFrequencyList, form } = this.props;
        return (
            <Field
                clearable={false}
                component={PeriodAmountField}
                data={{
                    currencies: [
                        {
                            label: i18nUtils.get(`currency.label.${masterCurrency}`),
                            value: masterCurrency,
                        },
                    ],
                    frequencyList: capFrequencyList.map((capFrequency) => ({
                        label: i18nUtils.get(`administration.signatures.transactions.capFrequency.${capFrequency}`),
                        value: capFrequency,
                    })),
                }}
                disableSelect
                idForm={form}
                name="topAmount"
                nestedErrorsObject
            />
        );
    }
}

export default ShowCurrency;
