import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { func, arrayOf, objectOf, shape, bool, string } from "prop-types";

import { Field } from "formik";
import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Selector from "pages/_components/fields/formik/Selector";

const FORM_ID = "administration.advanced.signature.modify";

class SignatureConfirm extends Component {
    static propTypes = {
        user: shape({ firstName: string, lastName: string }).isRequired,
        routerActions: shape({
            goBack: func,
        }),
        idActivity: string,
        idTransaction: string,
        schemeName: string.isRequired,
        signatureLevels: arrayOf(shape({ id: string, label: string })),
        signatureLevel: string,
        actions: objectOf(func).isRequired,
        formActions: objectOf(func).isRequired,
        hasSignatureEnabled: bool,
        match: shape({
            params: shape({ id: string.isRequired }),
        }).isRequired,
        fetching: bool,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
    };

    static defaultProps = {
        routerActions: null,
        idActivity: null,
        idTransaction: null,
        fetching: false,
        signatureLevel: "",
        signatureLevels: [],
        hasSignatureEnabled: false,
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    renderFormContent = () => {
        const { signatureLevels, signatureLevel, idTransaction, schemeName } = this.props;

        if (schemeName === "advanced") {
            if (!idTransaction) {
                return (
                    <Field
                        component={Selector}
                        options={signatureLevels}
                        idForm={FORM_ID}
                        name="signatureLevel"
                        value={signatureLevel || "N"}
                        renderAs="radio"
                        inLineControl
                    />
                );
            }
            if (signatureLevel) {
                return <I18n id="administration.signature.ticket.configure" SIGNATURE_LEVEL={signatureLevel} />;
            }
            return <I18n id="administration.signature.ticket.change.true" />;
        }
        return null;
    };

    renderForm = () => (
        <Container className="container--layout items-center" gridClassName="form-content container--flex-middle">
            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                {this.renderFormContent()}
            </Col>
        </Container>
    );

    formatSubmitSignatureValue = (signatureLevel) => {
        const { hasSignatureEnabled, schemeName } = this.props;
        if (schemeName === "medium") {
            return hasSignatureEnabled ? null : "A";
        }
        return signatureLevel === "N" ? null : signatureLevel;
    };

    handleSubmit = (data, formikBag) => {
        const { actions, formActions, match, idTransaction, idActivity } = this.props;
        const { signatureLevel, ...credentials } = data;

        if (!idTransaction) {
            actions.updateSignatureRequest(
                {
                    credentials,
                    idUser: match.params.id,
                    signatureLevel: this.formatSubmitSignatureValue(signatureLevel),
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const { fetching, signatureLevel, schemeName, user, credentialGroups, hasSignatureEnabled } = this.props;

        return (
            <Fragment>
                <Head
                    title="administration.signature.configureSignature"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />

                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <div className="above-the-fold">
                        <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <p className="text-lead">
                                    <I18n id="administration.forms.confirm.credentials" />
                                </p>
                                <div className="transfer-block">
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="administration.signature.user"
                                        data={`${user.firstName} ${user.lastName}`}
                                    />
                                    {schemeName === "medium" && (
                                        <div className="transfer-data">
                                            <Container.ColumnBody columnClass="list-wrapper">
                                                <I18n id={`administration.signature.change.${hasSignatureEnabled}`} />
                                            </Container.ColumnBody>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Container>

                        <AdministrationFormConfirmation
                            otherFieldFunction={this.renderForm}
                            otherInitialValues={{ signatureLevel: signatureLevel || "N" }}
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default SignatureConfirm;
