import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import * as statusIcons from "pages/forms/customForms/_pdf/Icons";
import LogoBanesco from "pages/forms/customForms/_pdf/LogoBanesco";
import LogoBanescoBackground from "pages/forms/customForms/_pdf/LogoBanescoBackground";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import FeatureFlag from "pages/_components/FeatureFlag";
import { arrayOf, bool, func, instanceOf, shape, string } from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { store } from "store";
import { calculateShortLabel } from "util/account";
import * as config from "util/config";
import { isMobileNative } from "util/device";
import { formatNumber } from "util/format";
import * as utils from "util/general";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import { getTransactionKind } from "util/transaction";

const getPath = (font) => {
    let fontPath = require(`styles/fonts/themeFonts/${font}`);
    fontPath = fontPath.replace(/^\//, "");

    if (!isMobileNative) {
        fontPath = `../../${fontPath}`;
    }
    return fontPath;
};

Font.register({
    family: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fonts: [
        {
            src: getPath("Lato-Regular.ttf"),
            fontWeight: "normal",
        },
        {
            src: getPath("Lato-Bold.ttf"),
            fontWeight: "bold",
        },
        {
            src: getPath("Lato-Light.ttf"),
            fontWeight: "light",
        },
    ],
});

export const styles = StyleSheet.create({
    header: {
        marginBottom: "12pt",
        backgroundColor: "red",
    },

    logo: {
        width: "150pt",
        margin: "auto 0pt 0pt 20pt",
        position: "absolute",
        bottom: "20pt",
        left: "0",
    },
    page: {
        flexDirection: "column",
        fontSize: "13pt",
        color: "#697887",
        width: "50",
        fontFamily: "Lato",
    },
    sectionTitle: {
        margin: "20pt 0 10pt 20pt",
        fontSize: "16pt",
    },
    title: {
        fontSize: "18pt",
        margin: "20pt",
    },
    value: {
        display: "inline",
        marginBottom: "6pt",
        textAlign: "left",
        fontWeight: "normal",
        fontSize: "16pt",
        color: "#697887",
    },
    valueSignature: {
        display: "inline",
        marginBottom: "2pt",
        textAlign: "left",
        fontWeight: "normal",
        fontSize: "12pt",
        color: "#697887",
    },
    stickerWrapper: {
        display: "flex",
        justifyContent: "center",
        padding: "2pt 8pt",
        backgroundColor: "#EBF0F5",
        marginBottom: "4pt",
        borderRadius: "4pt",
        marginLeft: "4pt",
        minWidth: "44pt",
    },
    sticker: {
        textAlign: "center",
        fontWeight: "normal",
        margin: "0 auto",
        fontSize: "16pt",
    },
    scaleHandler: {
        transform: "scale(0.75)",
    },
    ticketWrapper: {
        width: "424pt",
        margin: "0 auto",
        overflow: "hidden",
        paddingBottom: "64pt",
        position: "relative",
        minHeight: "519pt",
        backgroundColor: "white",
        borderRadius: "15pt",
        border: "1pt solid #DCE1E9",
    },
    headerWrapper: {
        width: "100%",
        borderTopLeftRadius: "14pt",
        borderTopRightRadius: "14pt",
        overflow: "hidden",
    },
    amountWrapper: {
        flexDirection: "column",
        paddingBottom: "16pt",
        marginBottom: "40pt",
        paddingTop: "16pt",
        marginTop: "-1pt",
        zIndex: "100",
    },
    amountLabel: {
        textAlign: "center",
        fontSize: "16pt",
    },
    amountValue: {
        color: "#556176",
        textAlign: "center",
        fontSize: "28pt",
        fontWeight: "bold",
    },
    status: {
        display: "flex",
        padding: "24pt 20pt 16pt 20pt",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        zIndex: "100",
    },
    statusIcon: {
        width: "40pt",
    },
    statusText: {
        marginLeft: "16pt",
        fontSize: "18pt",
        fontWeight: "bold",
        textAlign: "left",
        color: "#556176",
    },
    barckgroundWally: {
        backgroundColor: "#553CB9",
    },
    barckgroundPrimary: {
        backgroundColor: "#2E846B",
    },
    barckgroundError: {
        backgroundColor: "#FCE9E7",
    },
    textWhite: {
        color: "white",
    },
    data: {
        display: "block",
        padding: "0 24pt 18pt",
    },
    dataSignature: {
        display: "flex",
        padding: "0 24pt 2pt",
        marginBottom: "4pt",
    },
    wrapperSignature: {
        marginBottom: "24pt",
    },
    dataFirst: {
        width: "100%",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: "48pt 24pt 12pt",
    },
    label: {
        display: "inline",
        fontSize: "16pt",
        textAlign: "left",
        fontWeight: "bold",
        color: "#556176",
        marginRight: "5pt",
    },
    subtitle: {
        display: "inline",
        fontSize: "16pt",
        textAlign: "left",
        fontWeight: "bold",
        color: "#2E846B",
        marginRight: "5pt",
    },
    labelSignature: {
        display: "inline",
        fontSize: "12pt",
        textAlign: "left",
        fontWeight: "bold",
        color: "#556176",
        marginRight: "5pt",
    },
    labelHeader: {
        fontSize: "18pt",
        textAlign: "left",
        fontWeight: "900",
        color: "#556176",
    },
    product: {
        fontSize: "16pt",
        textAlign: "left",
        fontWeight: "normal",
        color: "#556176",
    },
    logoBackground: {
        width: "213pt",
        margin: "20pt 0pt 0pt 20pt",
        position: "absolute",
        bottom: "-20pt",
        right: "0",
        zIndex: "1",
    },
    marginTop18pt: {
        marginTop: "18pt",
    },
});

export const PDFTicket = ({ transaction, values, renderTicket, wally, usesJointAccount }) => {
    const { idTransactionStatus } = transaction;
    const idStatus = utils.statusMap.get(idTransactionStatus) ?? idTransactionStatus;

    return (
        <Provider store={store}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.scaleHandler}>
                        <View style={styles.ticketWrapper}>
                            {idStatus === "FINISHED" ? (
                                <PDFStatus transaction={transaction} wally={wally} />
                            ) : (
                                <PDFStatusError transaction={transaction} />
                            )}

                            {renderTicket(values)}
                            <PDFLogo />
                            <PDFLogoBackground />
                            {/* <PDFTitle title={i18n.get(title)} /> */}
                            {/* <PDFHeader transaction={transaction} /> */}
                            {/* <PDFSectionTitle title={i18n.get("forms.transaction.ticket.title")} /> */}
                            {usesJointAccount && <PDFSignatures transaction={transaction} />}
                        </View>
                    </View>
                </Page>
            </Document>
        </Provider>
    );
};

PDFTicket.propTypes = {
    transaction: shape({}).isRequired,
    values: shape({}).isRequired,
    renderTicket: func.isRequired,
    wally: bool,
    usesJointAccount: bool,
};

PDFTicket.defaultProps = {
    wally: false,
    usesJointAccount: false,
};

const capitalizeFirstLetter = (value) => value.charAt(0).toUpperCase() + value.slice(1);

// TICKET SUCCESS
export const PDFStatus = ({ transaction, wally }) => {
    const svg = utils.getTransactionStatusIcon(transaction.idTransactionStatus);
    const StatusIcon = statusIcons[`${capitalizeFirstLetter(svg)}`];
    let titleKind = getTransactionKind(transaction.idActivity);
    const idStatus = utils.statusMap.get(transaction.idTransactionStatus) ?? transaction.idTransactionStatus;
    if (transaction?.idActivity === "redeem.miles.connect.send" && transaction?.data?.miles === "Lifemiles") {
        titleKind = getTransactionKind(`${transaction?.idActivity}.lifemiles`);
    }
    return (
        <View style={styles.headerWrapper}>
            <View style={[styles.status, wally ? styles.barckgroundWally : styles.barckgroundPrimary]}>
                <View style={styles.statusIcon}>
                    <StatusIcon />
                </View>
                <Text style={[styles.statusText, styles.textWhite]}>
                    {i18n.get(`forms.transaction.ticket.status.${idStatus}.${titleKind}`)}
                </Text>
            </View>
        </View>
    );
};

PDFStatus.propTypes = {
    transaction: shape({}).isRequired,
    wally: bool,
};

PDFStatus.defaultProps = {
    wally: false,
};

// TICKET WITH ERROR
export const PDFStatusError = ({ transaction }) => {
    const svg = utils.getTransactionStatusIcon(transaction.idTransactionStatus);
    const StatusIcon = statusIcons[`${capitalizeFirstLetter(svg)}`];
    const titleKind = getTransactionKind(transaction.idActivity);

    return (
        <View style={styles.headerWrapper}>
            <View style={[styles.status, styles.barckgroundError]}>
                <View style={styles.statusIcon}>
                    <StatusIcon />
                </View>
                <Text style={[styles.statusText]}>
                    {i18n.get(`forms.transaction.ticket.status.${transaction.idTransactionStatus}.${titleKind}`)}
                </Text>
            </View>
        </View>
    );
};

PDFStatusError.propTypes = {
    transaction: shape({}).isRequired,
};

export const PDFLogo = () => (
    <View style={styles.logo}>
        <LogoBanesco />
    </View>
);

export const PDFLogoBackground = () => (
    <View style={styles.logoBackground}>
        <LogoBanescoBackground />
    </View>
);

export const PDFTitle = ({ title }) => (
    <View style={styles.title}>
        <Text>{title}</Text>
    </View>
);

PDFTitle.propTypes = {
    title: string.isRequired,
};

export const PDFSectionTitle = ({ title }) => (
    <View style={styles.sectionTitle}>
        <Text>{title}</Text>
    </View>
);

PDFSectionTitle.propTypes = {
    title: string.isRequired,
};

export const PDFHeader = ({ transaction }) => {
    const selectedOption = transaction.data.scheduler ? transaction.data.scheduler.selectedOption : null;
    const scheduled = selectedOption ? selectedOption !== schedulerUtils.TODAY : false;

    return (
        <View style={styles.header}>
            <View style={styles.data}>
                <Text style={styles.label}>{i18n.get("forms.transaction.ticket.date")}:</Text>
                <Text style={styles.value}>{transaction.submitDateTimeAsString}</Text>
            </View>
            <View style={styles.data}>
                <Text style={styles.label}>{i18n.get("forms.transaction.ticket.number")}:</Text>
                <Text style={styles.value}>{transaction.idTransaction}</Text>
            </View>
            {scheduled && (
                <View style={styles.data}>
                    <Text style={styles.label}>{i18n.get("forms.confirmation.scheduler")}:</Text>
                    <Text style={styles.value}>
                        <Message nohtml value={transaction.data.scheduler} />
                    </Text>
                </View>
            )}
        </View>
    );
};

PDFHeader.propTypes = {
    transaction: shape({}).isRequired,
};

export const PDFSignatures = ({ transaction }) => (
    <View style={styles.wrapperSignature}>
        <View style={styles.dataSignature}>
            <Text style={styles.subtitle}>{i18n.get("forms.transaction.ticket.title")}</Text>
        </View>
        <PDFSignatureLine
            label={i18n.get("forms.transaction.ticket.createdBy")}
            firstName={transaction.userCreatorFirstName}
            lastName={transaction.userCreatorLastName}
            date={transaction.creationDateTime}
            fullName={transaction.fullName}
            signatureLevel={transaction.signatureLevel}
        />

        {transaction.creationDateTime !== transaction.modificationDateTime && (
            <PDFSignatureLine
                label={i18n.get("forms.transaction.ticket.modifiedBy")}
                firstName={transaction.userEditorFirstName}
                lastName={transaction.userEditorLastName}
                date={transaction.modificationDateTime}
                signatureLevel={transaction.signatureLevel}
            />
        )}

        {transaction.signatures?.map((signature) => (
            <PDFSignatureLine
                label={i18n.get("forms.transaction.ticket.authorizedBy")}
                date={signature.creationDateTime}
                fullName={signature.userFullName}
                signatureLevel={signature.signatureLevel}
            />
        ))}

        <FeatureFlag id="feature.signatureSchema.dispatchControl">
            {transaction.dispatcher && (
                <PDFSignatureLine
                    label={i18n.get("forms.transaction.ticket.dispatchedBy")}
                    firstName={transaction.dispatcher.split[" "][0]}
                    lastName={transaction.dispatcher.split[" "][1]}
                />
            )}
        </FeatureFlag>
    </View>
);

PDFSignatures.propTypes = {
    transaction: shape({}).isRequired,
};

export const PDFSignatureLine = ({ label, firstName, lastName, date, fullName, signatureLevel }) => (
    <View style={styles.dataSignature}>
        <Text style={styles.labelSignature}>
            {label}:{" "}
            <Text style={styles.valueSignature}>
                {fullName || `${lastName}, ${firstName}`}
                {signatureLevel && ` (${signatureLevel})`}
                {date && <>- {format(date.replace("Z", ""), i18n.get("datepicker.format"))}</>}
            </Text>
        </Text>
    </View>
);

PDFSignatureLine.propTypes = {
    label: string.isRequired,
    firstName: string,
    lastName: string,
    fullName: string,
    signatureLevel: string,
    date: instanceOf(Date),
};

PDFSignatureLine.defaultProps = {
    firstName: null,
    lastName: null,
    fullName: null,
    signatureLevel: null,
    date: null,
};

export const PDFText = ({ labelKey, text, params }) => (
    <View style={styles.data}>
        <Text style={styles.label}>{text || i18n.get(labelKey, null, params)}:</Text>
    </View>
);

PDFText.propTypes = {
    labelKey: string,
    text: string,
    params: shape({}),
};

PDFText.defaultProps = {
    labelKey: null,
    text: null,
    params: null,
};

export const PDFTextField = ({ idForm, name, values, value, label }) => (
    <View style={styles.data}>
        <Text style={styles.label}>
            {`${label || i18n.get(`forms.${idForm}.${name}.label`)}: `}
            <Text style={styles.value}>{value || values?.[name]}</Text>
        </Text>
    </View>
);

PDFTextField.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
    label: string,
};

PDFTextField.defaultProps = {
    idForm: null,
    name: null,
    value: null,
    values: null,
    label: null,
};

export const PDFTextFieldFirst = ({ idForm, name, values, value, label }) => (
    <View style={styles.dataFirst}>
        <Text style={styles.label}>{label || i18n.get(`forms.${idForm}.${name}.label`)}:</Text>
        <Text style={styles.value}>{value || values?.[name]}</Text>
    </View>
);

PDFTextFieldFirst.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
    label: string,
};

PDFTextFieldFirst.defaultProps = {
    idForm: null,
    name: null,
    value: null,
    values: null,
    label: null,
};

export const PDFStickerList = ({ idForm, name, label, list }) => (
    <View style={styles.data}>
        <Text style={styles.label}>{label || i18n.get(`forms.${idForm}.${name}.label`)}:</Text>
        {list.map((item) => (
            <View style={styles.stickerWrapper}>
                <Text style={styles.sticker}>{item}</Text>
            </View>
        ))}
    </View>
);

PDFStickerList.propTypes = {
    idForm: string,
    name: string,
    list: shape({}),
    label: string,
};

PDFStickerList.defaultProps = {
    idForm: null,
    name: null,
    list: null,
    label: null,
};

export const PDFSelectorField = ({ idForm, name, options, values, value, label, optionLabelId, optionValueId }) => {
    let v = value;
    if (!v) {
        v = values[name];
    }

    const valueLabel = options?.find((option) => option[optionValueId] === v?.[0])?.[optionLabelId] || "";

    return (
        <View style={styles.data}>
            <Text style={styles.label}>{label || i18n.get(`forms.${idForm}.${name}.label`)}:</Text>
            <Text style={styles.value}>{valueLabel}</Text>
        </View>
    );
};

PDFSelectorField.propTypes = {
    idForm: string,
    name: string,
    options: arrayOf(shape({})),
    values: shape({}),
    value: arrayOf(string),
    label: string,
    optionLabelId: string,
    optionValueId: string,
};

PDFSelectorField.defaultProps = {
    idForm: null,
    name: null,
    options: null,
    values: null,
    value: null,
    label: null,
    optionLabelId: "label",
    optionValueId: "id",
};

export const PDFProductSelectorField = ({ idForm, name, value, values, label }) => {
    let v = value;
    if (!v) {
        v = values[name];
    }
    const data = values[`${name}Data`];
    const valueLabel = data ? calculateShortLabel(data.productType, data.number, data.otherLabel) : "";

    return (
        <PDFTextField idForm={idForm} value={valueLabel} label={label || i18n.get(`forms.${idForm}.${name}.label`)} />
    );
};

PDFProductSelectorField.propTypes = {
    idForm: string,
    name: string.isRequired,
    values: shape({}),
    value: string,
    label: string,
};

PDFProductSelectorField.defaultProps = {
    idForm: null,
    values: null,
    value: null,
    label: null,
};

export const getNumberRender = (value) => {
    const maximumDecimals = config.getInteger("defaultDecimal.maximum") || 0;
    const minimumDecimals = config.getInteger("defaultDecimal.minimum") || 0;
    return formatNumber(value?.quantity || value?.amount?.quantity, maximumDecimals, minimumDecimals);
};

// HighLighted
// eslint-disable-next-line no-confusing-arrow
export const PDFhighlightedField = ({ value, label, wally, idTransactionStatus }) =>
    idTransactionStatus === "FINISHED" ? (
        <View style={[styles.amountWrapper, wally ? styles.barckgroundWally : styles.barckgroundPrimary]}>
            <Text style={[styles.amountLabel, styles.textWhite]}>{label}</Text>
            <Text style={[styles.amountValue, styles.textWhite]}>{value}</Text>
        </View>
    ) : (
        <View style={[styles.amountWrapper]}>
            <Text style={[styles.amountLabel]}>{label}</Text>
            <Text style={[styles.amountValue]}>{value}</Text>
        </View>
    );

PDFhighlightedField.propTypes = {
    value: string,
    label: string,
    wally: bool,
    idTransactionStatus: string,
};

PDFhighlightedField.defaultProps = {
    value: null,
    label: null,
    wally: false,
    idTransactionStatus: null,
};

// TICKET SUCCESS
export const PDFAmountField = ({ idForm, name, values, value, label }) => (
    <View style={[styles.amountWrapper, styles.barckgroundPrimary]}>
        <Text style={[styles.amountLabel, styles.textWhite]}>{label || i18n.get(`forms.${idForm}.${name}.label`)}</Text>
        <Text style={[styles.amountValue, styles.textWhite]}>
            {value || `${i18n.get(`currency.label.${values[name]?.currency}`)} ${getNumberRender(values[name])}`}
        </Text>
    </View>
);

PDFAmountField.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
    label: string,
};

PDFAmountField.defaultProps = {
    idForm: null,
    name: null,
    values: null,
    value: null,
    label: null,
};

// TICKET WITH ERROR
export const PDFAmountFieldError = ({ idForm, name, values, value, label, showCurrency = true }) => (
    <View style={styles.amountWrapper}>
        <Text style={styles.amountLabel}>{label || i18n.get(`forms.${idForm}.${name}.label`)}:</Text>
        {showCurrency ? (
            <Text style={styles.amountValue}>
                {value || `${i18n.get(`currency.label.${values[name].currency}`)} ${getNumberRender(values[name])}`}
            </Text>
        ) : (
            <Text style={styles.amountValue}>{value || `${values[name]}`}</Text>
        )}
    </View>
);

PDFAmountFieldError.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
    label: string,
    showCurrency: bool,
};

PDFAmountFieldError.defaultProps = {
    idForm: null,
    name: null,
    values: null,
    value: null,
    label: null,
    showCurrency: true,
};

export const PDFBeneficiaryField = ({ idForm, name, values, value }) => {
    let v = value;
    if (!v) {
        v = values[name];
    }

    return (
        <View>
            {Boolean(v?.bank?.label) && (
                <PDFTextField
                    idForm={idForm}
                    value={v.bank.label}
                    label={i18n.get(`forms.${name}.bankSelector.label`)}
                />
            )}
            {Boolean(v?.productType?.label) && (
                <PDFTextField
                    idForm={idForm}
                    value={v.productType.label}
                    label={i18n.get(`forms.${name}.productType.label`)}
                />
            )}
            {Boolean(v?.number) && (
                <PDFTextField idForm={idForm} value={v.number} label={i18n.get(`forms.${name}.number.label`)} />
            )}
            {Boolean(v?.name) && (
                <PDFTextField idForm={idForm} value={v.name} label={i18n.get(`forms.${name}.name.label`)} />
            )}
            {Boolean(v?.email) && (
                <PDFTextField idForm={idForm} value={v.email} label={i18n.get(`forms.${name}.email.label`)} />
            )}
        </View>
    );
};

PDFBeneficiaryField.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
};

PDFBeneficiaryField.defaultProps = {
    idForm: null,
    name: null,
    values: null,
    value: null,
};

export const PDFHeaderField = ({ label }) => {
    if (!label) {
        return <div />;
    }
    return (
        <View style={styles.data}>
            <Text style={styles.labelHeader}>{i18n.get(label)}</Text>
        </View>
    );
};
PDFHeaderField.propTypes = {
    label: string.isRequired,
};
