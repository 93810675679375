/* eslint-disable no-confusing-arrow */
import AccessBarBtn from "pages/_components/AccessBarBtn";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { arrayOf, bool, number, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { selectors as wallySelectors } from "reducers/wally";
import { getUrlMarket } from "util/settings";

const AccessBar = ({ options, defaultAccesBar, notificationCount, showNotification, kuara, wally, isLocal }) =>
    defaultAccesBar ? (
        <Box className="access-bar-wrapper" component="nav" display="flex" alignX="between" alignY="center">
            <Box className="access-bar" display="flex">
                {options.map((option, index) =>
                    index === 2 ? (
                        <>
                            <Box display="flex">
                                {isLocal ? (
                                    <Button
                                        ariaLabel={kuara.label}
                                        className={`kuara-btn my-auto  ${kuara.className}`}
                                        href={kuara.href}
                                        image={kuara.image}
                                        onClick={kuara.onClick}
                                        imageMd>
                                        {showNotification && notificationCount > 0 && (
                                            <Badge count={notificationCount} circular />
                                        )}
                                    </Button>
                                ) : (
                                    <Button
                                        ariaLabel={wally.label}
                                        className={`wally-btn my-auto  ${wally.className}`}
                                        href={wally.href}
                                        image={wally.image}
                                        onClick={wally.onClick}
                                        imageMd>
                                        {showNotification && notificationCount > 0 && (
                                            <Badge count={notificationCount} circular />
                                        )}
                                        <span className="btn-link-span">{wally.label}</span>
                                    </Button>
                                )}
                            </Box>
                            <AccessBarBtn
                                key={`${option.label}_${option.image}`}
                                image={option.image}
                                label={option.label}
                                className={option.className}
                                href={option.href}
                                externalHref={option.externalHref}
                                onClick={option.onClick}
                            />
                        </>
                    ) : (
                        <AccessBarBtn
                            key={`${option.label}_${option.image}`}
                            image={option.image}
                            label={option.label}
                            className={option.className}
                            href={option.href}
                            externalHref={option.externalHref}
                            onClick={option.onClick}
                        />
                    ),
                )}
            </Box>
        </Box>
    ) : (
        <Box
            className="access-bar-wrapper access-bar-wrapper-blue"
            component="article"
            display="flex"
            alignX="between"
            alignY="center">
            <Box display="flex" column className="pr-5">
                <Text labelKey="login.access.app.title" size="6" color="inverse" className="pb-2" />
            </Box>
            <Button
                externalHref={getUrlMarket()}
                label="login.access.app.btn"
                bsStyle="outline"
                className="access-bar-app-btn"
                inverse
            />
        </Box>
    );

AccessBar.propTypes = {
    options: arrayOf(
        shape({
            image: string,
            href: string,
            label: string,
            className: string,
        }),
    ).isRequired,
    kuara: shape({
        image: string,
        href: string,
        label: string,
        className: string,
    }).isRequired,
    wally: shape({
        image: string,
        href: string,
        label: string,
        className: string,
    }).isRequired,
    defaultAccesBar: bool,
    notificationCount: number,
    showNotification: bool,
    isLocal: bool,
};

AccessBar.defaultProps = {
    defaultAccesBar: true,
    notificationCount: 0,
    showNotification: false,
    isLocal: false,
};

const mapStateToProps = (state) => ({
    notificationCount: wallySelectors.getPendingTransactionsWallyQuantity(state),
});

export default connect(mapStateToProps)(AccessBar);
