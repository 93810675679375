import React from "react";
import { string } from "prop-types";

import Image from "pages/_components/Image";

function EnvironmentTag({ type, name }) {
    return (
        <div className="environment-tag">
            {type && <Image className="svg-icon svg-caret" src={`images/icons/enviroment-${type}.svg`} />}
            <span className="visually-hidden"> {`${type}, ${name}`} </span>
            <span aria-hidden>{name}</span>
        </div>
    );
}

EnvironmentTag.propTypes = {
    name: string.isRequired,
    type: string.isRequired,
};

export default EnvironmentTag;
