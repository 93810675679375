import { Field, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import MainContainer from "pages/_components/MainContainer";
import InfoModal from "pages/_components/modal/InfoModal";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Scroll from "pages/_components/Scroll";
import Text from "pages/_components/Text";
import { arrayOf, bool, date, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Measure from "react-measure";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions, selectors } from "reducers/frequentDestination/frequentDestination";
import { actions as modalActions, selectors as modalselectors } from "reducers/modal";
import { compose } from "redux";
import { ReactComponent as Show } from "styles/images/search.svg";
import * as i18n from "util/i18n";

const FORM_ID = "frequentDestination.list";

class FrequentDestination extends Component {
    state = {
        top: 0,
        dimensions: null,
        filterText: null,
    };

    componentDidMount() {
        const { dispatch, typeFilter } = this.props;

        dispatch(actions.loadListRequest({ fieldType: typeFilter }));
    }

    setTop = (value) => {
        this.setState({ top: value });
    };

    setFilterText = (value) => {
        this.setState({ filterText: value });
    };

    renderPageHeader = () => (
        <>
            <Notification scopeToShow="frequentDestination" />
            <Head
                title="frequentDestination.frequentDestinations.list.title"
                addLinkToLabel="frequentDestination.new"
                addLinkTo="/createFrequentDestination"
            />
        </>
    );

    populateUsersData = () => {
        const { frequentDestinations, isModal, typeFilter } = this.props;

        if (!isModal) {
            return frequentDestinations.map((freqDest) => ({
                ...freqDest,
                key: freqDest.idFrequentDestination,
                freqDestName: freqDest.name,
                freqDestProductType: i18n.get(`frequentDestination.create.select.${freqDest.productType}`),
                freqDestNumber: this.setNumberByProductType(freqDest),
            }));
        }
        return frequentDestinations
            .filter((freqDest) => freqDest.productType === typeFilter)
            .map((freqDest) => ({
                ...freqDest,
                key: freqDest.idFrequentDestination,
                freqDestName: freqDest.name,
                freqDestProductType: i18n.get(`frequentDestination.create.select.${freqDest.productType}`),
                freqDestNumber: this.setNumberByProductType(freqDest),
            }));
    };

    setNumberByProductType = (freqDest) => {
        switch (freqDest.productType) {
            case "account":
            case "localAccount":
            case "foreignAccount":
                return freqDest.accountNumber;
            case "creditcard":
                return freqDest.creditCardNumber;
            case "loan":
                return freqDest.loanNumber;
            default:
                return "";
        }
    };

    revealFunction = () => (
        <Button className="btn-only-icon" bsStyle="link" block={false} onClick={this.onSearch}>
            <Show className="svg-image" />
        </Button>
    );

    onSearch = (event) => {
        let filterText = null;
        const { dispatch, values, typeFilter } = this.props;
        if (event) {
            if (event.type === "click") {
                const { filter } = values;
                filterText = filter;
            } else if (event.key === "Enter") {
                filterText = event.target.value;
            }
        }
        if (filterText != null) {
            this.setFilterText(filterText);
            dispatch(
                actions.loadListRequest({
                    fieldType: typeFilter,
                    pageNumber: 1,
                    filterText,
                }),
            );
        }
    };

    renderFilter = (formId = FORM_ID) => {
        const field = (
            <Field
                idForm={formId}
                component={TextField}
                name="filter"
                type="text"
                onKeyDown={this.onSearch}
                inputFunctions={this.revealFunction()}
                hideLabel
                noLabel
            />
        );
        return field;
    };

    onRowClick = (record) => {
        const { dispatch, isModal, onModalRowClick } = this.props;
        if (isModal) {
            const frequentDestionationOnRow = record;
            dispatch(modalActions.modalClose());
            onModalRowClick(frequentDestionationOnRow);
        } else {
            dispatch(actions.createDetail(record));
            dispatch(push(`/detailModifyDeleteFrequentDestination`));
        }
    };

    fetchMoreMovements = () => {
        const { filterText } = this.state;
        const { dispatch, typeFilter } = this.props;
        let { pageNumber } = this.props;

        pageNumber += 1;
        const filters = { pageNumber, fieldType: typeFilter, filterText };
        dispatch(actions.loadMoreRequest(filters));
    };

    handleClick = () => {
        this.fetchMoreMovements();
    };

    handleClickModalButtonAccept = () => {
        const { dispatch } = this.props;
        dispatch(modalActions.modalShow());
    };

    renderLoadMore = () => {
        const { fetching, hasMoreData, frequentDestinations } = this.props;
        const isEmpty = frequentDestinations.length === 0;
        if (!hasMoreData) {
            return (
                !isEmpty && (
                    <div className="text-center no-more-data">
                        <p>
                            <I18n id="frequentDestination.noMoreMovements" />
                        </p>
                    </div>
                )
            );
        }
        return (
            <div className="text-center no-more-data flex">
                <Button
                    bsStyle="link"
                    onClick={this.handleClick}
                    image="images/show.svg"
                    loading={fetching}
                    label="frequentDestination.searchFrequentDestination"
                    className="btn-small"
                />
            </div>
        );
    };

    createTable = () => {
        const { isDesktop, hasMoreData } = this.props;
        const rows = isDesktop ? 1 : 1;
        const { dimensions, top } = this.state;

        const endOfListItem = (
            <div className="table-row table-end" key="noMoreTransactions">
                <I18n id="frequentDestination.list.noRecords" />
            </div>
        );

        const columnsTemplate = "1fr 1fr auto";

        const list = this.populateUsersData().map((fq) => (
            <GridTable.Container
                columns={3}
                columnsTemplate={columnsTemplate}
                rows={rows}
                key={fq.key}
                onClick={() => this.onRowClick(fq)}
                renderAs="a">
                <GridTable.Data
                    columnStart={1}
                    alignX={isDesktop ? "center" : "flex-start"}
                    alignY={isDesktop ? "center" : "flex-start"}>
                    <Box display="flex" column alignX="flex-start" fullWidth>
                        <Box display="flex" alignX="flex-start">
                            <Text color="secondary" align="left" size="6" bold ellipsisNoFlex>
                                {fq.freqDestName}
                            </Text>
                            <Image className="ml-2" src="images/icons/favoriteStarOn.svg" />
                        </Box>
                        <Box display="flex">
                            <Text color="text" size="6" align="left" ellipsis>
                                A terceros otros bancos
                            </Text>
                        </Box>
                    </Box>
                </GridTable.Data>

                <GridTable.Data columnStart={2} alignX="flex-end" alignY="center" flexDirection="row">
                    <Box display="flex" column fullWidth>
                        <Text className="mr-2" color="text" size="6" ellipsis>
                            {fq.productType}
                        </Text>
                        <Text className="mr-2" color="text" size="6" ellipsis>
                            {fq.freqDestNumber}
                        </Text>
                        <Text className="mr-2" color="text" size="6" ellipsis>
                            {fq.freqDestProductType}
                        </Text>
                    </Box>
                </GridTable.Data>
                <GridTable.Data columnStart={3} alignY="center">
                    <ChevromRight />
                </GridTable.Data>
            </GridTable.Container>
        ));

        if (!isDesktop) {
            return (
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        if (!dimensions) {
                            this.setState({ dimensions: contentRect.bounds });
                        }
                    }}>
                    {({ measureRef }) => (
                        <div
                            className="containerDetails container-fluid"
                            style={{
                                flexGrow: 1,
                                overflow: "auto",
                                transform: `translate3d(0, ${top}px, 0)`,
                                transition: "transform .3s ease",
                            }}
                            ref={measureRef}>
                            <GridTable>
                                <GridTable.Body>
                                    {dimensions && (
                                        <Scroll
                                            containerBounds={dimensions}
                                            setTop={this.setTop}
                                            endOfListItem={endOfListItem}
                                            fetchMoreData={this.fetchMoreMovements}
                                            lastPage={!hasMoreData}
                                            isInfiniteScroll
                                            items={list}
                                            removeListenersWhenPulled
                                        />
                                    )}
                                </GridTable.Body>
                            </GridTable>
                        </div>
                    )}
                </Measure>
            );
        }

        return (
            <GridTable>
                {/* ESTO SE ESTÁ DESARROLLANDO PROBABLEMENTE NO VAYA */}
                {/* {isDesktop && (
                    <GridTable.Header>
                        <GridTable.Container columns={3} columnsTemplate={columnsTemplate}>
                            <GridTable.Data columnStart={1}>{i18n.get("frequentDestination.list.name")}</GridTable.Data>
                            <GridTable.Data columnStart={2}>{i18n.get("frequentDestination.list.type")}</GridTable.Data>
                            <GridTable.Data columnStart={3}>
                                {i18n.get("frequentDestination.list.number")}
                            </GridTable.Data>
                        </GridTable.Container>
                    </GridTable.Header>
                )} */}
                {/* ESTO SE ESTÁ DESARROLLANDO PROBABLEMENTE NO VAYA */}

                <GridTable.Body>{list}</GridTable.Body>
            </GridTable>
        );
    };

    createBodyPage = () => {
        const { fetching, frequentDestinations } = this.props;

        return (
            <>
                {this.renderPageHeader()}
                <MainContainer className="main-container" showLoader={fetching && !frequentDestinations.length}>
                    <div className="above-the-fold">
                        <Row className="justify-content-center">
                            <Col className="col-12">
                                <h4>{i18n.get("frequentDestination.list.index.tittle")}</h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={6} lg={4} className="col-12">
                                {this.renderFilter(FORM_ID)}
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col className="col-12">{this.createTable()}</Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col className="col-12">{this.renderLoadMore()}</Col>
                        </Row>
                    </div>
                </MainContainer>
            </>
        );
    };

    render() {
        const { isModal, displayModal, fetching, frequentDestinations } = this.props;
        if (isModal) {
            return (
                <>
                    {displayModal ? (
                        <InfoModal
                            noAcceptButton
                            bodyInfoText="texto"
                            modalSubtitle="frequentDestination.modal.subtittle.label"
                            useScroll
                            body={this.createTable()}
                            preBody={this.renderFilter()}
                            postBody={this.renderLoadMore()}
                            cancelLabel="frequentDestination.modal.button.cancel.label"
                            isFetching={fetching}
                            propToForceModalUpdate={frequentDestinations}
                        />
                    ) : (
                        <Button
                            label="frequentDestination.searchFrequentDestination"
                            onClick={this.handleClickModalButtonAccept}
                            image="images/icons/directory.svg"
                            bsStyle="link"
                        />
                    )}
                </>
            );
        }
        return this.createBodyPage();
    }
}

const mapStateToProps = (state) => ({
    frequentDestinations: selectors.getFrequentDestinations(state),
    pageNumber: selectors.getPageNumber(state),
    fetching: selectors.isFetching(state),
    hasMoreData: selectors.getHasMoreData(state),
    displayModal: modalselectors.getDisplayModal(state),
});

FrequentDestination.propTypes = {
    dispatch: func.isRequired,
    frequentDestinations: arrayOf(
        shape({
            name: string,
            productType: string,
            localBank: string,
            exteriorBank: shape({}),
            accountNumber: string,
            recipientName: string,
            recipientDocument: shape({}),
            recipientAddress: string,
            creditCardNumber: string,
            loanNumber: string,
            creationDate: date,
        }),
    ).isRequired,
    pageNumber: number,
    hasMoreData: bool,
    fetching: bool,
    isModal: bool,
    displayModal: bool,
    onModalRowClick: func,
    typeFilter: string,
    values: shape({}),
    isDesktop: bool,
};

FrequentDestination.defaultProps = {
    pageNumber: 1,
    hasMoreData: false,
    fetching: false,
    isModal: false,
    displayModal: false,
    typeFilter: "",
    onModalRowClick: () => {},
    values: {},
    isDesktop: true,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            filter: null,
        }),
    }),
)(FrequentDestination);
