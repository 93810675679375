import * as i18nUtils from "util/i18n";

import React, { Component } from "react";
import { node, shape, func, arrayOf, number } from "prop-types";

import AccountsListItem from "pages/accounts/ListItem";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import WidgetList from "pages/desktop/widgets/WidgetList";
import WithPermissions from "pages/_components/WithPermissions";
import { actions as accountsActions } from "reducers/accounts";
import { selectors as widgetSelectors } from "reducers/widgets";
import { connect } from "react-redux";
import * as config from "util/config";
import WidgetHeader from "./_components/WidgetHeader";

class Accounts extends Component {
    static propTypes = {
        accounts: arrayOf(shape({ length: number })).isRequired,
        closeButton: node,
        dispatch: func.isRequired,
        draggableItemProps: shape({}).isRequired,
    };

    static defaultProps = {
        closeButton: null,
    };

    hasOnlyOneSimplificateAccount = () => {
        const { accounts } = this.props;

        if (accounts.length !== 1) {
            return false;
        }

        let simplicateList = config.get("accounts.subcategory.simplified");
        simplicateList = simplicateList !== null ? simplicateList.split("|") : null;

        if (simplicateList === null) {
            return false;
        }

        let simplificadas = 0;
        accounts.forEach((account) => {
            if (simplicateList.includes(account.subProductType)) {
                simplificadas += 1;
            }
        });

        return simplificadas === 1;
    };

    renderNewAccountButton = () => {
        const { dispatch } = this.props;
        if (this.hasOnlyOneSimplificateAccount()) {
            return (
                <Button
                    image="/images/plusCircle.svg"
                    label="accounts.new"
                    bsStyle="link"
                    className="btn-widget"
                    onClick={() => {
                        dispatch(accountsActions.showModal());
                    }}
                />
            );
        }

        return (
            <Button
                externalHref={config.get("accessBar.requests.url")}
                image="/images/plusCircle.svg"
                label="accounts.new"
                bsStyle="link"
                className="btn-widget"
            />
        );
    };

    render() {
        const { closeButton, draggableItemProps } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetAccounts" };
        const itemsToShowButton = Number(config.get("widgets.product.maxToShow"));

        return (
            <WidgetList item={AccountsListItem} keyExtractor={(item) => item.idProduct} name="accounts">
                {(list, listLenght) => (
                    <>
                        <section
                            className="widget"
                            {...uiAutomationProp}
                            {...draggableItemProps}
                            aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                            <WidgetHeader title={i18nUtils.get("list.addWidget.accounts")} action={closeButton} />
                            {list}
                            <WithPermissions permissions={["accounts"]}>
                                <Box display="flex" alignX="between" fullWidth flex1 className="px-md-3" zIndex="0">
                                    <WithPermissions permissions={["accountOpen"]}>
                                        {this.renderNewAccountButton()}
                                    </WithPermissions>
                                    {listLenght > itemsToShowButton ? (
                                        <Button
                                            className="btn-widget"
                                            href="/accounts"
                                            label="widgets.accounts.myAccounts"
                                            bsStyle="link"
                                        />
                                    ) : null}
                                </Box>
                            </WithPermissions>
                            <div className="overlay" />
                        </section>
                    </>
                )}
            </WidgetList>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: widgetSelectors.getWidgetList(state, "accounts"),
});

export default connect(mapStateToProps)(Accounts);
