import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { Redirect } from "react-router-dom";
import { routerActions } from "react-router-redux";
import { shape, arrayOf, bool, string, func } from "prop-types";

import { actions, selectors } from "reducers/administration/usersInvite";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as formActions } from "reducers/form";
import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class UserInviteStep3 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        confirmationParams: shape({
            docCountry: string,
            docNumber: string,
            docType: string,
            document: string,
            email: string,
            firstName: string,
            lastName: string,
            mobilePhone: string,
            role: string,
            signatureLevel: string,
        }).isRequired,
        activeEnvironment: shape({ administrationScheme: string }).isRequired,
        idTransaction: string,
        idActivity: string,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, confirmationParams, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            dispatch(actions.userInviteConfirm(confirmationParams, credentials, formikBag));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    renderMediumSchemeConfiguration = () => {
        const { confirmationParams } = this.props;
        let signatureKeyLabel = "administration.users.invite.transaction.signature.yes";
        if (confirmationParams.signatureLevel === "N") {
            signatureKeyLabel = "administration.users.invite.transaction.signature.no";
        }
        return (
            <Fragment>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.invite.transaction.signature" />
                    </div>
                    <div className="data-desc">
                        <I18n id={signatureKeyLabel} />
                    </div>
                </div>
                <div className="form-group-text">
                    <label className="control-label">
                        <I18n id="administration.users.invite.roles.label" />
                    </label>
                </div>
                <div>
                    <I18n id={`invitation.permissions.role.${confirmationParams.role}`} />
                </div>
            </Fragment>
        );
    };

    renderAdvancedSchemeConfiguration = () => {
        const {
            confirmationParams: { signatureLevel, groupNames },
        } = this.props;
        const signatureLabel =
            signatureLevel === "N"
                ? i18nUtils.get("administration.users.edit.signatureLevel.dontSign")
                : signatureLevel;
        const groupText = groupNames.map((groupName) => (
            <Fragment key={groupName}>
                <span> {groupName} </span> <br />
            </Fragment>
        ));
        return (
            <Fragment>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.edit.signatureLevel" />
                    </div>
                    <div className="data-desc">{signatureLabel}</div>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.edit.groups" />
                    </div>
                    <div className="data-desc">{groupText}</div>
                </div>
            </Fragment>
        );
    };

    render() {
        const {
            isDesktop,
            credentialGroups,
            confirmationParams,
            activeEnvironment: { administrationScheme },
        } = this.props;

        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        if (!confirmationParams) {
            return <Redirect to="/administration/users" />;
        }

        return (
            <Fragment>
                <Notification scopeToShow="administrationUsersInvite" />
                <Head
                    title="administration.users.invite.title"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <div className="form-section-title">
                                    <h3>
                                        <I18n id="administration.users.invite.personal.data.subtitle" />
                                    </h3>
                                    <hr />
                                </div>
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="administration.users.invite.firstName.label" />
                                    </div>
                                    <div className="data-desc">{confirmationParams.firstName}</div>
                                </div>

                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="administration.users.invite.lastName.label" />
                                    </div>
                                    <div className="data-desc">{confirmationParams.lastName}</div>
                                </div>

                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="administration.users.invite.email.label" />
                                    </div>
                                    <div className="data-desc">{confirmationParams.email}</div>
                                </div>

                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="administration.users.invite.mobilePhone.label" />
                                    </div>
                                    <div className="data-desc">{confirmationParams.mobilePhone}</div>
                                </div>

                                <div className="form-section-title">
                                    <h3>
                                        <I18n id="administration.users.invite.initial.configuration.subtitle" />
                                    </h3>
                                    <hr />
                                </div>
                                {administrationScheme === "medium"
                                    ? this.renderMediumSchemeConfiguration()
                                    : this.renderAdvancedSchemeConfiguration()}
                            </Col>
                        </Container>

                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    confirmationParams: selectors.getConfirmationParams(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(UserInviteStep3);
