import classNames from "classnames";
import { Field, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import BanescoCheckbox from "pages/_components/fields/BanescoCheckbox";
import TextField from "pages/_components/fields/TextField";
import Select from "pages/forms/_components/_fields/Select";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    actions as beneficiaryActions,
    selectors as beneficiarySelectors,
} from "reducers/frequentDestination/frequentDestination";
import { selectors as sessionSelectors } from "reducers/session";
import { formatCreditCardNumber } from "util/creditCards";
import * as i18n from "util/i18n";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { FOREIGN_TRANSFER, INTERNAL_TRANSFER, LOCAL_TRANSFER } from "./validation/BeneficiaryFormValidation";

export const ID_ACTIVITY = `beneficiary.list`;
const ListBeneficiary = (props) => {
    const { isDesktop, isFetching, beneficiaryList, dispatch, totalPages, activeEnvironment } = props;
    const [filterData, setFilterData] = useState({ pageNumber: 1, beneficiaryType: [], searchValue: "" });
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [allDeleteCheckBox, setAllDeleteCheckBox] = useState(false);
    const [listToDelete, setListToDelete] = useState([]);
    const [showBeneficiaryDeleteModal, setShowBeneficiaryDeleteModal] = useState(false);

    const deleteList = listToDelete.length > 0;

    const beneficiaryTypeList = [
        {
            label: i18n.get("global.all"),
            value: "ALL",
        },
        {
            label: i18n.get("beneficiary.type.internal_transfer.label"),
            value: INTERNAL_TRANSFER,
        },
        {
            label: i18n.get("beneficiary.type.local_transfer.label"),
            value: LOCAL_TRANSFER,
        },
        {
            label: i18n.get("beneficiary.type.foreign_transfer.label"),
            value: FOREIGN_TRANSFER,
        },
    ];

    useEffect(() => {
        setTotalPage(totalPages);
    }, [totalPages]);

    const [beneficiaryType, setBeneficiaryType] = useState(beneficiaryTypeList[0]);

    const fetchBeneficiaryList = () => {
        dispatch(beneficiaryActions.getBeneficiaryList(filterData));
    };

    useEffect(() => {
        fetchBeneficiaryList();
    }, [filterData]);

    useEffect(
        () => () => {
            dispatch(beneficiaryActions.clearPreDataForm());
            dispatch(beneficiaryActions.clearBeneficiaryList());
        },
        [],
    );

    const handleCheckboxChange = (isChecked, id, type, extraData) => {
        if (isChecked) {
            setListToDelete((prevList) => [...prevList, { id, type, extraData }]);
        } else {
            setListToDelete((prevList) => prevList.filter((item) => item.id !== id));
        }
    };

    const handleAllCheckboxChange = (isChecked) => {
        setAllDeleteCheckBox(isChecked);

        if (isChecked) {
            const allBeneficiaryIds = beneficiaryList.map((beneficiary) => ({
                id: beneficiary.idBeneficiary,
                type: beneficiary.type,
                extraData: beneficiary.extraData,
            }));
            setListToDelete(allBeneficiaryIds);
        } else {
            setListToDelete([]);
        }
    };

    const handleDeleteSelected = () => {
        if (listToDelete.length > 0) {
            dispatch(beneficiaryActions.deleteSelectedRequest(listToDelete));
            setListToDelete([]);
            setAllDeleteCheckBox(false);
        }
    };

    const renderHeaderPage = () => (
        <>
            <Notification scopeToShow="frequentDestination" />
            <Head
                hideMenu
                titleText={i18n.get(`${ID_ACTIVITY}.title`)}
                onBack={() => {
                    props.history.replace({
                        pathname: "/desktop",
                        state: { transition: "transition-flow-close" },
                    });
                }}
                disableBackButton={false}
                addLinkToLabel={
                    activeEnvironment.permissions.beneficiaryManageToken ? `${ID_ACTIVITY}.create.title` : null
                }
                addLinkTo={activeEnvironment.permissions.beneficiaryManageToken ? "/createFrequentDestination" : null}
                pushRedirect
            />
        </>
    );

    const onChangeBeneficiaryType = (selectBeneficiaryType) => {
        setPage(1);
        if (!selectBeneficiaryType?.value) {
            return;
        }
        setBeneficiaryType(selectBeneficiaryType);
        const valueBeneficiary = selectBeneficiaryType.value === "ALL" ? [] : [selectBeneficiaryType.value];
        setFilterData({ pageNumber: 1, beneficiaryType: valueBeneficiary, searchValue: "" });
    };

    const renderHeaderSection = () => (
        <>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize
                initialValues={{
                    searchBeneficiaryTextValue: "",
                }}
                validate={(values) => {
                    const errors = {};
                    if (!values.searchBeneficiaryTextValue) {
                        errors.searchBeneficiaryTextValue = i18n.get(DEFAULT_REQUIRED);
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(false);
                    setFilterData({
                        pageNumber: 1,
                        beneficiaryType: [],
                        searchValue: values.searchBeneficiaryTextValue || "",
                    });

                    resetForm({
                        searchBeneficiaryTextValue: "",
                    });
                }}>
                {(formikItem) => (
                    <Box
                        background="white"
                        borderRadius="top-default"
                        position="relative"
                        className={classNames("pt-8 pb-8 px-5 mt-5 mt-md-0 transition-smooth", {
                            "pb-11": deleteList,
                        })}>
                        <Row className="background-white">
                            <Col xs={12} sm={12} md={3}>
                                <Box className="input-group">
                                    <Select
                                        name="beneficiaryTypeSelect"
                                        clearable={false}
                                        searchable={false}
                                        onChange={onChangeBeneficiaryType}
                                        options={beneficiaryTypeList}
                                        value={beneficiaryType || {}}
                                        optionClassName="needsclick"
                                    />
                                </Box>
                            </Col>
                            <Col xs={12} sm={10} md={5}>
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={ID_ACTIVITY}
                                    name="searchBeneficiaryTextValue"
                                    type="text"
                                    showLabel={false}
                                    placeholder={i18n.get("global.search")}
                                    serarchStyle
                                />
                            </Col>
                            <Col xs={12} sm={2} md={2} lg={2}>
                                <Button
                                    ariaLabel="Search"
                                    bsStyle="primary"
                                    image="images/search.svg"
                                    onClick={() => {
                                        formikItem.submitForm();
                                    }}
                                />
                            </Col>
                        </Row>
                        {deleteList && (
                            <Box background="component-background" position="absolute">
                                <Box display="flex" alignY="center" gap="5" className="delete-container pt-3">
                                    <Box display="inline-flex" gap="3">
                                        <Text>{listToDelete.length}</Text>
                                        <Text labelKey="frequentDestination.list.deleteSelected.number.label" />
                                    </Box>
                                    <Button
                                        image="images/icons/trashDelete.svg"
                                        {...(isDesktop ? { label: "global.delete" } : { ariaLabel: "global.delete" })}
                                        bsStyle={isDesktop ? "link" : "only-icon"}
                                        loading={isFetching}
                                        disabled={listToDelete.length === 0}
                                        onClick={() => {
                                            setShowBeneficiaryDeleteModal(true);
                                        }}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Formik>
        </>
    );

    const getColumnsTemplateHeader = () => (isDesktop ? "repeat(4, 1fr) 1.5rem" : "1fr minmax(10rem, 1fr) 1.5rem");
    const getColumnsTemplate = () => (isDesktop ? "repeat(4, 1fr) 1.5rem" : "2fr minmax(6rem, 1fr) 1.5rem");
    const renderTableHeader = () => (
        <GridTable.Header>
            <GridTable.Container columnsTemplate={getColumnsTemplateHeader()}>
                <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                    <Box display="flex" alignX="between" alignY="center" gap={isDesktop ? "2" : "0"}>
                        <BanescoCheckbox
                            name="AllCheckbox"
                            defaultChecked={allDeleteCheckBox}
                            onChange={(e) => {
                                handleAllCheckboxChange(e.target.checked);
                            }}
                            hideLabel
                        />

                        <Text size="6" color="text-boton" labelKey={`${ID_ACTIVITY}.header.beneficiaryName`} />
                    </Box>
                </GridTable.Data>
                <GridTable.Data columnStart={2} alignX={isDesktop ? "flex-start" : "flex-end"} inHeader>
                    <Text
                        size="6"
                        color="text-boton"
                        labelKey={
                            isDesktop
                                ? `${ID_ACTIVITY}.header.beneficiaryProductType`
                                : `${ID_ACTIVITY}.header.beneficiaryProductTypeAndBank`
                        }
                    />
                </GridTable.Data>
                {isDesktop && (
                    <>
                        <GridTable.Data columnStart={3} alignX="flex-end" inHeader>
                            <Text
                                size="6"
                                color="text-boton"
                                labelKey={`${ID_ACTIVITY}.header.beneficiaryProductNumber`}
                            />
                        </GridTable.Data>
                        <GridTable.Data columnStart={4} alignX="flex-end" inHeader>
                            <Text size="6" color="text-boton" labelKey={`${ID_ACTIVITY}.header.beneficiaryBankName`} />
                        </GridTable.Data>
                    </>
                )}
            </GridTable.Container>
        </GridTable.Header>
    );

    const renderItem = (beneficiary, idx) => {
        if (!beneficiary) {
            return <div />;
        }

        const { idBeneficiary, name, type, productType, productNumber, bankName, favorite, extraData } = beneficiary;

        return (
            <GridTable.Container
                className="product-data"
                columnsTemplate={getColumnsTemplate()}
                key={`beneficiary.list.container.${idBeneficiary}`}
                rows={isDesktop ? 1 : 3}
                onClick={() => {
                    props.history.push(`/viewFrequentDestination/${idBeneficiary}`);
                }}>
                <GridTable.Data className="p-0 " columnStart={1} rowWidth={3} alignX="flex-start">
                    <Box display="flex" alignX="between" alignY="center" gap={isDesktop ? "2" : "0"} fullWidth>
                        <BanescoCheckbox
                            name={`checkbox-${idx}`}
                            value="testing"
                            defaultChecked={allDeleteCheckBox}
                            onChange={(e) => {
                                handleCheckboxChange(e.target.checked, idBeneficiary, type, extraData);
                            }}
                            hideLabel
                        />

                        <Box display="flex" column fullWidth withEllipsis>
                            <Box display="flex">
                                <Box
                                    display="flex"
                                    gapX={isDesktop ? "0" : "0"}
                                    position="relative"
                                    className="auto-full-width">
                                    <Text size={isDesktop ? "6" : "5"} color="secondary" bold ellipsis>
                                        {name || ""}
                                    </Text>

                                    <Box display="flex" alignY="center" alignX="flex-start">
                                        <Button
                                            ariaLabel={
                                                favorite ? "global.favoriteStar.a11y" : "global.noFavoriteStar.a11y"
                                            }
                                            image={`images/icons/favoriteStar${favorite ? "On" : "Off"}.svg`}
                                            className={`star-btn ${favorite ? "disabled" : "off"}`}
                                            // disabled={completeFavorites && !favorite}
                                            bsStyle="only-icon"
                                            // loading={changingStatusFavorite}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                dispatch(
                                                    beneficiaryActions.setFavoriteBeneficiary(idBeneficiary, !favorite),
                                                );
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Text
                                color="text"
                                size={isDesktop ? "6" : "5"}
                                labelKey={`beneficiary.type.${(type || "").toLowerCase()}.label`}
                                ellipsis
                            />
                        </Box>
                    </Box>
                </GridTable.Data>
                <GridTable.Data
                    className={classNames("p-0", { "mr-5": isDesktop, "ml-5": !isDesktop })}
                    columnStart={2}
                    alignX={isDesktop ? "flex-start" : "flex-end"}>
                    <Text
                        color="text"
                        size={isDesktop ? "6" : "5"}
                        {...(!isDesktop && { align: "right" })}
                        labelKey={`beneficiary.productType.${(type || "").toLowerCase()}.${(
                            productType || ""
                        ).toLowerCase()}.label`}
                        ellipsis
                    />
                </GridTable.Data>
                <GridTable.Data
                    className="p-0 ml-5"
                    columnStart={isDesktop ? 3 : 2}
                    rowStart={isDesktop ? 1 : 2}
                    alignX="flex-end">
                    <Text color="text" size={isDesktop ? "6" : "5"} ellipsis align="right">
                        {productType === "TC" ? formatCreditCardNumber(productNumber) : productNumber || ""}
                    </Text>
                </GridTable.Data>
                <GridTable.Data
                    className="p-0 ml-5"
                    columnStart={isDesktop ? 4 : 2}
                    rowStart={isDesktop ? 1 : 3}
                    alignX="flex-end">
                    <Text color="text" size={isDesktop ? "6" : "5"} capitalize ellipsis align="right">
                        {bankName?.toLowerCase() || ""}
                    </Text>
                </GridTable.Data>
                <GridTable.Data
                    className="p-0 ml-5"
                    columnStart={isDesktop ? 5 : 3}
                    rowWidth={isDesktop ? 1 : 3}
                    alignX="flex-end">
                    <Image src="images/icons/chevronRight.svg" color="text-color" />
                </GridTable.Data>
            </GridTable.Container>
        );
    };

    const renderBody = () => {
        if (!beneficiaryList) {
            return <div />;
        }
        return beneficiaryList.map((beneficiary, idx) => renderItem(beneficiary, idx));
    };

    const renderList = () => (
        <GridTable>
            {renderTableHeader()}
            <GridTable.Body>
                {beneficiaryList && beneficiaryList.length > 0 && renderBody()}
                {page < totalPage && (
                    <Box display="flex" fullWidth alignX="center" className="no-more-data">
                        <Button
                            bsStyle="link"
                            onClick={() => {
                                const pageDef = page + 1;
                                setPage(pageDef);
                                setFilterData({ ...filterData, pageNumber: pageDef });
                            }}
                            image="images/arrow-down.svg"
                            loading={isFetching}
                            label="beneficiary.moreText"
                            className="btn-small"
                            imageRight
                        />
                    </Box>
                )}

                {(beneficiaryList.length === 0 || page === totalPage) && (
                    <Box
                        display="flex"
                        fullWidth
                        alignX="center"
                        className={classNames("no-more-data ", { "min-height-8rem": beneficiaryList.length === 0 })}>
                        <Text
                            labelKey={
                                beneficiaryList.length !== 0 && page === totalPage
                                    ? "beneficiary.noMoreToShowText"
                                    : "beneficiary.noMoreText"
                            }
                            component="p"
                            align="center"
                            bold
                            size={isDesktop ? "6" : "5"}
                            color="text"
                        />
                    </Box>
                )}
            </GridTable.Body>
        </GridTable>
    );

    return (
        <>
            {renderHeaderPage()}
            <MainContainer showLoader={isFetching && page === 1}>
                <Box className={isDesktop ? "" : "mx-n-5"} borderRadius="xl">
                    {renderHeaderSection()}

                    {renderList()}
                </Box>
            </MainContainer>
            <TextAndButtonsModal
                modalShow={showBeneficiaryDeleteModal}
                acceptFunction={() => {
                    handleDeleteSelected();
                    setShowBeneficiaryDeleteModal(false);
                }}
                cancelFunction={() => {
                    setShowBeneficiaryDeleteModal(false);
                }}
                confirmLabel="global.accept"
                headingText={i18n.get("beneficiary.delete.modal.title")}
                text={i18n.get("beneficiary.delete.modal.text")}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    beneficiaryList: beneficiarySelectors.getBeneficiaryList(state),
    isFetching: beneficiarySelectors.isFetching(state),
    totalPages: beneficiarySelectors.getTotalPages(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

ListBeneficiary.propTypes = {
    isDesktop: string.isRequired,
    isFetching: bool,
    beneficiaryList: arrayOf(shape({})),
    dispatch: func,
    totalPages: number.isRequired,
    activeEnvironment: shape({}).isRequired,
    history: shape({
        push: func.isRequired,
    }).isRequired,
};

ListBeneficiary.defaultProps = {
    isFetching: false,
    beneficiaryList: [],
    dispatch: () => {},
};

export default withRouter(connect(mapStateToProps)(ListBeneficiary));
