import classNames from "classnames";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Box from "pages/_components/Box";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as formSelectors } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import { get } from "util/i18n";
import { getTransactionKind } from "util/transaction";
import { selectors as sessionSelectors } from "reducers/session";
import ModifyTransaction from "./_confirmations/ModifyTransaction";

class FormActions extends Component {
    static propTypes = {
        onClick: func.isRequired,
        fetching: bool,
        mode: string.isRequired,
        draftsEnabled: bool,
        templatesEnabled: bool,
        transaction: shape({
            idTransactionStatus: string,
        }),
        parentTransaction: shape({}),
        isEditable: bool,
        isCancellingTransaction: bool.isRequired,
        location: shape({}),
        isDesktop: bool.isRequired,

        submitDisabled: bool,
        submitButtonLabel: string,
        cancelAction: func,
        submitAction: func,
        wally: bool,
        kuara: bool,
        usesJointAccount: bool,
        loggedUser: shape({}).isRequired,
        onlyBackToDesktopBtn: bool,
    };

    static defaultProps = {
        fetching: false,
        draftsEnabled: false,
        templatesEnabled: false,
        isEditable: true,
        transaction: {},
        location: {},
        parentTransaction: null,
        submitDisabled: false,
        submitButtonLabel: null,
        cancelAction: null,
        submitAction: null,
        wally: false,
        kuara: false,
        usesJointAccount: false,
        onlyBackToDesktopBtn: false,
    };

    state = {
        modifyTransactionConfirm: false,
    };

    getEditButtons = () => {
        const {
            onClick,
            fetching,
            draftsEnabled,
            templatesEnabled,
            mode,
            submitDisabled,
            submitButtonLabel,
            cancelAction,
            submitAction,
            isDesktop,
        } = this.props;

        const label = `global.continue`;
        const defaultLabelText = get("global.continue", "");

        if (mode === "edit") {
            return (
                <Row gapY="3" className="form-action-btn">
                    {draftsEnabled && (
                        <Col xs={12} md={6} mdOffset={3}>
                            <Button
                                type="button"
                                label="forms.saveDraft.link"
                                bsStyle="outline"
                                onClick={() => onClick("saveDraft")}
                                block
                            />
                        </Col>
                    )}

                    {templatesEnabled && (
                        <Col xs={12} md={3} mdOffset={3}>
                            <Button
                                type="button"
                                label="forms.saveTemplate.link"
                                bsStyle="outline"
                                onClick={() => onClick("createTemplate")}
                                ariaHasPopup="dialog"
                                expandable
                                block
                            />
                        </Col>
                    )}

                    {cancelAction && (
                        <Col xs={12} md={3} mdOffset={3}>
                            <Button
                                type="button"
                                label="global.cancel"
                                bsStyle="outline"
                                block
                                onClick={cancelAction}
                            />
                        </Col>
                    )}

                    <Col
                        xs={12}
                        md={templatesEnabled || cancelAction ? 3 : 4}
                        mdOffset={templatesEnabled || cancelAction ? 0 : 4}
                        {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            type="submit"
                            label={submitButtonLabel || label}
                            bsStyle="primary"
                            loading={fetching}
                            disabled={fetching || submitDisabled}
                            defaultLabelText={defaultLabelText}
                            block
                            onClick={submitAction || null}
                        />
                    </Col>
                </Row>
            );
        }

        return null;
    };

    getViewButtons = () => {
        const {
            onClick,
            fetching,
            transaction,
            parentTransaction,
            mode,
            isCancellingTransaction,
            location,
            isDesktop,
            usesJointAccount,
            wally,
            kuara,
            loggedUser,
            onlyBackToDesktopBtn,
        } = this.props;

        let { isEditable } = this.props;

        const hrefs = {
            "creditCard.payment.creditCardLocal.send": "/formCustom/payCreditCardLocal",
            "creditCard.payment.creditCardThird.send": "/formCustom/payCreditCardThird",
            "creditCard.recharge.creditCardLocal.send": "/formCustom/rechargeCreditCardLocal",
            "creditCard.recharge.creditCardThird.send": "/formCustom/rechargeCreditCardThird",
            "creditCard.payment.financingPlan.creditCardLocal.send": "/formCustom/financingPlan",
            "transfers.local.send": "/formCustom/transferLocal",
            "loan.payment.send": "/formCustom/payLoan",
            "transfers.payService.send": "/servicePayments",
            "fixed.term.deposit.create.send": "/deposits",
            "transfers.internal.send": "/formCustom/transferInternal",
            // TODO (enviar a chequera)
            "checks.suspend.send": `/checks/${transaction?.data?.idAccount}/${transaction?.data?.checkTypeValue}`,
            "transfers.foreign.send": "/formCustom/transferForeign",
            "installment.purchase.send": "/formCustom/installmentPurchase",
        };

        const hrefAnotherTransaction = hrefs[transaction?.idActivity] ?? "/wally";
        /* const getFallbackHref = () => {
            if (kuara) {
                return "/kuara";
            }
            return "/wally";
        }; */
        let { modifyTransactionConfirm } = this.state;
        const titleKind = getTransactionKind(transaction?.idActivity);

        let transactionSigned = false;
        const signature = transaction?.signatures?.find((s) => s.idUser === loggedUser.userId);
        if (signature && signature.extendedData) {
            const signatureData = JSON.parse(signature.extendedData);
            if (signatureData.jointCreationTime) {
                transactionSigned = true;
            }
        }

        if (mode === "view" && transaction && !parentTransaction) {
            const { idTransactionStatus } = transaction;

            isEditable = false;
            modifyTransactionConfirm = false;
            if (
                (idTransactionStatus === "PENDING" || idTransactionStatus === "SCHEDULED") &&
                location.pathname.match("/transaction/") &&
                !usesJointAccount
            ) {
                return (
                    <>
                        <Col
                            xs={12}
                            md={idTransactionStatus === "PENDING" ? 4 : 6}
                            mdOffset={isEditable || modifyTransactionConfirm ? 0 : 3}>
                            <Button
                                onClick={() => onClick("cancelTransaction")}
                                label={
                                    idTransactionStatus === "PENDING"
                                        ? "forms.cancelTransaction.link"
                                        : "forms.transaction.ticket.button.cancel.scheduled"
                                }
                                bsStyle="outline"
                                // loading={isCancellingTransaction}
                                block
                            />
                        </Col>

                        {isEditable && (
                            <Col xs={12} md={idTransactionStatus === "PENDING" ? 4 : 6}>
                                <Button
                                    onClick={() => this.verifyBeforeModifyTransaction()}
                                    label={
                                        idTransactionStatus === "PENDING"
                                            ? "forms.modifyTransaction.link"
                                            : "forms.transaction.ticket.button.modify.scheduled"
                                    }
                                    bsStyle="outline"
                                    loading={isCancellingTransaction}
                                    block
                                />
                            </Col>
                        )}

                        {idTransactionStatus === "PENDING" && (
                            <Col xs={12} md={idTransactionStatus === "PENDING" ? 4 : 6}>
                                <Button
                                    onClick={() => onClick("signTransaction")}
                                    label="forms.signTransaction.link"
                                    bsStyle="primary"
                                    loading={fetching}
                                    block
                                />
                            </Col>
                        )}

                        {/* Opening modal */}
                        {modifyTransactionConfirm && (
                            <ModifyTransaction
                                modifyTransactionConfirm={modifyTransactionConfirm}
                                onClose={this.cancelModifyHandleClick}
                                modifyTransactionClick={() => onClick("modifyTransaction")}
                                block
                            />
                        )}
                    </>
                );
            }
            if (location.pathname.match("/formCustom/")) {
                return (
                    <>
                        <Col xs={12} md={6} {...(onlyBackToDesktopBtn && { mdOffset: 3 })}>
                            <Button bsStyle="outline" label="global.goToDesktop" href="/desktop" block />
                        </Col>
                        {!onlyBackToDesktopBtn && (
                            <Col
                                xs={12}
                                md={6}
                                className={classNames({
                                    "grid-reversed": !isDesktop,
                                })}>
                                <Button
                                    bsStyle="primary"
                                    label={`global.anotherTransaction.${titleKind}`}
                                    href={hrefAnotherTransaction}
                                    block
                                />
                            </Col>
                        )}
                    </>
                );
            }

            const WallyTransactionPath = location.pathname.match("/sendWallyTransaction");
            const WallyRequestTransactionPath = location.pathname.match("/sendWallyRequestTransaction");
            const WallyTransactionsPath = location.pathname.match("/transactionWally");
            const WallyTransactionsPendingPath = location.pathname.match("/transactionPendingWally");

            const KuaraTransactionPath = location.pathname.match("/sendKuaraTransaction");
            const KuaraRequestTransactionPath = location.pathname.match("/sendKuaraRequestTransaction");
            const KuaraTransactionsPath = location.pathname.match("/transactionKuara");

            if (
                (wally || kuara) &&
                (WallyTransactionPath ||
                    WallyRequestTransactionPath ||
                    WallyTransactionsPendingPath ||
                    KuaraTransactionPath ||
                    KuaraRequestTransactionPath)
            ) {
                return (
                    <>
                        <Col xs={12} md={6}>
                            <Button
                                bsStyle="outline"
                                label="global.goToDesktop"
                                href={kuara ? "/kuara" : "/wally"}
                                block
                            />
                        </Col>
                        <Col
                            xs={12}
                            md={6}
                            className={classNames({
                                "grid-reversed": !isDesktop,
                            })}>
                            {WallyTransactionPath && (
                                <Button
                                    bsStyle="primary"
                                    {...(kuara && { className: "kuara-color" })}
                                    label="global.anotherTransaction.wally"
                                    href={kuara ? "/sendKuara" : "/sendWally"}
                                    block
                                />
                            )}
                            {WallyRequestTransactionPath && (
                                <Button
                                    bsStyle="primary"
                                    {...(kuara && { className: "kuara-color" })}
                                    label="global.anotherRequest.wally"
                                    href={kuara ? "/requestKuara" : "/requestWally"}
                                    block
                                />
                            )}
                            {WallyTransactionsPendingPath && (
                                <Button
                                    bsStyle="primary"
                                    {...(kuara && { className: "kuara-color" })}
                                    label="global.anotherTransactionPending.wally"
                                    href={kuara ? "/pendingKuara" : "/pendingWally"}
                                    block
                                />
                            )}
                        </Col>
                    </>
                );
            }
            if ((wally || kuara) && (WallyTransactionsPath || KuaraTransactionsPath)) {
                return (
                    <>
                        <Col xs={12} md={6}>
                            <Button
                                bsStyle="outline"
                                label="global.back"
                                href={kuara ? "/lastMovementsKuara" : "/lastMovementsWally"}
                                block
                            />
                        </Col>
                    </>
                );
            }
        }

        if (mode === "view" && transaction.idTransactionStatus === "PENDING" && usesJointAccount) {
            return (
                <Box display="flex" alignY="center" className="ticket">
                    <Row>
                        <Col xs="12" md={6}>
                            <Button
                                onClick={() => onClick("cancelTransaction")}
                                label="global.cancel"
                                bsStyle="outline"
                                loading={isCancellingTransaction}
                                block
                            />
                        </Col>
                        <Col
                            xs="12"
                            md={6}
                            className={classNames({
                                "grid-reversed": !isDesktop,
                            })}>
                            <Button
                                onClick={() => onClick("signTransaction")}
                                label="forms.signTransaction.link"
                                bsStyle="primary"
                                loading={fetching}
                                disabled={
                                    !parentTransaction ||
                                    transaction.idTransaction === parentTransaction.idTransaction ||
                                    transactionSigned
                                }
                                block
                            />
                        </Col>
                    </Row>
                </Box>
            );
        }

        return null;
    };

    verifyBeforeModifyTransaction = () => {
        const { onClick, transaction } = this.props;

        if (transaction.signatures.length > 0) {
            this.setState({ modifyTransactionConfirm: true });
        } else {
            onClick("modifyTransaction");
        }
    };

    cancelModifyHandleClick = () => {
        this.setState({ modifyTransactionConfirm: false });
    };

    render() {
        const editButtons = this.getEditButtons();
        const viewButtons = this.getViewButtons();

        return (
            (editButtons || viewButtons) && (
                <Row>
                    {editButtons}
                    {viewButtons}
                </Row>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    isCancellingTransaction: formSelectors.getFetching(state) && formSelectors.getIsCancellingTransaction(state),
    isModalVisible: templateSelectors.isCreateModalVisible(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(FormActions));
