import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { routerActions } from "react-router-redux/actions";
import Redirect from "react-router-dom/Redirect";
import { func, arrayOf, oneOf, shape, string } from "prop-types";

import { actions, selectors } from "reducers/administration/users";
import { actions as formActions } from "reducers/form";

import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class UserActionConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        usersToApplyAction: arrayOf(string).isRequired,
        usersNamesToApplyAction: arrayOf(string).isRequired,
        userAction: oneOf(["block", "unblock", "delete"]).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    handleSubmit = (credentials, formikBag) => {
        const {
            dispatch,
            usersToApplyAction,
            usersNamesToApplyAction,
            userAction,
            idTransaction,
            idActivity,
        } = this.props;

        if (!idTransaction) {
            dispatch(
                actions.changeUserStatusConfirmation(
                    usersToApplyAction,
                    usersNamesToApplyAction,
                    userAction,
                    credentials,
                    formikBag,
                ),
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { usersToApplyAction, usersNamesToApplyAction, userAction, credentialGroups } = this.props;

        if (!usersToApplyAction || usersToApplyAction.length === 0) {
            return <Redirect to="/administration/users" />;
        }

        const usersText = usersNamesToApplyAction.map((userName) => <span key={userName}> {userName} </span>);
        const titleKey = `administration.users.action.title.${userAction}`;

        return (
            <Fragment>
                <Head title={titleKey} onBack={this.handleBack} closeLinkTo="/administration/users" />
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <p className="text-lead">
                                    <I18n id="administration.forms.confirm.credentials" />
                                </p>
                                <div className="transfer-block">
                                    <h4 className="form-title">
                                        <I18n id={`administration.users.action.confirmation.${userAction}`} />
                                    </h4>
                                    {usersText}
                                </div>
                            </Col>
                        </Container>

                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userAction: selectors.getUserAction(state),
    usersToApplyAction: selectors.getUsersToApplyAction(state),
    usersNamesToApplyAction: selectors.getUsersNamesToApplyAction(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(UserActionConfirmation);
