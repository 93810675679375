import { call, put, takeLatest } from "redux-saga/effects";
import { routerActions } from "react-router-redux";

import * as form from "middleware/form";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as permissionsUtil from "util/permissions";
import { credentialsToUnderscoreFormat } from "util/form.js";
import { actions as settingActions } from "reducers/settings";

const allowsProductSelection = ({ childrenList, permissionList }) =>
    !childrenList.length &&
    permissionList.length &&
    (permissionList[0].simpleAllowProductSelection || permissionList[0].mediumAllowProductSelection);
const parsePermissionsResponse = (data) => {
    const { groups } = data;

    // This is to convert generic permissions to permissions on specific products
    // when needed
    const permissionsFromGroups = groups.reduce((result, group) => {
        if (group.childrenList.length) {
            const innerPermissions = group.childrenList.reduce(
                (innerResult, innerPermission) => ({
                    ...innerResult,
                    [innerPermission.idItem]: allowsProductSelection(innerPermission)
                        ? permissionsUtil.convertSmartGroupsToProducts(
                              data.permissions[innerPermission.idItem],
                              data.products,
                          )
                        : data.permissions[innerPermission.idItem],
                }),
                {},
            );
            return {
                ...result,
                ...innerPermissions,
            };
        }
        return {
            ...result,
            [group.idItem]: allowsProductSelection(group)
                ? permissionsUtil.convertSmartGroupsToProducts(data.permissions[group.idItem], data.products)
                : data.permissions[group.idItem],
        };
    }, {});
    // Not all the permissions belong to a group so I also need the permissions from the data.permissions object
    const permissions = {
        ...data.permissions,
        ...permissionsFromGroups,
    };
    return {
        ...data,
        permissions,
    };
};
const loadPermissionsRequest = (middleware, actions) =>
    function* loadPermissions({ id }) {
        const permissionsResponse = yield call(middleware.loadPermissionsRequest, id);

        if (permissionsResponse.type === "W") {
            yield put(actions.loadPermissionsFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            const data = parsePermissionsResponse(permissionsResponse.data.data);
            yield put(actions.loadPermissionsSuccess(data));
        }
    };
const updatePermissionsPreview = (middleware, actions) =>
    function* updatePreview({ data, setSubmitting }) {
        const response = yield call(middleware.updatePermissionsPreview, data);

        setSubmitting(false);

        if (response.type === "W") {
            const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.simple.modify.permissions.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.onSuccess(data.idUser));
            yield put(actions.updatePermissionsPreviewSuccess(data.permissions, credentialGroups));
        }
    };

const updatePermissionsRequest = (middleware, actions) =>
    function* updatePermissions({ data, formikBag }) {
        const { credentials, ...restOfParams } = data;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        const response = yield call(middleware.updatePermissionsRequest, {
            ...restOfParams,
            ...credentialsWithUnderscore,
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (response.type === "W") {
            const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            setErrors(response.data.data);
            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        } else {
            yield put(notificationActions.showNotification(response.data.message, "success", ["administration"]));
            yield put(routerActions.go(-2));
            yield put(actions.updatePermissionsRequestSuccess());
            yield put(settingActions.updatePermissionLocal(response?.data?.data?.permissions));
        }
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_PERMISSIONS_REQUEST, loadPermissionsRequest(middleware, actions)),
    takeLatest(types.UPDATE_PERMISSIONS_PREVIEW, updatePermissionsPreview(middleware, actions)),
    takeLatest(types.UPDATE_PERMISSIONS_REQUEST, updatePermissionsRequest(middleware, actions)),
];

export default sagasCreator;
