import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import PermissionsList from "pages/administration/_components/PermissionsList";
import { shape } from "prop-types";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";

class AdministrationPermissionsTicket extends Component {
    static propTypes = {
        actions: shape({}).isRequired,
        match: shape({}).isRequired,
        user: shape({}).isRequired,
    };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadPermissionsTicketRequest(match.params.idTransaction);
    }

    render() {
        const { user } = this.props;

        return (
            <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper"
                        label="administration.permissions.user"
                        data={`${user.firstName} ${user.lastName}`}
                    />
                    <div className="transfer-data data-wrapper">
                        <Container.ColumnBody columnClass="list-wrapper">
                            <PermissionsList>
                                {(list) =>
                                    (list ? (
                                        <Fragment>
                                            <h4 className="form-title">
                                                <I18n id="administration.permissions.permissionsApplied" />
                                            </h4>
                                            {list}
                                        </Fragment>
                                    ) : (
                                        <I18n id="administration.permissions.empty" />
                                    ))
                                }
                            </PermissionsList>
                        </Container.ColumnBody>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default AdministrationPermissionsTicket;
