import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, number as int, shape, string } from "prop-types";
import React, { Component } from "react";

class AdditionalCardItem extends Component {
    render() {
        const { isDesktop, additional } = this.props;

        const { iconType, type, brand, franchise, numberMask, avaible } = additional;

        const cardLogo = brand === "visa" ? `${brand}-blue` : brand;

        const textColor = "heading";

        return (
            <>
                <GridTable.Data columnStart={1} rowWidth={isDesktop ? 1 : 2} alignX="center">
                    <Image
                        src={`images/icons/creditCard-${iconType}.svg`}
                        wrapperClassName="pt-2 pt-md-0"
                        className="svg-icon"
                    />
                </GridTable.Data>

                <GridTable.Data columnStart={2} alignX="flex-start" alignY={isDesktop ? "center" : "flex-end"}>
                    <Text color={textColor} ellipsis labelKey={type} />
                </GridTable.Data>

                <GridTable.Data
                    columnStart={isDesktop ? 3 : 2}
                    rowStart={isDesktop ? 1 : 2}
                    alignX={isDesktop ? "center" : "flex-start"}>
                    <Image
                        wrapperWidth="auto"
                        wrapperHeight="auto"
                        {...(brand === "visa" && { height: "8" })}
                        src={`images/${cardLogo}-creditCard.svg`}
                        className="svg-icon mr-2"
                    />
                    <Text color={textColor} ellipsis>
                        {`${franchise.charAt(0).toUpperCase()}${franchise
                            .slice(1)
                            .toLowerCase()} **** ${numberMask.slice(-4)}`}
                    </Text>
                </GridTable.Data>

                {isDesktop ? (
                    <GridTable.Data columnStart={4} alignX="flex-end">
                        <Text ellipsis size="6" align="right" light color={textColor} className="mr-md-3">
                            Disponible{" "}
                        </Text>
                        <FormattedAmount
                            color={textColor}
                            size="5"
                            bold
                            quantity={avaible}
                            className="data-amount justify-content-end"
                        />
                    </GridTable.Data>
                ) : (
                    <>
                        <GridTable.Data columnStart={3} alignX="flex-end" alignY="flex-end">
                            <Text size="6" light color={textColor} align="right">
                                Disponible{" "}
                            </Text>
                        </GridTable.Data>
                        <GridTable.Data columnStart={3} rowStart={2}>
                            <FormattedAmount
                                color={textColor}
                                size="5"
                                bold
                                quantity={avaible}
                                className="data-amount justify-content-end"
                            />
                        </GridTable.Data>
                    </>
                )}
            </>
        );
    }
}

AdditionalCardItem.propTypes = {
    isDesktop: bool,
    additional: shape({
        iconType: string,
        type: string,
        brand: string,
        franchise: string,
        numberMask: string,
        avaible: int,
    }).isRequired,
};

AdditionalCardItem.defaultProps = {
    isDesktop: true,
};

export default resizableRoute(AdditionalCardItem);
