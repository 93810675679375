import Step0Content from "pages/login/_components/Step0Content";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import { func, number, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { replace } from "react-router-redux";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as momentDaySelectors } from "reducers/momentDay";
import { actions as sessionActions } from "reducers/session";
import * as config from "util/config";
import { isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";
import {
    REVELOCK_CUSTOMER_ID_DEFAULT,
    setAutoLogoutActionRevelock,
    setCustomerIdRevelock,
} from "util/revelockMobile/revelockMobile.util";
import { validatePermissionMobile } from "util/permissions/permission.util";
import { actions as revelockActions, selectors as revelockSelectors } from "reducers/revelock/revelock.reducer";
import { getMobileTagManagetId } from "util/tagManager/tagManager.util";
import TagManager from "react-gtm-module";

const Login = (props) => {
    const { momentDay, showSuspiciousActivityModal, dispatch, location } = props;

    const [initialized, setInitialized] = useState(false);
    const [showSuspiciousModal, setShowSuspiciousModal] = useState(undefined);
    const showChangeLoginModal = new URLSearchParams(location.search).get("changeLogin") || false;

    const checkInitialization = () => {
        if (!initialized && momentDay) {
            dispatch(loginActions.initLoginFlow());
            setInitialized(true);
            window.common.hideSplashScreen();
        }
    };

    const callbackLogoutRevelock = () => {
        dispatch(revelockActions.showSuspiciousActivityModal());
        dispatch(sessionActions.logout());
    };

    const initRevelockConfiguration = () => {
        if (!isMobileNativeFunc()) {
            return;
        }
        const customerId = config.get("revelock.mobile.customerId", REVELOCK_CUSTOMER_ID_DEFAULT);
        setCustomerIdRevelock(customerId);
        setAutoLogoutActionRevelock(callbackLogoutRevelock);
    };

    const initMobileGoogleTagManager = () => {
        if (!isMobileNativeFunc()) {
            return;
        }

        const gtmId = getMobileTagManagetId(config);
        if (!gtmId || gtmId === "") {
            // eslint-disable-next-line no-console
            console.log("Google tag manager id, not found");
            return;
        }

        TagManager.initialize({
            gtmId,
            events: {
                sendUserInfo: "userInfo",
            },
        });
    };

    useEffect(() => {
        validatePermissionMobile();
        checkInitialization();
        initRevelockConfiguration();
        initMobileGoogleTagManager();
    }, []);

    useEffect(() => {
        if (showSuspiciousActivityModal && showSuspiciousActivityModal > 0) {
            setShowSuspiciousModal(true);
        }
    }, [showSuspiciousActivityModal]);

    return (
        <>
            <Step0Content />
            <ConfirmDialog
                showDialog={showSuspiciousModal}
                title={undefined}
                description={i18n.get("revelock.suspicious.activity.text") || ""}
                onConfirm={() => {
                    setShowSuspiciousModal(undefined);
                    dispatch(revelockActions.clearSuspiciousActivityModal());
                }}
                confirmLabel="global.accept"
                textClassName="mx-md-5"
            />
            <ConfirmDialog
                showDialog={showChangeLoginModal}
                title={i18n.get("login.fingerprint.device.change.title")}
                description={i18n.get("login.fingerprint.device.change.text")}
                onConfirm={() => dispatch(replace("/"))}
                confirmLabel="global.accept"
                textClassName="mx-md-5"
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    momentDay: momentDaySelectors.getMomentDay(state) || "",
    rememberedUser: loginSelectors.getRememberedUser(state),
    showSuspiciousActivityModal: revelockSelectors.getShowSuspiciousActivityModal(state),
});

Login.propTypes = {
    momentDay: string.isRequired,
    showSuspiciousActivityModal: number,
    dispatch: func,
};

Login.defaultProps = {
    showSuspiciousActivityModal: 0,
    dispatch: () => {},
};

export default withRouter(connect(mapStateToProps)(Login));
