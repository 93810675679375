import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "pages/_components/Row";
import classNames from "classnames";
import { shape, func, string, bool } from "prop-types";

import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
/* eslint-disable react/no-multi-comp */
import PageLoading from "pages/_components/PageLoading";
import AdministrationHeading from "pages/administration/_components/Heading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import PermissionsList from "pages/administration/_components/PermissionsList";
import CapsList from "pages/administration/_components/CapsList";
import UserDetailsGroupList from "pages/administration/_components/UserDetailsGroupList";
import Heading from "pages/_components/Heading";
import Nav from "pages/_components/Nav";
import FeatureFlag from "pages/_components/FeatureFlag";
import RestrictionsUserProfile from "pages/administration/restrictions/RestrictionsUserProfile";
import Image from "pages/_components/Image";

class Details extends Component {
    static propTypes = {
        actions: shape({
            loadDetailsRequest: func.isRequired,
            changeUserStatusPreview: func.isRequired,
            updateDispatcherRequest: func.isRequired,
            getRestrictions: func.isRequired,
        }).isRequired,
        match: shape({
            params: shape({ id: string }),
        }).isRequired,
        routerActions: shape({
            goBack: func.isRequired,
        }).isRequired,
        userEnvStatus: string,
        user: shape({
            idUser: string,
            fullName: string,
        }),
        userInfo: shape({
            dispatcher: bool,
        }),
        hasMassiveEnabled: bool,
        hasSignatureEnabled: bool,
        signatureLevel: string,
        schemeName: string,
        fetching: bool.isRequired,
    };

    static defaultProps = {
        userEnvStatus: "",
        user: {},
        userInfo: {},
        hasMassiveEnabled: false,
        hasSignatureEnabled: false,
        signatureLevel: "",
        schemeName: "",
    };

    componentDidMount() {
        const { actions, match } = this.props;
        const idUser = match.params.id;
        actions.loadDetailsRequest(idUser);
        actions.getRestrictions(idUser);
    }

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    handleSelect = (key, fullName) => {
        const { actions, match, userEnvStatus } = this.props;
        let actionOnTheUser = "delete";

        if (key === 0) {
            actionOnTheUser = userEnvStatus === "active" ? "block" : "unblock";
        }

        actions.changeUserStatusPreview([match.params.id], [fullName], actionOnTheUser);
    };

    handleToggleDispatcher = (dispatcher) => {
        const { user, actions } = this.props;
        actions.updateDispatcherRequest(user.idUser, dispatcher);
    };

    renderHeader = () => {
        const { user, userEnvStatus, hasMassiveEnabled } = this.props;
        const actionKey = userEnvStatus === "active" ? "administration.block" : "administration.unblock";
        const isAdministrator = !hasMassiveEnabled;

        return (
            <Fragment>
                <Head onBack={this.handleBack} hasCenterContent>
                    <h2>{user.fullName}</h2>{" "}
                    {isAdministrator && (
                        <span className="admin-label">
                            <I18n id="administration.administrator" />
                        </span>
                    )}
                </Head>
                <AdministrationHeading>
                    {({ email, fullName }) => (
                        <div>
                            <Grid>
                                <Row className="content-data">
                                    <Heading.Data>
                                        <Heading.DataGroup label="administration.permissions.email" data={email} />
                                    </Heading.Data>
                                    <Heading.Data>
                                        <Heading.DataGroup
                                            label="administration.permissions.status"
                                            data={
                                                <span>
                                                    <I18n id={`user.status.${userEnvStatus}`} />
                                                </span>
                                            }
                                            dataClassName={classNames("data-tag", {
                                                "data-tag-blocked": userEnvStatus === "blocked",
                                            })}
                                        />
                                    </Heading.Data>
                                </Row>
                            </Grid>
                            {hasMassiveEnabled && (
                                <Grid fluid>
                                    <Nav
                                        className="product-detail-commands"
                                        variant="pills"
                                        onSelect={(key) => this.handleSelect(key, fullName)}>
                                        <Nav.Item className="btn btn-outline" href="#">
                                            <Image src="images/lock--checked.svg" className="svg-icon svg-caret" />

                                            <I18n id={actionKey} />
                                        </Nav.Item>
                                        <Nav.Item className="btn btn-outline" href="#">
                                            <Image src="images/delete-message.svg" className="svg-icon svg-caret" />
                                            <I18n id="administration.delete" />
                                        </Nav.Item>
                                    </Nav>
                                </Grid>
                            )}
                        </div>
                    )}
                </AdministrationHeading>
            </Fragment>
        );
    };

    renderAdvancedContent = () => {
        const { user, signatureLevel, schemeName, userInfo } = this.props;

        return (
            <Fragment>
                {this.renderHeader()}
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="container--layout flex-grow-1">
                            <Container.Column md={4} sm={12}>
                                <Container.ColumnHeader
                                    title="administration.channels.title"
                                    linkText="administration.configure"
                                    url={`/administration/${schemeName}/channels/${user.idUser}`}
                                />
                                <Container.ColumnBody>
                                    <CapsList />
                                </Container.ColumnBody>
                                <Container.ColumnHeader
                                    title="administration.permissions.signature"
                                    linkText="administration.configure"
                                    url={`/administration/${schemeName}/signature/${user.idUser}/confirm`}
                                />
                                <Container.ColumnBody>
                                    <p>{signatureLevel || i18nUtils.get("global.no")}</p>
                                </Container.ColumnBody>
                            </Container.Column>
                            <Container.Column md={4} sm={12}>
                                <Container.ColumnHeader
                                    title="administration.groups.index.title"
                                    linkText="administration.configure"
                                    url={`/administration/${schemeName}/groupsOfUser/${user.idUser}`}
                                />
                                <Container.ColumnBody>
                                    <UserDetailsGroupList />
                                </Container.ColumnBody>
                                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                                    <Container.ColumnHeader
                                        title="administration.dispatcher.title"
                                        linkText={`administration.users.dispatcher.${userInfo.dispatcher}`}
                                        url={`/administration/users/dispatcher/${user.idUser}/confirm`}
                                    />
                                    <Container.ColumnBody>
                                        <p>
                                            {userInfo.dispatcher
                                                ? i18nUtils.get("global.yes")
                                                : i18nUtils.get("global.no")}
                                        </p>
                                    </Container.ColumnBody>
                                </FeatureFlag>
                            </Container.Column>
                            <Container.Column md={4} sm={12}>
                                <Container.ColumnHeader title="administration.restrictions.title" />
                                <Container.ColumnBody>
                                    <RestrictionsUserProfile idUser={user.idUser} />
                                </Container.ColumnBody>
                            </Container.Column>
                        </Container>
                    </div>
                </MainContainer>{" "}
            </Fragment>
        );
    };

    renderMediumContent = () => {
        const { user, hasSignatureEnabled, schemeName, userInfo } = this.props;

        return (
            <Fragment>
                {this.renderHeader()}
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="container--layout flex-grow-1">
                            <Container.Column md={4} sm={12}>
                                <Container.ColumnHeader
                                    title="administration.channels.title"
                                    linkText="administration.configure"
                                    url={`/administration/${schemeName}/channels/${user.idUser}`}
                                />
                                <Container.ColumnBody>
                                    <CapsList />
                                </Container.ColumnBody>
                                <Container.ColumnHeader
                                    title="administration.permissions.signature"
                                    linkText={`administration.signature.configure.text.${hasSignatureEnabled}`}
                                    url={`/administration/${schemeName}/signature/${user.idUser}/confirm`}
                                />
                                <Container.ColumnBody>
                                    <p>
                                        <I18n id={`administration.signature.${hasSignatureEnabled}`} />
                                    </p>
                                </Container.ColumnBody>
                            </Container.Column>
                            <Container.Column md={4} sm={12}>
                                <Container.ColumnHeader
                                    title="administration.permissions"
                                    linkText="administration.configure"
                                    url={`/administration/${schemeName}/permissions/${user.idUser}`}
                                />
                                <Container.ColumnBody columnClass="list-wrapper">
                                    <PermissionsList>
                                        {(list) => list || i18nUtils.get("administration.permissions.empty")}
                                    </PermissionsList>
                                </Container.ColumnBody>
                                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                                    <Container.ColumnHeader
                                        title="administration.dispatcher.title"
                                        linkText={`administration.users.dispatcher.${userInfo.dispatcher}`}
                                        url={`/administration/users/dispatcher/${user.idUser}/confirm`}
                                    />
                                    <Container.ColumnBody>
                                        <p>
                                            {userInfo.dispatcher
                                                ? i18nUtils.get("global.yes")
                                                : i18nUtils.get("global.no")}
                                        </p>
                                    </Container.ColumnBody>
                                </FeatureFlag>
                            </Container.Column>

                            <Container.Column md={4} sm={12}>
                                <Container.ColumnHeader title="administration.restrictions.title" />
                                <Container.ColumnBody>
                                    <RestrictionsUserProfile idUser={user.idUser} />
                                </Container.ColumnBody>
                            </Container.Column>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    };

    renderContent = () => {
        const { schemeName } = this.props;

        if (schemeName === "medium") {
            return this.renderMediumContent();
        }

        return this.renderAdvancedContent();
    };

    render() {
        const { fetching } = this.props;
        const isLoading = fetching; // && !Object.keys(user).length;

        return (
            <Fragment>
                <Notification scopeToShow="administration" />
                <PageLoading loading={isLoading}>{!isLoading && this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

export default Details;
