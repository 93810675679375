/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from "classnames";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { arrayOf, bool, element, node, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config, Spring } from "react-spring";
import { selectors as desktopSelectors } from "reducers/desktop";
import { selectors as i18nSelectors } from "reducers/i18n";

const MenuDropdown = ({
    children,
    className,
    isSidebarCollapsed,
    openWrapperClassName,
    fetching,
    titleKey,
    image,
    bsStyle,
    disabled,
    notificationCount,
    notificationCountTextID,
    location,
    id,
    // eslint-disable-next-line no-unused-vars
    lang,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasActiveDropdown, setHasActiveDropdown] = useState(false);
    const pathCurrentSplit = `${location?.pathname}/`;

    const hasActive = children?.some((c) => pathCurrentSplit.includes(`${c?.props?.linkTo}/`));

    useEffect(() => {
        setHasActiveDropdown(hasActive && !isOpen);
    }, [isOpen, hasActive]);

    useEffect(() => {
        if (isSidebarCollapsed) {
            setIsOpen(false);
        }
    }, [isSidebarCollapsed]);

    const handleClick = () => {
        setIsOpen((open) => !open);
    };

    const onBlurHandler = (ix) => {
        setIsOpen(!ix);
    };

    // eslint-disable-next-line no-unused-vars
    const onKeyHandler = (e) => {
        // if (e.key === "Tab") {
        //     setIsOpen(true);
        // }
    };

    return (
        <Box
            id={id}
            component="li"
            className={classNames("menu-dropdown", className, { "is-active-dropdown": hasActiveDropdown })}
            ref={node}>
            <Box display="flex" fullWidth alignX="between" alignY="center">
                <Button
                    image={image}
                    loading={fetching}
                    onClick={handleClick}
                    className="menu-dropdown-btn"
                    labelClassName="menu-dropdown-btn-text"
                    label={titleKey}
                    bsStyle={bsStyle}
                    expandable
                    aria-haspopup
                    aria-expanded={isOpen}
                    disabled={disabled}>
                    {notificationCount > 0 && (
                        <>
                            <Badge count={notificationCount} />
                            <Text labelKey={notificationCountTextID} className="visually-hidden" />
                        </>
                    )}

                    <Image
                        className={classNames({ "rotate-arrow": isOpen })}
                        wrapperClassName={classNames("menu-dropdown-arrow")}
                        src="images/styledmenu-arrow-down-evenodd.svg"
                        width="3"
                    />
                </Button>
            </Box>
            {isOpen && (
                <Spring
                    config={config.stiff}
                    from={{ height: 0 }}
                    to={{
                        height: "auto",
                        overflow: "hidden",
                    }}>
                    {(styles) =>
                        !fetching && (
                            <ul
                                style={styles}
                                className={classNames("menu-dropdown-ul", openWrapperClassName, {
                                    "is-opened": isOpen,
                                })}>
                                {children &&
                                    children.map((child, ix) => (
                                        <Box
                                            component="li"
                                            onBlur={() => ix === children.length - 1 && onBlurHandler(true)}
                                            onKeyDown={(e) => onKeyHandler(e)}
                                            className="menu-dropdown-item">
                                            {React.cloneElement(child, { component: "div" })}
                                        </Box>
                                    ))}
                            </ul>
                        )
                    }
                </Spring>
            )}
        </Box>
    );
};

MenuDropdown.propTypes = {
    children: arrayOf(element).isRequired,
    isSidebarCollapsed: bool.isRequired,
    className: string,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    openWrapperClassName: string,
    fetching: bool,
    titleKey: string,
    image: string,
    bsStyle: string,
    disabled: bool,
    notificationCount: number,
    notificationCountTextID: string,
    lang: string.isRequired,
    id: string,
};

MenuDropdown.defaultProps = {
    className: null,
    bsStyle: null,
    fetching: false,
    openWrapperClassName: null,
    titleKey: null,
    image: null,
    disabled: false,
    notificationCount: 0,
    notificationCountTextID: "",
    id: null,
};

const mapStateToProps = (state) => ({
    isSidebarCollapsed: desktopSelectors.isSidebarCollapsed(state),
    lang: i18nSelectors.getLang(state),
});

export default withRouter(connect(mapStateToProps)(MenuDropdown));
