import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import * as configUtil from "util/config";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";

const FORM_ID = "settings.personalDataUpdateStep2";

const PersonalDataUpdateStep2 = ({
    isSubmitting,
    isFetching,
    isDesktop,
    dispatch,
    exchangeToken,
    preDataStep2,
    currentUserData,
    isDisabledStep2,
    setFieldValue,
    isDisabledDistrict,
    isDisabledCity,
    isDisabledJurisdiction,
    provinceListAux,
    districtByProvinceList,
    jurisdictionByDistrictList,
    cityByStateProvinceList,
    isFetchingProvince,
    isFetchingDistrict,
    isFetchingJuridistion,
    isFetchingCity,
}) => {
    // Section 1
    const [countryId, setCountryId] = useState();
    const [province, setProvince] = useState();
    const [district, setDistrict] = useState();
    const [jurisdiction, setJurisdiction] = useState();
    const [street, setStreet] = useState();
    const [building, setBuilding] = useState();
    const [house, setHouse] = useState();
    const [reference, setReference] = useState();
    const [city, setCity] = useState();

    // Selectors
    const [countryList, setCountryList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [jurisdictionList, setJurisdictionList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const { setLoading } = useLoadingGlobalProvider();
    const countriesWithCityCatalogue = configUtil.getArray("settings.userData.cityCatalogueCountries");

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        dispatch(updateUserDataActions.preFormStep2(exchangeToken));
    }, [dispatch]);

    useEffect(
        () => () => {
            dispatch(updateUserDataActions.enableStep2(false));
            dispatch(updateUserDataActions.disableDistrict(true));
            dispatch(updateUserDataActions.disableJurisdiction(true));
            dispatch(updateUserDataActions.disableCity(true));
        },
        [],
    );

    useEffect(() => {
        if (!isFetching && currentUserData) {
            const Country = currentUserData.ResidenceCountry;

            const {
                AddrDesc,
                Building,
                CountyDistrict,
                HouseNumber,
                Jurisdiction,
                Province,
                Street,
                City,
            } = currentUserData.PersonData?.Contact?.Locator?.PostAddr[0];

            const countryCode =
                Country?.CountryCode === "Panama" || Country?.CountryCode === "Panamá" ? "PA" : Country?.CountryCode;
            setCountryId(countryCode);
            setFieldValue("country", countryCode);

            const provinceCode = Province?.Cod;
            setProvince(provinceCode);

            if (countryCode === "PA") {
                setFieldValue("province", provinceCode);

                const districtCode = `${provinceCode}${CountyDistrict?.Cod}`;
                setDistrict(districtCode);
                setFieldValue("district", districtCode);

                setJurisdiction(Jurisdiction?.Cod);
                setFieldValue("corregiment", Jurisdiction?.Cod);

                setReference(AddrDesc);
                setFieldValue("referencePoint", AddrDesc);
            }

            setStreet(Street);
            setFieldValue("street", Street);

            setBuilding(Building?.Desc);
            setFieldValue("residentialBuilding", Building?.Desc);

            setHouse(HouseNumber);
            setFieldValue("floorApartmentHouse", HouseNumber);

            if (countriesWithCityCatalogue.includes(countryCode)) {
                setFieldValue("stateProvince", provinceCode);
                setCity(City?.Cod);
                setFieldValue("city", City?.Cod);
            } else if (countryCode !== "PA") {
                setFieldValue("stateProvince", provinceCode);
                setCity(City?.Desc);
                setFieldValue("city", City?.Desc);
            }
        }
    }, []);

    useEffect(() => {
        if (!isFetching && preDataStep2) {
            setCountryList(preDataStep2?.countryList);
            setProvinceList(preDataStep2?.provinceList);
            setDistrictList(preDataStep2?.districtList);
            setJurisdictionList(preDataStep2?.jurisdictionList);
            setCityList(preDataStep2?.cityList);
        }
    }, [preDataStep2]);

    const enableStep2 = () => {
        dispatch(updateUserDataActions.enableStep2(!isDisabledStep2));
    };

    const handleChangeCountry = (countryCode) => {
        setCountryId(countryCode);
        setFieldValue("country", countryCode);
        setProvince("");
        setFieldValue("province", "");

        if (countryCode === "PA") {
            setDistrict("");
            setFieldValue("district", "");
            setJurisdiction("");
            setFieldValue("corregiment", "");
            if (!isDisabledDistrict) {
                dispatch(updateUserDataActions.disableDistrict(true));
            }

            if (!isDisabledJurisdiction) {
                dispatch(updateUserDataActions.disableJurisdiction(true));
            }
        } else {
            setFieldValue("stateProvince", "");
            setCity("");
            setFieldValue("city", "");
            if (!isDisabledCity) {
                dispatch(updateUserDataActions.disableCity(true));
            }
        }

        dispatch(updateUserDataActions.getProvinceListRequest(countryCode));
    };

    useEffect(() => {
        if (!isFetching && !isDisabledStep2 && provinceListAux && provinceListAux.length > 0) {
            setProvinceList(provinceListAux);
        }
    }, [provinceListAux]);

    const handleChangeProvince = (provinceCode) => {
        setProvince(provinceCode);
        setFieldValue("province", provinceCode);

        if (countryId === "PA") {
            setDistrict("");
            setFieldValue("district", "");
            setJurisdiction("");
            setFieldValue("corregiment", "");

            if (isDisabledDistrict) {
                dispatch(updateUserDataActions.disableDistrict(false));
            }
            dispatch(updateUserDataActions.disableJurisdiction(true));
            dispatch(updateUserDataActions.getDistrictListRequest(provinceCode));
        } else if (countryId !== "PA") {
            setCity("");
            setFieldValue("city", "");

            dispatch(updateUserDataActions.disableCity(false));
            dispatch(updateUserDataActions.getCityListRequest(provinceCode));
        }
    };

    useEffect(() => {
        if (!isFetching && !isDisabledStep2 && districtByProvinceList && districtByProvinceList.length > 0) {
            setDistrictList(districtByProvinceList);
        }
    }, [districtByProvinceList]);

    const handleChangeDistrict = (districtCode) => {
        setDistrict(districtCode);
        setFieldValue("district", districtCode);
        setJurisdiction("");
        setFieldValue("corregiment", "");
        dispatch(updateUserDataActions.disableJurisdiction(false));
        dispatch(updateUserDataActions.getJurisdictionListRequest(districtCode));
    };

    useEffect(() => {
        if (!isFetching && !isDisabledStep2 && jurisdictionByDistrictList && jurisdictionByDistrictList.length > 0) {
            setJurisdictionList(jurisdictionByDistrictList);
        }
    }, [jurisdictionByDistrictList]);

    const handleChangeJurisdiction = (jurisdictionCode) => {
        setJurisdiction(jurisdictionCode);
        setFieldValue("corregiment", jurisdictionCode);
    };

    useEffect(() => {
        if (!isFetching && !isDisabledStep2 && cityByStateProvinceList && cityByStateProvinceList.length > 0) {
            setCityList(cityByStateProvinceList);
        }
    }, [cityByStateProvinceList]);

    const handleChangeCity = (cityCode) => {
        setCity(cityCode);
        setFieldValue("city", cityCode);
    };

    const handleCancel = () => {
        dispatch(updateUserDataActions.enableStep2(false));
        dispatch(updateUserDataActions.disableDistrict(true));
        dispatch(updateUserDataActions.disableJurisdiction(true));
        dispatch(updateUserDataActions.disableCity(true));
        dispatch(push("/desktop"));
    };

    const renderLoading = () => (
        <Box
            display="flex"
            alignY="center"
            alignX="flex-start"
            className="pt-7 px-2"
            fullHeight
            fullWidth
            position="absolute"
            left="0"
            top="0">
            <Button loading bsStyle="link" className="min-width-12" />
            <Box
                display="flex"
                alignY="center"
                alignX="center"
                className="min-height-7 min-width-12 background-loading-in-select"
                fullWidth
                position="absolute"
                borderRadius="form"
                background="component-background"
                top="8"
                left="0"
            />
        </Box>
    );

    return (
        <>
            <Notification scopeToShow="personalDataUpdate" />
            <Form className="mx-n-5">
                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 pt-7 pb-10 pb-md-11 mb-5 mb-md-8"
                    borderRadius="default">
                    <Box className="mb-8">
                        <Field
                            component={SwitchField}
                            idForm={FORM_ID}
                            name="modifyDataControl"
                            onChange={enableStep2}
                        />
                    </Box>

                    <Row>
                        <Col xs={6}>
                            <Field
                                idForm={FORM_ID}
                                name="country"
                                component={Selector}
                                options={countryList}
                                value={countryId}
                                valueKey="value"
                                labelKey="label"
                                labelNoMarginTop
                                disabled={isDisabledStep2}
                                handleChange={handleChangeCountry}
                                searchable
                            />
                        </Col>
                        {!isFetching && countryId === "PA" && (
                            <>
                                <Col xs={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="province"
                                            component={Selector}
                                            options={provinceList}
                                            value={province}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep2}
                                            handleChange={handleChangeProvince}
                                            searchable
                                        />
                                        {isFetchingProvince && renderLoading()}
                                    </Box>
                                </Col>
                                <Col xs={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="district"
                                            component={Selector}
                                            options={districtList}
                                            value={district}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep2 || isDisabledDistrict}
                                            handleChange={handleChangeDistrict}
                                            searchable
                                        />
                                        {isFetchingDistrict && renderLoading()}
                                    </Box>
                                </Col>
                                <Col xs={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="corregiment"
                                            component={Selector}
                                            options={jurisdictionList}
                                            value={jurisdiction}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep2 || isDisabledJurisdiction}
                                            handleChange={handleChangeJurisdiction}
                                            searchable
                                        />
                                        {isFetchingJuridistion && renderLoading()}
                                    </Box>
                                </Col>
                            </>
                        )}
                        {!isFetching && countriesWithCityCatalogue.includes(countryId) && (
                            <>
                                <Col xs={6} md={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="stateProvince"
                                            component={Selector}
                                            options={provinceList}
                                            value={province}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep2}
                                            handleChange={handleChangeProvince}
                                            searchable
                                        />
                                        {isFetchingProvince && renderLoading()}
                                    </Box>
                                </Col>
                            </>
                        )}
                        {!isFetching && countriesWithCityCatalogue.includes(countryId) && (
                            <>
                                <Col xs={12}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="city"
                                            component={Selector}
                                            options={cityList}
                                            value={city}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep2 || isDisabledCity}
                                            handleChange={handleChangeCity}
                                            searchable
                                        />
                                        {isFetchingCity && renderLoading()}
                                    </Box>
                                </Col>
                            </>
                        )}
                        {!isFetching && countryId !== "PA" && !countriesWithCityCatalogue.includes(countryId) && (
                            <>
                                <Col xs={6} md={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="stateProvince"
                                            component={Selector}
                                            options={provinceList}
                                            value={province}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep2}
                                            handleChange={handleChangeProvince}
                                            searchable
                                        />
                                        {isFetchingProvince && renderLoading()}
                                    </Box>
                                </Col>
                                <Col xs={12}>
                                    <Field
                                        idForm={FORM_ID}
                                        name="city"
                                        component={TextField}
                                        initValue={city}
                                        type="text"
                                        labelNoMarginTop
                                        disabled={isDisabledStep2 || isDisabledCity}
                                        maxLength="35"
                                        pattern="^[a-zA-Z0-9 ]*$"
                                    />
                                </Col>
                            </>
                        )}
                        <Col xs={12}>
                            <Field
                                idForm={FORM_ID}
                                name="street"
                                component={TextField}
                                initValue={street}
                                type="text"
                                labelNoMarginTop
                                disabled={isDisabledStep2}
                                maxLength="35"
                                pattern="^[a-zA-Z0-9.,\/\- ]*$"
                            />
                        </Col>
                        <Col xs={6} md={countryId === "PA" ? 4 : 6}>
                            <Field
                                idForm={FORM_ID}
                                name="residentialBuilding"
                                component={TextField}
                                type="text"
                                labelNoMarginTop
                                initValue={building}
                                disabled={isDisabledStep2}
                                maxLength="45"
                                pattern="^[a-zA-Z0-9.,\/\- ]*$"
                            />
                        </Col>
                        <Col xs={6} md={countryId === "PA" ? 4 : 6}>
                            <Field
                                idForm={FORM_ID}
                                name="floorApartmentHouse"
                                component={TextField}
                                type="text"
                                labelNoMarginTop
                                initValue={house}
                                disabled={isDisabledStep2}
                                maxLength="45"
                                pattern="^[a-zA-Z0-9.,\/\- ]*$"
                            />
                        </Col>
                        {!isFetching && countryId === "PA" && (
                            <>
                                <Col xs={12} md={4}>
                                    <Field
                                        idForm={FORM_ID}
                                        name="referencePoint"
                                        component={TextField}
                                        type="text"
                                        labelNoMarginTop
                                        initValue={reference}
                                        disabled={isDisabledStep2}
                                        pattern="^[a-zA-Z0-9 ,.]*$"
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                </Box>
                <Row className="px-5 px-md-0" {...(!isDesktop && { gapY: "3" })}>
                    <Col xs={12} md={4} mdOffset={2}>
                        <Button label="global.cancel" bsStyle="outline" onClick={handleCancel} block />
                    </Col>
                    <Col xs={12} md={4} {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            label="global.continue"
                            loading={isSubmitting || isFetching}
                            type="submit"
                            bsStyle="primary"
                            block
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

PersonalDataUpdateStep2.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    exchangeToken: string.isRequired,
    preDataStep2: shape({}),
    currentUserData: shape({}),
    isDisabledStep2: bool,
    isDisabledDistrict: bool,
    isDisabledJurisdiction: bool,
    isDisabledCity: bool,
    setFieldValue: func.isRequired,
    districtByProvinceList: shape({}),
    jurisdictionByDistrictList: shape({}),
    cityByStateProvinceList: shape({}),
    provinceListAux: shape({}),
    isFetchingProvince: bool,
    isFetchingDistrict: bool,
    isFetchingJuridistion: bool,
    isFetchingCity: bool,
};

PersonalDataUpdateStep2.defaultProps = {
    isFetching: true,
    currentUserData: null,
    preDataStep2: null,
    isDisabledStep2: true,
    isDisabledDistrict: true,
    isDisabledJurisdiction: true,
    isDisabledCity: true,
    districtByProvinceList: {},
    jurisdictionByDistrictList: {},
    cityByStateProvinceList: {},
    provinceListAux: {},
    isFetchingProvince: false,
    isFetchingDistrict: false,
    isFetchingJuridistion: false,
    isFetchingCity: false,
};

const mapStateToProps = (state) => ({
    isFetching: updateUserDataSelectors.isFetching(state),
    exchangeToken: updateUserDataSelectors.getExchangeToken(state),
    currentUserData: updateUserDataSelectors.getCurrentUserData(state),
    preDataStep2: updateUserDataSelectors.getPreDataStep2(state),
    isDisabledStep2: updateUserDataSelectors.isDisabledStep2(state),
    isDisabledDistrict: updateUserDataSelectors.isDisabledDistrict(state),
    isDisabledJurisdiction: updateUserDataSelectors.isDisabledJurisdiction(state),
    isDisabledCity: updateUserDataSelectors.isDisabledCity(state),
    districtByProvinceList: updateUserDataSelectors.getDistrictByProvinceList(state),
    jurisdictionByDistrictList: updateUserDataSelectors.getJurisdictionList(state),
    provinceListAux: updateUserDataSelectors.getProvinceList(state),
    cityByStateProvinceList: updateUserDataSelectors.getCityList(state),
    isFetchingProvince: updateUserDataSelectors.isFetchingProvince(state),
    isFetchingDistrict: updateUserDataSelectors.isFetchingDistrict(state),
    isFetchingJuridistion: updateUserDataSelectors.isFetchingJuridistion(state),
    isFetchingCity: updateUserDataSelectors.isFetchingCity(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            country: "",
            province: "",
            stateProvince: "",
            district: "",
            corregiment: "",
            street: "",
            residentialBuilding: "",
            floorApartmentHouse: "",
            referencePoint: "",
            city: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                country: Yup.string().required(i18n.get(`${FORM_ID}.country.required`)),
                province: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().required(i18n.get(`${FORM_ID}.province.required`)),
                    otherwise: Yup.string().notRequired(),
                }),
                stateProvince: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().notRequired(),
                    otherwise: Yup.string().required(i18n.get(`${FORM_ID}.stateProvince.required`)),
                }),
                district: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().required(i18n.get(`${FORM_ID}.district.required`)),
                }),
                corregiment: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().required(i18n.get(`${FORM_ID}.corregiment.required`)),
                }),
                city: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().notRequired(),
                    otherwise: Yup.string().required(i18n.get(`${FORM_ID}.city.required`)),
                }),
                street: Yup.string().required(i18n.get(`${FORM_ID}.street.required`)),
                residentialBuilding: Yup.string().required(i18n.get(`${FORM_ID}.residentialBuilding.required`)),
                floorApartmentHouse: Yup.string().required(i18n.get(`${FORM_ID}.floorApartmentHouse.required`)),
                referencePoint: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().required(i18n.get(`${FORM_ID}.referencePoint.required`)),
                }),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(updateUserDataActions.saveForm(values, 2, formikBag));
        },
    }),
)(PersonalDataUpdateStep2);
