import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Enviroments from "pages/_components/Enviroments";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import { bool, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "login.step3";

class Step3Content extends Component {
    static propTypes = {
        className: string,
        isSubmitting: bool,
        environments: shape([]).isRequired,
    };

    static defaultProps = {
        className: null,
        isSubmitting: false,
    };

    render() {
        const { className, environments, isSubmitting } = this.props;

        return (
            <Form className={className}>
                <Field
                    name="environment"
                    environments={environments}
                    component={Enviroments}
                    legendTextID="login.step3.environment.required"
                    fromSetDefaultEnvironmentPage={false}
                />
                <Box className="mb-10 mb-md-8">
                    <Field name="rememberEnvironment" idForm={FORM_ID} component={SwitchField} formGroup />
                </Box>
                <Box className="mb-3 mb-md-0">
                    <Button type="submit" loading={isSubmitting} label="login.nextStep" bsStyle="primary" block />
                </Box>
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    environments: Object.entries(loginSelectors.getEnvironments(state)),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ environment: "", rememberEnvironment: false }),
        validationSchema: () =>
            Yup.object().shape({
                environment: Yup.string().required(i18n.get(`${FORM_ID}.environment.required`)),
            }),
        handleSubmit: ({ environment, rememberEnvironment }, formikBag) => {
            formikBag.props.dispatch(loginActions.loginStep3(environment, rememberEnvironment, formikBag));
        },
    }),
)(Step3Content);
