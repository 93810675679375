import classNames from "classnames";
import AccordionItem from "pages/_components/Accordion/AccordionItem";
import Box from "pages/_components/Box";
import { bool, node, string, func, number } from "prop-types";
import React, { Component } from "react";

export const AccordionContext = React.createContext();

class Accordion extends Component {
    static propTypes = {
        accordionBorder: string,
        children: node.isRequired,
        className: string,
        grayBox: bool,
        whiteBox: bool,
        simpleStyle: bool,
        noMargin: bool,
        customHandlerClick: func,
        defaultOpenItem: number,
    };

    static defaultProps = {
        accordionBorder: "md",
        className: "navigational-list",
        grayBox: false,
        whiteBox: false,
        simpleStyle: false,
        noMargin: false,
        customHandlerClick: null,
        defaultOpenItem: null,
    };

    state = {
        itemOpen: this.props.defaultOpenItem,
    };

    validateActive = (isActive) => {
        const { customHandlerClick } = this.props;
        if (customHandlerClick) {
            customHandlerClick(isActive);
        }
    };

    handleClick = (num) => {
        const { itemOpen } = this.state;
        if (num === itemOpen) {
            this.setState({ itemOpen: null });
        } else {
            this.setState({ itemOpen: num });
        }
    };

    static Item = AccordionItem;

    render() {
        const { className, children, grayBox, whiteBox, simpleStyle, noMargin, accordionBorder } = this.props;
        const { itemOpen } = this.state;

        return (
            <AccordionContext.Provider
                value={{ itemOpen, onClick: this.handleClick, validateActive: this.validateActive }}>
                <Box
                    component="ul"
                    overflow="hidden"
                    borderRadius={accordionBorder}
                    className={classNames(className, {
                        "accordion-boxed accordion-gray-boxed": grayBox,
                        "accordion-boxed accordion-white-boxed": whiteBox,
                        "simple-style": simpleStyle,
                        "m-0": noMargin,
                    })}>
                    {children}
                </Box>
            </AccordionContext.Provider>
        );
    }
}

export default Accordion;
