import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React from "react";

const EmptyTextBox = ({ footerLink, isDesktop, text }) => (
    <Box
        display="flex"
        column
        alignX="center"
        alignY="center"
        borderRadius="default"
        border="dashed-image-border-base-color"
        background="component-background"
        className="px-5 py-5 px-md-9 py-md-4 mt-md-12">
        <Box display="inline-block" className="full-width text-align-center mb-4">
            <Text size={isDesktop ? "6" : "5"} color="heading" align="center" className="mr-2" labelKey={text} />
        </Box>
        {footerLink && (
            <Box display="inline-block" className="full-width text-align-center">
                <Text
                    size={isDesktop ? "7" : "6"}
                    color="heading"
                    align="center"
                    labelKey="product.empty.footText.first"
                />
                <Button
                    to="/formCustom/createFixedTermDepositForm"
                    bsStyle="link"
                    className="py-0 px-2"
                    externalHref="http://banesco.com.pa"
                    removeMinHeight>
                    <Text size={isDesktop ? "7" : "6"} color="primary">
                        http://banesco.com.pa
                    </Text>
                </Button>
                <Text
                    size={isDesktop ? "7" : "6"}
                    color="heading"
                    align="center"
                    labelKey="product.empty.footText.second"
                />
            </Box>
        )}
    </Box>
);

EmptyTextBox.propTypes = {
    footerLink: bool,
    isDesktop: bool.isRequired,
    text: string,
};

EmptyTextBox.defaultProps = {
    footerLink: false,
    text: null,
};

export default resizableRoute(EmptyTextBox);
