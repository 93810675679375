import Button from "pages/_components/Button";
import FeatureFlag from "pages/_components/FeatureFlag";
import Checkbox from "pages/_components/fields/Checkbox";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import { arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";
import Table from "rc-table";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/users";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";

const UserName = ({ name, dispatcher, massiveEnabled }) => (
    <div className="data-wrapper data-wrapper-flex">
        <span className="data-desc">{name}</span>
        {!massiveEnabled && <Image src="images/administrator.svg" className="svg-icon svg-caret" />}
        <FeatureFlag id="feature.signatureSchema.dispatchControl">
            {dispatcher && <Image src="images/dispatcher.svg" className="svg-icon svg-caret" />}
        </FeatureFlag>
    </div>
);

UserName.propTypes = {
    name: string.isRequired,
    dispatcher: bool.isRequired,
    massiveEnabled: bool.isRequired,
};

class AdministrationUsers extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        activeEnvironment: shape({ administrationScheme: string }).isRequired,
        users: arrayOf(shape({ fullName: string, email: string, lastLoginAsMediumString: string })).isRequired,
        usersInfo: objectOf(
            shape({
                massiveEnabled: bool.isRequired,
                status: string.isRequired,
                signatureLevel: string,
            }),
        ).isRequired,
        currentPage: number,
        totalPages: number,
        fetching: bool,
        fetchingExport: bool,
        fetchingMoreUsers: bool,
        hasMoreData: bool,
    };

    static defaultProps = {
        currentPage: 0,
        totalPages: 0,
        fetching: false,
        fetchingExport: false,
        fetchingMoreUsers: false,
        hasMoreData: false,
    };

    state = {
        selectedUsers: new Map(),
        selectAllChecked: false,
        blockedQuantity: 0,
        unblockedQuantity: 0,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.loadListRequest());
    }

    onRowClick = (record) => {
        const {
            dispatch,
            activeEnvironment: { administrationScheme },
        } = this.props;
        const path = {
            simple: "simple/permissions",
            medium: "medium/details",
            advanced: "advanced/details",
        };

        dispatch(routerActions.push(`/administration/${path[administrationScheme]}/${record.idUser}`));
    };

    signatureToLabel = (signatureLevel) => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;
        if (administrationScheme === "medium") {
            return signatureLevel === "A" ? i18n.get("global.yes") : i18n.get("global.no");
        }
        return signatureLevel || i18n.get("global.no");
    };

    generateTableColumns = (massiveDissabledQuantity) => {
        const { selectAllChecked, selectedUsers } = this.state;
        const { activeEnvironment, users } = this.props;
        const columnArray = [
            {
                key: "check",
                dataIndex: "check",
                title: (
                    <Checkbox
                        hideLabel
                        onChange={this.handleSelectAll}
                        name="selectAllUsers"
                        checked={
                            selectAllChecked ||
                            (users.length === selectedUsers.size + massiveDissabledQuantity && selectedUsers.size !== 0)
                        }
                        disabled={users.length === massiveDissabledQuantity}
                    />
                ),
                width: 20,
                onCell: () => ({
                    onClick(e) {
                        e.stopPropagation();
                    },
                }),
            },
            {
                key: "userName",
                dataIndex: "userName",
                title: i18n.get("administration.users.list.username"),
                width: 150,
            },
            {
                className: "text-ellipsis",
                key: "email",
                dataIndex: "email",
                title: i18n.get("administration.users.list.email"),
                width: 300,
            },
            {
                key: "signature",
                dataIndex: "signature",
                title: i18n.get("administration.users.list.signature"),
                width: 50,
            },
            {
                className: "text-center",
                key: "lastLogin",
                dataIndex: "lastLogin",
                title: i18n.get("administration.users.list.lastLogin"),
                width: 150,
            },
            {
                className: "text-center",
                key: "status",
                dataIndex: "status",
                title: i18n.get("administration.users.list.status"),
                width: 100,
            },
        ];
        if (activeEnvironment.administrationScheme !== "simple") {
            return columnArray;
        }
        const auxArray = columnArray.slice(1, columnArray.length);
        auxArray.splice(2, 1);
        return auxArray;
    };

    populateUsersData = (massiveDissabledQuantity) => {
        const { activeEnvironment, users, usersInfo } = this.props;
        const { selectedUsers, selectAllChecked } = this.state;

        return users.map((user) => ({
            key: user.idUser,
            check: activeEnvironment.administrationScheme !== "simple" && (
                <Checkbox
                    onChange={() => this.handleCheckClick(user.idUser, user.fullName, massiveDissabledQuantity)}
                    hideLabel
                    id={user.idUser}
                    name={user.idUser}
                    checked={
                        usersInfo[user.idUser].massiveEnabled && (selectAllChecked || selectedUsers.has(user.idUser))
                    }
                    disabled={!usersInfo[user.idUser].massiveEnabled}
                />
            ),
            idUser: user.idUser,
            userName: (
                <UserName
                    name={user.fullName}
                    massiveEnabled={usersInfo[user.idUser].massiveEnabled}
                    dispatcher={usersInfo[user.idUser].dispatcher}
                />
            ),
            email: user.email,
            signature: this.signatureToLabel(usersInfo[user.idUser].signatureLevel),
            lastLogin: user.lastLoginAsMediumString,
            status: (
                <div className="data-wrapper data-wrapper-flex">
                    <div
                        className={`data-tag ${usersInfo[user.idUser].status === "blocked" ? "data-tag-blocked" : ""}`}>
                        <span> {i18n.get(`user.status.${usersInfo[user.idUser].status}`)} </span>
                    </div>
                </div>
            ),
        }));
    };

    renderPageHeader = () => {
        const { activeEnvironment, fetchingExport } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="administrationUsers" />
                <Head
                    title="administration.users.list.title"
                    exportList
                    handleClick={this.handleClickDownload}
                    handleInviteClick={
                        activeEnvironment.administrationScheme !== "simple" ? this.handleInviteUserClcik : null
                    }
                    isFetchingExport={fetchingExport}
                />
            </Fragment>
        );
    };

    handleCheckClick = (idUserChecked, nameUserChecked, massiveDissabledQuantity) => {
        const { users, usersInfo } = this.props;
        const { selectedUsers, selectAllChecked } = this.state;
        let { blockedQuantity, unblockedQuantity } = this.state;

        if (!selectedUsers.has(idUserChecked)) {
            selectedUsers.set(idUserChecked, nameUserChecked);
            if (usersInfo[idUserChecked].status === "blocked") {
                blockedQuantity += 1;
            } else {
                unblockedQuantity += 1;
            }

            if (selectedUsers.size === users.length - massiveDissabledQuantity) {
                this.setState({
                    selectAllChecked: true,
                });
            }
        } else {
            selectedUsers.delete(idUserChecked);
            if (usersInfo[idUserChecked].status === "blocked") {
                blockedQuantity -= 1;
            } else {
                unblockedQuantity -= 1;
            }
            if (selectAllChecked) {
                this.setState({ selectAllChecked: false });
            }
        }
        this.setState({
            selectedUsers,
            blockedQuantity,
            unblockedQuantity,
        });
    };

    handleSelectAll = () => {
        const { selectAllChecked } = this.state;
        const { users, usersInfo } = this.props;
        const newSelectedUsers = new Map();
        let blockedQuantity = 0;
        let unblockedQuantity = 0;
        if (!selectAllChecked) {
            users.forEach((user) => {
                if (usersInfo[user.idUser].massiveEnabled) {
                    newSelectedUsers.set(user.idUser, user.fullName);
                    if (usersInfo[user.idUser].status === "blocked") {
                        blockedQuantity += 1;
                    } else {
                        unblockedQuantity += 1;
                    }
                }
            });
        }

        this.setState((prevState) => ({
            selectAllChecked: !prevState.selectAllChecked,
            selectedUsers: newSelectedUsers,
            blockedQuantity,
            unblockedQuantity,
        }));
    };

    handleMoreDataClick = () => {
        const { dispatch, currentPage } = this.props;

        this.setState({ selectAllChecked: false });
        dispatch(actions.loadMoreRequest({ pageNumber: currentPage + 1 }));
    };

    handleClickDownload = (format) => {
        const { dispatch } = this.props;

        dispatch(actions.exportListRequest(format));
    };

    handleActionClick = (action) => {
        const { dispatch } = this.props;
        const { selectedUsers } = this.state;

        dispatch(actions.changeUserStatusPreview([...selectedUsers.keys()], [...selectedUsers.values()], action));
    };

    handleInviteUserClcik = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.push("/administration/users/invite"));
    };

    renderActionButtons = () => {
        const { selectedUsers, blockedQuantity, unblockedQuantity } = this.state;
        return (
            <Fragment>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                    <div
                        style={{
                            display: "inline-block",
                            marginRight: ".5rem",
                        }}>
                        {selectedUsers.size} {i18n.get("administration.users.list.selected.quantity")}
                    </div>
                    {unblockedQuantity > 0 && (
                        <Button
                            className="btn btn-quiet btn-small"
                            key="block"
                            label="administration.block"
                            onClick={() => this.handleActionClick("block")}
                        />
                    )}
                    {blockedQuantity > 0 && (
                        <Button
                            className="btn btn-quiet btn-small"
                            key="unblock"
                            label="administration.unblock"
                            onClick={() => this.handleActionClick("unblock")}
                        />
                    )}
                    <Button
                        className="btn btn-quiet btn-small"
                        key="delete"
                        label="administration.delete"
                        onClick={() => this.handleActionClick("delete")}
                    />
                </div>
            </Fragment>
        );
    };

    renderTableFooter = () => {
        const { totalPages, hasMoreData, fetchingMoreUsers } = this.props;

        return (
            <div>
                {totalPages > 1 &&
                    (hasMoreData ? (
                        <div className="text-center no-more-data" key="noMoreUsers">
                            <Button
                                className="btn btn-link"
                                onClick={this.handleMoreDataClick}
                                loading={fetchingMoreUsers}
                                image="images/show.svg"
                                label="administration.users.list.more"
                            />
                        </div>
                    ) : (
                        <div className="text-center no-more-data" key="noMoreUsers">
                            <p className="text-lead">
                                <I18n id="administration.users.list.more.noMoreData" />
                            </p>
                        </div>
                    ))}
            </div>
        );
    };

    render() {
        const { usersInfo, totalPages, fetching, users } = this.props;
        const { selectedUsers } = this.state;
        const massiveDissabledQuantity = Object.values(usersInfo).filter((userInfo) => !userInfo.massiveEnabled).length;
        const columns = this.generateTableColumns(massiveDissabledQuantity);

        return (
            <Fragment>
                {this.renderPageHeader()}
                <MainContainer className="main-container" showLoader={fetching && !users.length}>
                    <Row>
                        <Col className="col-12">
                            <div className="flex-container">
                                {selectedUsers.size === 0 ? (
                                    <Fragment>
                                        <h4>{i18n.get("administration.users.index.title")}</h4>
                                    </Fragment>
                                ) : (
                                    <Fragment>{this.renderActionButtons()}</Fragment>
                                )}
                            </div>

                            <Table
                                columns={columns}
                                data={this.populateUsersData(massiveDissabledQuantity)}
                                rowKey={(record) => record.key}
                                emptyText={i18n.get("administration.users.list.noRecords")}
                                onRow={(record) => ({
                                    onClick: () => this.onRowClick(record),
                                })}
                                footer={totalPages > 1 ? this.renderTableFooter : null}
                            />
                        </Col>
                    </Row>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    users: selectors.getUsers(state),
    usersInfo: selectors.getUsersInfo(state),
    currentPage: selectors.getCurrentPage(state),
    totalPages: selectors.getTotalPages(state),
    fetchingExport: selectors.isFetchingExport(state),
    fetching: selectors.isFetching(state),
    fetchingMoreUsers: selectors.isFetchingMoreUsers(state),
    hasMoreData: selectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(AdministrationUsers);
