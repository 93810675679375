import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    TRANSACTIONAL_COUNTRIES_REQUEST: "transactionalProfile/TRANSACTIONAL_COUNTRIES_REQUEST",
    TRANSACTIONAL_COUNTRIES_SUCCESS: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SUCCESS",
    TRANSACTIONAL_COUNTRIES_FAILED: "transactionalProfile/TRANSACTIONAL_COUNTRIES_FAILED",
    TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST",
    TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS",
    TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED",
    TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST: "transactionalProfile/TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST",
    TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS: "transactionalProfile/TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS",
    TRANSACTIONAL_COUNTRIES_UPDATE_FAILED: "transactionalProfile/TRANSACTIONAL_COUNTRIES_UPDATE_FAILED",
    TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST: "transactionalProfile/TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST",
    TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS: "transactionalProfile/TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS",
};

export const INITIAL_STATE = {
    isFetching: false,
    countriesList: [],
    accountList: [],
    credentialsGroups: [],
    hasCompleteCredentialGroups: false,
};

export default createReducer(INITIAL_STATE, {
    [types.TRANSACTIONAL_COUNTRIES_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        countriesList: [],
        hasProfile: null,
        accountSelected: action.account,
    }),

    [types.TRANSACTIONAL_COUNTRIES_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        countriesList: action.countries,
        hasProfile: action.hasProfile,
    }),

    [types.TRANSACTIONAL_COUNTRIES_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        countriesList: [],
        hasProfile: null,
        accountSelected: null,
    }),
    [types.TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        countriesList: [],
        hasProfile: false,
        accountSelected: null,
        accountList: [],
    }),

    [types.TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        countriesList: action.countries,
        hasProfile: action.hasProfile,
        accountList: action.accounts,
        accountSelected: action.account,
    }),

    [types.TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        countriesList: [],
        hasProfile: false,
        accountSelected: null,
        accountList: [],
    }),
    [types.TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),

    [types.TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        countriesList: action.countries,
        hasProfile: action.hasProfile,
    }),

    [types.TRANSACTIONAL_COUNTRIES_UPDATE_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: action.credentialsGroups,
        hasCompleteCredentialGroups: true,
    }),
    [types.TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST]: (state) => ({
        ...state,
        hasCompleteCredentialGroups: false,
    }),
});

export const actions = {
    transactionalCountriesRequest: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_REQUEST, "accountId", "account"),

    transactionalCountriesRequestSuccess: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_SUCCESS,
        "countries",
        "hasProfile",
    ),

    transactionalCountriesRequestFailed: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_FAILED),

    transactionalCountriesSettingRequest: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST, "onClose"),
    transactionalCountriesSettingSuccess: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS,
        "countries",
        "hasProfile",
        "accounts",
        "account",
    ),
    transactionalCountriesSettingFailed: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED),

    transactionalCountriesUpdateRequest: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST,
        "accountId",
        "countriesList",
        "hasProfileState",
        "onFinish",
        "otp",
        "formikBag",
    ),
    transactionalCountriesUpdateSuccess: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS,
        "countries",
        "hasProfile",
    ),
    transactionalCountriesUpdateFailed: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_UPDATE_FAILED),
    transactionalProfileActions: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_UPDATE_FAILED),
    credentialsGroupsRequest: makeActionCreator(types.TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST, "data"),
    credentialsGroupsSuccess: makeActionCreator(types.TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS, "credentialsGroups"),
};

export const selectors = {
    getFetching: ({ transactionalProfile }) => transactionalProfile.isFetching,
    getCountriesList: ({ transactionalProfile }) => transactionalProfile.countriesList,
    getHasProfile: ({ transactionalProfile }) => transactionalProfile.hasProfile,
    getAccount: ({ transactionalProfile }) => transactionalProfile.accountSelected,
    getAccounts: ({ transactionalProfile }) => transactionalProfile.accountList,
    getCredentialsGroups: ({ transactionalProfile }) => transactionalProfile.credentialsGroups,
    hasCompleteCredentialGroups: ({ transactionalProfile }) => transactionalProfile.hasCompleteCredentialGroups,
};
