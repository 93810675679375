import { Field } from "formik";
import Box from "pages/_components/Box";
import Credential from "pages/_components/fields/credentials/Credential";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TokenButtonStatusWrapper from "pages/_components/TokenButton/TokenButtonStatusWrapper";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actions as softTokenActions, selectors as softTokenSelector } from "reducers/softToken";
import { isMobileNativeFunc } from "util/device";
import { ENT000, hasRegisteredIdentity } from "util/softToken.util";
import { TOKEN_STATUS_INVALID_WRAPPER } from "util/userToken.util";

const CredentialTokenComponent = ({
    credentials,
    dispatch,
    tokenStatus,
    onChangeToken,
    propsForm,
    isDesktop,
    isFetching,
    relativeStyleError,
    warningIcon,
}) => {
    const [userHasToken, setUserHasToken] = useState(false);
    const hasCredentials = credentials.length > 0;

    const validateUserToken = async () => {
        if (!isMobileNativeFunc() || !hasCredentials) {
            setUserHasToken(false);
            return;
        }
        dispatch(softTokenActions.getStatusTokenRequest());
        const hasTokenResponse = await hasRegisteredIdentity();
        if (!hasTokenResponse) {
            setUserHasToken(false);
            return;
        }

        const { code: codeIdentity, data: dataIdentity } = JSON.parse(hasTokenResponse);
        if (!codeIdentity || !dataIdentity || codeIdentity !== ENT000 || dataIdentity !== "true") {
            setUserHasToken(false);
            return;
        }

        setUserHasToken(true);
    };

    useEffect(() => {
        validateUserToken();
    }, []);

    const renderCredentials = () => (
        <>
            {credentials.map((credential) => (
                <Box className="form-confirmation-info-wrapper">
                    <Box display="flex" alignX="center" fullWidth className="mb-5">
                        <Text
                            color="text"
                            size={isDesktop ? "7" : "6"}
                            align="center"
                            labelKey="forms.confirmation.enterCredentials"
                        />
                    </Box>

                    <Field
                        {...propsForm}
                        key={credential}
                        idForm="form.credential"
                        name={credential}
                        component={Credential}
                        type={credential}
                        labelNoMarginTop
                        tooltipLightSyled
                        tooltipPosition="left-centered"
                        relativeStyleError={relativeStyleError}
                        warningIcon={warningIcon}
                        tooltipInfo={
                            <Box display="flex" column alignY="center">
                                <Text labelKey="forms.confirmation.tooltipToken" className="tooltip-text mb-3" />
                                <Image wrapperClassName="svg-image" src="images/transfers-tooltip.svg" />
                            </Box>
                        }
                    />
                </Box>
            ))}
        </>
    );
    // eslint-disable-next-line no-confusing-arrow
    // eslint-disable-next-line no-confusing-arrow
    const renderCredentialToken = () =>
        isFetching || (tokenStatus && userHasToken && !TOKEN_STATUS_INVALID_WRAPPER.includes(tokenStatus)) ? (
            <TokenButtonStatusWrapper
                loading={isFetching}
                tokenStatus={tokenStatus}
                onChangeToken={(code) => {
                    if (onChangeToken) {
                        onChangeToken(code);
                    }
                }}
                credentials={credentials}
            />
        ) : (
            renderCredentials()
        );

    const renderDesktop = () => renderCredentials();
    const renderMobile = () => renderCredentialToken();

    const loadingToken = () => (
        <div className="widget-preloader no-background min-height-2rem">
            <div>
                <span />
                <span />
                <span />
            </div>
        </div>
    );

    return (
        <>
            {isFetching && !isMobileNativeFunc() && loadingToken()}
            {!isMobileNativeFunc() && !isFetching && renderDesktop()}
            {isMobileNativeFunc() && renderMobile()}
        </>
    );
};

const mapStateToProps = (state) => ({
    tokenStatus: softTokenSelector.getStatusToken(state),
    isFetching: softTokenSelector.isFetching(state),
});

CredentialTokenComponent.propTypes = {
    credentials: arrayOf(string).isRequired,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    tokenStatus: string,
    onChangeToken: func,
    propsForm: shape({}),
    isFetching: bool.isRequired,
    relativeStyleError: bool,
    warningIcon: string,
};

CredentialTokenComponent.defaultProps = {
    tokenStatus: undefined,
    onChangeToken: () => {},
    propsForm: {},
    relativeStyleError: true,
    warningIcon: "tooltip.svg",
};

export default connect(mapStateToProps)(resizableRoute(CredentialTokenComponent));
