import * as API from "middleware/api";

export const inviteUsersPre = () => API.executeWithAccessToken("/administration.users.invite.pre");

export const inviteUserVerify = (params, captcha) =>
    API.executeWithAccessToken("/administration.users.invite.verify", {
        ...params,
        captcha,
    });

export const inviteUserPreview = (params) => API.executeWithAccessToken("/administration.users.invite.preview", params);

export const inviteUserConfirm = (params) => API.executeWithAccessToken("/administration.users.invite.send", params);
