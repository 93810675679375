import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Row from "pages/_components/Row";
import DefaultModal from "pages/_components/modal/DefaultModal";
import MessageModal from "pages/_components/modal/MessageModal";
import ChatLayout from "pages/communications/_components/ChatLayout";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import { actions as communicationsActions } from "reducers/communications";
import * as i18n from "util/i18n";
import Text from "pages/_components/Text";

// const ButtonWrapper = ({ isFetching, onSubmit, onDelete, isDesktop }) => (
const ButtonWrapper = ({ onDelete }) => (
    <Box display="flex" alignX="center" alignY="center" className="pt-4 pb-10-safe pb-md-3">
        <Row gapX="4" gapY="3">
            {onDelete && (
                <Col xs={12} md="4" mdOffset={4}>
                    <Button bsStyle="outline" label="communications.message.delete.label" onClick={onDelete} block />
                </Col>
            )}
            {/* Se volverá a agregar */}
            {/* {onDelete && (
                <Col xs={12} md="4" mdOffset={2}>
                    <Button bsStyle="outline" label="communications.message.delete.label" onClick={onDelete} block />
                </Col>
            )}
            <Col xs={12} md="4" mdOffset={onDelete ? 0 : 4} {...(!isDesktop && { className: "grid-reversed" })}>
                <Button
                    bsStyle="primary"
                    label="communications.message.reply.label"
                    onClick={onSubmit}
                    block
                    loading={isFetching}
                />
            </Col> */}
        </Row>
    </Box>
);

ButtonWrapper.propTypes = {
    // Se volverá a agregar
    // onSubmit: func.isRequired,
    // isDesktop: bool.isRequired,
    // isFetching: bool.isRequired,
    onDelete: func,
};

ButtonWrapper.defaultProps = {
    onDelete: null,
};
class Reader extends Component {
    static propTypes = {
        attachments: arrayOf(
            shape({
                idCommunication: number,
                files: arrayOf(
                    shape({
                        idFile: number,
                        fileName: string,
                    }),
                ),
            }),
        ),
        dispatch: func.isRequired,

        detail: arrayOf(
            shape({
                body: string,
                communicationTray: shape({
                    name: string,
                }),
                idCommunication: number,
                subject: string,
                sentDateAsString: string,
            }),
        ),
        selectedIndex: number.isRequired,
        isDesktop: bool.isRequired,
        isFetching: bool,
        isReply: bool,
        handleFirstTime: func,
        handleMessageModalClose: func,
        handleReplyModalShow: func,
    };

    static defaultProps = {
        attachments: null,
        detail: null,
        isFetching: true,
        isReply: false,
        handleFirstTime: () => {},
        handleMessageModalClose: null,
        handleReplyModalShow: null,
    };

    constructor(...args) {
        super(...args);

        this.state = {
            modalShow: false,
            deleteModalShow: false,
            showButtonWrapper: true,
            detail: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (
            props.detail?.length !== state.detail?.length ||
            props.detail[0]?.idCommunication !== state.detail[0]?.idCommunication
        ) {
            return {
                ...state,
                detail: props.detail,
            };
        }
        return { ...state };
    }

    handleDeleteMessage = () => {
        const { detail, dispatch, selectedIndex, handleMessageModalClose, isDesktop } = this.props;
        dispatch(communicationActions.deleteRequest(detail[0].idCommunication, selectedIndex, true));

        if (!isDesktop) {
            handleMessageModalClose();
        }
        this.setState({ deleteModalShow: false });
    };

    hideDefaultModal = () => {
        this.setState({ deleteModalShow: false });
    };

    handleReplyClick = () => {
        const { detail, dispatch, isDesktop, handleReplyModalShow } = this.props;
        dispatch(communicationActions.detailRequest(detail[0].idCommunication));
        dispatch(communicationsActions.showReplyPanel());
        if (!isDesktop) {
            handleReplyModalShow();
        }
    };

    handleCloseClick = () => {
        const { dispatch } = this.props;
        dispatch(communicationsActions.hideAllPanels());
    };

    handleDownload = (idFile) => () => {
        const { dispatch } = this.props;
        dispatch(communicationActions.downloadAttachmentRequest(idFile));
    };

    scrollToBottom = () => {
        const nodes = document.getElementsByClassName("view-content");
        let objDiv = nodes[nodes.length - 1];
        objDiv = document.getElementById("chatLayout");
        objDiv.scrollTop = objDiv.scrollHeight;
    };

    handleDeleteClick = () => {
        this.setState({ deleteModalShow: true });
    };

    createButtonOrText = (text) => {
        const { showButtonWrapper } = this.state;
        const regex = /\(BAN-LINK (.*?)\|(.*?) BAN-LINK\)/;
        const match = text.match(regex);

        if (match) {
            const beforeText = text.substring(0, match.index);
            const buttonText = match[1].trim();
            const buttonUrl = match[2].trim();
            const afterText = text.substring(match.index + match[0].length);

            if (showButtonWrapper) {
                this.setState({ showButtonWrapper: false });
            }

            return (
                <>
                    {beforeText && <Text className="message-wrapper" defaultValue={beforeText} size="6" breakWord />}

                    <Box display="flex" alignX="center" className="mt-6" fullWidth>
                        <Button bsStyle="primary" externalHref={buttonUrl}>
                            <Text>{buttonText}</Text>
                        </Button>
                    </Box>
                    {afterText && <Text className="message-wrapper" defaultValue={afterText} size="6" breakWord />}
                </>
            );
        }

        if (!showButtonWrapper) {
            this.setState({ showButtonWrapper: true });
        }

        return <Text className="message-wrapper" defaultValue={text} size="6" breakWord />;
    };

    render() {
        const { attachments, isDesktop, isFetching, isReply } = this.props;
        const { modalShow, deleteModalShow, detail, showButtonWrapper } = this.state;

        return (
            <Box
                display="flex"
                column
                position="relative"
                fullWidth
                {...((!isDesktop || (isFetching && !isReply)) && { fullHeight: true })}>
                <DefaultModal
                    modalShow={deleteModalShow}
                    acceptFunction={this.handleDeleteMessage}
                    cancelFunction={this.hideDefaultModal}
                    headingText={i18n.get("communications.delete.modal.title")}
                    text={i18n.get("communications.delete.modal.text")}
                />
                {!isDesktop && (
                    <MessageModal
                        modalShow={modalShow}
                        onCloseClick={() => {
                            this.setState({
                                modalShow: false,
                            });
                        }}
                        isReply
                    />
                )}
                <PageLoading loading={isFetching && !isReply} bgTransparent>
                    {detail && (
                        <Box
                            {...(!isDesktop && {
                                display: "flex",
                                alignX: "between",
                                column: true,
                                fullWidth: true,
                                flex1: true,
                            })}>
                            <Box id="chatLayout">
                                <ChatLayout
                                    detail={detail}
                                    attachments={attachments}
                                    scrollToBottom={this.scrollToBottom}
                                    handleDownloadAttachment={this.handleDownload}
                                    title={detail[0].subject}
                                    onClose={this.handleCloseClick}
                                    createButtonOrText={this.createButtonOrText}
                                />
                            </Box>
                        </Box>
                    )}

                    {showButtonWrapper && detail && !isReply && (
                        <ButtonWrapper
                            isFetching={isFetching}
                            onSubmit={this.handleReplyClick}
                            onDelete={this.handleDeleteClick}
                            isDesktop={isDesktop}
                        />
                    )}
                </PageLoading>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    detail: communicationSelectors.detail(state),
    attachments: communicationSelectors.attachments(state),
    selectedIndex: communicationSelectors.getSelectedIndex(state),
    isFetching: communicationSelectors.isFetching(state),
});

export default connect(mapStateToProps)(Reader);
