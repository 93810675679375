import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { bool, func, shape } from "prop-types";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import * as i18n from "util/i18n";

import Box from "pages/_components/Box";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";

const FORM_ID = "recoveryPassword.step1";

class Step1Content extends Component {
    notificationTypeOptions = [
        {
            id: "sms",
            label: "SMS",
        },
        {
            id: "mail",
            label: "email",
        },
    ];

    render() {
        const { isSubmitting, isMobile, setFieldValue, values } = this.props;

        return (
            <Form>
                <Box display="flex" column fullWidth {...(isMobile ? { fullHeight: true } : { fullHeight: false })}>
                    <Box display="flex" column className="pb-7">
                        <Text
                            labelKey="recoveryPassword.step1.title"
                            align="center"
                            size={`${isMobile ? "5" : "6"}`}
                            component="p"
                            className="mt-0 mb-5"
                            color="text"
                        />
                        <Text
                            labelKey="recoveryPassword.step1.title2"
                            align="center"
                            size={`${isMobile ? "5" : "6"}`}
                            component="p"
                            className="my-0"
                            color="text"
                        />
                    </Box>
                    <Box display="flex" className="my-auto pb-3">
                        <Field
                            name="username"
                            idForm={FORM_ID}
                            autoComplete="on"
                            component={TextField}
                            placeholder=""
                            maxLength={50}
                        />
                    </Box>
                    <Box display="block" className="my-auto pb-5">
                        <Text
                            align="left"
                            size={`${isMobile ? "6" : "5"}`}
                            color="text-boton"
                            labelKey="recoveryPassword.step1.label.notificationType"
                        />
                        <RadioButtonGroup
                            radioLabelClassName="color-text-boton"
                            inLineControl={false}
                            name="notificationType"
                            selectorId="accountType"
                            options={this.notificationTypeOptions}
                            onChange={(e) => {
                                setFieldValue("notificationType", e);
                            }}
                            value={values?.notificationType}
                            radioButtonGroupFlex
                            className="pt-3"
                        />
                    </Box>
                    <Box display="flex" className="mb-7 mb-md-0 mt-auto">
                        <Button label="global.continue" type="submit" bsStyle="primary" loading={isSubmitting} block />
                    </Box>
                </Box>
            </Form>
        );
    }
}

Step1Content.propTypes = {
    isSubmitting: bool,
    isMobile: bool.isRequired,
    setFieldValue: func,
    values: shape({}),
};

Step1Content.defaultProps = {
    isSubmitting: false,
    values: null,
    setFieldValue: null,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({ username: "", notificationType: "mail" }),
    validationSchema: () =>
        Yup.object().shape({
            username: Yup.string().required(i18n.get(`${FORM_ID}.username.required`)),
        }),
    handleSubmit: (values, formikBag) => {
        const { username, notificationType } = values;
        formikBag.props.dispatch(recoveryPasswordActions.recoveryPassStep1(username, notificationType, formikBag));
    },
})(Step1Content);
