import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { actions as formActions } from "reducers/form";
import { selectors, actions } from "reducers/administration/common/administrationTicket";
import { string, shape, bool, node, func } from "prop-types";

import I18n from "pages/_components/I18n";
import TransactionStatus from "pages/transactions/_components/TransactionStatus";
import FormSignatures from "pages/forms/_components/FormSignatures";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

class AdministrationTicket extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        history: shape({
            goBack: func,
        }).isRequired,
        transactionData: shape({
            data: shape({}),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ idTransaction: string }),
        }).isRequired,
        fetching: bool.isRequired,
        children: node.isRequired,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        const { idTransaction } = match.params;
        dispatch(actions.loadAdministrationTicketRequest(idTransaction));
    }

    onClick = () => {
        const { dispatch, transactionData } = this.props;

        dispatch(
            formActions.signTransactionPreview({
                idActivity: transactionData.idActivity,
                idTransaction: transactionData.idTransaction,
                ticketData: transactionData.data,
            }),
        );
    };

    handleClose = () => {
        const { history } = this.props;
        history.goBack();
    };

    getViewButtons = () => {
        const { fetching, transactionData } = this.props;

        if (transactionData) {
            const { idTransactionStatus } = transactionData;
            if (idTransactionStatus === "PENDING") {
                return (
                    <Button
                        onClick={() => this.onClick("signTransaction")}
                        label="forms.signTransaction.link"
                        bsStyle="primary"
                        loading={fetching}
                    />
                );
            }
        }
        return null;
    };

    render() {
        const { fetching, transactionData, children } = this.props;
        const viewButtons = this.getViewButtons();

        if (transactionData.data) {
            const { idTransaction, idTransactionStatus, submitDateTimeAsString } = transactionData;
            return (
                <Fragment>
                    <Notification scopeToShow="administration" />
                    <Head
                        titleText={transactionData.activityName}
                        onBack={this.handleBack}
                        onClose={this.handleClose}
                    />
                    <MainContainer showLoader={fetching}>
                        <div className="above-the-fold">
                            <Container
                                className="container--layout items-center flex-grow-1"
                                gridClassName="form-content">
                                <Container.Column xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                    <Container.ColumnBody columnClass="media-object">
                                        <figure>
                                            <TransactionStatus
                                                iconFidelity="ticket"
                                                idTransactionStatus={idTransactionStatus}
                                            />
                                        </figure>
                                        <div className="media-object--text">
                                            <p className="text-lead">
                                                <I18n id={`forms.transaction.ticket.status.${idTransactionStatus}`} />
                                            </p>
                                        </div>
                                    </Container.ColumnBody>
                                </Container.Column>
                            </Container>

                            <Container
                                className="container--layout items-center flex-grow-1"
                                gridClassName="form-content">
                                <Container.Column xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                    <h3 className="data-title">
                                        <I18n id="forms.transaction.ticket" />
                                    </h3>
                                    <Container.ColumnBody>
                                        <div className="data-wrapper">
                                            <div className="data-label">
                                                <I18n id="forms.transaction.ticket.date" />
                                            </div>
                                            <span className="data-date">{submitDateTimeAsString}</span>
                                        </div>
                                        <div className="data-wrapper">
                                            <div className="data-label">
                                                <I18n id="forms.transaction.ticket.number" />
                                            </div>
                                            <span className="data-numeric">{idTransaction}</span>
                                        </div>
                                    </Container.ColumnBody>
                                </Container.Column>
                            </Container>
                            {children}
                            <Container
                                className="container--layout items-center flex-grow-1"
                                gridClassName="form-content">
                                <FormSignatures transaction={transactionData} />
                            </Container>

                            <Container
                                className="container--layout items-center flex-grow-1"
                                gridClassName="form-content">
                                <Container.Column xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                    <Container.ColumnBody>{viewButtons}</Container.ColumnBody>
                                </Container.Column>
                            </Container>
                        </div>
                    </MainContainer>
                </Fragment>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    fetching: selectors.isFetching(state),
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(AdministrationTicket);
