import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { func, arrayOf, shape, string } from "prop-types";

import { actions, selectors } from "reducers/administration/restrictions";
import { actions as formActions } from "reducers/form";
import I18n from "pages/_components/I18n";
import { Col } from "react-bootstrap";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class RestrictionsDeleteConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({}).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    componentDidMount() {
        const { dispatch, idTransaction, idActivity } = this.props;

        dispatch(actions.restrictionDeleteRequest(idTransaction, idActivity));
    }

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, match, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            dispatch(actions.deleteUserAccessRestrictions(match.params.userId, credentials, formikBag));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { credentialGroups } = this.props;
        if (!credentialGroups) {
            return null;
        }

        return (
            <Fragment>
                <Head
                    title="administration.restrictions.delete"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout flex-grow-1">
                            <Col className="col-12">
                                <I18n id="administration.restrictions.restrictions.beforeDelete.user" />
                            </Col>
                        </section>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(RestrictionsDeleteConfirmation);
