import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import MainContainer from "pages/_components/MainContainer";
import Tabs from "pages/_components/Tabs";
import { arrayOf, bool, func, node, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { push } from "react-router-redux";
import { LOAN_OVERDUE_STATUS_NAB } from "util/account";

class ProductBody extends Component {
    static propTypes = {
        activateAccountPermission: bool,
        activeEnvironment: shape(),
        background: string,
        beforeTabs: node,
        children: node.isRequired,
        detailHeadInfo: node,
        disclaimerLabelkey: string,
        disclaimerTextParams: shape(),
        disclaimerStyled: string,
        dispatch: func,
        fees: arrayOf(shape({})).isRequired,
        fetching: func,
        filters: oneOfType([shape({}), arrayOf(shape({}))]),
        isDesktop: bool.isRequired,
        product: shape(),
        productKind: string,
        tabBackground: string,
        tabBorderRadius: string,
        wally: bool,
        withTabs: bool,
    };

    static defaultProps = {
        activateAccountPermission: false,
        activeEnvironment: undefined,
        background: "component-background",
        beforeTabs: null,
        detailHeadInfo: null,
        disclaimerLabelkey: null,
        disclaimerTextParams: null,
        disclaimerStyled: null,
        dispatch: () => {},
        fetching: null,
        filters: null,
        product: {},
        productKind: null,
        tabBackground: null,
        tabBorderRadius: null,
        wally: false,
        withTabs: true,
    };

    getRenderLoanDisclaimer = () => {
        const { product } = this.props;

        if (
            !!product.overdueStatus &&
            product.overdueStatus !== "CUR" &&
            product.overdueStatus !== "NAB" &&
            product.productType === "PA"
        ) {
            return (
                <Disclaimer
                    className="mt-5 mt-md-0 mb-3"
                    styled="warning"
                    labelKey="loan.detail.disclaimer"
                    image="images/icons/warning.svg"
                    borderRadius="top-xl"
                    textParams={{
                        LOANINFO: `${product.alias} - ${product.number}`,
                    }}
                />
            );
        }
        if (product.overdueStatus === "NAB" && product.productType === "PA") {
            return (
                <Disclaimer
                    className="mt-5 mt-md-0 mb-3"
                    styled="error"
                    labelKey="loan.detail.error.disclaimer"
                    image="images/icons/disclaimerError.svg"
                    borderRadius="top-xl"
                    textParams={{
                        LOANINFO: `${product.alias} - ${product.number}`,
                    }}
                />
            );
        }
        return null;
    };

    getRenderLoanDisclaimerMobile = () => {
        const { isDesktop, product } = this.props;
        if (
            !!product.overdueStatus &&
            product.overdueStatus !== "CUR" &&
            product.overdueStatus !== "NAB" &&
            product.productType === "PA"
        ) {
            return (
                <Disclaimer
                    className="mb-3"
                    styled="warning"
                    labelKey="loan.detail.disclaimer"
                    image="images/icons/warning.svg"
                    borderRadius="top-xl"
                    textParams={{
                        LOANINFO: `${product.alias} - ${product.number}`,
                    }}
                />
            );
        }
        if (product.overdueStatus === "NAB" && product.productType === "PA") {
            return (
                <Disclaimer
                    {...(isDesktop && { className: "mb-3" })}
                    styled="error"
                    labelKey="loan.detail.error.disclaimer"
                    image="images/icons/disclaimerError.svg"
                    borderRadius="top-xl"
                    textParams={{
                        LOANINFO: `${product.alias} - ${product.number}`,
                    }}
                />
            );
        }

        return null;
    };

    getRenderAccountDisclaimer = () => {
        const { product, activateAccountPermission, activeEnvironment } = this.props;

        if (product.status === "INACTIVA" && activateAccountPermission) {
            return (
                <Disclaimer
                    className="mt-5 mt-md-0 mb-3"
                    styled="warning"
                    labelKey="account.detail.disclaimer1"
                    image="images/icons/warning.svg"
                    borderRadius="top-xl"
                    textParams={{
                        ACCOUNT_INFO: `${product.shortLabel}`,
                        ACCOUNT_NUMBER: `${product.number}`,
                    }}
                />
            );
        }
        if (product.status === "NOACTIVA") {
            return (
                <Disclaimer
                    className="mt-5 mt-md-0 mb-3"
                    styled="warning"
                    labelKey="account.detail.disclaimer2"
                    image="images/icons/warning.svg"
                    borderRadius="top-xl"
                    textParams={{
                        ACCOUNT_NUMBER: `${product.number}`,
                    }}
                />
            );
        }
        if (product.status === "INACTIVA" && !activateAccountPermission && activeEnvironment.type === "corporate") {
            return (
                <Disclaimer
                    className="mt-5 mt-md-0 mb-3"
                    styled="warning"
                    labelKey="account.detail.disclaimer3"
                    image="images/icons/warning.svg"
                    borderRadius="top-xl"
                    textParams={{
                        ACCOUNT_INFO: `${product.shortLabel}`,
                        ACCOUNT_NUMBER: `${product.number}`,
                    }}
                />
            );
        }
        return null;
    };

    getRenderAccountDisclaimerMobile = () => {
        const { product, activateAccountPermission, activeEnvironment } = this.props;

        if (product.status === "INACTIVA" && activateAccountPermission) {
            return (
                <Disclaimer
                    className="mb-3"
                    styled="warning"
                    labelKey="account.detail.disclaimer1"
                    image="images/icons/warning.svg"
                    borderRadius="top-xl"
                    textParams={{
                        ACCOUNT_INFO: `${product.shortLabel}`,
                        ACCOUNT_NUMBER: `${product.number}`,
                    }}
                />
            );
        }
        if (product.status === "NOACTIVA") {
            return (
                <Disclaimer
                    className="mb-3"
                    styled="warning"
                    labelKey="account.detail.disclaimer2"
                    image="images/icons/warning.svg"
                    borderRadius="top-xl"
                    textParams={{
                        ACCOUNT_NUMBER: `${product.number}`,
                    }}
                />
            );
        }
        if (product.status === "INACTIVA" && !activateAccountPermission && activeEnvironment.type === "corporate") {
            return (
                <Disclaimer
                    className="mb-3"
                    styled="warning"
                    labelKey="account.detail.disclaimer3"
                    image="images/icons/warning.svg"
                    borderRadius="top-xl"
                    textParams={{
                        ACCOUNT_INFO: `${product.shortLabel}`,
                        ACCOUNT_NUMBER: `${product.number}`,
                    }}
                />
            );
        }
        return null;
    };

    render() {
        const {
            background,
            beforeTabs,
            children,
            detailHeadInfo,
            disclaimerLabelkey,
            disclaimerTextParams,
            disclaimerStyled,
            fetching,
            filters,
            isDesktop,
            product,
            tabBackground,
            tabBorderRadius,
            productKind,
            wally,
            withTabs,
        } = this.props;
        return withTabs
            ? (isDesktop && (
                  <MainContainer showLoader={fetching}>
                      <Box component="section">
                          <Box background={background} borderRadius="xl">
                              {productKind === "accounts" && this.getRenderAccountDisclaimer()}
                              {productKind === "loan" && this.getRenderLoanDisclaimer()}
                              {disclaimerLabelkey && (
                                  <Disclaimer
                                      styled={disclaimerStyled}
                                      labelKey={disclaimerLabelkey}
                                      textParams={disclaimerTextParams}
                                      borderRadius="top-default"
                                  />
                              )}

                              {filters && <Tabs className="pt-0" components={children} />}
                          </Box>
                      </Box>
                  </MainContainer>
              )) || (
                  <MainContainer showLoader={fetching} className="px-0">
                      {beforeTabs}
                      <Box component="section">{detailHeadInfo}</Box>
                      <Box component="section">
                          <Box background={background} borderRadius="xl">
                              {productKind === "accounts" && this.getRenderAccountDisclaimerMobile()}
                              {productKind === "loan" && this.getRenderLoanDisclaimerMobile()}
                              {disclaimerLabelkey && (
                                  <Disclaimer
                                      styled={disclaimerStyled}
                                      labelKey={disclaimerLabelkey}
                                      textParams={disclaimerTextParams}
                                      borderRadius="top-default"
                                  />
                              )}

                              {!isDesktop && product.productType === "PA" && (
                                  <Box display="flex" className="px-5 mb-3 mt-5" fullWidth>
                                      <Button
                                          bsStyle="primary"
                                          label="loan.detail.pay.loan.button"
                                          {...(product?.overdueStatus &&
                                              product.overdueStatus !== LOAN_OVERDUE_STATUS_NAB && {
                                                  onClick: () => {
                                                      const { dispatch } = this.props;
                                                      dispatch(push(`/formCustom/payLoan?loan=${product.idProduct}`));
                                                  },
                                              })}
                                          block
                                          {...(product.overdueStatus === LOAN_OVERDUE_STATUS_NAB && { disabled: true })}
                                      />
                                  </Box>
                              )}

                              <Tabs
                                  className="pt-0"
                                  background={tabBackground}
                                  borderRadius={tabBorderRadius}
                                  components={children}
                                  wally={wally}
                              />
                          </Box>
                      </Box>
                  </MainContainer>
              )
            : (isDesktop && <MainContainer showLoader={fetching}>{children}</MainContainer>) || (
                  <MainContainer showLoader={fetching} className="px-0">
                      {detailHeadInfo}
                      {children}
                  </MainContainer>
              );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    activateAccountPermission: sessionSelectors.hasPermissions(state, ["activateAccount"]),
});

export default connect(mapStateToProps)(ProductBody);
