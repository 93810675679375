import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import CapsList from "pages/administration/_components/CapsList";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { shape, func, string } from "prop-types";

class AdministrationChannelsTicket extends Component {
    static propTypes = {
        actions: shape({
            loadChannelsTicketRequest: func.isRequired,
        }).isRequired,
        match: shape({
            params: shape({
                idTransaction: string.isRequired,
            }).isRequired,
        }).isRequired,
        user: shape({
            firstName: string.isRequired,
            lastName: string.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadChannelsTicketRequest(match.params.idTransaction);
    }

    render() {
        const { user } = this.props;

        return (
            <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper"
                        label="administration.channels.user"
                        data={`${user.firstName} ${user.lastName}`}
                    />
                    <div className="transfer-data data-wrapper">
                        <Container.ColumnBody columnClass="list-wrapper">
                            <CapsList />
                        </Container.ColumnBody>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default AdministrationChannelsTicket;
