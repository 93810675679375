import SideBarModal from "pages/_components/modal/SideBarModal";
import UpdateUserDataStepZero from "pages/updateUserData/UpdateUserDataStepZero";
import { bool, func, shape } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";

const PAGE_ID = "settings.personalDataUpdateStep1";

const PersonalDataUpdateStepZeroModal = ({ dispatch, isShowUpdateUserDataStepZero, location }) => {
    const hidePersonalDataUpdateStepZero = () => {
        dispatch(updateUserDataActions.hideUpdateUserDataStepZero());
    };

    return (
        <SideBarModal
            show={isShowUpdateUserDataStepZero}
            onClose={hidePersonalDataUpdateStepZero}
            modalId={PAGE_ID}
            title="settings.userData.update.pre.stepZero.title">
            <UpdateUserDataStepZero location={location} />
        </SideBarModal>
    );
};

PersonalDataUpdateStepZeroModal.propTypes = {
    dispatch: func,
    isShowUpdateUserDataStepZero: bool,
    location: shape({}),
};

PersonalDataUpdateStepZeroModal.defaultProps = {
    dispatch: () => {},
    isShowUpdateUserDataStepZero: false,
    location: {},
};

const mapStateToProps = (state) => ({
    isShowUpdateUserDataStepZero: updateUserDataSelectors.isShowUpdateUserDataStepZero(state),
});

export default connect(mapStateToProps)(PersonalDataUpdateStepZeroModal);
