import { CORPORATE_GROUP_ENVIRONMENT_TYPE, RETAIL_ENVIRONMENT_TYPE } from "constants.js";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Select from "pages/forms/_components/_fields/Select";
import ChannelFilter from "pages/transactions/_components/ChannelFilter";
import EnvironmentFilter from "pages/transactions/_components/EnvironmentFilter";
import PeriodFilter from "pages/transactions/_components/PeriodFilter";
import StatusFilter from "pages/transactions/_components/StatusFilter";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component, createElement } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as configUtil from "util/config";
import * as i18n from "util/i18n";
import { historicOperationTypes } from "../HistoricOperationTypes";

const components = {
    period: PeriodFilter,
    channel: ChannelFilter,
    status: StatusFilter,
    environment: EnvironmentFilter,
};

const historicOperationSorting = (a, b) => {
    if (a.value === "all" || b.value === "all") {
        return a.value === "all" ? -1 : 1;
    }
    if (a.label < b.label || a.value === "all") {
        return -1;
    }
    if (a.label > b.label || b.value === "all") {
        return 1;
    }
    return 0;
};

class HiddenFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool,
        onlyPendings: bool,
        onlyProcessing: bool,
        pendingDispatch: bool,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        isTransactionList: bool,
        operationTypeListRetail: arrayOf(string).isRequired,
        operationTypeListCorporate: arrayOf(string).isRequired,
        historic: bool,
        resetHandleOrder: func.isRequired,
        setOperationType: func,
        clearFilters: func,
        setDateFrom: func,
        setDateTo: func,
        lastRegistryNumber: string.isRequired,
        status: arrayOf(string),
        dateFromIsRequired: bool,
        setFilterValues: func.isRequired,
    };

    static defaultProps = {
        fetching: false,
        onlyPendings: false,
        onlyProcessing: false,
        pendingDispatch: false,
        isTransactionList: false,
        historic: false,
        setOperationType: () => {},
        clearFilters: () => {},
        setDateFrom: () => {},
        setDateTo: () => {},
        status: [],
        dateFromIsRequired: false,
    };

    state = {
        // eslint-disable-next-line react/destructuring-assignment
        selectedFilter: this.props.historic ? "period" : "all",
        selectedOperationType: "all",
    };

    componentDidMount() {
        const { setOperationType } = this.props;
        setOperationType("all");
    }

    componentDidUpdate(prevProps) {
        if (this.props?.status?.toString() !== prevProps?.status?.toString() && this.props?.status?.toString() === "") {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                selectedFilter: this.props.historic ? "period" : "all",
                selectedOperationType: "all",
            });
        }
    }

    handleChange = (selectedOption) => {
        const { clearFilters } = this.props;
        if (clearFilters) {
            clearFilters();
        }
        this.setState({ selectedFilter: selectedOption.value });
    };

    handleChangeOperationType = (selectedOption) => {
        this.setState({ selectedOperationType: selectedOption.value });
        const { setOperationType } = this.props;
        if (setOperationType && selectedOption?.value) {
            setOperationType(selectedOption.value);
        }
    };

    handleClick = () => {
        const { dispatch, pendingDispatch, onlyPendings, onlyProcessing, resetHandleOrder } = this.props;
        const { selectedOperationType, selectedFilter } = this.state;

        if (selectedFilter === "lastMonth") {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            const dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
            const dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            dispatch(
                transactionsActions.loadListRequest(
                    { operationType: selectedOperationType, dateFrom, dateTo },
                    onlyPendings,
                    onlyProcessing,
                    pendingDispatch,
                    false,
                ),
            );
        } else {
            dispatch(
                transactionsActions.loadListRequest(
                    { operationType: selectedOperationType },
                    onlyPendings,
                    onlyProcessing,
                    pendingDispatch,
                    false,
                ),
            );
        }

        resetHandleOrder();
    };

    setLastMonthFilter = (selectedFilter) => {
        if (selectedFilter === "lastMonth") {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            const dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
            const dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            const { setDateFrom, setDateTo } = this.props;
            if (setDateFrom && setDateTo) {
                setDateFrom(moment(dateFrom).format("YYYY-MM-DD"));
                setDateTo(moment(dateTo).format("YYYY-MM-DD"));
            }
        }
    };

    renderFilter = () => {
        const { selectedFilter, selectedOperationType } = this.state;
        const {
            dispatch,
            fetching,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            resetHandleOrder,
            historic,
            dateFromIsRequired,
            lastRegistryNumber,
            setFilterValues,
        } = this.props;

        let props = {
            dispatch,
            isDesktop: true,
            fetching,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            resetHandleOrder,
            historic,
            dateFromIsRequired,
            lastRegistryNumber,
            setFilterValues,
        };
        if (selectedFilter === "channel") {
            props = { ...props, selectedOperationType };
        }
        if (selectedFilter === "period") {
            props = { ...props, selectedOperationType };
        }

        if (selectedFilter === "status") {
            props = { ...props, selectedOperationType };
        }

        if (selectedFilter === "all" || selectedFilter === "lastMonth") {
            this.setLastMonthFilter(selectedFilter);
            return (
                <Col sm={12} md={4} className="height-auto mt-3 mt-md-0">
                    <Button
                        bsStyle="primary"
                        label="product.filters.filter"
                        block
                        loading={fetching}
                        onClick={this.handleClick}
                    />
                </Col>
            );
        }

        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const { selectedFilter, selectedOperationType } = this.state;
        const {
            activeEnvironment,
            isTransactionList,
            operationTypeListRetail,
            operationTypeListCorporate,
            historic,
        } = this.props;
        let operationTypeOptions = null;

        if (historic) {
            operationTypeOptions = Object.keys(historicOperationTypes).map((key) => ({
                value: key,
                label:
                    key !== "all"
                        ? i18n.get(`transactions.list.filters.operationType.historic.${key}`)
                        : i18n.get("transactions.list.filters.operationType.all.label"),
            }));
            operationTypeOptions.sort(historicOperationSorting);
        } else if (activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE) {
            operationTypeOptions = operationTypeListCorporate.map((operationType) => ({
                value: operationType,
                label:
                    operationType !== "all"
                        ? i18n.get(`activities.${operationType}`)
                        : i18n.get("transactions.list.filters.operationType.all.label"),
            }));
        } else if (activeEnvironment.type === RETAIL_ENVIRONMENT_TYPE) {
            operationTypeOptions = operationTypeListRetail.map((operationType) => ({
                value: operationType,
                label:
                    operationType !== "all"
                        ? i18n.get(`activities.${operationType}`)
                        : i18n.get("transactions.list.filters.operationType.all.label"),
            }));
        }

        let options = isTransactionList
            ? [
                  {
                      value: "all",
                      label: i18n.get("transactions.list.filters.operationType.all.label"),
                  },
                  {
                      value: "lastMonth",
                      label: i18n.get("transactions.list.filter.searchBy.lastMonth"),
                  },
                  {
                      value: "period",
                      label: i18n.get("transactions.list.filter.searchBy.period"),
                  },
                  {
                      value: "channel",
                      label: i18n.get("transactions.list.filter.searchBy.channel"),
                  },
                  {
                      value: "status",
                      label: i18n.get("transactions.list.filter.searchBy.status"),
                  },
              ]
            : [
                  {
                      value: "all",
                      label: i18n.get("transactions.list.filters.operationType.all.label"),
                  },
                  {
                      value: "period",
                      label: i18n.get("transactions.list.filter.searchBy.period"),
                  },
                  {
                      value: "channel",
                      label: i18n.get("transactions.list.filter.searchBy.channel"),
                  },
              ];

        if (activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE) {
            options = options.concat({
                value: "environment",
                label: i18n.get("accounts.movements.filters.searchBy.client"),
            });
        }

        return (
            <>
                <>
                    <Col xs={12} md={!historic ? 5 : 3}>
                        <Row gapY="3" gapX={!historic ? "7" : "0"}>
                            <Col xs={12} {...(!historic && { md: 6 })}>
                                <Box className="form-group form-group--select">
                                    <Box display="flex" alignY="center" className="data-label-special-mb">
                                        <Text
                                            component="label"
                                            htmlFor="react-select-operationType-input"
                                            labelKey="transactions.list.filter.operationType.label"
                                            className="data-label"
                                        />
                                    </Box>
                                    <Box className="input-group ">
                                        <Select
                                            id="operationType"
                                            name="operationType"
                                            label="transactions.list.filter.operationType"
                                            onChange={this.handleChangeOperationType}
                                            options={operationTypeOptions}
                                            value={selectedOperationType}
                                        />
                                    </Box>
                                </Box>
                            </Col>
                            {!historic && (
                                <Col xs={12} md={6}>
                                    <Box className="form-group form-group--select">
                                        <Box display="flex" alignY="center" className="data-label-special-mb">
                                            <Text
                                                component="label"
                                                htmlFor="react-select-searchBy-input"
                                                labelKey="transactions.list.filter.searchBy.label"
                                                className="data-label"
                                            />
                                        </Box>
                                        <Box className="input-group ">
                                            <Select
                                                id="searchBy"
                                                name="searchBy"
                                                label="transactions.list.filter.searchBy.label"
                                                onChange={this.handleChange}
                                                options={options}
                                                value={selectedFilter}
                                            />
                                        </Box>
                                    </Box>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col xs={12} md={7} className="height-auto">
                        <Box display="flex" alignY="flex-end" fullHeight>
                            <Row>{this.renderFilter()}</Row>
                        </Box>
                    </Col>
                </>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    operationTypeListRetail: configUtil.getArray("transaction.list.operationType.options.retail"),
    operationTypeListCorporate: configUtil.getArray("transaction.list.operationType.options.corporate"),
    lastRegistryNumber: transactionsSelectors.getLastRegistryNumber(state),
});

export default connect(mapStateToProps)(withTransactionFilterContext(HiddenFilters));
