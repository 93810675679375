import InfoCard from "pages/_components/InfoCard";
import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import Row from "pages/_components/Row";
import { bool, node, shape } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import Slider from "react-slick";
import InfoCardSimplified from "../InfoCardSimpflied";

const getDesktopAccountDataArray = (account) => {
    const accountArray = [
        {
            currency: account.currency,
            quantity: account.totalBalance,
            title: "accounts.details.info.totalBalance",
        },
        {
            currency: account.currency,
            quantity: account.detainedBalance,
            title: "accounts.details.info.detainedBalance",
            tooltip: "accounts.details.info.detainedBalance.tooltip",
        },
        {
            currency: account.currency,
            quantity: account.deferredBalance,
            title: "accounts.details.info.totalCheckDeposit",
            plus: "images/plus-icon.svg",
        },
        {
            currency: account.currency,
            quantity: account.averageBalanceCurrent,
            title: "accounts.details.info.averageBalance",
            tooltip: "accounts.details.info.averageBalance.tooltip",
        },
    ];

    if (account.simplified) {
        accountArray.push({
            // solo para cuentas simplificadas
            simplified: true,
            currency: account.currency,
            quantityRequired: account.averageBalanceRequired,
            titleRequired: "accounts.details.info.requiredAverageBalance",
            quantityCurrent: account.averageBalanceCurrent,
            titleCurrent: "accounts.details.info.currentAverageBalance",
        });
    }

    if (account.pledgedBalance > 0) {
        accountArray.push({
            // solo si es mayor que cero
            currency: account.currency,
            quantity: account.pledgedBalance,
            title: "accounts.details.info.pledgedAmount",
            tooltip: "accounts.details.info.pledgedAmount.tooltip",
        });
    }

    if (account.creditLineAmount > 0) {
        accountArray.push({
            // solo si es mayor que cero
            currency: account.currency,
            quantity: account.creditLineAmount,
            title: "accounts.details.info.creditLine",
        });
    }

    return accountArray;
};

const getMobileAccountDataArray = (account) => {
    const accountArray = [
        {
            currency: account.currency,
            quantity: account.totalBalance,
            title: "accounts.details.info.totalBalance",
        },
        {
            currency: account.currency,
            quantity: account.detainedBalance,
            title: "accounts.details.info.detainedBalance",
            tooltip: "accounts.details.info.detainedBalance.tooltip",
        },
        {
            currency: account.currency,
            quantity: account.averageBalanceCurrent,
            title: "accounts.details.info.averageBalance",
            tooltip: "accounts.details.info.averageBalance.tooltip",
        },
    ];

    // solo para cuentas simplificadas
    if (account.simplified) {
        accountArray.push({
            currency: account.currency,
            quantity: account.averageBalanceRequired,
            title: "accounts.details.info.requiredAverageBalance",
        });
        accountArray.push({
            currency: account.currency,
            quantity: account.averageBalanceCurrent,
            title: "accounts.details.info.currentAverageBalance",
        });
    }

    accountArray.push({
        currency: account.currency,
        quantity: account.deferredBalance,
        title: "accounts.details.info.totalCheckDeposit",
        plus: "images/plus-icon.svg",
    });

    if (account.creditLineAmount > 0) {
        accountArray.push({
            // solo si es mayor que cero
            currency: account.currency,
            quantity: account.creditLineAmount,
            title: "accounts.details.info.creditLine",
        });
    }

    if (account.pledgedBalance > 0) {
        accountArray.push({
            // solo si es mayor que cero
            currency: account.currency,
            quantity: account.pledgedBalance,
            title: "accounts.details.info.pledgedAmount",
            tooltip: "accounts.details.info.pledgedAmount.tooltip",
        });
    }

    return accountArray;
};

export const AccountStatus = {
    Actived: "ACTIVA",
    Inactived: "INACTIVA",
    NoActived: "NOACTIVA",
};

export const actionChangeStatusAccount = {
    Inactived: {
        actionName: "inactived",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: AccountStatus.Inactived,
    },
    Active: {
        actionName: "active",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: AccountStatus.Actived,
    },
};

class DetailHeadInfo extends Component {
    render() {
        const { account, children, isDesktop } = this.props;

        const settings = {
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: 1,
            arrows: false,
        };

        return (
            <div className="detail-head-info pt-0">
                {(isDesktop && (
                    <>
                        <Row className="justify-content-center" {...(account.simplified && { gapX: "3" })}>
                            <Col md={account.simplified ? 3 : 4} className="height-auto">
                                <RemarkableProductInfo
                                    currency={account.currency}
                                    quantity={account.balance}
                                    status={account.status}
                                    isDesktop={isDesktop}
                                    productType={account.productType}
                                    shortLabel={account.shortLabel}
                                    accountNumber={account.number}
                                    idProduct={account.idProduct}
                                />
                            </Col>
                            <Col md={account.simplified ? 9 : 8} sm={12}>
                                <Row className="justify-content-center" gapX="3" gapY="3">
                                    {getDesktopAccountDataArray(account).map((el) => (
                                        <Col xs={12} md={4} key={el.quantity + el.title} className="height-auto">
                                            {el.simplified ? (
                                                <InfoCardSimplified
                                                    titleRequired={el.titleRequired}
                                                    currency={el.currency}
                                                    quantityRequired={el.quantityRequired}
                                                    titleDate={el.titleCurrent}
                                                    quantityDate={el.quantityCurrent}
                                                />
                                            ) : (
                                                <InfoCard
                                                    title={el.title}
                                                    currency={el.currency}
                                                    quantity={el.quantity}
                                                    tooltip={el.tooltip}
                                                    isDesktop={isDesktop}
                                                />
                                            )}
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                        {children}
                    </>
                )) || (
                    <div className="slick-slider-wrapper">
                        <Slider {...settings}>
                            {getMobileAccountDataArray(account)
                                .reduce((acc, el, i) => {
                                    const index = Math.floor(i / 4);

                                    if (!acc[index]) {
                                        acc[index] = [];
                                    }

                                    acc[index].push(el);

                                    return acc;
                                }, [])
                                .map((el) => (
                                    <Row className="d-grid-i px-0" gapX="5" gapY="3" key={el[0].quantity + el[0].title}>
                                        {el.map((accountOne, idx) => (
                                            <Col
                                                xs={6}
                                                className="height-auto"
                                                key={accountOne.quantity + accountOne.title}>
                                                <InfoCard
                                                    title={accountOne.title}
                                                    currency={accountOne.currency}
                                                    quantity={accountOne.quantity}
                                                    tooltipPosition={idx % 2 ? "bottom-right" : "bottom-left"}
                                                    tooltip={accountOne.tooltip}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                ))}
                        </Slider>
                        {children}
                    </div>
                )}
            </div>
        );
    }
}

DetailHeadInfo.propTypes = {
    children: node,
    account: shape({}),
    isDesktop: bool.isRequired,
};

DetailHeadInfo.defaultProps = {
    account: null,
    children: null,
};

export default DetailHeadInfo;
