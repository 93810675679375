import React, { Component } from "react";
import I18n from "pages/_components/I18n";
import { Col } from "react-bootstrap";
import { Field } from "formik";
import Row from "pages/_components/Row";
import BitwiseDays from "pages/_components/fields/BitwiseDays";
import Checkbox from "pages/_components/Checkbox";
import Select from "pages/_components/fields/Select";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import * as maskUtils from "util/mask";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import { shape, string, bool, func, arrayOf } from "prop-types";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";

const FORM_ID = "administration.restrictions";

class CalendarRestriction extends Component {
    static propTypes = {
        timeZones: arrayOf(string),
        idUser: string,
        values: shape({}),
        calendarEnabled: bool.isRequired,
        setFieldValue: func.isRequired,
    };

    static defaultProps = {
        timeZones: [],
        idUser: null,
        values: {},
    };

    allDaysOnCLick = (val) => {
        const { setFieldValue } = this.props;
        if (val) {
            setFieldValue("days", 127);
        } else {
            setFieldValue("days", 0);
        }
    };

    onDaysValueChange = (val) => {
        const { setFieldValue } = this.props;
        setFieldValue("allDays", val === 127);
    };

    render() {
        const { timeZones, idUser, values, calendarEnabled } = this.props;

        const header = (
            <div>
                <div className="form-group-text">
                    <h4 className="table-legend">
                        <I18n id="administration.restrictions.calendarEnabled.label" />
                    </h4>
                </div>
            </div>
        );

        if (!calendarEnabled && idUser) {
            return (
                <div>
                    {header}
                    <div className="restrictions-warning">
                        <Image src="images/warningIcon.svg" className="svg-icon" />{" "}
                        <span>
                            <I18n id="administration.restrictions.restrictions.disabled" />
                        </span>
                    </div>
                </div>
            );
        }

        const tzOptions = timeZones.map((tz) => ({
            value: tz,
            label: tz,
        }));

        const shouldHideCalendarControls = values.perpetual && idUser;
        return (
            <div className="borderRestriction">
                {idUser !== null && header}
                {idUser && <Field name="perpetual" component={Checkbox} idForm={FORM_ID} />}
                {idUser === null && (
                    <Field name="calendarEnabled" idForm={FORM_ID} component={SwitchField} formGroup biggerLabel />
                )}
                {values.calendarEnabled && !shouldHideCalendarControls && (
                    <Row>
                        <Col className="col-12 col-6">
                            <fieldset>
                                <legend className="control-label text-capitalize control-leyend">
                                    <I18n id={`${FORM_ID}.perDay`} />
                                </legend>

                                <Field
                                    name="allDays"
                                    component={Checkbox}
                                    idForm={FORM_ID}
                                    onChange={this.allDaysOnCLick}
                                />

                                <Field
                                    name="days"
                                    component={BitwiseDays}
                                    idForm={FORM_ID}
                                    hideLabel
                                    onChange={this.onDaysValueChange}
                                />
                            </fieldset>
                        </Col>
                        <Col className="col-12 col-6">
                            <fieldset>
                                <legend className="control-label text-capitalize control-leyend">
                                    <I18n id={`${FORM_ID}.perHour`} />
                                </legend>

                                <Row>
                                    <Col sm={12} md={12} className="col-12">
                                        <Select
                                            name="timeZone"
                                            className="form-group"
                                            label={i18n.get(`${FORM_ID}.timeZone.label`)}
                                            options={tzOptions}
                                            searchable
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6} md={6} className="col-12">
                                        <Field
                                            className="form-control"
                                            autoComplete="off"
                                            component={MaskedTextField}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            mask={maskUtils.timeCodeMask()}
                                            name="startTime"
                                        />
                                    </Col>
                                    <Col sm={6} md={6} className="col-12">
                                        <Field
                                            className="form-control"
                                            autoComplete="off"
                                            component={MaskedTextField}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            mask={maskUtils.timeCodeMask()}
                                            name="endTime"
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

export default CalendarRestriction;
