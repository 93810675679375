import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, number, shape } from "prop-types";
import React, { useState } from "react";
import withRouter from "react-router-dom/withRouter";
import * as i18nUtils from "util/i18n";

const ChipsButtonsGroup = ({ buttonsGroup, isDesktop, defaultSelect, onClickHandler }) => {
    const [selectedIdButton, setSelectedIdButton] = useState(defaultSelect);

    const handlerSelectButton = (buttonId) => {
        setSelectedIdButton(buttonId);
        if (typeof onClickHandler === "function") {
            onClickHandler(buttonId);
        }
    };
    return (
        <Box
            component="nav"
            aria-label={i18nUtils.get("global.navTypes.a11y")}
            display="flex"
            gap="5"
            alignX={isDesktop ? "center" : "evenly"}>
            {buttonsGroup.map((button) => (
                <Button
                    key={button.id}
                    className={classNames({
                        "is-active": button?.id === selectedIdButton,
                    })}
                    label={button?.label}
                    onClick={() => {
                        handlerSelectButton(button.id);
                    }}
                    {...(button?.id === selectedIdButton && { image: "images/check.svg" })}
                    bsStyle="chip"
                    disabled={button?.permission}
                />
            ))}
        </Box>
    );
};

ChipsButtonsGroup.propTypes = {
    buttonsGroup: shape.isRequired,
    isDesktop: bool.isRequired,
    onClickHandler: func.isRequired,
    defaultSelect: number,
};
ChipsButtonsGroup.defaultProps = {
    defaultSelect: null,
};

export default resizableRoute(withRouter(ChipsButtonsGroup));
