import React, { Component } from "react";
import { connect } from "react-redux";
import { string, shape, objectOf } from "prop-types";
import { selectors } from "reducers/administration/common/administrationTicket";

import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";

class RestrictionsUserDeleteTicketData extends Component {
    static propTypes = {
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
    };

    render() {
        const { transactionData } = this.props;
        if (transactionData.data) {
            return (
                <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <div className="transfer-data data-wrapper">
                            <Container.ColumnBody columnClass="list-wrapper">
                                <h4 className="form-title">
                                    <I18n id="administration.restrictions.user.deleted.all" />
                                </h4>
                                {transactionData.data.userName}
                            </Container.ColumnBody>
                        </div>
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(RestrictionsUserDeleteTicketData);
