import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import HeaderEnrollment from "pages/enrollment/_components/HeaderEnrollment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import DocumentField from "pages/_components/fields/Document";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "enrollment.wizard.validateDocument";

class Document extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        const { dispatch, location } = this.props;

        if (location.pathname === "/enrollment/document") {
            this.setState({
                loading: true,
            });

            dispatch(enrollmentActions.pre(this.onFinish));
        }
    }

    onFinish = () => {
        this.setState({
            loading: false,
        });
    };

    cancel = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment"));
    };

    goValidationCode = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment/invitationCode"));
    };

    renderDocumentForm = () => {
        const { documentData, isSubmitting, isDesktop } = this.props;
        return (
            <>
                <HeaderEnrollment title="enrollment.document.title" subtitle="enrollment.document.subtitle" />

                <Form className={classNames("display-flex flex-column", { "full-height": !isDesktop })}>
                    <Row gapY={isDesktop ? "8" : "3"} className="mb-5 mb-md-8 height-auto">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Box background="white" borderRadius="xxl" className="white-boxed">
                                {!isDesktop ? (
                                    <Box fullWidth display="flex" alignX="center" className="pb-5">
                                        <Button
                                            href="/enrollment/invitationCode"
                                            bsStyle="link"
                                            label="enrollment.document.alreadyHaveCode"
                                        />
                                    </Box>
                                ) : null}
                                <Field
                                    countries={documentData?.countryList}
                                    documentCountryMap={documentData?.documentTypeList}
                                    idNumberMap={documentData?.idNumberMap1}
                                    idNumber2Map={documentData?.idNumberMap2}
                                    component={DocumentField}
                                    name="document"
                                    reducedColumnGap
                                    labelNoMarginTop
                                    spacingInputs="mb-5 mb-md-7"
                                    idNumberStyle2
                                />
                                {isDesktop ? (
                                    <Box fullWidth display="flex" alignX="center" className="pt-5">
                                        <Button
                                            href="/enrollment/invitationCode"
                                            className="remove-min-height py-0"
                                            bsStyle="link"
                                            label="enrollment.document.alreadyHaveCode"
                                        />
                                    </Box>
                                ) : null}
                            </Box>
                        </Col>
                    </Row>
                    <Box {...(!isDesktop && { className: "mt-auto" })}>
                        <Row gapY={isDesktop ? "8" : "3"}>
                            <Col xs={12} md={3} mdOffset={3}>
                                <Button
                                    label="global.cancel"
                                    type="button"
                                    bsStyle="outline"
                                    className="btn-uppercase"
                                    onClick={this.cancel}
                                    block
                                />
                            </Col>
                            <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Form>
            </>
        );
    };

    renderDocumentConfirmation = () => {
        const { documentNumber, isSubmitting, isDesktop } = this.props;
        return (
            <>
                <HeaderEnrollment title="enrollment.document.title" />
                <Box display="flex" column {...(!isDesktop && { className: "full-height" })}>
                    <Row gapY={isDesktop ? "0" : "3"} className="mb-5 mb-md-8">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Box
                                display="flex"
                                alignY="center"
                                background="success"
                                borderRadius="md"
                                border="success-1"
                                className="py-5 px-7 mb-5 mb-md-7"
                                fullWidth>
                                <Box display="flex" alignY="center">
                                    <Image src="images/icons/successIcon.svg" />
                                </Box>

                                <Text
                                    color="text-boton"
                                    className="ml-3"
                                    labelKey="enrollment.documentConfirmation.confirmation"
                                />
                            </Box>
                            <Box background="white" borderRadius="xxl" className="white-boxed">
                                <Box display="flex" alignX="center" fullWidth className="mb-3">
                                    <Text labelKey="enrollment.documentConfirmation.yourId" align="center" />
                                </Box>
                                <Box display="flex" alignX="center" fullWidth className="mb-10">
                                    <Text align="center" size="4" bold>
                                        {documentNumber}
                                    </Text>
                                </Box>
                                <Box
                                    display="flex"
                                    alignX="center"
                                    fullWidth
                                    {...(!isDesktop && { className: "mb-8" })}>
                                    <Text labelKey="enrollment.documentConfirmation.message" align="center" />
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                    <Box {...(!isDesktop && { className: "mt-auto" })}>
                        <Row gapY={isDesktop ? "8" : "3"}>
                            <Col xs={12} md={3} mdOffset={3}>
                                <Button
                                    label="global.cancel"
                                    type="button"
                                    bsStyle="outline"
                                    className="btn-uppercase"
                                    onClick={this.cancel}
                                    block
                                />
                            </Col>
                            <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    type="button"
                                    loading={isSubmitting}
                                    onClick={this.goValidationCode}
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Box>
            </>
        );
    };

    render() {
        const { loading } = this.state;

        return (
            <PageLoading loading={loading} bgTransparent>
                <>
                    <Switch>
                        <Route exact path="/enrollment/document" render={this.renderDocumentForm} />

                        <Route exact path="/enrollment/documentConfirmation" render={this.renderDocumentConfirmation} />
                    </Switch>
                </>
            </PageLoading>
        );
    }
}

Document.propTypes = {
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    isSubmitting: bool.isRequired,
    documentData: shape({}),
    documentNumber: string,
    isDesktop: bool.isRequired,
};

Document.defaultProps = {
    documentData: null,
    documentNumber: null,
};

const mapStateToProps = (state) => ({
    documentData: enrollmentSelectors.getDocumentData(state),
    documentNumber: enrollmentSelectors.getDocumentNumber(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            document: {
                documentCountry: "",
                documentType: "",
                documentNumber1: "",
                documentNumber2: "",
                idNumber1: "",
                idNumber2: "",
            },
        }),
        validationSchema: () =>
            Yup.object().shape({
                document: Yup.object().shape({
                    documentCountry: Yup.string().required(i18n.get(`${FORM_ID}.documentCountry.empty`)),
                    documentType: Yup.string().required(i18n.get(`${FORM_ID}.documentType.empty`)),
                    documentNumber1: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.empty`)),
                    documentNumber2: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.empty`)),
                    }),
                    idNumber1: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) =>
                            documentCountry === "PA" || (documentCountry === "VE" && documentType === "CED"),
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.empty`)),
                    }),
                    idNumber2: Yup.string().when("documentCountry", {
                        is: "PA",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.empty`)),
                    }),
                }),
            }),
        handleSubmit: ({ document }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(enrollmentActions.validateDocument(document, formikBag));
        },
    }),
)(Document);
