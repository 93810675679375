import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import ModalNotification from "pages/forms/_components/ModalNotification";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { actions as templateActions, selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "forms.template";

class CreatTemplateModal extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        isVisible: bool.isRequired,
        errors: shape({}).isRequired,
    };

    handleHide = () => {
        this.props.dispatch(templateActions.closeCreateModal());
    };

    render() {
        const { isSubmitting, isDesktop, isVisible, errors } = this.props;

        return (
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                onHide={this.handleHide}
                show={isVisible}
                className={classNames("drawer")}>
                <div className="modal-container">
                    <Modal.Header closeButton>
                        <Modal.Title id="modalTitleID">
                            <Text labelKey={`${FORM_ID}.title`} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Box fullWidth fullHeight className="scrollable-content px-0 px-md-9">
                            <Form className="full-width full-height">
                                <ModalNotification formId={FORM_ID} errors={errors} />
                                <Box display="flex" column alignX="center" alignY="between" fullHeight fullWidth>
                                    <Field name="templateName">
                                        {({ field, form }) => (
                                            <TextField
                                                maxLength={100}
                                                pattern="^[a-zA-Z0-9 ]*$"
                                                autoFocus={isDesktop}
                                                idForm={FORM_ID}
                                                field={field}
                                                form={{ ...form, touched: { templateName: true } }}
                                            />
                                        )}
                                    </Field>
                                    <Box display="flex" alignX="center" className="py-6 mb-5">
                                        <Text align="center" labelKey="forms.template.note.send" />
                                    </Box>
                                    <Box className="drawer-form-button" fullWidth>
                                        <Button
                                            type="submit"
                                            label="forms.template.send"
                                            bsStyle="primary"
                                            loading={isSubmitting}
                                            block
                                        />
                                    </Box>
                                </Box>
                            </Form>
                        </Box>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    isVisible: templateSelectors.isCreateModalVisible(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            templateName: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                templateName: Yup.string().required(i18n.get(`${FORM_ID}.templateName.required`)),
            }),
        handleSubmit: ({ templateName }, formikBag) => {
            const { idForm, values, idActivityTemplate } = formikBag.props;

            formikBag.props.dispatch(
                templateActions.saveTemplate(idForm, values, templateName, formikBag, idActivityTemplate),
            );

            formikBag.resetForm();
        },
    }),
    resizableRoute,
)(CreatTemplateModal);
