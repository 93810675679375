import EnrollmentStepIndicator, { Step } from "pages/enrollment/_components/EnrollmentStepIndicator";
import I18n from "pages/_components/I18n";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";

import { shape, number, string, bool } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { selectors as enrollmentSelectors } from "reducers/enrollment";

const Stepper = ({ currentStep, className, completed, stepsList, showLabelMobile, isDesktop }) => {
    const stepLabel = stepsList.find((item) => item.stepNumber === currentStep);

    return (
        <>
            <EnrollmentStepIndicator className={className}>
                {stepsList.map((step) => (
                    <>
                        <Step currentStep={currentStep} stepNumber={step.stepNumber} completed={completed}>
                            <I18n id={step.label} />
                        </Step>
                    </>
                ))}
            </EnrollmentStepIndicator>
            {showLabelMobile && !isDesktop && (
                <Box display="flex" alignX="center" fullWidth className="pt-3">
                    <Text labelKey={stepLabel.label} align="center" color="secondary" size="1" bold />
                </Box>
            )}
        </>
    );
};

Stepper.propTypes = {
    isDesktop: bool.isRequired,
    currentStep: number.isRequired,
    completed: number,
    className: string,
    showLabelMobile: bool,
    stepsList: shape({}).isRequired,
};

Stepper.defaultProps = {
    completed: null,
    className: undefined,
    showLabelMobile: false,
};

const mapStateToProps = (state) => ({
    userExists: enrollmentSelectors.isUserExists(state),
});

export default connect(mapStateToProps)(resizableRoute(Stepper));
