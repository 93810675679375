import React from "react";
import { Svg, Path } from "@react-pdf/renderer";

const LogoBanesco = () => (
    <Svg width="153" height="36" viewBox="0 0 153 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.9628 20.3969C44.02 20.3969 45.5294 20.5065 45.5294 22.235C45.5294 23.7294 44.3239 24.0432 42.7299 24.0432H39.9005V20.3969H41.9628ZM42.182 13.5028C43.5518 13.5028 44.832 13.5028 44.832 15.057C44.832 16.2126 43.9005 16.7905 42.6054 16.7905H39.9005V13.5028H42.182ZM43.0587 28.6658C48.1943 28.6658 51.5716 27.1316 51.5716 22.6883C51.5716 20.5463 50.416 18.7033 48.274 18.1504C49.0337 17.7068 49.6688 17.0782 50.12 16.323C50.5711 15.5679 50.8238 14.7108 50.8543 13.8316C50.8543 9.91135 47.3127 8.85034 42.8843 8.85034H33.4199V28.6259L43.0587 28.6658Z"
            fill="#00457C"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.1745 28.6646V28.3906C68.4323 27.7879 68.4024 27.3944 68.4024 26.5525V19.8279C68.4024 16.3858 67.9939 13.8503 61.1846 13.8503C55.501 13.8503 53.3341 15.6386 53.3341 18.6921H58.8135C58.953 17.4568 60.0239 17.2924 60.806 17.2924C61.4137 17.2924 62.674 17.4269 62.674 18.4729C62.674 21.1678 52.4375 18.199 52.4375 24.7344C52.4375 27.7231 54.8484 29.0731 57.5682 29.0731C59.7101 29.0731 61.3589 28.6347 62.9778 27.1254L63.2518 28.6596L69.1745 28.6646ZM60.1684 25.8402C59.0426 25.8402 58.3552 25.3421 58.3552 24.6298C58.3552 22.7618 60.8459 23.2002 62.6939 22.2986C62.8334 24.2911 62.1958 25.8402 60.1684 25.8402"
            fill="#00457C"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70.0913 28.6596H75.7998V21.3322C75.7998 19.4941 76.2681 18.3434 78.0264 18.3434C79.6404 18.3434 80.0189 19.6087 80.0189 21.0383V28.6596H85.7274V20.0421C85.7274 16.057 83.9442 13.8503 80.1534 13.8503C77.9866 13.8503 76.5869 14.7868 75.2967 16.5203V14.2638H70.0913V28.6596Z"
            fill="#00457C"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M92.8845 19.8478C92.8845 18.3086 93.8459 17.3571 95.5495 17.3571C96.2086 17.3719 96.8372 17.6381 97.3067 18.101C97.7762 18.564 98.051 19.1889 98.075 19.8478H92.8845ZM103.783 22.7568C103.863 17.5365 100.655 13.8503 95.3153 13.8503C90.7077 13.8503 87.166 16.874 87.166 21.6061C87.166 26.438 90.7326 29.078 95.3452 29.078C98.6926 29.078 102.319 27.8975 103.315 24.3309H97.8359C97.6394 24.7259 97.3323 25.0554 96.952 25.2791C96.5718 25.5028 96.1347 25.6112 95.6939 25.5911C95.3203 25.5986 94.9491 25.5305 94.6024 25.391C94.2558 25.2514 93.9409 25.0433 93.6767 24.779C93.4124 24.5148 93.2043 24.1999 93.0648 23.8533C92.9252 23.5067 92.8571 23.1354 92.8646 22.7618L103.783 22.7568Z"
            fill="#00457C"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M104.232 24.1704C104.536 28.0409 108.158 29.0571 111.455 29.0571C116.725 29.0571 119.196 27.0247 119.196 24.031C119.196 18.6512 110.11 20.0011 110.11 17.9887C110.11 17.2764 110.743 16.9924 111.729 16.9924C112.142 16.9754 112.548 17.1003 112.88 17.3461C113.039 17.4742 113.17 17.6336 113.265 17.8143C113.36 17.9951 113.417 18.1935 113.433 18.3972H118.618C118.29 14.5715 114.633 13.8542 111.48 13.8542C108.491 13.8542 104.671 14.9252 104.671 18.6163C104.671 24.1904 113.488 22.3523 113.488 24.6586C113.488 25.6548 112.551 25.9487 111.674 25.9487C111.173 25.9585 110.681 25.8046 110.275 25.5104C110.077 25.3535 109.92 25.1521 109.815 24.9227C109.71 24.6933 109.661 24.4423 109.672 24.1904L104.232 24.1704Z"
            fill="#00457C"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.758 19.7681C135.345 15.4991 131.719 13.8503 127.878 13.8503C123.131 13.8503 119.669 16.6847 119.669 21.6061C119.669 26.5276 123.241 29.078 127.878 29.078C131.863 29.078 135.35 27.4591 135.848 23.1005H130.1C129.935 24.2263 129.357 25.2425 127.878 25.2425C126.204 25.2425 125.387 23.8178 125.387 21.651C125.387 19.9474 125.746 17.7158 127.878 17.7158C128.155 17.6979 128.432 17.7409 128.69 17.8416C128.948 17.9422 129.181 18.0982 129.372 18.2986C129.76 18.6997 129.978 19.2351 129.98 19.793L135.758 19.7681Z"
            fill="#00457C"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M144.715 17.6909C146.21 17.6112 147.131 19.1853 147.131 21.3222C147.131 24.037 146.2 25.2176 144.666 25.2176C143.131 25.2176 142.175 24.037 142.175 21.3222C142.175 19.7033 142.748 17.6909 144.695 17.6909H144.715ZM136.486 21.6261C136.486 26.5376 140.058 29.0631 144.671 29.0631C149.283 29.0631 152.85 26.5725 152.85 21.3222C152.85 16.8391 149.717 13.8503 144.641 13.8503C139.784 13.8503 136.486 16.6299 136.486 21.6361"
            fill="#00457C"
        />
        <Path
            d="M7.03906 5.47171C7.03906 4.50171 7.32658 3.55348 7.86528 2.74682C8.40397 1.94017 9.16968 1.31128 10.0657 0.939626C10.9616 0.567971 11.9477 0.470226 12.8992 0.658739C13.8507 0.847253 14.7249 1.31357 15.4115 1.99876C16.0981 2.68396 16.5662 3.55728 16.7566 4.5084C16.9471 5.45952 16.8513 6.44574 16.4815 7.34246C16.1117 8.23919 15.4843 9.00617 14.6788 9.5465C13.8732 10.0868 12.9256 10.3763 11.9556 10.3782C10.6543 10.3782 9.40627 9.8613 8.48611 8.94115C7.56596 8.021 7.04902 6.773 7.04902 5.47171"
            fill="#00795D"
        />
        <Path
            d="M15.0083 13.6045C15.0104 12.6779 15.2745 11.7709 15.7701 10.9881C16.2657 10.2052 16.9727 9.57858 17.8093 9.18044C18.6459 8.7823 19.5781 8.62893 20.4982 8.73803C21.4183 8.84714 22.2887 9.21427 23.009 9.79703C23.7293 10.3798 24.2701 11.1544 24.5689 12.0314C24.8677 12.9085 24.9123 13.8521 24.6977 14.7534C24.483 15.6548 24.0177 16.477 23.3556 17.1251C22.6935 17.7733 21.8616 18.2209 20.9559 18.4164C20.4391 17.331 19.6253 16.4142 18.6088 15.7724C17.5923 15.1306 16.4147 14.79 15.2125 14.79H15.1528C15.0564 14.4022 15.0079 14.0041 15.0083 13.6045Z"
            fill="#00457C"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3012 21.1486C10.3012 20.1772 10.5893 19.2276 11.129 18.4199C11.6687 17.6122 12.4357 16.9827 13.3332 16.6109C14.2307 16.2392 15.2182 16.1419 16.1709 16.3314C17.1237 16.5209 17.9988 16.9887 18.6857 17.6756C19.3726 18.3625 19.8404 19.2377 20.0299 20.1904C20.2194 21.1431 20.1221 22.1307 19.7504 23.0281C19.3787 23.9256 18.7491 24.6927 17.9414 25.2324C17.1338 25.7721 16.1842 26.0601 15.2128 26.0601C13.9105 26.0588 12.662 25.5409 11.7412 24.6201C10.8204 23.6993 10.3026 22.4508 10.3012 21.1486ZM0 27.151C0 23.7687 3.71602 20.8597 9.05094 19.5695C8.96493 19.8961 8.9066 20.2294 8.8766 20.5658C6.57526 21.4076 5.10579 22.6928 5.10579 24.1373C5.10579 26.6628 9.62877 28.7151 15.2078 28.7151C20.7868 28.7151 25.3098 26.6628 25.3098 24.1373C25.3098 22.6928 23.8453 21.4076 21.5489 20.5708C21.5181 20.2348 21.4615 19.9017 21.3795 19.5745C26.6945 20.8647 30.3857 23.7737 30.3857 27.151C30.3857 31.7338 23.5962 35.4348 15.2028 35.4348C6.80938 35.4348 0 31.7338 0 27.151Z"
            fill="#E42313"
        />
    </Svg>
);

export default LogoBanesco;
