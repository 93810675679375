import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import HeaderEnrollment from "pages/enrollment/_components/HeaderEnrollment";
import { getPasswordHelp } from "pages/settings/ChangePassword";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import TextField from "pages/_components/fields/TextField";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions } from "reducers/enrollment";
import { compose } from "redux";
import { startSensorDataCapture } from "util/monitor/monitorDbf.utils";
import * as config from "util/config";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "enrollment.wizard.finishCreate";

class CreateUser extends Component {
    componentDidMount() {
        startSensorDataCapture();
    }

    getUsernameHelp = () => (
        <ul>
            <li>
                <Text labelKey="enrollment.usernameHelp.rule1" />
            </li>
            <li>
                <Text labelKey="enrollment.usernameHelp.rule2" />
            </li>
        </ul>
    );

    cancel = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment"));
    };

    render() {
        const { isSubmitting, isDesktop } = this.props;
        const maxLength = config.getInteger("core.password.maxLength");

        return (
            <>
                <HeaderEnrollment title="enrollment.createUser.title" subtitle="enrollment.createUser.subtitle" />
                <Form className={classNames("display-flex flex-column", { "full-height": !isDesktop })}>
                    <Row gapY={isDesktop ? "8" : "3"} className="mb-5 mb-md-8 height-auto">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Box
                                background="white"
                                borderRadius="xxl"
                                className="pt-7 pt-md-9 pb-9 pb-md-8 px-5 px-md-12 mx-n-5 mx-md-n-0 white-boxed">
                                <Box fullWidth className="mb-7 mb-md-7">
                                    <Field
                                        idForm={FORM_ID}
                                        name="newUsername"
                                        tooltip={this.getUsernameHelp()}
                                        component={TextField}
                                        placeholder=""
                                        labelNoMarginTop
                                        maxLength={config.getInteger(`${FORM_ID}.field.newUsername.maxLength`) || 70}
                                    />
                                </Box>
                                <Box fullWidth className="mb-7 mb-md-7">
                                    <Field
                                        name="newPassword"
                                        idForm={FORM_ID}
                                        component={Credential}
                                        maxLength={maxLength}
                                        placeholder=""
                                        tooltip={getPasswordHelp()}
                                        labelNoMarginTop
                                    />
                                </Box>
                                <Box fullWidth className="mb-7 mb-md-7">
                                    <Field
                                        name="newPasswordRepeat"
                                        idForm={FORM_ID}
                                        component={Credential}
                                        maxLength={maxLength}
                                        placeholder=""
                                        labelNoMarginTop
                                    />
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                    <Box {...(!isDesktop && { className: "mt-auto" })}>
                        <Row gapY={isDesktop ? "8" : "3"}>
                            <Col xs={12} md={3} mdOffset={3}>
                                <Button
                                    label="global.cancel"
                                    type="button"
                                    className="btn-outline"
                                    onClick={this.cancel}
                                    block
                                />
                            </Col>
                            <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Form>
            </>
        );
    }
}

CreateUser.propTypes = {
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
};

export default compose(
    connect(),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            newUsername: "",
            newPassword: "",
            newPasswordRepeat: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                newUsername: Yup.string().required(i18n.get(`${FORM_ID}.newUsername.empty`)),
                newPassword: Yup.string().required(i18n.get(`${FORM_ID}.newPassword.empty`)),
                newPasswordRepeat: Yup.string().required(i18n.get(`${FORM_ID}.newPasswordRepeat.empty`)),
            }),
        handleSubmit: ({ newUsername, newPassword, newPasswordRepeat }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(enrollmentActions.finishCreate(newUsername, newPassword, newPasswordRepeat, formikBag));
        },
    }),
)(CreateUser);
