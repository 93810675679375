import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { Redirect } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { arrayOf, bool, func, shape, string } from "prop-types";
import * as Yup from "yup";

import * as arrayUtils from "util/array";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FeatureFlag from "pages/_components/FeatureFlag";
import Selector from "pages/_components/fields/formik/Selector";
import MultiSelect from "pages/_components/fields/formik/MultiSelect";
import StepperFieldGroup from "pages/_components/fields/StepperFieldGroup";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import SectionTitle from "pages/_components/SectionTitle";
import ShowCurrency from "./ShowCurrency";

const FORM_ID = "administration.signatures.create";

class SignaturesSchemeCreate extends Component {
    static propTypes = {
        actions: shape({
            createSignaturesSchemePreRequest: func,
        }),
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        capFrequencyList: arrayOf(string),
        fetching: bool,
        functionalGroups: arrayOf(
            shape({
                idFeature: string,
            }),
        ),
        environmentProducts: arrayOf(Object),
        isDesktop: bool.isRequired,
        masterCurrency: string,
        routerActions: shape({
            goBack: func,
            push: func,
        }),
        signatureTypeList: arrayOf(string),
        selectedFunctionalGroups: arrayOf(string),
    };

    static defaultProps = {
        actions: null,
        capFrequencyList: [],
        fetching: false,
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        routerActions: null,
        signatureTypeList: null,
        selectedFunctionalGroups: [],
        environmentProducts: [],
    };

    componentDidMount() {
        const { actions } = this.props;

        actions.createSignaturesSchemePreRequest();
    }

    handleSubmit = ({
        functionalGroups,
        signatureType,
        signatureLevelsCounts,
        toggleFunctionalGroups,
        toggleProducts,
        selectedProducts,
        topAmount,
        toggleDispatchControl,
    }) => {
        const {
            credentialGroups,
            actions,
            activeEnvironment: { administrationScheme },
            routerActions,
        } = this.props;

        actions.createSignaturesSchemeConfirmPre(
            {
                selectedFunctionalGroups: toggleFunctionalGroups ? functionalGroups : [],
                selectedProducts: toggleProducts ? selectedProducts : [],
                signatureLevelsCounts,
                signatureType,
                topAmount,
                signatureDispatch: toggleDispatchControl,
            },
            credentialGroups,
        );

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/create/confirm`);
    };

    renderContent = () => {
        const {
            activeEnvironment: { administrationScheme },
            selectedFunctionalGroups,
        } = this.props;

        let signatureLevelsCounts = {};
        if (administrationScheme === "advanced") {
            signatureLevelsCounts = configUtils.getArray("administration.signatures.signatureLevels").reduce(
                (acc, signLevel) => ({
                    ...acc,
                    [signLevel]: 0,
                }),
                signatureLevelsCounts,
            );
        } else {
            signatureLevelsCounts = {
                A: 0,
            };
        }

        const initialValues = {
            functionalGroups: selectedFunctionalGroups,
            signatureLevelsCounts,
            toggleFunctionalGroups: selectedFunctionalGroups.length > 0,
            topAmount: {
                amount: "",
                period: configUtils.get("administration.signatures.topAmount.defaultFrequency"),
            },
            signatureType: "",
            signatureDispatch: false,
            selectedProducts: [],
        };

        return (
            <Fragment>
                <MainContainer>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={this.validationSchema}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    };

    renderForm = ({ isSubmitting, values }) => {
        const {
            activeEnvironment,
            capFrequencyList,
            functionalGroups,
            environmentProducts,
            masterCurrency,
            signatureTypeList,
        } = this.props;
        const { administrationScheme } = activeEnvironment;
        const { signatureType, toggleFunctionalGroups, toggleProducts } = values;

        return (
            <Form className="above-the-fold">
                <Container className="container--layout flex-grow-1 items-center" gridClassName="form-content">
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <p className="text-lead">
                            <I18n id={`administration.signatures.create.${administrationScheme}.subtitle`} />
                        </p>
                    </Col>
                </Container>
                <Container className="container--layout flex-grow-1 items-center" gridClassName="form-content">
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        {this.renderRequiredSignatures(administrationScheme)}
                    </Col>
                </Container>
                <Container className="container--layout flex-grow-1 items-center" gridClassName="form-content">
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <FeatureFlag id="feature.signatureSchema.dispatchControl">
                            <SectionTitle
                                title="details.administration.dispatcher.label"
                                subTitle="administration.signatures.dispatcher"
                            />
                            <Field component={SwitchField} idForm={FORM_ID} name="toggleDispatchControl" />
                        </FeatureFlag>
                    </Col>
                </Container>
                <Container className="container--layout flex-grow-1 items-center" gridClassName="form-content">
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        {signatureTypeList &&
                            this.renderTransactions(
                                capFrequencyList,
                                functionalGroups,
                                masterCurrency,
                                toggleFunctionalGroups,
                                toggleProducts,
                                environmentProducts,
                                signatureTypeList,
                                administrationScheme === "advanced" && signatureType && signatureType === "AMOUNT",
                            )}
                    </Col>
                </Container>
                <Container className="container--layout items-center" gridClassName="form-content">
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <Button bsStyle="primary" label="global.continue" loading={isSubmitting} type="submit" block />
                    </Col>
                </Container>
            </Form>
        );
    };

    renderFunctionalGroups = (functionalGroups, showFunctionalGroups) => (
        <Fragment>
            <Field component={SwitchField} idForm={FORM_ID} name="toggleFunctionalGroups" />
            {showFunctionalGroups && (
                <MultiSelect
                    label="administration.signatures.functionalGroups.label"
                    name="functionalGroups"
                    options={arrayUtils.mapItemsIds(
                        functionalGroups.map((functionalGroup) => ({
                            label: i18nUtils.get(
                                `administration.signatures.functionalGroups.${functionalGroup.idFeature}`,
                            ),
                            value: functionalGroup.idFeature,
                        })),
                        "value",
                    )}
                    placeholder="administration.signatures.functionalGroups.add">
                    {({ label }) => <span className="data-desc">{label}</span>}
                </MultiSelect>
            )}
        </Fragment>
    );

    renderProducts = (environmentProducts, toggleProducts) => (
        <Fragment>
            <Field component={SwitchField} idForm={FORM_ID} name="toggleProducts" />
            {toggleProducts && (
                <MultiSelect
                    label="administration.signatures.products.label"
                    name="selectedProducts"
                    options={arrayUtils.mapItemsIds(
                        environmentProducts.map((product) => ({
                            label: product.label,
                            value: product.idProduct,
                        })),
                        "value",
                    )}
                    placeholder="administration.signatures.products.add">
                    {({ label }) => <span className="data-desc">{label}</span>}
                </MultiSelect>
            )}
        </Fragment>
    );

    renderRequiredSignatures = (administrationScheme) => (
        <Fragment>
            <SectionTitle
                title="administration.signatures.create.requiredSignatures"
                subTitle={`administration.signatures.create.${administrationScheme}.signersLevel`}
            />
            <div className="form-group form-group--stepper-field">
                <div className="control-label form-group-text">
                    <I18n id={`administration.signatures.create.${administrationScheme}.signersCount`} />
                </div>

                <Field
                    component={StepperFieldGroup}
                    hideLabel={administrationScheme === "medium"}
                    idForm={FORM_ID}
                    name="signatureLevelsCounts"
                    min="0"
                    max={configUtils.get("administration.signatures.maxNeeded")}
                    options={
                        administrationScheme === "medium"
                            ? ["A"]
                            : configUtils.getArray("administration.signatures.signatureLevels")
                    }
                    showFirstErrorWhenEquals
                />
            </div>
        </Fragment>
    );

    renderTransactions = (
        capFrequencyList,
        functionalGroups,
        masterCurrency,
        showFunctionalGroups,
        toggleProducts,
        environmentProducts,
        signatureTypeList,
        transactionWithAmount,
    ) => (
        <Fragment>
            <SectionTitle
                title="administration.signatures.create.transactions"
                subTitle="administration.signatures.create.transactions.subtitle"
            />
            <div className="form-group">
                <div className="control-label form-group-text">
                    <I18n id="administration.signatures.create.transactions.type" />
                </div>
                <div className="form-group-control-list">
                    <Field
                        component={Selector}
                        idForm={FORM_ID}
                        name="signatureType"
                        options={signatureTypeList.map((signatureType) => ({
                            id: signatureType,
                            label: i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`),
                        }))}
                        renderAs="radio"
                    />
                </div>
            </div>
            {transactionWithAmount && masterCurrency && capFrequencyList && (
                <ShowCurrency masterCurrency={masterCurrency} capFrequencyList={capFrequencyList} form={FORM_ID} />
            )}
            {functionalGroups && this.renderFunctionalGroups(functionalGroups, showFunctionalGroups)}
            {environmentProducts && this.renderProducts(environmentProducts, toggleProducts)}
        </Fragment>
    );

    validationSchema = () => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;
        const maxSignersCount = configUtils.get("administration.signatures.maxNeeded");

        return Yup.lazy((values) =>
            Yup.object().shape({
                functionalGroups: values.toggleFunctionalGroups
                    ? Yup.array()
                          .of(Yup.string())
                          .min(1, i18nUtils.get("administration.signatures.functionalGroups.atLeastOne"))
                    : Yup.array().notRequired(),
                signatureLevelsCounts: Yup.object().shape(
                    administrationScheme === "medium"
                        ? {
                              A: Yup.number()
                                  .moreThan(
                                      0,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .max(
                                      maxSignersCount,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .required(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`))
                                  .typeError(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`)),
                          }
                        : configUtils.getArray("administration.signatures.signatureLevels").reduce((levels, level) => {
                              const newLevels = levels;
                              newLevels[level] = Object.values(values.signatureLevelsCounts).some((value) => value)
                                  ? Yup.number()
                                        .min(
                                            0,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .max(
                                            maxSignersCount,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .required(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                        .typeError(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                  : Yup.number().moreThan(0, i18nUtils.get(`${FORM_ID}.signersCount.atLeastOne`));
                              return newLevels;
                          }, {}),
                ),
                signatureType: Yup.string().required(i18nUtils.get(`${FORM_ID}.signatureType.required`)),
                topAmount: Yup.object().when("signatureType", {
                    is: (signatureType) => administrationScheme === "advanced" && signatureType === "AMOUNT",
                    then: Yup.object().shape({
                        amount: Yup.number()
                            .moreThan(0, i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.mustBePositive`))
                            .required(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`))
                            .typeError(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`)),
                        period: Yup.string().required(),
                    }),
                    otherwise: Yup.object().notRequired(),
                }),
            }),
        );
    };

    render() {
        const {
            fetching,
            isDesktop,
            activeEnvironment: { administrationScheme },
        } = this.props;

        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        return (
            <Fragment>
                <Notification scopeToShow="administrationCreateSignatureScheme" />
                <Head
                    title="administration.signatures.create.title"
                    closeLinkTo={`/administration/${administrationScheme}/signaturesSchemes`}
                />
                <PageLoading loading={fetching}>{this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

export default resizableRoute(SignaturesSchemeCreate);
