import React, { useEffect, useState } from "react";
import { compose } from "redux";

import * as i18n from "util/i18n";
import RadioButton from "pages/_components/fields/Radio";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";
import Box from "pages/_components/Box";
import { bool, func, shape, string, arrayOf, oneOfType } from "prop-types";

const RadioButtonCustom = ({
    setValue,
    options,
    value,
    idField,
    onChange,
    initialChange = true,
    genericPropRender = {},
    mode,
    gap,
}) => {
    const requestChange = (itemSelected) => {
        if (onChange) {
            onChange(itemSelected);
        }
    };

    const [initChange, setInitChange] = useState(initialChange);

    useEffect(() => {
        if (value && initChange) {
            requestChange(value);
        }
        if (!initChange) {
            setInitChange(true);
        }
        // eslint-disable-next-line
    }, [value]);

    const handleChange = (event) => {
        const loan = event.target.value;
        setValue(loan);
        requestChange(loan);
    };

    const renderPreviewMode = () => {
        if (options && value) {
            const currentValue = options.filter((item) => item.key === value);
            return (
                <>
                    <span>{currentValue && currentValue[0] ? i18n.get(currentValue[0].label) : <div />}</span>
                </>
            );
        }

        return <div />;
    };

    const renderEditMode = () => (
        <>
            <Box display="flex" alignX="between" alignY="center" {...(gap && { gap })} {...genericPropRender}>
                {options.map((option) => (
                    <RadioButton
                        id={`${idField}_${option.key}`}
                        className="mx-3"
                        checked={option.key === value}
                        inLineControl
                        key={`${idField}_${option.key}`}
                        label={i18n.get(option.label)}
                        name={idField}
                        onChange={handleChange}
                        value={option.value}
                    />
                ))}
            </Box>
        </>
    );
    return <>{mode === "edit" ? renderEditMode() : renderPreviewMode()}</>;
};

RadioButtonCustom.propTypes = {
    setValue: func,
    value: oneOfType([
        string,
        shape({
            isFrequentDestination: bool,
            value: string,
        }),
    ]),
    onChange: func,
    idField: string.isRequired,
    options: arrayOf(shape({})),
    initialChange: bool,
    mode: string,
    genericPropRender: shape({}),
    gap: string,
};

RadioButtonCustom.defaultProps = {
    options: {},
    onChange: () => {},
    setValue: () => {},
    initialChange: true,
    mode: "",
    genericPropRender: {},
    value: null,
    gap: null,
};

export default compose(withFocus, formField())(RadioButtonCustom);
