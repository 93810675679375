import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import HeadSimple from "pages/_components/HeadSimple";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { useStartSensorDataCapture } from "pages/forms/customForms/hooks/useStartSensorDataCapture";
import { bool, func, shape } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { actions as loginActions } from "reducers/login";
import { actions as recoveryUserActions, selectors as recoveryUserSelectors } from "reducers/recoveryUser";

const RecoverUserStep2 = (props) => {
    const { dispatch, fetching, formData, isMobile } = props;

    useStartSensorDataCapture("preview");

    useEffect(() => {
        if (!formData.documentCountry || !formData.documentType || !formData.documentNumber) {
            dispatch(replace("/recoverUser/step1"));
        }
    }, [formData, dispatch]);

    const handlerHeaderClose = () => {
        dispatch(loginActions.reset());
    };

    const handlerButtonSenData = () => {
        const { documentCountry, documentType, documentNumber } = formData;
        dispatch(recoveryUserActions.sendForm(documentCountry, documentType, documentNumber));
    };

    return (
        <Box display="flex" column background="white" fullHeight className="px-5 px-md-7 pt-7 pt-md-0 pb-10 pb-md-7">
            <HeadSimple onRightClick={handlerHeaderClose} title="recoverUser.step1.header" className="mb-2 mb-md-0" />
            <MainContainer>
                <Box fullHeight className="content-recoveryUser-wrapper">
                    <Box
                        display="flex"
                        className="pb-md-0 pt-md-5 px-md-5"
                        column
                        fullWidth
                        {...(isMobile && { fullHeight: true })}>
                        <Box display="flex" alignX="center">
                            <Text
                                component="p"
                                color="text"
                                size={isMobile ? "5" : "6"}
                                align="center"
                                labelKey="recoverUser.step2.title"
                            />
                        </Box>

                        <Box column display="flex" alignX="center" row alignY="center" className="mb-8 mt-auto">
                            <Text
                                component="p"
                                color="heading"
                                size={isMobile ? "3" : "5"}
                                labelKey="recoverUser.step2.identification"
                            />
                            <Text component="p" color="heading" size={isMobile ? "3" : "5"}>
                                {formData.documentNumberToShow}
                            </Text>
                        </Box>

                        <Box display="flex mt-auto">
                            <Button
                                onClick={handlerButtonSenData}
                                bsStyle="primary"
                                label="global.continue"
                                loading={fetching}
                                disabled={fetching}
                                block
                            />
                        </Box>
                    </Box>
                </Box>
            </MainContainer>
        </Box>
    );
};

RecoverUserStep2.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    formData: shape({}),
};

RecoverUserStep2.defaultProps = {
    formData: {},
};

const mapStateToProps = (state) => ({
    fetching: recoveryUserSelectors.getFetching(state),
    formData: recoveryUserSelectors.getFormData(state),
});

export default connect(mapStateToProps)(resizableRoute(RecoverUserStep2));
