import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_LIST_REQUEST: "administrationUsers/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "administrationUsers/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "administrationUsers/LOAD_LIST_SUCCESS",
    LOAD_MORE_REQUEST: "administrationUsers/LOAD_MORE_REQUEST",
    LOAD_MORE_SUCCESS: "administrationUsers/LOAD_MORE_SUCCESS",
    LOAD_SINGLE_SUCCESS: "administrationUsers/LOAD_SINGLE_SUCCESS",
    UPDATE_DISPATCHER_REQUEST: "administrationUsers/UPDATE_DISPATCHER_REQUEST",
    UPDATE_DISPATCHER_FAILURE: "administrationUsers/UPDATE_DISPATCHER_FAILURE",
    UPDATE_DISPATCHER_SUCCESS: "administrationUsers/UPDATE_DISPATCHER_SUCCESS",
    EXPORT_LIST_REQUEST: "administrationUsers/EXPORT_LIST_REQUEST",
    EXPORT_LIST_FAILURE: "administrationUsers/EXPORT_LIST_FAILURE",
    EXPORT_LIST_SUCCESS: "administrationUsers/EXPORT_LIST_SUCCESS",
    CHANGE_USER_STATUS_PREVIEW: "administrationUsers/CHANGE_USER_STATUS_PREVIEW",
    CHANGE_USER_STATUS_PREVIEW_SUCCESS: "administrationUsers/CHANGE_USER_STATUS_PREVIEW_SUCCESS",
    CHANGE_USER_STATUS_CONFIRMATION: "administrationUsers/CHANGE_USER_STATUS_CONFIRMATION",
};

export const INITIAL_STATE = {
    list: [],
    usersInfoById: {},
    userAction: null,
    usersToApplyAction: [],
    usersNamesToApplyAction: [],
    currentPage: 1,
    totalPages: 0,
    hasMoreData: false,
    isInviteEnabled: false,
    fetching: true,
    fetchingMoreUsers: false,
    fetchingExport: false,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_LIST_REQUEST]: (state, { pageNumber }) => ({
        ...state,
        fetching: true,
        currentPage: pageNumber,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (state, { data }) => ({
        list: data.users,
        usersInfoById: data.usersExtendedInfo,
        currentPage: data.currentPage,
        totalPages: data.totalPages,
        hasMoreData: data.currentPage < data.totalPages,
        isInviteEnabled: data.inviteEnabled,
        fetching: false,
    }),

    [types.LOAD_SINGLE_SUCCESS]: (state, { data }) => ({
        ...state,
        usersInfoById: {
            ...state.usersInfoById,
            [data.idUser]: data,
        },
        fetching: false,
    }),

    [types.UPDATE_DISPATCHER_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_DISPATCHER_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.UPDATE_DISPATCHER_SUCCESS]: (state, { data }) => ({
        ...state,
        usersInfoById: {
            ...state.usersInfoById,
            [data.idUser]: data,
        },
        fetching: false,
    }),

    [types.LOAD_MORE_REQUEST]: (state) => ({
        ...state,
        fetchingMoreUsers: true,
    }),
    [types.LOAD_MORE_SUCCESS]: (state, { data }) => ({
        ...state,
        fetchingMoreUsers: false,
        list: state.list ? state.list.concat(data.users) : data.list,
        usersInfoById: state.usersInfoById ? { ...state.usersInfoById, ...data.usersExtendedInfo } : data.usersInfoById,
        hasMoreData: data.currentPage < data.totalPages,
        currentPage: data.currentPage,
    }),

    [types.EXPORT_LIST_REQUEST]: (state) => ({
        ...state,
        fetchingExport: true,
    }),
    [types.EXPORT_LIST_FAILURE]: (state) => ({
        ...state,
        fetchingExport: false,
    }),
    [types.EXPORT_LIST_SUCCESS]: (state) => ({
        ...state,
        fetchingExport: false,
    }),
    [types.CHANGE_USER_STATUS_PREVIEW_SUCCESS]: (
        state,
        { userList, userNameList, userAction, credentialGroups, idTransaction, idActivity },
    ) => ({
        ...state,
        userAction,
        usersToApplyAction: userList,
        usersNamesToApplyAction: userNameList,
        idTransaction,
        idActivity,
        credentialGroups,
    }),
});

export const actions = {
    loadListRequest: (filters) => ({
        type: types.LOAD_LIST_REQUEST,
        filters,
    }),
    loadMoreRequest: (filters) => ({
        type: types.LOAD_MORE_REQUEST,
        filters,
    }),
    exportListRequest: (format) => ({
        type: types.EXPORT_LIST_REQUEST,
        format,
    }),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "data"),
    loadSingleSuccess: makeActionCreator(types.LOAD_SINGLE_SUCCESS, "data"),
    updateDispatcherRequest: makeActionCreator(types.UPDATE_DISPATCHER_REQUEST, "data", "formikBag"),
    updateDispatcherFailure: makeActionCreator(types.UPDATE_DISPATCHER_FAILURE),
    updateDispatcherSuccess: makeActionCreator(types.UPDATE_DISPATCHER_SUCCESS, "data"),
    changeUserStatusPreview: (userList, userNameList, userAction) => ({
        type: types.CHANGE_USER_STATUS_PREVIEW,
        userList,
        userNameList,
        userAction,
    }),
    changeUserStatusConfirmation: (usersToApplyAction, userNameList, userAction, credentials, formikBag) => ({
        type: types.CHANGE_USER_STATUS_CONFIRMATION,
        usersToApplyAction,
        userNameList,
        userAction,
        credentials,
        formikBag,
    }),
};

export const selectors = {
    getUsers: ({ administrationUsers }) => administrationUsers.list,
    getUsersInfo: ({ administrationUsers }) => administrationUsers.usersInfoById,
    getUserInfo: ({ administrationUsers }, user) => (user ? administrationUsers.usersInfoById[user.idUser] : null),
    isFetching: ({ administrationUsers }) => administrationUsers.fetching,
    isFetchingMoreUsers: ({ administrationUsers }) => administrationUsers.fetchingMoreUsers,
    getCurrentPage: ({ administrationUsers }) => administrationUsers.currentPage,
    getTotalPages: ({ administrationUsers }) => administrationUsers.totalPages,
    getHasMoreData: ({ administrationUsers }) => administrationUsers.hasMoreData,
    isFetchingExport: ({ administrationUsers }) => administrationUsers.fetchingExport,
    getUserAction: ({ administrationUsers }) => administrationUsers.userAction,
    getUsersToApplyAction: ({ administrationUsers }) => administrationUsers.usersToApplyAction,
    getUsersNamesToApplyAction: ({ administrationUsers }) => administrationUsers.usersNamesToApplyAction,
    getIdTransaction: ({ administrationUsers }) => administrationUsers.idTransaction,
    getIdActivity: ({ administrationUsers }) => administrationUsers.idActivity,
    getCredentialGroups: ({ administrationUsers }) => administrationUsers.credentialGroups,
};
