import React, { Component } from "react";
import { func, string, shape, arrayOf, objectOf } from "prop-types";
import { connect } from "react-redux";

import { permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";

import ProductLabelIcon from "pages/_components/ProductLabelIcon";
import Accordion from "pages/_components/Accordion";
import PermissionsAmount from "pages/administration/_components/PermissionsAmount";

class PermissionsList extends Component {
    static propTypes = {
        permissions: objectOf(arrayOf(string)),
        products: arrayOf(
            shape({
                idProduct: string,
                number: string,
                productAlias: string,
                label: string,
            }),
        ).isRequired,
        groups: administrationUtils.groupsPropType.isRequired,
        children: func.isRequired,
    };

    static defaultProps = {
        permissions: {},
    };

    renderProducts = (option) => {
        const { permissions: permissionsFromProps, products: productFromProps } = this.props;

        return (
            <ul className="list">
                {productFromProps.reduce((products, { idProduct, number, productAlias, label }) => {
                    const permissions = permissionsFromProps[option.idItem];

                    if (!permissions.includes(idProduct)) {
                        return products;
                    }

                    return [
                        ...products,
                        <li className="list-item" key={idProduct}>
                            <div className="list-item-inner">
                                <div
                                    style={{
                                        display: "inline-flex",
                                    }}
                                    className="product-label">
                                    <ProductLabelIcon number={number} />
                                    <div className="product-label-text">
                                        <div className="product-label-name">{productAlias || label}</div>
                                        {productAlias && <div className="product-label-info">{label}</div>}
                                    </div>
                                </div>
                            </div>
                        </li>,
                    ];
                }, [])}
            </ul>
        );
    };

    renderItem = (option) => (
        <li className="list-item" key={option.idItem}>
            <div className="list-item-inner">{option.label}</div>
        </li>
    );

    loadOptions = (childrenList) =>
        childrenList.reduce((categoryOptions, option) => {
            if (option.childrenList && option.childrenList.length) {
                const subOptions = this.loadOptions(option.childrenList);

                if (!subOptions.length) {
                    return categoryOptions;
                }

                return [
                    ...categoryOptions,
                    <li className="list-item" key={option.idItem}>
                        <div className="list-item-inner">
                            <span className="navigational-list-subtitle">{option.label}</span>
                        </div>
                        <ul className="list">{subOptions}</ul>
                    </li>,
                ];
            }

            const { permissions: permissionsFromProps } = this.props;

            const permissions = permissionsFromProps[option.idItem] || [];

            if (permissions.length) {
                const [permission] = option.permissionList || [];

                return [
                    ...categoryOptions,
                    permission && permission.simpleAllowProductSelection ? (
                        <li className="list-item" key={option.idItem}>
                            <div className="list-item-inner">
                                <span className="navigational-list-subtitle">{option.label}</span>
                            </div>
                            {this.renderProducts(option)}
                        </li>
                    ) : (
                        this.renderItem(option)
                    ),
                ];
            }

            return categoryOptions;
        }, []);

    render() {
        const { children, groups, permissions } = this.props;

        return (
            <PermissionsAmount permissions={permissions}>
                {(amountsById) => {
                    if (!Object.keys(amountsById).length) {
                        return children();
                    }

                    return children(
                        <Accordion className="list list--permissions">
                            {groups.reduce((mainCategories, option) => {
                                const { childrenList, idItem, label, permissionList = [] } = option;
                                const categoryOptions = this.loadOptions(childrenList);
                                const optionsAmount = amountsById[idItem];
                                const [permission] = permissionList;

                                if ((permission && permission.simpleAllowProductSelection) || categoryOptions.length) {
                                    if (permission && permission.simpleAllowProductSelection && !optionsAmount) {
                                        return mainCategories;
                                    }

                                    return [
                                        ...mainCategories,
                                        <Accordion.Item
                                            key={idItem}
                                            number={mainCategories.length}
                                            item={
                                                <span>
                                                    <span>{label}</span>{" "}
                                                    <span className="list-item-hint">{optionsAmount}</span>
                                                </span>
                                            }>
                                            {permission && permission.simpleAllowProductSelection ? (
                                                this.renderProducts(option)
                                            ) : (
                                                <ul className="list">{categoryOptions}</ul>
                                            )}
                                        </Accordion.Item>,
                                    ];
                                }

                                if (!optionsAmount) {
                                    return mainCategories;
                                }

                                return [...mainCategories, this.renderItem(option)];
                            }, [])}
                        </Accordion>,
                    );
                }}
            </PermissionsAmount>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    products: permissionsSelectors.getProducts(state),
    permissions: ownProps.permissions || permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
});

export default connect(mapStateToProps)(PermissionsList);
