import EnrollmentCreateUser from "pages/enrollment/CreateUser";
import EnrollmentDocument from "pages/enrollment/Document";
import EnrollmentFinish from "pages/enrollment/Finish";
import EnrollmentInvitationCode from "pages/enrollment/InvitationCode";
import EnrollmentStart from "pages/enrollment/Start";
import EnrollmentSummary from "pages/enrollment/Summary";
import EnrollmentValidateUser from "pages/enrollment/ValidateUser";
import Error from "pages/error/Error";
import ServerError from "pages/error/ServerError";
import BackofficeTransaction from "pages/forms/BackofficeTransaction";
import LanguageSelection from "pages/login/LanguageSelection";
import Login from "pages/login/Login";
import LoginStep1 from "pages/login/LoginStep1";
import LoginStep3 from "pages/login/LoginStep3";
import LoginStep4 from "pages/login/LoginStep4";
import WebSiteConstruction from "pages/messagesWeb/WebSiteConstruction";
import OneTouchAccess from "pages/OneTouchAccess/OneTouchAccess";
import RecoveryPassStep1 from "pages/recoveryPassword/RecoveryPassStep1";
import RecoveryPassStep2 from "pages/recoveryPassword/RecoveryPassStep2";
import RecoveryPassStep3 from "pages/recoveryPassword/RecoveryPassStep3";
import RecoveryPassStep4 from "pages/recoveryPassword/RecoveryPassStep4";
import RecoveryUserStep1 from "pages/recoveryUser/RecoveryUserStep1";
import RecoveryUserStep2 from "pages/recoveryUser/RecoveryUserStep2";
import TokenActivationFailed from "pages/Token/TokenActivationFailed";
import TokenActivationHelp from "pages/Token/TokenActivationHelp";
import TokenActivationPending from "pages/Token/TokenActivationPending";
import TokenActivationStep1 from "pages/Token/TokenActivationStep1";
import TokenActivationStep2 from "pages/Token/TokenActivationStep2";
import TokenActivationSuccess from "pages/Token/TokenActivationSuccess";
import { resizableRoute } from "pages/_components/Resizable";
import BackofficeLayout from "pages/_layouts/BackofficeLayout";
import EnrollmentLayout from "pages/_layouts/EnrollmentLayout";
import ExternalLayout from "pages/_layouts/ExternalLayout";
import SimpleLayout from "pages/_layouts/SimpleLayout";
import { bool, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";
import { selectors as sessionSelectors } from "reducers/session";
import PrivateRoutes from "routes/PrivateRoutes";
import { isMobileNativeFunc } from "util/device";
// import UiComponents from "pages/UiComponents";
import TokenActivationAttemps from "pages/Token/TokenActivationAttemps";
import TokenActivationFailedDocument from "pages/Token/TokenActivationFailedDocument";
import InvalidMobileVersionError from "pages/error/mobile/InvalidMobileVersionError";

class Routes extends Component {
    static propTypes = {
        hasActiveSession: bool,
        isMobileNative: bool.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    shouldComponentUpdate(nextProps) {
        const {
            location: { pathname },
            hasActiveSession,
        } = this.props;
        // With oauth, the login step 1 sets an active session before routing to step3
        // Changing the value of active session triggers a componentDidMount on the login
        // componente and logs out the recently created session
        // This avoids that error
        if (pathname.includes("loginStep1") || pathname === "/") {
            if (!hasActiveSession && nextProps.hasActiveSession) {
                return false;
            }
        }
        return true;
    }

    render() {
        const { hasActiveSession } = this.props;
        return (
            <Switch>
                <SimpleLayout exact path="/serverError" component={ServerError} background="menu-background" />
                <SimpleLayout exact path="/error" component={Error} background="menu-background" />
                <SimpleLayout exact path="/websiteunderconstruction" component={WebSiteConstruction} headerMobile />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/"
                    loginBar
                    component={isMobileNativeFunc() ? Login : LoginStep1}
                    {...(isMobileNativeFunc() ? { pageStep0: true } : { pageStep0: false, hideNotification: true })}
                />
                <ExternalLayout
                    exact
                    path="/transfersAccsess"
                    landingPage="transfersAccess"
                    withoutBackgroundMobile
                    component={OneTouchAccess}
                    hasActiveSession={hasActiveSession}
                    redirect={hasActiveSession}
                />
                <ExternalLayout
                    exact
                    path="/paymentsAccess"
                    landingPage="paymentsAccess"
                    withoutBackgroundMobile
                    component={OneTouchAccess}
                    hasActiveSession={hasActiveSession}
                    redirect={hasActiveSession}
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationStep1"
                    landingPage="tokenActivationStep1"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationStep1}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationStep2"
                    landingPage="tokenActivationStep2"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationStep2}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationHelp"
                    landingPage="tokenActivationHelp"
                    withoutBackgroundMobile
                    background="white"
                    component={TokenActivationHelp}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationSuccess"
                    landingPage="tokenActivationSuccess"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationSuccess}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationPending"
                    landingPage="tokenActivationPending"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationPending}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationFailed"
                    landingPage="tokenActivationFailed"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationFailed}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                    componentProps={{
                        showDocumentButton: "1",
                    }}
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationAttemps"
                    landingPage="tokenActivationAttemps"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationAttemps}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationFailedDocument"
                    landingPage="tokenActivationFailedDocument"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationFailedDocument}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />

                <ExternalLayout
                    exact
                    path="/tokenEntrustActivationFailed"
                    landingPage="tokenEntrustActivationFailed"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationFailed}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                    componentProps={{
                        showDocumentButton: "0",
                    }}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/loginStep1"
                    hideNotification
                    loginBar
                    component={LoginStep1}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/loginStep3"
                    component={LoginStep3}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/loginStep4"
                    component={LoginStep4}
                />
                <ExternalLayout
                    exact
                    path="/recoveryPassword/step1"
                    withoutBackgroundMobile
                    component={RecoveryPassStep1}
                />
                <ExternalLayout
                    exact
                    path="/recoveryPassword/step2"
                    withoutBackgroundMobile
                    component={RecoveryPassStep2}
                />
                <ExternalLayout
                    exact
                    path="/recoveryPassword/step3"
                    withoutBackgroundMobile
                    component={RecoveryPassStep3}
                />
                <ExternalLayout
                    exact
                    path="/recoveryPassword/step4"
                    withoutBackgroundMobile
                    component={RecoveryPassStep4}
                />
                <ExternalLayout exact path="/recoverUser/step1" component={RecoveryUserStep1} withoutBackgroundMobile />
                <ExternalLayout exact path="/recoverUser/step2" component={RecoveryUserStep2} withoutBackgroundMobile />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/languageSelection"
                    component={LanguageSelection}
                />
                <EnrollmentLayout exact path="/enrollment" component={EnrollmentStart} showBack />
                <EnrollmentLayout exact path="/enrollment/document" step={1} component={EnrollmentDocument} showBack />
                <EnrollmentLayout
                    exact
                    path="/enrollment/documentConfirmation"
                    step={1}
                    completed={1}
                    component={EnrollmentDocument}
                    showBack
                />
                <EnrollmentLayout
                    exact
                    step={2}
                    completed={1}
                    path="/enrollment/invitationCode/:invitationCode"
                    component={EnrollmentInvitationCode}
                    showBack
                />
                <EnrollmentLayout
                    exact
                    step={2}
                    completed={1}
                    path="/enrollment/invitationCode"
                    component={EnrollmentInvitationCode}
                    showBack
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/createUser"
                    component={EnrollmentCreateUser}
                    showBack
                    step={3}
                    completed={2}
                />
                <EnrollmentLayout exact path="/enrollment/finish" component={EnrollmentFinish} step={3} completed={3} />
                <EnrollmentLayout
                    exact
                    path="/enrollment/validateUser"
                    component={EnrollmentValidateUser}
                    showBack
                    step={3}
                    completed={2}
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/summary"
                    component={EnrollmentSummary}
                    showBack
                    step={4}
                    completed={3}
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/finishAssociate"
                    component={EnrollmentFinish}
                    step={4}
                    completed={4}
                />
                {/* <SimpleLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/ui-components"
                    hideNotification
                    loginBar
                    component={UiComponents}
                /> */}
                <BackofficeLayout exact path="/forms/backoffice/ticket" component={BackofficeTransaction} />
                <SimpleLayout
                    exact
                    path="/invalidMobileVersion"
                    component={InvalidMobileVersionError}
                    background="menu-background"
                />

                <PrivateRoutes />
            </Switch>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Routes)));
