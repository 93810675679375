import classNames from "classnames";
import { REGION_PAN, REGION_VE, USD_CURRENCY } from "constants.js";
import { Field } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FormTransition from "pages/forms/_components/FormTransition";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { PDFAmountField, PDFAmountFieldError, PDFTextField } from "pages/forms/customForms/PDFTicket";
import CardDeliveryAddress from "pages/forms/customForms/_customFields/CardDeliveryAddress";
import { useMetaData } from "pages/forms/customForms/hooks/TransferInternalHooks";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import * as configUtils from "util/config";
import { CREDIT_CARD_TYPE_BAND } from "util/creditCards";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import * as Yup from "yup";
import { useCostInfo, useSelectorOptions } from "./hooks/ReportCreditCardHooks";

const ListItem = ({ title, cost, isDesktop }) => (
    <Box component="li" className="list-styled">
        <Text color="heading-color" size={isDesktop ? "7" : "6"}>
            {title}
            {cost}
        </Text>
    </Box>
);
ListItem.propTypes = {
    isDesktop: bool.isRequired,
    title: string,
    cost: string,
};
ListItem.defaultProps = {
    title: null,
    cost: null,
};
const InfoData = ({ isDesktop, data }) => (
    <Box display="flex" alignX="between" gap="3" fullWidth wrap>
        {data.map((list) => (
            <Box component="ul" key={list.id}>
                <Text bold color="heading-color" size={isDesktop ? "7" : "6"}>
                    {list.title}
                </Text>
                {list.items.map((item) => (
                    <ListItem key={item.id} {...item} isDesktop={isDesktop} />
                ))}
            </Box>
        ))}
    </Box>
);
InfoData.propTypes = {
    isDesktop: bool.isRequired,
    data: shape({}).isRequired,
};
const ReportReplaceCard = (props) => {
    const {
        mode,
        match,
        currentLang,
        preDataForm,
        dispatch,
        fromBackoffice,
        isDesktop,
        creditCardDetail,
        fetching,
        location,
        data,
        transaction,
    } = props;

    const isReportCardLocation = location.pathname.includes("/formCustom/reportReplaceCard/");
    const isReportCard = data?.isReportCard || isReportCardLocation;

    const ID_FORM = `report.${isReportCard ? "replace" : "renew"}.card`;
    const ID_ACTIVITY = `${ID_FORM}.send`;
    const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

    const formTitle = `forms.report.${isReportCard ? "replace" : "renew"}.card.title`;

    const estimatedMinimumTime = configUtils.getInteger("creditCard.delivery.estimated.minimum.time");
    const estimatedMaximumTime = configUtils.getInteger("creditCard.delivery.estimated.maximum.time");

    const costPanama = parseFloat(configUtils.get("creditCard.delivery.Panama.cost"));
    const costVenezuela = parseFloat(configUtils.get("creditCard.delivery.Vanezuela.cost"));
    const costRestCountry = parseFloat(configUtils.get("creditCard.delivery.restWorld.cost"));

    const costHolder = parseFloat(configUtils.get("creditCard.type.holder.cost"));
    const costMetalic = parseFloat(configUtils.get("creditCard.type.metallic.cost"));
    const costPedidosYa = parseFloat(configUtils.get("creditCard.type.pedidosya.cost"));
    const costStripe = parseFloat(configUtils.get("creditCard.type.stripe.cost"));

    const itmbsPercentage = configUtils.get("value.percentage.ITBMS") / 100;

    const [reasonList, countryList, provinceList, districtList, jurisdictionList] = useSelectorOptions(
        preDataForm,
        isReportCard,
    );

    const [costInfo] = useCostInfo(isReportCard);
    const [costByTypeCard, setCostByTypeCard] = useState(0);
    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);

    useEffect(() => {
        if (mode === "edit") {
            const productId = match.params.id;
            if (!creditCardDetail) {
                dispatch(creditCardActions.detailRequest(productId));
            } else {
                const shouldShow = isReportCard
                    ? creditCardDetail?.showReplaceOption
                    : creditCardDetail?.showRenewOption;
                if (!shouldShow) {
                    dispatch(push("/desktop"));
                }
            }
            if (creditCardDetail?.isMetalic) {
                setCostByTypeCard(costMetalic);
            } else if (creditCardDetail?.isPedidosYa) {
                setCostByTypeCard(costPedidosYa);
            } else if (creditCardDetail?.artifactType === CREDIT_CARD_TYPE_BAND) {
                setCostByTypeCard(costStripe);
            } else if (creditCardDetail) {
                setCostByTypeCard(costHolder);
            }
        }
    }, [creditCardDetail, mode]);
    useEffect(() => {
        if (mode === "edit" || mode === "view") {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData: {} }));
        }
    }, [mode]);
    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: transaction?.idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const processAddress = (address) => {
        const addressParts = [];
        addressParts.push(address?.street);
        addressParts.push(address?.residential);
        addressParts.push(address?.apartment);
        addressParts.push(address?.referencePoint);
        addressParts.push(address?.country?.label);
        addressParts.push(address?.province?.label || address?.province);
        addressParts.push(address?.city);
        addressParts.push(address?.district?.label);
        addressParts.push(address?.jurisdiction?.label);
        addressParts.push(address?.districjurisdictiont?.label);

        addressParts.push(address?.postalCode);

        return addressParts.filter(Boolean).join(", ");
    };

    const fieldRequired = i18n.get("fields.defaultForm.defaultField.requiredError");

    const validationSchema = () =>
        Yup.object().shape({
            reason: Yup.array().test(
                "valid-reason",
                fieldRequired,
                (value) => value.length === 1 && reasonList.some((reasonObj) => reasonObj.id === value[0].id),
            ),
            address: Yup.object().shape({
                country: Yup.object().required(fieldRequired),
                province: Yup.mixed().when("country.id", {
                    is: "PA",
                    then: Yup.object().required(fieldRequired),
                    otherwise: Yup.string().required(fieldRequired),
                }),
                district: Yup.mixed().when("country.id", {
                    is: "PA",
                    then: Yup.object().required(fieldRequired),
                }),
                jurisdiction: Yup.mixed().when("country.id", {
                    is: "PA",
                    then: Yup.object().required(fieldRequired),
                }),
                city: Yup.mixed().when("country.id", {
                    is: "PA",
                    otherwise: Yup.string().required(fieldRequired),
                }),
                street: Yup.string().required(fieldRequired),
                residential: Yup.string().required(fieldRequired),
                apartment: Yup.string().required(fieldRequired),
                referencePoint: Yup.mixed().when("country.id", {
                    is: (id) => id === "PA" || id === "VE",
                    then: Yup.string().required(fieldRequired),
                }),
                postalCode: Yup.mixed().when("country.id", {
                    is: (id) => id === "PA" || id === "VE",
                    otherwise: Yup.string().required(fieldRequired),
                }),
            }),
        });

    const renderTicket = (values) => {
        const ticketFinished = transaction?.idTransactionStatus === "FINISHED";
        return (
            <>
                {ticketFinished ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        value={`${values?.creditCardDetail?.numberMask} ${values?.creditCardDetail?.franchise}`}
                        label={i18n.get(
                            `forms.report.${values?.isReportCard ? "replace" : "renew"}.card${
                                values?.isStripe ? "Stripe" : ""
                            }.header`,
                        )}
                    />
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        value={`${values?.creditCardDetail?.numberMask} ${values?.creditCardDetail?.franchise}`}
                        label={i18n.get(
                            `forms.report.${values?.isReportCard ? "replace" : "renew"}.card${
                                values?.isStripe ? "Stripe" : ""
                            }.header`,
                        )}
                    />
                )}
                <PDFTextField
                    idForm={ID_FORM}
                    value={values?.scheduler?.valueDate?.format("DD/MM/YYYY")}
                    label={i18n.get("forms.report.card.date.label")}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={i18n.get(formTitle)}
                    label={i18n.get("transactions.ticket.typeOperation.label")}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        values?.reason
                            ? reasonList?.find((reason) => reason?.id === values?.reason[0]?.id)?.label ||
                              values?.reason[0]?.label
                            : ""
                    }
                    label={i18n.get("forms.report.replace.card.reason.label")}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    label={i18n.get("forms.report.card.amount.label")}
                    value={`${values?.amount?.currency} ${values?.amount?.quantity}`}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    label={i18n.get("forms.report.replace.card.address.title")}
                    value={processAddress(values?.address)}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    label={i18n.get("forms.report.card.deliveryTime.label")}
                    value={i18n.get("forms.report.card.deliveryTime.info", null, {
                        MIN: values?.deliveryTime?.estimatedMinimumTime || 0,
                        max: values?.deliveryTime?.estimatedMaximumTime || 0,
                    })}
                />
            </>
        );
    };
    const cardDeliveryAdress = () => (
        <Field
            {...genericProps}
            isLocalForm
            component={CardDeliveryAddress}
            key="address"
            name="address"
            idField="address"
            countryList={countryList}
            provinceList={provinceList}
            districtList={districtList}
            jurisdictionList={jurisdictionList}
            mode={mode}
        />
    );
    const renderFields = (setFieldValue, values) => {
        if (mode === "edit" && creditCardDetail) {
            if (!values?.productId) {
                setFieldValue("productId", creditCardDetail?.idProduct);
                const newCreditCardDetail = {
                    productId: creditCardDetail?.idProduct,
                    numberMask: creditCardDetail?.numberMask,
                    franchise: creditCardDetail?.franchise,
                };
                setFieldValue("creditCardDetail", newCreditCardDetail);
                setFieldValue("isReportCard", isReportCard);
                setFieldValue("isStripe", creditCardDetail?.artifactType === CREDIT_CARD_TYPE_BAND);
            }

            if (!values?.reason) {
                setFieldValue("reason", !isReportCard ? reasonList : []);
            }
            if (!values?.scheduler) {
                setFieldValue("scheduler", {
                    selectedOption: schedulerUtils.TODAY,
                    valueDate: moment(new Date(), "YYYY-MM-DD"),
                });
            }

            if (!values?.deliveryTime) {
                setFieldValue("deliveryTime", {
                    estimatedMinimumTime,
                    estimatedMaximumTime,
                });
            }

            let costByDestination = 0;
            costByDestination = values?.address?.country?.id ? costRestCountry : costByDestination;
            costByDestination = values?.address?.country?.id === REGION_PAN ? costPanama : costByDestination;
            costByDestination = values?.address?.country?.id === REGION_VE ? costVenezuela : costByDestination;

            const totalAmount = (
                (parseFloat(costByDestination) + (isReportCard ? parseFloat(costByTypeCard) : 0)) *
                (1 + itmbsPercentage)
            ).toFixed(2);

            const newAmount = { quantity: totalAmount, currency: USD_CURRENCY };

            if (totalAmount !== values?.amount?.quantity && values?.address?.country) {
                setFieldValue("amount", newAmount);
            }
        }

        return (
            <>
                {mode === "edit" && (
                    <>
                        <Row gapY="5" {...(!isDesktop && { className: "pb-3" })}>
                            <Col xs={12} md={6}>
                                <Box
                                    display="flex"
                                    background="component-background"
                                    borderRadius="default"
                                    className={classNames("px-5 px-md-9 mt-5 mt-md-0 py-8 mb-3 mb-md-5 mx-n-5", {
                                        "py-7": !isReportCard && isDesktop,
                                    })}>
                                    <Text
                                        component="label"
                                        addColon
                                        bold
                                        size="5"
                                        color="heading-color"
                                        className="m-0"
                                        labelKey="creditCard.label"
                                    />

                                    <Text uppercase>
                                        {values?.creditCardDetail?.numberMask} {values?.creditCardDetail?.franchise}
                                    </Text>
                                </Box>
                                <Box
                                    display="flex"
                                    background="component-background"
                                    borderRadius="default"
                                    className={classNames("px-5 px-md-9 pt-7 pb-9 mb-3 mb-md-5 mx-n-5", {
                                        "py-7 py-md-9": !isReportCard && isDesktop,
                                    })}>
                                    {isReportCard ? (
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.Selector}
                                            key="reason"
                                            name="reason"
                                            idField="reason"
                                            customPlaceholder={i18n.get("forms.placeholder.select")}
                                            optionList={reasonList}
                                            renderAs="combo"
                                        />
                                    ) : (
                                        <Box display="flex">
                                            <Text
                                                component="label"
                                                color="heading-color"
                                                size="5"
                                                bold
                                                addColon
                                                className="m-0"
                                                labelKey="forms.report.replace.card.reason.label"
                                            />

                                            <Text
                                                component="p"
                                                color="heading-color"
                                                size="5"
                                                uppercase
                                                className="m-0">
                                                {reasonList && reasonList[0].label}
                                            </Text>
                                        </Box>
                                    )}
                                </Box>
                                {!isDesktop && <Box className="mb-3">{cardDeliveryAdress()}</Box>}
                                <Box
                                    display="flex"
                                    background="secondary-background-color"
                                    borderRadius="default"
                                    className={classNames("px-5 px-md-7 py-7 mb-3 mb-md-5", {
                                        "py-9": !isReportCard && isDesktop,
                                        "py-7": !isReportCard && !isDesktop,
                                    })}>
                                    <InfoData isDesktop={isDesktop} data={costInfo} />
                                </Box>
                                <Box
                                    display="flex"
                                    alignX="between"
                                    alignY="center"
                                    background="heading-color"
                                    borderRadius="default"
                                    className={classNames("px-5 px-md-9 py-5 py-md-7", {
                                        "py-9": !isReportCard && isDesktop,
                                        "py-5": !isReportCard && !isDesktop,
                                    })}>
                                    <Text
                                        component="label"
                                        color="inverse"
                                        className="m-0"
                                        labelKey="forms.report.card.amount.label"
                                    />
                                    <FormattedAmount
                                        size="big"
                                        bold
                                        color="inverse"
                                        currency={values?.amount?.currency || USD_CURRENCY}
                                        quantity={values?.amount?.quantity || 0}
                                    />
                                </Box>
                            </Col>
                            {isDesktop && (
                                <Col xs={12} md={6}>
                                    {cardDeliveryAdress()}
                                </Col>
                            )}
                        </Row>
                    </>
                )}
                {(mode === "preview" || mode === "view") && (
                    <>
                        <Box
                            display="flex"
                            column
                            alignY="center"
                            fullWidth
                            className="mb-9 pt-3 pb-4"
                            {...(mode === "view" &&
                                transaction?.idTransactionStatus === "FINISHED" && {
                                    background: "primary",
                                })}>
                            <Text
                                component="label"
                                size="5"
                                color={
                                    mode === "view" && transaction?.idTransactionStatus === "FINISHED"
                                        ? "inverse-color"
                                        : "heading-color"
                                }
                                labelKey={`forms.report.${values?.isReportCard ? "replace" : "renew"}.card${
                                    values?.isStripe ? "Stripe" : ""
                                }.header`}
                            />
                            <Text
                                component="label"
                                size={mode === "view" ? "1" : "big"}
                                color={
                                    mode === "view" && transaction?.idTransactionStatus === "FINISHED"
                                        ? "inverse-color"
                                        : "heading-color"
                                }
                                bold>
                                {values?.creditCardDetail?.numberMask} {values?.creditCardDetail?.franchise}
                            </Text>
                        </Box>

                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.scheduler?.valueDate?.format("DD/MM/YYYY")}
                                label="forms.report.card.date.label"
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={i18n.get(formTitle)}
                                label="transactions.ticket.typeOperation.label"
                                shouldRender={mode === "view"}
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                marginStyle={mode === "view"}
                                label="forms.report.replace.card.reason.label"
                                value={
                                    values?.reason
                                        ? reasonList?.find((reason) => reason?.id === values?.reason[0]?.id)?.label ||
                                          values?.reason[0]?.label
                                        : ""
                                }
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                type="amount_type"
                                marginStyle={mode === "view"}
                                amount={{
                                    currency: values?.amount?.currency,
                                    quantity: values?.amount?.quantity,
                                }}
                                label="forms.report.card.amount.label"
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                marginStyle={mode === "view"}
                                label="forms.report.replace.card.address.title"
                                value={processAddress(values?.address)}
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                marginStyle={mode === "view"}
                                shouldRender={mode === "view"}
                                label="forms.report.card.deliveryTime.label"
                                value={i18n.get("forms.report.card.deliveryTime.info", null, {
                                    MIN: values?.deliveryTime?.estimatedMinimumTime || 0,
                                    max: values?.deliveryTime?.estimatedMaximumTime || 0,
                                })}
                            />
                        </Box>
                    </>
                )}
            </>
        );
    };
    const formProps = {
        title: formTitle,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        showSchedulerMessage: false,
        validationSchema,
        onlyBackToDesktopBtn: true,
        fetching: fetching || (!creditCardDetail && mode === "edit"),
    };
    return <FormTransition {...props} {...formProps} />;
};
const mapStateToProps = (state, ownProps) => ({
    preDataForm: formSelectors.getPreData(state),
    fetching: formSelectors.getFetching(state),
    creditCardDetail: creditCardSelectors.getDetailInList(state, ownProps?.match?.params?.id),
    data: formSelectors.getData(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    previewData: formSelectors.getPreviewData(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});
ReportReplaceCard.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    history: shape({}).isRequired,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    fromBackoffice: bool,
    expired: bool,
    willExpire: bool,
    creditCardDetail: shape({}).isRequired,
    match: shape({}).isRequired,
    location: shape({}).isRequired,
    fetching: bool.isRequired,
    data: shape({}),
    transaction: shape({}),
};
ReportReplaceCard.defaultProps = {
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    expired: false,
    willExpire: true,
    data: {},
    transaction: {},
};
export default connect(mapStateToProps)(resizableRoute(ReportReplaceCard));
