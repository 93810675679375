import classNames from "classnames";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import { arrayOf, bool, func, node, number, oneOfType, string } from "prop-types";
import React, { Component } from "react";

class Checkbox extends Component {
    static propTypes = {
        block: bool,
        checked: bool,
        error: string,
        formGroup: bool,
        hideLabel: bool,
        idForm: string,
        label: oneOfType([string, node]),
        labelColor: string,
        labelSize: string,
        name: oneOfType([number, string]),
        noMarginBottom: bool,
        onBlur: func,
        onChange: func,
        readOnly: bool,
        topAlign: bool,
        value: oneOfType([bool, string, arrayOf(string)]),
        enableControlInput: bool,
    };

    static defaultProps = {
        block: true,
        checked: false,
        error: "",
        formGroup: true,
        hideLabel: false,
        idForm: "",
        label: "",
        labelColor: null,
        labelSize: null,
        name: "",
        noMarginBottom: false,
        onBlur: null,
        onChange: null,
        readOnly: false,
        topAlign: false,
        value: "",
        enableControlInput: false,
    };

    render() {
        const {
            block,
            error,
            formGroup,
            hideLabel,
            idForm,
            label,
            labelColor,
            labelSize,
            noMarginBottom,
            readOnly,
            topAlign,
            enableControlInput = false,
            ...props
        } = this.props;

        return (
            <div
                className={classNames("c-control c-control--has-icon c-control--checkbox", {
                    "has-error": error,
                    "c-control-block": block,
                    "form-group": formGroup,
                    "form-group-margin-bottom": !noMarginBottom,
                })}>
                <input
                    id={props.name}
                    tabIndex={props.tabIndex}
                    className={classNames("c-control-input", {
                        "control-input-top-align": topAlign,
                        "c-control-mark-bottom": enableControlInput,
                    })}
                    type="checkbox"
                    {...props}
                    readOnly={readOnly}
                />

                <label
                    className={classNames("c-control-label", { "control-label-top-align": topAlign })}
                    htmlFor={props.name}>
                    <div className="c-control-icons">
                        <div className={classNames("c-control-mark", { "c-control-mark-bottom": enableControlInput })}>
                            <Image src="images/icons/checkBox.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>

                    {!hideLabel && !labelSize && (
                        <div className="form-group-text">{label || <I18n id={`${idForm}.${props.name}.label`} />}</div>
                    )}
                    {!hideLabel && labelSize && label && (
                        <Text size={labelSize} color={labelColor}>
                            {label}
                        </Text>
                    )}
                </label>
                {error && <FieldError error={error} />}
            </div>
        );
    }
}

export default Checkbox;
