import React, { Component } from "react";
import { Field, Form, withFormik } from "formik";
import { connect } from "react-redux";
import { compose } from "redux";
import { func, bool } from "prop-types";
import * as Yup from "yup";

import * as i18n from "util/i18n";
import * as config from "util/config";
import { getPasswordHelp } from "pages/settings/ChangePassword";
import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import Text from "pages/_components/Text";

const FORM_ID = "recoveryPassword.step4";

class Step4Content extends Component {
    render() {
        const { isMobile, isSubmitting } = this.props;
        const maxLength = config.getInteger("core.password.maxLength");

        return (
            <Form>
                <Box display="flex" column fullWidth {...(isMobile ? { fullHeight: true } : { fullHeight: false })}>
                    <Box display="flex" fullWidth className="pb-7">
                        <Text
                            labelKey="recoveryPassword.step4.title"
                            align="center"
                            size="5"
                            component="p"
                            className="mt-0 mb-5"
                            color="text"
                        />
                    </Box>
                    <Box display="flex" column className="my-auto pb-7">
                        <Field
                            name="newPassword"
                            idForm={FORM_ID}
                            component={Credential}
                            maxLength={maxLength}
                            placeholder=""
                            tooltip={getPasswordHelp()}
                            className="mb-7"
                            tooltipPosition="bottom-right"
                        />

                        <Field
                            name="newPasswordRepeat"
                            idForm={FORM_ID}
                            component={Credential}
                            maxLength={maxLength}
                            placeholder=""
                        />
                    </Box>
                    <Box display="flex" column className="mb-7 mb-md-0 mt-auto">
                        <Button
                            label="recoveryPassword.step4.confirm"
                            type="submit"
                            bsStyle="primary"
                            loading={isSubmitting}
                            block
                        />
                    </Box>
                </Box>
            </Form>
        );
    }
}

Step4Content.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    isSubmitting: bool.isRequired,
};

export default compose(
    connect(),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ newPassword: "", newPasswordRepeat: "" }),
        validationSchema: () =>
            Yup.object().shape({
                newPassword: Yup.string().required(i18n.get(`${FORM_ID}.newPassword.required`)),
                newPasswordRepeat: Yup.string().required(i18n.get(`${FORM_ID}.newPasswordRepeat.required`)),
            }),

        handleSubmit: (values, formikBag) => {
            const {
                props: { exchangeToken },
            } = formikBag;

            const { newPassword, newPasswordRepeat } = values;

            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryPassStep4(newPassword, newPasswordRepeat, exchangeToken, formikBag),
            );
        },
    }),
)(Step4Content);
