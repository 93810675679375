import { call, put, takeLatest, select } from "redux-saga/effects";
import { replace } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";

import formTypes from "reducers/types/form";
import { types, actions } from "reducers/administration/common/administrationTicket";
import { types as userTypes } from "reducers/administration/users";
import { types as userInviteTypes } from "reducers/administration/usersInvite";
import { types as groupTypes } from "reducers/administration/groups";
import { actions as notificationActions } from "reducers/notification";
import {
    channelsActions as mediumChannelsActions,
    permissionsActions,
    detailsActions as mediumDetailsActions,
    signaturesSchemesActions as mediumSignatureSchemeActions,
} from "reducers/administration/medium";
import {
    detailsActions as advancedDetailsActions,
    channelsActions as advancedChannelsActions,
    groupsActions as groupsOfUserActions,
    signaturesSchemesActions as advancedSignatureSchemeActions,
} from "reducers/administration/advanced";
import { actions as groupFormDataActions } from "reducers/administration/common/groupFormData";
import { actions as restrictionActions } from "reducers/administration/restrictions";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import * as form from "middleware/form";

const sagas = [
    takeLatest(types.LOAD_ADMINISTRATION_TICKET_REQUEST, readAdministrationTransaction),
    takeLatest(formTypes.SIGN_TRANSACTION_PREVIEW_SUCCESS, signAdministrativeTransaction),
];

export default sagas;

const statusToAction = { blocked: "block", active: "unblock" };

function* readAdministrationTransaction({ idTransaction }) {
    const transactionResponse = yield call(form.readTransaction, idTransaction);

    if (transactionResponse.type === "W") {
        yield put(actions.loadPermissionsTicketFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        yield put(actions.loadAdministrationTicketSuccess(transactionResponse.data.data.transaction));
    }
}

function* signAdministrativeTransaction(params) {
    if (!params.idForm) {
        const {
            ticketData,
            submitActionParams: { idActivity, idTransaction },
        } = params;
        const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
        const responseCredentials = yield call(form.listCredentialsGroups, null, idActivity);
        const credentialGroups = responseCredentials.data.data.groups;
        const { permissions, signatureLevel, groupIdList, groupNameList } = ticketData;
        let data = {};
        switch (idActivity) {
            case "administration.users.blockunblock.send":
            case "administration.users.delete.send":
                {
                    const { userIdList, userNameList } = ticketData;
                    const userAction = statusToAction[ticketData.newStatus] || "delete";

                    yield put({
                        type: userTypes.CHANGE_USER_STATUS_PREVIEW_SUCCESS,
                        userList: userIdList,
                        userNameList,
                        userAction,
                        idTransaction,
                        idActivity,
                        credentialGroups,
                    });
                    yield put(replace(`/administration/confirmUserAction`));
                }
                break;
            case "administration.groups.blockunblock.send":
            case "administration.groups.delete.send":
                {
                    let groupAction = "delete";

                    if (ticketData.blocked !== undefined) {
                        groupAction = ticketData.blocked ? "block" : "unblock";
                    }
                    yield put({
                        type: groupTypes.CHANGE_GROUP_STATUS_PREVIEW_SUCCESS,
                        groupList: groupIdList,
                        groupNameList,
                        groupAction,
                        idTransaction,
                        idActivity,
                        credentialGroups,
                    });
                    yield put(replace(`/administration/confirmGroupAction`));
                }
                break;
            case "administration.users.invite.send":
                yield put({
                    type: userInviteTypes.ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS,
                    params: ticketData,
                    credentialGroups,
                    idTransaction,
                    idActivity,
                });
                yield put(replace("/administration/users/inviteStep3"));
                break;
            case "administration.medium.modify.channels.send":
                {
                    const caps = params.ticketData.enabledChannels.reduce((result, item, index) => {
                        const key = item === "all" ? "topAmount" : item;
                        // eslint-disable-next-line no-param-reassign
                        result[key] = {
                            amount: params.ticketData.maxAmounts[index],
                            frequency: params.ticketData.capFrequencies[index],
                        };
                        return result;
                    }, {});

                    if (administrationScheme === "medium") {
                        yield put(
                            mediumChannelsActions.updateChannelsPreviewSuccess(
                                caps,
                                credentialGroups,
                                idTransaction,
                                idActivity,
                            ),
                        );
                    } else {
                        yield put(
                            advancedChannelsActions.updateChannelsPreviewSuccess(
                                caps,
                                credentialGroups,
                                idTransaction,
                                idActivity,
                            ),
                        );
                    }
                    yield put(
                        replace(`/administration/${administrationScheme}/channels/${params.ticketData.idUser}/confirm`),
                    );
                }
                break;
            case "administration.medium.modify.permissions.send":
                yield put(replace(`/administration/medium/permissions/${params.ticketData.idUser}/confirm`));
                yield put(
                    permissionsActions.updatePermissionsPreviewSuccess(
                        permissions,
                        credentialGroups,
                        idTransaction,
                        idActivity,
                    ),
                );

                break;
            case "administration.medium.modify.signature.send":
                if (administrationScheme === "medium") {
                    yield put(
                        mediumDetailsActions.loadDataForSignUpdateSignature({
                            signatureLevel: signatureLevel === "A" ? null : signatureLevel,
                            credentialGroups,
                            idActivity,
                            idTransaction,
                        }),
                    );
                } else {
                    yield put(
                        advancedDetailsActions.loadDataForSignUpdateSignature({
                            signatureLevel,
                            credentialGroups,
                            idActivity,
                            idTransaction,
                        }),
                    );
                }
                yield put(
                    replace(`/administration/${administrationScheme}/signature/${params.ticketData.idUser}/confirm`),
                );
                break;
            case "administration.user.detail.groups.modify.send":
                yield put(replace(`/administration/advanced/groupsOfUser/${params.ticketData.idUser}/confirm`));
                yield put(
                    groupsOfUserActions.updateGroupsOfUserPreviewSuccess(
                        params.ticketData.groups.map(String),
                        credentialGroups,
                        idTransaction,
                        idActivity,
                    ),
                );

                break;

            case "administration.advanced.group.modify.send":
            case "administration.advanced.group.create.send":
                if (ticketData.id) {
                    yield put(routerActions.push(`/administration/advanced/group/${ticketData.id}/confirm/step1`));
                } else {
                    yield put(routerActions.push("/administration/advanced/group/create/confirm/step1"));
                    yield put(groupFormDataActions.submitCreateGroupFormPreviewSuccess(ticketData));
                }
                yield put(
                    groupFormDataActions.submitGroupFormPreviewSuccess(credentialGroups, idTransaction, idActivity),
                );
                break;
            case "administration.signatures.create.send":
                data = {
                    functionalGroups: ticketData.functionalGroups,
                    signatureLevelsCounts: ticketData.signatureLevelsCounts,
                    signatureType: ticketData.signatureType,
                    topAmount: { amount: ticketData.maxAmount, period: ticketData.capFrequencies[0] },
                };
                if (administrationScheme === "medium") {
                    yield put(
                        mediumSignatureSchemeActions.createSignaturesSchemeConfirmPre(
                            data,
                            credentialGroups,
                            idTransaction,
                            idActivity,
                        ),
                    );
                } else {
                    yield put(
                        advancedSignatureSchemeActions.createSignaturesSchemeConfirmPre(
                            data,
                            credentialGroups,
                            idTransaction,
                            idActivity,
                        ),
                    );
                }
                yield put(replace(`/administration/${administrationScheme}/signaturesSchemes/create/confirm`));
                break;

            case "administration.signatures.modify.send":
                data = {
                    signatureId: ticketData.signatureId,
                    functionalGroups: ticketData.functionalGroups,
                    signatureLevelsCounts: ticketData.signatureLevelsCounts,
                    signatureType: ticketData.signatureType,
                    topAmount: { amount: ticketData.maxAmount, period: ticketData.capFrequencies[0] },
                };
                if (administrationScheme === "medium") {
                    yield put(
                        mediumSignatureSchemeActions.modifySignaturesSchemeConfirmPre(
                            data,
                            credentialGroups,
                            idTransaction,
                            idActivity,
                        ),
                    );
                } else {
                    yield put(
                        advancedSignatureSchemeActions.modifySignaturesSchemeConfirmPre(
                            data,
                            credentialGroups,
                            idTransaction,
                            idActivity,
                        ),
                    );
                }
                yield put(
                    replace(
                        `/administration/${administrationScheme}/signaturesSchemes/${ticketData.signatureId}/confirm`,
                    ),
                );

                break;

            case "administration.signatures.delete.send":
                if (administrationScheme === "medium") {
                    yield put(mediumSignatureSchemeActions.deleteSignaturesSchemeConfirmPre(idTransaction, idActivity));
                } else {
                    yield put(
                        advancedSignatureSchemeActions.deleteSignaturesSchemeConfirmPre(idTransaction, idActivity),
                    );
                }
                yield put(
                    replace(
                        `/administration/${administrationScheme}/signaturesSchemes/remove/${ticketData.signatureId}`,
                    ),
                );

                break;

            case "administration.restrictions.manage.send":
                yield put(restrictionActions.manageRestrictionsConfirmPre(ticketData, null, idTransaction, idActivity));

                yield put(replace(`/administration/restrictions/manage/confirmation`));
                break;
            case "administration.restrictions.user.delete.send":
                yield put(restrictionActions.restrictionDeleteRequest(idTransaction, idActivity));

                yield put(replace(`/administration/restrictions/delete/:sign`));
                break;
            default:
                break;
        }
    }
}
