import CreditCardsListItem from "pages/creditCards/ListItem";
import WidgetList from "pages/desktop/widgets/WidgetList";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { node, shape, func, string } from "prop-types";
import React, { Component } from "react";
import * as i18nUtils from "util/i18n";
import { actions as productRequestActions } from "reducers/productRequest";
// import * as config from "util/config";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import * as config from "util/config";
import WidgetHeader from "./_components/WidgetHeader";

class CreditCards extends Component {
    static propTypes = {
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        dispatch: func.isRequired,
        loggedUser: shape({
            bankType: string,
        }),
    };

    static defaultProps = {
        closeButton: null,
        loggedUser: null,
    };

    render() {
        const uiAutomationProp = { "data-ui-automation": "widgetCreditCards" };

        const { closeButton, draggableItemProps, dispatch, loggedUser } = this.props;
        const { bankType } = loggedUser;
        const requestCard = config.get(`card.requestCard.byBankType.${bankType}`);

        return (
            <WidgetList slider item={CreditCardsListItem} keyExtractor={(item) => item.idProduct} name="creditCards">
                {(list) => (
                    <section
                        className="widget"
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        <WidgetHeader title={i18nUtils.get("list.addWidget.creditCards")} action={closeButton} />
                        {list}
                        <div className="overlay" />
                        {requestCard && (
                            <Box display="flex" alignX="between" fullWidth flex1 className="px-md-3">
                                <Button
                                    image="/images/plusCircle.svg"
                                    label="widgets.creditCards.requestCreditCard"
                                    bsStyle="link"
                                    className="btn-widget"
                                    onClick={() => {
                                        dispatch(productRequestActions.modalShow("TC"));
                                    }}
                                />
                            </Box>
                        )}
                    </section>
                )}
            </WidgetList>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(CreditCards);
