import { call, put, takeLatest, select } from "redux-saga/effects";
import { replace } from "react-router-redux";

import { NO_TRANSITION } from "constants.js";
import * as depositsMiddleware from "middleware/deposits";
import { types, selectors } from "reducers/deposits";
import { actions as notificationActions } from "reducers/notification";
import { downloadCSVFile, downloadMobileFile, downloadPdf, downloadXls } from "util/download";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";
import { unifyListParams } from "util/deposit";
import { isMobileNativeFunc } from "util/device";
import b64toBlob from "b64-to-blob";
import { actions as desktopActions } from "reducers/desktop";

const DEFAULT_FILENAME_DEPOSIT_CERTIFICATE = "fixed_term_deposit_certificate.pdf";
const sagas = [
    takeLatest(types.DEPOSIT_READ_REQUEST, depositRead),
    takeLatest(types.DEPOSIT_DETAILS_REQUEST, depositDetails),
    takeLatest(types.DEPOSIT_DOWNLOAD_STATEMENT_REQUEST, downloadStatement),
    takeLatest(types.DEPOSIT_FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovements),
    takeLatest(types.DEPOSIT_MOVEMENT_DETAILS_REQUEST, movementDetails),
    takeLatest(types.EDIT_MOVEMENT_NOTE_REQUEST, editMovementNote),
    takeLatest(types.LIST_DEPOSIT_STATEMENTS_REQUEST, listStatements),
    takeLatest(types.LIST_DEPOSIT_REQUEST, listDepositsRequest),
    takeLatest(types.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
    takeLatest(types.DOWNLOAD_CERTIFIED_REQUEST, downloadCertified),
    takeLatest(types.DEPOSIT_FETCH_MOVEMENTS_REQUEST, fetchMovements),
];

export default sagas;

function* depositDetails({ idSelectedDeposit, formikBag }) {
    const filters = yield select(selectors.getFilters);
    const response = yield call(depositsMiddleware.listMovements, idSelectedDeposit, filters);

    if (response) {
        if (response.type === "W" && formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else if (response.status === 200) {
            yield put({
                type: types.DEPOSIT_DETAILS_SUCCESS,
                ...unifyListParams(response),
            });
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* depositRead({ idDeposit }) {
    const response = yield call(depositsMiddleware.readDeposit, idDeposit);

    if (response && response.status === 200) {
        yield put({
            type: types.DEPOSIT_READ_SUCCESS,
            deposit: response.data.data ? response.data.data.deposit : response.data,
            totalFavorites: response.data.data.totalFavorites,
        });
    }
}

function* downloadStatement({ idDeposit, idStatement }) {
    const response = yield call(depositsMiddleware.downloadStatement, idDeposit, idStatement);

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        downloadPdf(fileName, content);

        yield put({
            type: types.DEPOSIT_DOWNLOAD_STATEMENT_SUCCESS,
        });
    }
}

function* editMovementNote({ depositId, movementId, note }) {
    const response = yield call(depositsMiddleware.editMovementNote, depositId, movementId, note);

    if (response && response.status === 200) {
        const confirmationMessage = i18n.get("deposits.movement.detail.noteSaved");

        yield put(notificationActions.showNotification(confirmationMessage, "success", ["movementDetail"]));
    } else {
        const errorMessage = i18n.get("deposits.movement.detail.noteUnsaved");

        yield put(notificationActions.showNotification(errorMessage, "error", ["movementDetail"]));
    }
}

function* fetchMovements({ depositId, filters }) {
    const response = yield call(depositsMiddleware.listMovements, depositId, filters);

    if (response && response.status === 200) {
        yield put({
            type: types.DEPOSIT_FETCH_MOVEMENTS_SUCCESS,
            ...unifyListParams(response),
        });
    }
}

function* fetchMoreMovements({ depositId, filters }) {
    const response = yield call(depositsMiddleware.listMovements, depositId, filters);

    if (response && response.status === 200) {
        yield put({
            type: types.DEPOSIT_FETCH_MORE_MOVEMENTS_SUCCESS,
            ...unifyListParams(response),
        });
    }
}

function* listDepositsRequest() {
    const response = yield call(depositsMiddleware.listDeposits);
    if (response && response.status === 200) {
        const { deposits } = response.data.data;

        if (deposits.length === 1) {
            yield put({
                type: types.LIST_DEPOSIT_SUCCESS,
                ...response.data.data,
            });

            yield put(desktopActions.setLayout(null));
            yield put(
                replace({
                    pathname: `/deposits/${deposits[0].idProduct}`,
                    state: { transition: NO_TRANSITION },
                }),
            );
        } else {
            yield put({
                type: types.LIST_DEPOSIT_SUCCESS,
                ...response.data.data,
            });
        }
    }
}

function* listStatements({ idDeposit }) {
    const response = yield call(depositsMiddleware.listStatements, idDeposit);

    if (response && response.status === 200) {
        yield put({
            type: types.LIST_DEPOSIT_STATEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* movementDetails({ idDeposit, idStatement }) {
    const response = yield call(depositsMiddleware.movementDetails, idDeposit, idStatement);

    if (response && response.status === 200) {
        yield put({
            type: types.DEPOSIT_MOVEMENT_DETAILS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* downloadMovements({ idDeposit, format }) {
    const filters = yield select(selectors.getFilters);
    const { type, data } = yield call(depositsMiddleware.downloadMovements, idDeposit, filters, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["deposit/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else if (format === "xls") {
            downloadXls(fileName, content);
        } else if (format === "csv") {
            downloadCSVFile(fileName, content);
        } else {
            yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["deposit/details"]),
            );
        }

        yield put({ type: types.DOWNLOAD_MOVEMENTS_SUCCESS });
    }
}

function* downloadFileOnMobile(content, contentType, fileName) {
    if (!content || !contentType) {
        yield;
        return;
    }

    const fileBlob = b64toBlob(content, contentType);
    downloadMobileFile(fileBlob, fileName || DEFAULT_FILENAME_DEPOSIT_CERTIFICATE, contentType);
    yield;
}

function* downloadCertified({ data }) {
    const { type, data: dataBackend } = yield call(depositsMiddleware.downloadCertified, data);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_CERTIFIED_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["deposit/details"]),
        );
    } else {
        const { content, fileName, contentType } = dataBackend.data;

        if (isMobileNativeFunc()) {
            yield call(downloadFileOnMobile, content, contentType, fileName);
        } else {
            downloadPdf(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_CERTIFIED_SUCCESS });
    }
}
