import classNames from "classnames";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, node, func, oneOfType, shape, string } from "prop-types";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as momentDayActions, selectors as momentDaySelectors } from "reducers/momentDay";
import imageMomentDay from "util/momentday/resources";

const MomentDay = (props) => {
    const { dispatch, isMobile, momentDay, className, children, pageStep0, style, withoutBackgroundMobile } = props;

    useEffect(() => {
        const hour = new Date().getHours();
        const defineMomentDay =
            (hour > 6 && hour < 12 && "morning") || (hour >= 12 && hour < 18 && "afternoon") || "night";
        dispatch(momentDayActions.setMomentDay(defineMomentDay));
    }, [dispatch]);
    const imageSrc = isMobile ? imageMomentDay[`${momentDay}Mobile`] : `/${imageMomentDay[momentDay]}`;

    return (
        <Fragment>
            <PageLoading loading={!momentDay}>
                {momentDay && (
                    <div className={className} style={style}>
                        <div
                            className={classNames("login-background-wrapper", {
                                "background-and-logo": !pageStep0 && isMobile && !withoutBackgroundMobile,
                            })}>
                            {children}
                            {!isMobile ? <img src={imageSrc} alt={`${momentDay}`} /> : null}
                        </div>
                    </div>
                )}
            </PageLoading>
        </Fragment>
    );
};

MomentDay.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    className: string,
    children: oneOfType([arrayOf(node), node]),
    style: shape({}),
    pageStep0: bool,
    momentDay: string,
    withoutBackgroundMobile: bool,
};
MomentDay.defaultProps = {
    className: "",
    children: {},
    style: {},
    pageStep0: false,
    momentDay: "",
    withoutBackgroundMobile: false,
};
const mapStateToProps = (state) => ({
    momentDay: momentDaySelectors.getMomentDay(state) || "",
});

export default withRouter(connect(mapStateToProps)(resizableRoute(MomentDay)));
