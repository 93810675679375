import Box from "pages/_components/Box";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import React from "react";

// Dashboard Tour steps
// eslint-disable-next-line
export const dashboardTourSteps = [
    {
        target: "#appDefaultContainer",
        title: <I18n id="dashboardTour.title.step1" />,
        content: <Text component="p" className="m-0" labelKey="dashboardTour.text.step1" />,
        placement: "center",
    },
    {
        target: "#toggleSidebar",
        title: <I18n id="dashboardTour.title.step2" />,
        content: <Text component="p" className="m-0" labelKey="dashboardTour.text.step2" />,
        placement: "bottom",
    },
    {
        target: "#menuTransfers",
        title: <I18n id="dashboardTour.title.step3" />,
        content: <Text component="p" className="m-0" labelKey="dashboardTour.text.step3" />,
        placement: "bottom",
    },
    {
        target: "#eyeButton",
        title: <I18n id="dashboardTour.title.step4" />,
        content: <Text component="p" className="m-0" labelKey="dashboardTour.text.step4" />,
        placement: "bottom",
    },
    {
        target: "#notificationButton",
        title: <I18n id="dashboardTour.title.step5" />,
        content: <Text component="p" className="m-0" labelKey="dashboardTour.text.step5" />,
        placement: "bottom",
    },
    {
        target: "#userPopup",
        title: <I18n id="dashboardTour.title.step6" />,
        content: <Text component="p" className="m-0" labelKey="dashboardTour.text.step6" />,
        placement: "bottom",
    },
    {
        target: "#editDashboardBtn",
        title: <I18n id="dashboardTour.title.step7" />,
        content: <Text component="p" className="m-0" labelKey="dashboardTour.text.step7" />,
        placement: "auto",
    },
    {
        target: "#appDefaultContainer",
        title: <I18n id="dashboardTour.title.step8" />,
        content: (
            <Box>
                <Image src="images/icons/contextualMenu.svg" className="mb-5" />
                <Text component="p" className="m-0" labelKey="dashboardTour.text.step8" />
            </Box>
        ),

        placement: "center",
    },
    {
        target: "#appDefaultContainer",
        title: <I18n id="dashboardTour.title.step9" />,
        content: (
            <Box>
                <Image src="images/tour/tourCreditCards.svg" className="mb-5" />
                <Text component="p" className="m-0" labelKey="dashboardTour.text.step9" />
            </Box>
        ),
        placement: "center",
    },
    {
        target: "#shortCuts",
        title: <I18n id="dashboardTour.title.step10" />,
        content: <Text component="p" className="m-0" labelKey="dashboardTour.text.step10" />,
        placement: "bottom",
    },
];
