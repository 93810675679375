import { createReducer, makeActionCreator } from "util/redux";
// Action types
export const types = {
    RESET: "recoveryUser/RESET",
    RECOVERY_USER_STEP1_PRE_REQUEST: "recoveryUser/RECOVERY_USER_STEP1_PRE_REQUEST",
    RECOVERY_USER_STEP1_PRE_SUCCESS: "recoveryUser/RECOVERY_USER_STEP1_PRE_SUCCESS",
    RECOVERY_USER_STEP1_SAVE_FORM: "recoveryUser/RECOVERY_USER_STEP1_SAVE_FORM",
    RECOVERY_USER_STEP2_SEND_REQUEST: "recoveryUser/RECOVERY_USER_STEP1_SEND_REQUEST",
    RECOVERY_USER_STEP2_SEND_SUCCESS: "recoveryUser/RECOVERY_USER_STEP1_SUCCESS",
    RECOVERY_USER_REQUEST_FAILURE: "recoveryUser/RECOVERY_USER_STEP1_FAILURE",
};

// Initial state
export const INITIAL_STATE = {
    fetching: false,
    preData: {},
    formData: {
        documentCountry: "",
        documentType: "",
        documentNumber: "",
        documentNumberToShow: "",
    },
};

// Reducer
export default createReducer(INITIAL_STATE, {
    [types.RESET]: () => ({ ...INITIAL_STATE }),
    [types.RECOVERY_USER_STEP1_PRE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.RECOVERY_USER_STEP1_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preData: action.preData,
    }),
    [types.RECOVERY_USER_STEP1_SAVE_FORM]: (state, action) => ({ ...state, formData: action.formData }),
    [types.RECOVERY_USER_STEP2_SEND_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.RECOVERY_USER_STEP2_SEND_SUCCESS]: (state) => ({ ...state, fetching: false }),
    [types.RECOVERY_USER_REQUEST_FAILURE]: (state) => ({ ...state, fetching: false }),
});

// Action creators
export const actions = {
    preForm: makeActionCreator(types.RECOVERY_USER_STEP1_PRE_REQUEST),
    preFormSuccess: makeActionCreator(types.RECOVERY_USER_STEP1_PRE_SUCCESS, "preData"),
    saveForm: makeActionCreator(types.RECOVERY_USER_STEP1_SAVE_FORM, "formData"),
    sendForm: makeActionCreator(
        types.RECOVERY_USER_STEP2_SEND_REQUEST,
        "documentCountry",
        "documentType",
        "documentNumber",
    ),
    sendFormSuccess: makeActionCreator(types.RECOVERY_USER_STEP2_SEND_SUCCESS),
    requestFailure: makeActionCreator(types.RECOVERY_USER_REQUEST_FAILURE),
    reset: makeActionCreator(types.RESET),
};

// Selectors
export const selectors = {
    getPreData: ({ recoveryUser }) => recoveryUser.preData,
    getFormData: ({ recoveryUser }) => recoveryUser.formData,
    getFetching: ({ recoveryUser }) => recoveryUser.fetching,
};
