import { Field } from "formik";
import moment from "moment";
import Select from "pages/forms/_components/_fields/Select";
import LapseSubOption from "pages/forms/_components/_scheduler/LapseSubOption";
import Box from "pages/_components/Box";
import BitwiseDaysInline from "pages/_components/fields/BitwiseDaysInline";
import DateField from "pages/_components/fields/DateField";
import Text from "pages/_components/Text";
import { arrayOf, bool, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import * as schedulerUtils from "util/scheduler";

const Mustache = require("mustache");

const FORM_ID = "scheduler";

class CustomFrequencySubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool),
        errors: objectOf(string),
        nonWorkingDays: arrayOf(bool),
        lapse: shape({
            day: string,
        }).isRequired,
        frequency: string.isRequired,
        startsOn: string.isRequired,
        customFreq: string,
    };

    static defaultProps = {
        errors: null,
        enabledWeekDays: [],
        nonWorkingDays: [],
        customFreq: "",
    };

    state = {
        occurrenceOptions: [],
    };

    componentDidMount() {
        const { startsOn } = this.props;
        this.onStartsChange(moment(startsOn.toDate()));
    }

    onStartsChange = (date) => {
        const perDayOption = Mustache.render(i18nUtils.get("scheduler.monthly.everyMonthOnDay"), {
            dayNumber: date.date(),
        });
        const occurrence = schedulerUtils.getOccurrenceWithWeekOfMonth(dateUtils.weekOfMonth(date));
        const perOccurrenceOption = Mustache.render(i18nUtils.get("scheduler.monthly.everyMonthOnRelativeDayOfaWeek"), {
            [occurrence]: true,
            day: date.format("dddd"),
        });

        this.setState({
            occurrenceOptions: [
                {
                    value: "day",
                    label: perDayOption,
                },
                {
                    value: "occurrence",
                    label: perOccurrenceOption,
                },
            ],
        });
    };

    render() {
        const { frequency, lapse, startsOn, enabledWeekDays, nonWorkingDays, errors, customFreq } = this.props;
        const { occurrenceOptions } = this.state;
        const dayOptions = Array.from({ length: 42 }, (_, index) => ({ value: `${index + 1}`, label: `${index + 1}` }));

        return (
            <>
                <Col className="col-12 col-4">
                    <Box className="form-group">
                        <Box display="flex" alignY="center" className="data-label-special-mb">
                            <Text
                                htmlFor="untilValue.date"
                                component="legend"
                                labelKey="scheduler.every"
                                className="data-label"
                            />
                        </Box>
                        <div className="input-group">
                            <Field
                                component={Select}
                                name="customFreqValue"
                                className="slideFromBottom"
                                options={dayOptions}
                                clearable={false}
                                searchable={false}
                            />
                        </div>
                    </Box>
                </Col>
                <Col className="col-12 col-8">
                    <div className="input-group select-no-label">
                        <Field
                            component={Select}
                            name="customFreq"
                            className="slideFromBottom"
                            options={schedulerUtils.listEvery()}
                            clearable={false}
                            searchable={false}
                        />
                    </div>
                </Col>
                {customFreq === schedulerUtils.WEEK && (
                    <Col className="col-12">
                        <Field name="days" component={BitwiseDaysInline} labelKey="scheduler.selectDays" />
                    </Col>
                )}
                <Col sm={12} md={12} className="col-12">
                    <Field
                        component={DateField}
                        idForm={FORM_ID}
                        name="startsOn"
                        minDate={moment(new Date())}
                        cssDisabledToday
                        maxDate={null}
                        handleChange={this.onStartsChange}
                        dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18nUtils.getLang())}
                    />
                </Col>
                {customFreq === schedulerUtils.MONTH && (
                    <Col className="col-12">
                        <div className="input-group">
                            <Field
                                component={Select}
                                name="occurrenceType"
                                className="slideFromBottom"
                                options={occurrenceOptions}
                                clearable={false}
                                searchable={false}
                            />
                        </div>
                    </Col>
                )}
                {frequency !== schedulerUtils.ONCE && (
                    <div className="scheduler-frequency-suboptions">
                        <Field
                            name="lapse"
                            component={LapseSubOption}
                            enabledWeekDays={enabledWeekDays}
                            errors={errors}
                            firstWorkingDate={startsOn}
                            nonWorkingDays={nonWorkingDays}
                            value={lapse}
                            frequency={frequency}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default CustomFrequencySubOption;
