import * as API from "middleware/api";

export const recoveryUserStep1PreData = () => API.executeAnonymous("/session.recoverUser.predata");

export const recoveryUserStep1SendData = (documentCountry, documentType, documentNumber, fingerPrintToken) =>
    API.executeAnonymous("/session.recoverUser.step1", {
        _documentCountry: documentCountry,
        _documentType: documentType,
        _documentNumber: documentNumber,
        fingerPrintToken,
    });
