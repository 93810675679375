import { call, put, takeLatest } from "redux-saga/effects";
import { authenticateHandlerTypes, actions as authenticateHandlerActions } from "reducers/authenticateHandler";
import * as softtokenApi from "middleware/softToken/softToken";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { replace } from "react-router-redux";
import { deleteIdentity } from "util/softToken.util";
import { USER_AUTHENTICATOR_PASSWORD } from "util/userToken.util";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";

const sagas = [
    takeLatest(authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST, getTokenStatusRequest),
    takeLatest(authenticateHandlerTypes.GET_TOKEN_INFO_REQUEST, getTokenInfoRequest),
    takeLatest(authenticateHandlerTypes.GET_PASSWORD_STATUS_REQUEST, getAuthenticatorStatusRequest),
    takeLatest(authenticateHandlerTypes.DESACTIVATE_TOKEN_REQUEST, desactivateSoftToken),
    takeLatest(authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_REQUEST, unlockAuthenticator),
    takeLatest(authenticateHandlerTypes.DELETE_TOKEN_PIN_REQUEST, deleteTokenPinRequest),
    takeLatest(authenticateHandlerTypes.VALIDATE_TOKEN_PIN_REQUEST, validateTokenPinRequest),
    takeLatest(authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_REQUEST, getTokenInfoStatusRequest),
];

export default sagas;

function* getTokenStatusRequest() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.validateStatusToken, deviceUuid);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        const { tokenStatus, pinCode, pinTries, serialToken } = response.data.data;
        yield put(authenticateHandlerActions.getTokenStatusSuccess(tokenStatus, pinCode, pinTries, serialToken));
    }
}

function* getTokenInfoRequest() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.getTokenInfo, deviceUuid);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        const { pinCode, pinTries } = response.data.data;
        yield put(authenticateHandlerActions.getTokenInfoSuccess(pinCode, pinTries));
    }
}

function* deleteTokenPinRequest() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.deleteTokenPinRequest, deviceUuid);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("token.pin.delete.message"), "success", [
                "settings/authenticatorHandler",
                "/desktop",
            ]),
        );
        yield put(authenticateHandlerActions.deleteTokenPinSucces());
    }
}

function* validateTokenPinRequest(pinCode) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.validateTokenPinRequest, deviceUuid, pinCode?.pinCode);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["externalLayout"]),
        );
        yield put(authenticateHandlerActions.hideValidationPinModal());
    } else {
        const { isPinCodeValid, pinTries } = response.data.data;
        yield put(authenticateHandlerActions.validateTokenPinSucces(isPinCodeValid, pinTries));

        if (!isPinCodeValid) {
            yield put(
                notificationActions.showNotification(i18n.get("token.pin.pinCode.noValid"), "error", [
                    "externalLayout",
                ]),
            );
        }
    }
}

function* getAuthenticatorStatusRequest({ authenticatorType }) {
    const response = yield call(softtokenApi.validateStatusAuthenticator, authenticatorType);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        const { authenticatorStatus } = response.data.data;
        yield put(authenticateHandlerActions.getPasswordStatusSuccess(authenticatorStatus));
    }
}

function* desactivateSoftToken({ redirect, isFromMigrateScreen }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "token.entrust.desactivateToken");
    const response = yield call(softtokenApi.desactivateUserToken, deviceUuid, fingerPrintToken);
    if (response.type === "W") {
        yield put({ type: authenticateHandlerTypes.DESACTIVATE_TOKEN_FAILED });
        yield put(
            notificationActions.showNotification(i18n.get("token.desactivate.error.message"), "error", [
                "settings/authenticatorHandler",
                "/desktop",
            ]),
        );
    } else {
        if (window?.app?.getDeviceUUID()) {
            yield call(deleteIdentity);
        }
        yield put({ type: authenticateHandlerTypes.DESACTIVATE_TOKEN_SUCCESS });
        yield put({ type: authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST });
        yield put(
            notificationActions.showNotification(i18n.get("token.desactivate.success.message"), "success", [
                "settings/authenticatorHandler",
                "/desktop",
            ]),
        );
        if (isFromMigrateScreen) {
            if (redirect) {
                yield put(replace(redirect ?? "/desktop"));
            }
        }
    }
}

function* unlockAuthenticator({ authenticatorType }) {
    const response = yield call(softtokenApi.unlockUserAuthenticator, authenticatorType);

    if (response && response.type === "I") {
        yield put({ type: authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_SUCCESS });
        yield put({
            type: authenticateHandlerTypes.GET_PASSWORD_STATUS_REQUEST,
            authenticatorType: USER_AUTHENTICATOR_PASSWORD,
        });
        yield put(
            notificationActions.showNotification(i18n.get("authenticator.password.unlock.success.message"), "success", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        yield put({
            type: authenticateHandlerTypes.CLEAN,
        });
        yield put(
            notificationActions.showNotification(i18n.get("authenticator.password.unlock.error.message"), "error", [
                "settings",
            ]),
        );
        yield put(replace("/settings"));
    }
}

function* getTokenInfoStatusRequest({ onFinish }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.getTokenInfo, deviceUuid);

    if (typeof onFinish === "function") {
        onFinish();
    }

    if (!response?.type || !response?.data?.data) {
        yield put(authenticateHandlerActions.getTokenInfoStatusFailure());
        yield;
        return;
    }

    if (response.type === "W") {
        yield put(authenticateHandlerActions.getTokenInfoStatusFailure());
        yield;
        return;
    }
    const { tokenStatus } = response.data.data;
    yield put(authenticateHandlerActions.getTokenInfoStatusSuccess(tokenStatus));
}
