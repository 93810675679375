import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import Row from "pages/_components/Row";
import { arrayOf, bool, func, int, node, number, shape, string } from "prop-types";
import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import Grid from "react-bootstrap/lib/Grid";
import DetailHeaderTitleForProducts from "./DetailHeaderTitleForProducts";

const ProductsHeader = ({
    backLinkTo,
    children,
    className,
    contextOptions,
    dispatch,
    isDesktop,
    idProduct,
    onBack,
    product,
    secondaryBtn,
    totalFavorites,
    selectProduct,
    titleImage,
}) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const main = document.querySelector("main");

        const handleScroll = () => {
            const isScrolled = main.scrollTop > 0;
            setScrolled(isScrolled);
        };

        main.addEventListener("scroll", handleScroll);

        return () => {
            main.removeEventListener("scroll", handleScroll);
        };
    }, [scrolled]);

    const backBtn = () => (
        <Button
            className="pl-md-0 pr-md-2 mr-md-3 btn-back ml-n-4"
            {...(onBack && !backLinkTo && { onClick: onBack })}
            {...(backLinkTo && !onBack && { href: backLinkTo })}
            image="images/arrowLeft.svg"
            ariaLabel="global.back"
            bsStyle="link"
        />
    );

    const titles = () => (
        <DetailHeaderTitleForProducts
            className={classNames("product-head-new-data--details", { "mx-auto": !contextOptions })}
            dispatch={dispatch}
            isDesktop={isDesktop}
            product={product}
            titleImage={titleImage}
            totalFavorites={totalFavorites}
            selectProduct={selectProduct}
        />
    );

    const contextualMenu = (options) => (
        <Dropdown
            ariaLabel="global.contextMenu.a11y"
            image="images/contextualMenu.svg"
            buttonClass="py-0 pl-3 pr-3 pr-md-0 ml-md-3 btn-only-icon icon-big btn-context"
            bsStyle="link"
            pullLeft>
            {options.map((item) => (
                <Button
                    key={item.label}
                    {...(item.href && { href: item.href + idProduct })}
                    onClick={() => {
                        if (item && item.onClick) {
                            item.onClick();
                        }
                    }}
                    label={item.label}
                    className="dropdown__item-btn"
                    bsStyle="link"
                    disabled={item.disabled}
                />
            ))}
        </Dropdown>
    );

    const extraInfo = (extra) => (
        <Box component="section" className="px-0 pb-0 pb-md-7" fullWidth>
            <Grid className="my-0 mx-auto px-0">
                <Row className="justify-content-center px-0">
                    <Col className="col-12">{extra}</Col>
                </Row>
            </Grid>
        </Box>
    );

    return isDesktop ? (
        <Box component="header" className="product-head-wrapper">
            <Box
                component="section"
                display="flex"
                alignX="between"
                alignY="center"
                className={classNames("container product-head-new px-md-7 px-lg-0", className)}>
                <Box display="flex" alignY="flex-start" className="product-head-new__data">
                    {backBtn()}
                    {titles()}
                </Box>

                <Box display="flex" alignX="flex-end" alignY="center" className="product-head-new-actions">
                    {secondaryBtn && (
                        <Box display="flex" className="product-head-new-actions--buttons pr-0">
                            {secondaryBtn.map((item) => (
                                <Button
                                    key={item.label}
                                    label={item.label}
                                    bsStyle={item.bsStyle}
                                    {...(item.onClick && { onClick: item.onClick })}
                                    {...(item.href && { href: item.href + idProduct })}
                                    {...(item.disabled && { disabled: true })}
                                    {...(item.loading && { loading: item.loading })}
                                />
                            ))}
                        </Box>
                    )}
                    {contextOptions && contextualMenu(contextOptions)}
                </Box>
            </Box>
            {extraInfo(children)}
        </Box>
    ) : (
        <Box component="header" className={classNames("product-head-wrapper", { "shadow-filter-header": scrolled })}>
            <Box
                display="flex"
                alignX="between"
                alignY="center"
                fullWidth
                className={classNames("product-head-new py-md-5 pl-4 pr-3", className)}>
                {backBtn()}
                {titles()}
                {contextOptions && contextualMenu(contextOptions)}
            </Box>

            {extraInfo(children)}
        </Box>
    );
};

ProductsHeader.propTypes = {
    backLinkTo: string,
    btnLabel: string,
    children: node.isRequired,
    className: string,
    contextOptions: arrayOf(shape({})),
    dispatch: func.isRequired,
    handleOptionsClick: func,
    href: string,
    idProduct: string,
    isDesktop: bool.isRequired,
    label: string,
    onBack: func,
    product: shape({
        account: string,
        closingDate: string,
        creditLimitCurrency: string.isRequired,
        extraInfoMarked: string,
        extraInfo: string,
        expirationDate: string,
        favorite: bool.isRequired,
        holder: string,
        idEnvironment: int,
        idProduct: string,
        label: string,
        lastPaymentDate: string,
        number: string.isRequired,
        ownerName: string,
        productAlias: string,
        productType: string.isRequired,
        shortLabel: string,
    }).isRequired,
    titleImage: string,
    secondaryBtn: arrayOf(shape({})),
    totalFavorites: number,
    selectProduct: shape({}),
};

ProductsHeader.defaultProps = {
    backLinkTo: "",
    btnLabel: null,
    className: "",
    contextOptions: null,
    handleOptionsClick: null,
    href: null,
    idProduct: null,
    label: null,
    onBack: null,
    secondaryBtn: null,
    totalFavorites: null,
    selectProduct: undefined,
    titleImage: null,
};

export default ProductsHeader;
