import classNames from "classnames";
import Logo from "pages/login/_components/Logo";
import Step4Content from "pages/login/_components/Step4Content";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as enrollmentSelectors } from "reducers/enrollment";

class LoginStep4 extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        termsAndConditions: bool.isRequired,
        isLoading: bool,
    };

    static defaultProps = {
        isLoading: false,
    };

    componentDidMount() {
        const { dispatch, termsAndConditions } = this.props;

        if (!termsAndConditions) {
            dispatch(push("/desktop"));
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
        dispatch(push("/"));
    };

    render() {
        const { isDesktop, isLoading } = this.props;

        return (
            <>
                {isDesktop ? (
                    <Head
                        additionalClassName="special-header-login"
                        logo={<Logo isDesktop={isDesktop} className="svg-image logo" />}
                        accessibilityTextId="activities.session.login.step4.a11y"
                        onClose={this.onHeaderClose}
                        flex
                    />
                ) : (
                    <Box top="0" position="absolute" fullWidth>
                        <Head
                            onClose={this.onHeaderClose}
                            logo={<Logo isDesktop={isDesktop} className="svg-image logo" />}
                        />
                    </Box>
                )}

                <Notification scopeToShow="loginStep4" />
                <MainContainer
                    showLoader={isLoading}
                    className={classNames("main-container", {
                        "container-fluid with-special-header-login": isDesktop,
                    })}>
                    <Step4Content className={classNames("app-default-main styled-2")} isDesktop={isDesktop} />
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    termsAndConditions: loginSelectors.getTermsAndConditions(state),
    isLoading: enrollmentSelectors.isFetching(state),
});
export default connect(mapStateToProps)(LoginStep4);
