/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import { STATUS_CHECK } from "constants.js";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import Checkbox from "pages/_components/Checkbox";
import Container from "pages/_components/Container";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text/Text";
import FieldLabel from "pages/_components/fields/FieldLabel";
import LoadingModal from "pages/_components/modal/LoadingModal";
import SideBarModal from "pages/_components/modal/SideBarModal";
import ProductList from "pages/_components/product/List";
import StopChecksEditForm from "pages/checks/StopChecksEditForm";
import { bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Select from "pages/forms/_components/_fields/Select";
import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import * as configUtil from "util/config";
import * as i18n from "util/i18n";
import * as numberUtils from "util/number";

const { decimalSeparator, thousandSeparator } = numberUtils.numberFormat();

const Checks = (props) => {
    const {
        match,
        dispatch,
        isDesktop,
        isFetching,
        checks,
        accountNumber,
        initialCheckNumber,
        finalCheckNumber,
        currentCheckbook,
        isCheckUrlFetch,
    } = props;

    const checksFirstPage = configUtil.getInteger("checksFirstPage") || 10;
    const checksPerPage = configUtil.getInteger("checksPerPage") || 50;

    const statusOptionsSelect = [
        {
            value: [STATUS_CHECK.AVAILABLE],
            label: i18n.get("checks.status.selector.available"),
        },
        {
            value: [STATUS_CHECK.CLEARED, STATUS_CHECK.RETURNED],
            label: i18n.get("checks.status.selector.paid_returned"),
        },
        {
            value: [STATUS_CHECK.ISSUED],
            label: i18n.get("checks.status.selector.pending"),
        },
        {
            value: [STATUS_CHECK.STOPPED],
            label: i18n.get("checks.status.selector.suspended"),
        },
    ];

    const [checksView, setChecksView] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [isFirstFetched, setIsFirstFetched] = useState(true);
    const [isStopCheckOpen, setIsStopCheckOpen] = useState(false);
    const [isStopCheckbook, setIsStopCheckbook] = useState(false);
    const [amountValue, setAmountValue] = useState("");
    const [numberValue, setNumberValue] = useState("");
    const [checksSelectSuspend, setChecksSelectSuspend] = useState([]);
    const [isSearchCheck, setIsSearchCheck] = useState(true);
    const [isActiveSuspend, setIsActiveSuspend] = useState(false);
    const [isFirstFilter, setIsFirstFilter] = useState(true);
    const [statusSelected, setStatusSelected] = useState(
        statusOptionsSelect?.find((option) => option?.value?.includes(STATUS_CHECK.AVAILABLE)),
    );
    const [checksTypeValueSuspend, setChecksTypeValueSuspend] = useState([]);
    const productId = match.params.idProduct;

    useEffect(() => {
        if (match.path.includes("/searchchecks/")) {
            setIsSearchCheck(true);
            dispatch(checksActions.listAllChecksRequest(productId));
        } else {
            setIsSearchCheck(false);
            const checkbookId = match.params.idCheckbook;
            dispatch(checksActions.listChecksRequest(productId, checkbookId));
        }
    }, []);

    useEffect(() => {
        if (isSearchCheck) {
            setChecksView([]);
        } else {
            const checkDefaultFiltered = checks?.filter((check) => check.status === STATUS_CHECK.AVAILABLE);
            setIsActiveSuspend(true);
            setChecksView(checkDefaultFiltered);
        }
    }, [checks]);

    const handleClickFilter = () => {
        const checkFiltered = checks?.filter((check) => {
            let filterCondition =
                statusSelected && !isSearchCheck ? statusSelected?.value?.includes(check.status) : true;
            filterCondition = isSearchCheck && !numberValue && !amountValue ? false : filterCondition;
            filterCondition = numberValue ? filterCondition && check.numberOfCheck === numberValue : filterCondition;
            filterCondition = amountValue
                ? filterCondition && parseFloat(check.checkAmount?.toFixed(2)) === amountValue
                : filterCondition;
            return filterCondition;
        });
        setChecksSelectSuspend([]);
        setChecksTypeValueSuspend([]);
        setIsFirstFetched(true);
        setIsFirstFilter(false);
        setPageNumber(1);
        const allChecksAvailable =
            statusSelected === STATUS_CHECK.AVAILABLE ||
            (checkFiltered.length > 0 &&
                checkFiltered?.reduce((acc, curr) => acc && curr.status === STATUS_CHECK.AVAILABLE, true));
        setIsActiveSuspend(allChecksAvailable);
        setChecksView(checkFiltered);
    };

    const handleBack = () => {
        dispatch(push(`/checkbooks/${productId}`));
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleClickFilter();
        }
    };

    const getColumnsTemplate = () => {
        if (isDesktop) {
            return "0.75fr 1fr 1fr 1.5fr 1.5fr 1fr";
        }
        if (isActiveSuspend) {
            return "1fr 1fr";
        }
        return "1fr 1fr 1.5rem";
    };
    const handleStopCheck = () => {
        setIsStopCheckOpen(true);
    };

    const renderItem = (check) => (
        <GridTable.Container
            className={classNames("py-md-1 px-5 px-md-7 min-height-10-i", {
                "py-5": !check.reason,
                "py-3": check.reason,
            })}
            columnsTemplate={getColumnsTemplate()}
            rows={1}
            {...(!isDesktop &&
                [STATUS_CHECK.RETURNED, STATUS_CHECK.CLEARED].includes(check.status) && {
                    onClick: () => {
                        dispatch(checksActions.getUrlCheckRequest(productId, check.numberOfCheck));
                    },
                })}
            key={check.numberOfCheck}>
            <GridTable.Data columnStart={1} alignX="flex-start">
                {check.status === STATUS_CHECK.AVAILABLE ? (
                    <Box
                        display="flex"
                        alignY="center"
                        alignX="flex-start"
                        fullWidth
                        fullHeight
                        className="checkbox-table">
                        <Checkbox
                            key={check.numberOfCheck}
                            name={`checkbox_${check.numberOfCheck}`}
                            className="mb-0 c-control-block"
                            formGroup
                            noLabel
                            onChange={(value) => {
                                if (value) {
                                    setChecksSelectSuspend([...checksSelectSuspend, check.numberOfCheck]);
                                    setChecksTypeValueSuspend([...checksTypeValueSuspend, check.idCheckBook]);
                                } else {
                                    const newCheckSelectSuspend = checksSelectSuspend?.filter(
                                        (numberCheck) => check.numberOfCheck !== numberCheck,
                                    );
                                    const newCheckTypeValueSuspend = checksTypeValueSuspend?.filter(
                                        (checkBook) => check.idCheckBook !== checkBook,
                                    );
                                    setChecksSelectSuspend(newCheckSelectSuspend);
                                    setChecksTypeValueSuspend(newCheckTypeValueSuspend);
                                }
                            }}
                            checked={checksSelectSuspend.includes(check.numberOfCheck)}
                        />
                        {!isDesktop && (
                            <Text className="ml-3" size="5" color="heading" bold>
                                {check.numberOfCheck}
                            </Text>
                        )}
                    </Box>
                ) : isDesktop ? (
                    <FormattedDate date={check.registrationDate} />
                ) : check?.status === STATUS_CHECK.AVAILABLE ? (
                    <Text color="heading" size="5" bold>
                        {check.numberOfCheck}
                    </Text>
                ) : (
                    <Box display="flex" column>
                        <Text color="heading" size="6" bold className="mb-2">
                            {check.numberOfCheck}
                        </Text>
                        <FormattedDate date={check.registrationDate} />
                    </Box>
                )}
            </GridTable.Data>
            {isDesktop && (
                <>
                    <GridTable.Data columnStart={2} alignX="center">
                        <Text>{check.numberOfCheck}</Text>
                    </GridTable.Data>
                    <GridTable.Data columnStart={3} alignX="flex-end">
                        <FormattedAmount
                            quantity={check.checkAmount ? check.checkAmount : "-"}
                            noCurrency
                            size="5"
                            color="heading"
                            className="justify-content-flex-end mb-1"
                        />
                    </GridTable.Data>
                </>
            )}
            <GridTable.Data columnStart={isDesktop ? 4 : 2} alignX={isDesktop ? "center" : "flex-end"}>
                {isDesktop ? (
                    <Text labelKey={`checks.status.${check.status.toLowerCase()}`} />
                ) : check?.status === STATUS_CHECK.AVAILABLE ? (
                    <Sticker status="success" className="ml-3" bold>
                        {i18n.get(`checks.status.${check.status.toLowerCase()}`)}
                    </Sticker>
                ) : (
                    <Box display="flex" alignX="flex-end" column>
                        <FormattedAmount
                            quantity={check.checkAmount}
                            noCurrency
                            size="5"
                            color="text"
                            className="justify-content-flex-end mb-1"
                        />
                        {check?.status === STATUS_CHECK.CLEARED ? (
                            <Sticker status="success" className="ml-3" bold>
                                {i18n.get(`checks.status.${check.status.toLowerCase()}`)}
                            </Sticker>
                        ) : (
                            <Sticker
                                status={check?.status === "RETURNED" ? "warning" : "error"}
                                className="ml-auto"
                                bold>
                                {i18n.get(`checks.status.${check.status.toLowerCase()}`)}
                            </Sticker>
                        )}
                    </Box>
                )}
            </GridTable.Data>
            {check.reason && !isDesktop && (
                <GridTable.Data columnStart={2} rowStart={2} alignX="flex-end">
                    <Text className="pt-2">{check.reason}</Text>
                </GridTable.Data>
            )}

            {isDesktop ? (
                <>
                    <GridTable.Data columnStart={5} alignX="center">
                        <Text>{check.reason ? check.reason : "-"}</Text>
                    </GridTable.Data>
                    <GridTable.Data columnStart={6} alignX="flex-end">
                        {[STATUS_CHECK.RETURNED, STATUS_CHECK.CLEARED].includes(check.status) && (
                            <Button
                                bsStyle="link"
                                image="images/plusCircle.svg"
                                onClick={() => {
                                    dispatch(checksActions.getUrlCheckRequest(productId, check.numberOfCheck));
                                }}>
                                <Text labelKey="checks.downloadImage.label" />
                            </Button>
                        )}
                    </GridTable.Data>
                </>
            ) : (
                <>
                    {[STATUS_CHECK.RETURNED, STATUS_CHECK.CLEARED].includes(check.status) && (
                        <GridTable.Data
                            className="p-0 ml-5"
                            columnStart={3}
                            {...(check.reason && { rowWidth: "3" })}
                            alignX="center">
                            <Image src="images/icons/chevronRight.svg" color="text-color" />
                        </GridTable.Data>
                    )}
                </>
            )}
        </GridTable.Container>
    );

    const renderHeaderList = () => (
        <GridTable.Container
            columnsTemplate={getColumnsTemplate()}
            className={isDesktop ? "gapX-9" : "gapX-3"}
            padding="py-2 px-5 px-md-7">
            {isDesktop && (
                <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                    <Text labelKey="checks.header.table.date" />
                </GridTable.Data>
            )}
            <GridTable.Data columnStart={isDesktop ? 2 : 1} alignX={isDesktop ? "center" : "flex-start"} inHeader>
                <Text>
                    {isDesktop && i18n.get("checks.header.table.checkNumber")}
                    {!isDesktop && isActiveSuspend && `${i18n.get("checks.header.tablemobile.checkNumber")}`}
                    {!isDesktop &&
                        !isActiveSuspend &&
                        `${i18n.get("checks.header.tablemobile.checkNumber")} / ${i18n.get(
                            "checks.header.table.date",
                        )}`}
                </Text>
            </GridTable.Data>
            {isDesktop && (
                <GridTable.Data columnStart={3} alignX="flex-end" inHeader>
                    <Text labelKey="checks.header.table.amount" />
                </GridTable.Data>
            )}

            <GridTable.Data columnStart={isDesktop ? 4 : 2} alignX={isDesktop ? "center" : "flex-end"} inHeader>
                <Text>
                    {isDesktop && i18n.get("checks.header.table.status")}
                    {!isDesktop && isActiveSuspend && `${i18n.get("checks.header.table.amount")}`}
                    {!isDesktop &&
                        !isActiveSuspend &&
                        `${i18n.get("checks.header.table.amount")} / ${i18n.get("checks.header.table.status")}`}
                </Text>
            </GridTable.Data>
            {isDesktop && (
                <>
                    <GridTable.Data columnStart={5} alignX="center" inHeader>
                        <Text labelKey="checks.header.table.reason" />
                    </GridTable.Data>

                    <GridTable.Data columnStart={6} alignX="flex-end" inHeader>
                        <Text labelKey="checks.header.table.action" />
                    </GridTable.Data>
                </>
            )}
        </GridTable.Container>
    );

    const renderList = (list, renderLoadMore) => {
        const isEmpty = list.length === 0;

        return (
            <Container className="container--layout scrollable movements-container">
                <GridTable>
                    <GridTable.Header>{renderHeaderList()}</GridTable.Header>
                    <GridTable.Body>
                        {list}
                        {!isEmpty && renderLoadMore()}
                    </GridTable.Body>
                </GridTable>
            </Container>
        );
    };

    const renderDetailCheckbook = () => (
        <Box
            component="article"
            background="white"
            borderRadius="default"
            className="pt-3 px-5 pb-4 mt-3 mb-3"
            toBorderInMobile>
            <Box display="flex" className="mb-3">
                <Text color="heading" labelKey="checkbooks.header.table.initialCheckNumber" size="5" bold addColon />
                <Text color="heading" size="5">
                    {currentCheckbook.initialCheckNumber}
                </Text>
            </Box>
            <Box display="flex" className="mb-3">
                <Text color="heading" labelKey="checkbooks.header.table.activationDate" size="5" bold addColon />
                <FormattedDate date={currentCheckbook.activationDate} color="heading" size="5" />
            </Box>
            <Box display="flex" className="mb-3">
                <Text color="heading" labelKey="checkbooks.header.table.status" size="5" bold addColon />
                <Text color="heading" size="5">
                    {currentCheckbook.status === "AC"
                        ? i18n.get(`checkbooks.status.${currentCheckbook.status}`)
                        : currentCheckbook.status}
                </Text>
            </Box>
            <Box display="flex" className="mb-3">
                <Text color="heading" labelKey="checkbooks.header.table.numberOfChecks" size="5" bold addColon />

                <Text color="heading" size="5">
                    {currentCheckbook.numberOfChecks}
                </Text>
            </Box>
            <Box display="flex" className="mb-3">
                <Text color="heading" labelKey="checkbooks.header.table.available" size="5" bold addColon />

                <Text color="heading" size="5">
                    {currentCheckbook.available}
                </Text>
            </Box>
            <Box display="flex">
                <Text labelKey="checkbooks.header.table.notAvailable" color="heading" size="5" bold addColon />

                <Text color="heading" size="5">
                    {currentCheckbook.notAvailable}
                </Text>
            </Box>
        </Box>
    );

    const renderFilterDesktop = () => (
        <Row alignY="flex-end">
            {!isSearchCheck && (
                <Col xs={6} md={3}>
                    <Box className="form-group form-group--select">
                        <FieldLabel labelKey="checkbooks.filter.status.label" labelNoMarginTop />
                        <Box className="input-group ">
                            <Box flex1>
                                <Select
                                    clearable={false}
                                    searchable={false}
                                    value={statusSelected}
                                    label="checks.filters.searchBy"
                                    onChange={(option) => setStatusSelected(option)}
                                    options={statusOptionsSelect}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Col>
            )}
            <Col xs={6} md={3}>
                <Box className="form-group">
                    <FieldLabel labelKey="checkbooks.filter.amount.label" labelNoMarginTop />
                    <Box className="input-group">
                        <NumberFormat
                            id="amountFilter"
                            name="amountFilter"
                            className="form-control"
                            type="text"
                            onChange={(event) =>
                                setAmountValue(numberUtils.createDecimalNumber(event.target.value, decimalSeparator))
                            }
                            maxLength="20"
                            value={amountValue}
                            placeholder="0.00"
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                            autoComplete="off"
                            inputMode="decimal"
                            onKeyDown={handleKeyDown}
                        />
                    </Box>
                </Box>
            </Col>
            <Col xs={6} md={3}>
                <Box className="form-group">
                    <FieldLabel labelKey="checkbooks.filter.number.label" labelNoMarginTop />
                    <Box className="input-group">
                        <input
                            className="form-control"
                            type="number"
                            max="9999999"
                            min="0"
                            value={numberValue}
                            onChange={(e) => setNumberValue(e.target.value)}
                            name="checkNumberInput"
                            placeholder=""
                            onKeyDown={handleKeyDown}
                        />
                    </Box>
                </Box>
            </Col>
            <Col xs={6} md={3}>
                <Button
                    label="product.filters.filter"
                    bsStyle="primary"
                    className={classNames("mt-auto", { "px-9": isDesktop, "px-8": !isDesktop })}
                    disabled={checks?.length === 0}
                    onClick={handleClickFilter}
                />
            </Col>
        </Row>
    );

    const renderFilterMobile = () => (
        <>
            <Row alignY="flex-end" gapY="7">
                <Col xs={6}>
                    <Box className="form-group">
                        <FieldLabel labelKey="checkbooks.filter.amount.label" labelNoMarginTop />
                        <Box className="input-group">
                            <NumberFormat
                                id="amountFilter"
                                name="amountFilter"
                                className="form-control"
                                type="text"
                                onChange={(event) =>
                                    setAmountValue(
                                        numberUtils.createDecimalNumber(event.target.value, decimalSeparator),
                                    )
                                }
                                maxLength="20"
                                value={amountValue}
                                placeholder="0.00"
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={decimalSeparator}
                                fixedDecimalScale
                                autoComplete="off"
                                inputMode="decimal"
                                onKeyDown={handleKeyDown}
                            />
                        </Box>
                    </Box>
                </Col>
                <Col xs={6}>
                    <Box className="form-group">
                        <FieldLabel labelKey="checkbooks.filter.number.label" labelNoMarginTop />
                        <Box className="input-group">
                            <input
                                className="form-control"
                                type="number"
                                max="9999999"
                                min="0"
                                value={numberValue}
                                onChange={(e) => setNumberValue(e.target.value)}
                                name="checkNumberInput"
                                placeholder=""
                                onKeyDown={handleKeyDown}
                            />
                        </Box>
                    </Box>
                </Col>
                {!isSearchCheck ? (
                    <>
                        <Col xs={10}>
                            <Box className="form-group form-group--select">
                                <FieldLabel
                                    idField="react-select-checksFilter-input"
                                    labelKey="checkbooks.filter.status.label"
                                    labelNoMarginTop
                                />
                                <Box className="input-group ">
                                    <Select
                                        id="checksFilter"
                                        clearable={false}
                                        searchable={false}
                                        value={statusSelected}
                                        label="checks.filters.searchBy"
                                        onChange={(option) => setStatusSelected(option)}
                                        options={statusOptionsSelect}
                                    />
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={2}>
                            <Button bsStyle="primary" onClick={handleClickFilter} image="images/search.svg" />
                        </Col>
                    </>
                ) : (
                    <Col xs={12}>
                        <Button bsStyle="primary" block onClick={handleClickFilter} label="product.filters.filter" />
                    </Col>
                )}
            </Row>
        </>
    );

    const getChecks = () => {
        if (isSearchCheck) {
            return checksView;
        }

        const max = checksFirstPage + (pageNumber - 1) * checksPerPage;

        if (checksView.length < max) {
            return checksView;
        }

        return checksView.slice(0, max);
    };

    const hasMoreMovements = () => {
        const max = checksFirstPage + (pageNumber - 1) * checksPerPage;
        return checksView.length > max;
    };

    return (
        <>
            <Notification scopeToShow="checks" />
            <Head
                onBack={handleBack}
                titleText={
                    isSearchCheck
                        ? i18n.get("checks.header.search.title")
                        : `${i18n.get("checks.header.title")} ${initialCheckNumber} - ${finalCheckNumber}`
                }
                subtitlePrefix={i18n.get("accounts.productType.checking")}
                subtitle={accountNumber}
                {...(!isDesktop &&
                    currentCheckbook.notAvailable !== 0 && {
                        rightChildren: (
                            <Dropdown
                                image="images/contextualMenu.svg"
                                buttonClass="py-0 px-0 pr-md-0 ml-md-3 btn-only-icon icon-big btn-context"
                                bsStyle="link"
                                pullLeft>
                                <Button
                                    bsStyle="link"
                                    label="checkbooks.button.action.suspend"
                                    className="dropdown__item-btn"
                                    onClick={() => {
                                        setIsStopCheckbook(true);
                                        setIsStopCheckOpen(true);
                                    }}
                                />
                            </Dropdown>
                        ),
                    })}
            />

            <MainContainer showLoader={isFetching}>
                {!isDesktop && !isSearchCheck && renderDetailCheckbook()}
                <Box
                    component="article"
                    background="white"
                    borderRadius="default"
                    className={classNames("pt-5 mb-5", { "mt-5": isSearchCheck })}
                    toBorderInMobile>
                    <Box
                        display="flex"
                        className={classNames("pb-md-8 px-5 px-md-7", {
                            "pb-2": isActiveSuspend,
                            "pb-10": !isActiveSuspend,
                        })}
                        fullWidth>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        <Row {...(isDesktop ? { gapX: "7" } : isActiveSuspend ? { gapY: "2" } : { gapY: "0" })}>
                            <Col xs={12} md={9}>
                                {isDesktop && renderFilterDesktop()}
                                {!isDesktop && renderFilterMobile()}
                            </Col>
                            {isActiveSuspend && (
                                <Col xs={12} md={3}>
                                    <Box
                                        display="flex"
                                        alignX={isDesktop ? "flex-end" : "flex-start"}
                                        alignY="flex-end"
                                        fullHeight>
                                        <Button
                                            bsStyle="link"
                                            onClick={handleStopCheck}
                                            image="images/icons/locked.svg"
                                            disabled={checksSelectSuspend.length === 0}>
                                            <Text labelKey="checks.button.action.suspend" />
                                        </Button>
                                    </Box>
                                </Col>
                            )}
                        </Row>
                    </Box>
                    <ProductList
                        fetching={isFetching}
                        items={getChecks()}
                        renderItem={renderItem}
                        lastPage={isSearchCheck ? true : !hasMoreMovements()}
                        onLoadMoreClick={() => {
                            setPageNumber(pageNumber + 1);
                            setIsFirstFetched(false);
                        }}
                        firstFetched={isFirstFetched}
                        noMoreDataText={isSearchCheck ? "" : "checks.noMoreChecks"}
                        loadMoreText={isSearchCheck ? "" : "checks.searchChecks"}
                        noDataText={isFirstFilter && isSearchCheck ? "" : "checks.noDataText"}
                        noFiltersDataText={isFirstFilter && isSearchCheck ? "" : "checks.noDataText"}
                        {...(!isDesktop && { noMinHeight10rem: true })}>
                        {renderList}
                    </ProductList>
                </Box>
            </MainContainer>
            <SideBarModal
                show={isStopCheckOpen}
                onClose={() => {
                    setIsStopCheckOpen(false);
                    setIsStopCheckbook(false);
                }}
                title={isStopCheckbook ? "checkbooks.title.sidebar.suspend" : "checks.title.sidebar.suspend"}
                scrollable>
                <StopChecksEditForm
                    onChangeStatus={() => setIsStopCheckOpen(false)}
                    checksToSuspend={
                        isStopCheckbook
                            ? [
                                  currentCheckbook.initialCheckNumber,
                                  (
                                      parseInt(currentCheckbook?.initialCheckNumber || "0", 10) +
                                      currentCheckbook.numberOfChecks -
                                      1
                                  ).toString(),
                              ]
                            : checksSelectSuspend.sort((a, b) => a - b)
                    }
                    productId={productId}
                    quantityChecks={
                        isStopCheckbook ? currentCheckbook.numberOfChecks : checksSelectSuspend.length.toString()
                    }
                    type={isStopCheckbook ? "checkbooks" : "checks"}
                    checkTypeValue={currentCheckbook?.id || checksTypeValueSuspend[0]}
                />
            </SideBarModal>
            {isCheckUrlFetch && (
                <div className="modal-container">
                    <LoadingModal showModal={isCheckUrlFetch} content={i18n.get("checks.getUrl.loading.content")} />
                </div>
            )}
        </>
    );
};

Checks.propTypes = {
    dispatch: func.isRequired,
    match: shape({}).isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    checks: shape({}).isRequired,
    accountNumber: string.isRequired,
    initialCheckNumber: number.isRequired,
    finalCheckNumber: number.isRequired,
    currentCheckbook: shape({}).isRequired,
    isCheckUrlFetch: bool.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: checksSelectors.getFetching(state),
    checks: checksSelectors.checks(state),
    accountNumber: checksSelectors.accountNumber(state),
    initialCheckNumber: checksSelectors.initialCheckNumber(state),
    finalCheckNumber: checksSelectors.finalCheckNumber(state),
    currentCheckbook: checksSelectors.currentCheckbook(state),
    isCheckUrlFetch: checksSelectors.isFetchingCheckUrl(state),
});

export default connect(mapStateToProps)(resizableRoute(Checks));
