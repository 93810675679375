import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { arrayOf, bool, func, number, oneOfType, objectOf, shape, string } from "prop-types";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Notification from "pages/_components/Notification";

class SignaturesSchemeModify extends Component {
    static propTypes = {
        actions: shape({
            modifySignaturesSchemeRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        fetching: bool.isRequired,
        functionalGroups: arrayOf(string),
        masterCurrency: string,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        routerActions: shape({
            goBack: func,
        }),
        signatureLevelsCounts: oneOfType([
            shape({
                A: number,
            }),
            shape(
                configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                    const result = res;
                    result[signLevel] = number;

                    return result;
                }, {}),
            ),
        ]),
        signatureType: string,
        selectedProducts: arrayOf(string),
        environmentProducts: arrayOf(Object),
        topAmount: shape({
            amount: oneOfType([number, string]),
            period: string,
        }),
        formActions: objectOf(func).isRequired,
        idActivity: string,
        idTransaction: string,
        signatureDispatch: bool,
    };

    static defaultProps = {
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        match: null,
        routerActions: null,
        signatureLevelsCounts: null,
        signatureType: "",
        topAmount: {
            amount: 0,
            period: "daily",
        },
        idActivity: null,
        idTransaction: null,
        signatureDispatch: false,
        environmentProducts: [],
        selectedProducts: [],
    };

    componentDidMount() {
        const { routerActions, signatureLevelsCounts, signatureType } = this.props;

        if (!signatureLevelsCounts || !signatureType) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const {
            idTransaction,
            idActivity,
            formActions,
            actions,
            functionalGroups,
            selectedProducts,
            match: {
                params: { idSignature },
            },
            signatureLevelsCounts,
            signatureType,
            topAmount,
            signatureDispatch,
        } = this.props;

        if (!idTransaction) {
            actions.modifySignaturesSchemeRequest(
                {
                    credentials,
                    functionalGroups,
                    products: selectedProducts,
                    signatureId: idSignature,
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                    signatureDispatch,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    renderFunctionalGroups = (functionalGroups) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label="administration.signatures.functionalGroups.label"
                data={functionalGroups.map((functionalGroup) => (
                    <div key={functionalGroup}>
                        <I18n id={`administration.signatures.functionalGroups.${functionalGroup}`} />
                    </div>
                ))}
            />
        </div>
    );

    renderProducts = (selectedProducts, environmentProducts) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label="administration.signatures.products.label"
                data={environmentProducts
                    .filter((product) => selectedProducts.indexOf(product.idProduct) > -1)
                    .map((product) => (
                        <div key={product}>{product.label}</div>
                    ))}
            />
        </div>
    );

    renderSchemeReference = (administrationScheme) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label={
                    administrationScheme === "medium"
                        ? `administration.signatures.modify.${administrationScheme}.confirm.signersCount`
                        : `administration.signatures.modify.${administrationScheme}.confirm.schemeReference`
                }
                data={this.signersCount()}
            />
        </div>
    );

    renderTopAmount = (masterCurrency, topAmount) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label="administration.signatures.modify.topAmount.label"
                data={
                    <Fragment>
                        <FormattedAmount
                            className="data-amount content-data-strong"
                            currency={masterCurrency}
                            frequency={i18nUtils.get(
                                "administration.signatures.modify.advanced.topAmountPerPeriod",
                                null,
                                {
                                    period: i18nUtils.get(
                                        `administration.signatures.transactions.capFrequency.${topAmount.period}`,
                                    ),
                                },
                            )}
                            quantity={topAmount.amount}
                        />
                    </Fragment>
                }
            />
        </div>
    );

    renderTransactionType = (administrationScheme, signatureType) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label={`administration.signatures.modify.${administrationScheme}.confirm.transactionType`}
                data={i18nUtils.get(`administration.signatures.modify.signatureType.${signatureType}`)}
            />
        </div>
    );

    signersCount = () => {
        const {
            activeEnvironment: { administrationScheme },
            signatureLevelsCounts,
        } = this.props;

        return administrationScheme === "medium"
            ? `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts)
                  .sort()
                  .reduce((result, key) => result + key.toString().repeat(signatureLevelsCounts[key]), "");
    };

    render() {
        const {
            credentialGroups,
            activeEnvironment: { administrationScheme },
            fetching,
            functionalGroups,
            masterCurrency,
            routerActions,
            signatureLevelsCounts,
            signatureType,
            topAmount,
            environmentProducts,
            selectedProducts,
        } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="administrationModifySignatureScheme" />
                <Head
                    title={`administration.signatures.modify.${administrationScheme}.confirm.title`}
                    onBack={routerActions.goBack}
                    closeLinkTo={`/administration/${administrationScheme}/signaturesSchemes`}
                />
                <MainContainer showLoader={fetching}>
                    <div className="above-the-fold">
                        <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <p className="text-lead">
                                    <span>
                                        <I18n id="administration.forms.confirm.credentials" />
                                    </span>
                                </p>
                                {signatureLevelsCounts && this.renderSchemeReference(administrationScheme)}
                                {signatureType && this.renderTransactionType(administrationScheme, signatureType)}
                                {functionalGroups &&
                                    functionalGroups.length > 0 &&
                                    this.renderFunctionalGroups(functionalGroups)}
                                {selectedProducts &&
                                    selectedProducts.length > 0 &&
                                    this.renderProducts(selectedProducts, environmentProducts)}
                                {topAmount &&
                                    administrationScheme === "advanced" &&
                                    signatureType === "AMOUNT" &&
                                    this.renderTopAmount(masterCurrency, topAmount)}
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default SignaturesSchemeModify;
