import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUEST: "creditLines/LIST_REQUEST",
    LIST_FAILURE: "creditLines/LIST_FAILURE",
    LIST_SUCCESS: "creditLines/LIST_SUCCESS",
    FETCH: "creditLines/FETCH",
};

export const INITIAL_STATE = {
    fetching: false,
    list: [],
    total: null,
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LIST_SUCCESS]: (_, action) => ({
        fetching: false,
        list: action.list,
        total: action.total,
    }),
    [types.FETCH]: (state) => ({
        ...state,
        fetching: true,
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST),
    listFailure: makeActionCreator(types.LIST_FAILURE),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "list", "total"),
    fetch: makeActionCreator(types.FETCH),
};

export const selectors = {
    isFetching: ({ creditLines }) => creditLines.fetching,
    getList: ({ creditLines }) => creditLines.list,
    getTotal: ({ creditLines }) => creditLines.total,
};
