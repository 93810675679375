import * as API from "middleware/api";

export const validateStatusToken = (deviceUuid) =>
    API.executeWithAccessToken("/token.entrust.validateStatus", { deviceUuid });

export const getTokenInfo = (deviceUuid) => API.executeAnonymous("/token.get.info", { deviceUuid });

export const saveUserToken = (deviceUuid, credentials, deviceModel, deviceBrand) =>
    API.executeWithAccessToken("/token.entrust.saveSoftToken", { deviceUuid, credentials, deviceModel, deviceBrand });

export const resetActivateToken = (deviceUuid) =>
    API.executeWithAccessToken("/token.entrust.resetActivateToken", { deviceUuid });
export const activateUserToken = (deviceUuid, registrationCode, _exchangeToken, fingerPrintToken) =>
    API.executeWithAccessToken("/token.entrust.activateUserToken", { deviceUuid, registrationCode, fingerPrintToken });

export const saveUserTokenPublic = (deviceUuid, credentials, deviceModel, deviceBrand, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/token.entrust.saveSoftToken.public",
        { deviceUuid, credentials, deviceModel, deviceBrand },
        _exchangeToken,
    );

export const activateUserTokenPublic = (deviceUuid, registrationCode, _exchangeToken, fingerPrintToken) =>
    API.executeWithExchangeToken(
        "/token.entrust.activateUserToken.public",
        { deviceUuid, registrationCode, fingerPrintToken },
        _exchangeToken,
    );

export const desactivateUserToken = (deviceUuid, fingerPrintToken) =>
    API.executeWithAccessToken("/token.entrust.desactivateToken", { deviceUuid, fingerPrintToken });

export const unlockUserAuthenticator = (authenticatorType) =>
    API.executeWithAccessToken("/authenticator.entrust.unlock", { authenticatorType });

export const validateStatusAuthenticator = (authenticatorType) =>
    API.executeWithAccessToken("/authenticator.entrust.validateStatus", { authenticatorType });

export const deleteTokenPinRequest = (deviceUuid) => API.executeWithAccessToken("/token.pin.delete", { deviceUuid });

export const validateTokenPinRequest = (deviceUuid, pinCode) =>
    API.executeAnonymous("/token.pin.unlock", { deviceUuid, pinCode });

export const migrateEntrustTokenPre = (deviceUuid) =>
    API.executeWithAccessToken("/token.entrust.migrateEntrustTokenPre", { deviceUuid });

export const resendOtpAuthenticate = () => API.executeWithAccessToken("/token.entrust.resendOtpAuthenticate");

export const validateStatusTokenOnBase = (deviceUuid) =>
    API.executeWithAccessToken("/token.onBase.validateStatus", { deviceUuid });
