import classNames from "classnames";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";

class MultiSelectField extends Component {
    static propTypes = {
        idForm: string.isRequired,
        className: string,
        allOption: string,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        handleChange: func,
        hideLabel: bool,
        hidePlaceholder: bool,
        options: arrayOf(string).isRequired,
        initialOptions: arrayOf(string),
        searchable: bool,
        i18nOptionsPrefix: string,
        textOptionsMap: shape({}),
    };

    static defaultProps = {
        className: null,
        allOption: null,
        handleChange: null,
        hideLabel: false,
        hidePlaceholder: false,
        i18nOptionsPrefix: "",
        searchable: false,
        initialOptions: null,
        textOptionsMap: null,
    };

    state = {
        isFocused: false,
        selectedOptions: [],
    };

    componentDidMount() {
        const { form, field, initialOptions } = this.props;

        if (initialOptions) {
            this.setState({ selectedOptions: initialOptions });
            form.setFieldValue(field.name, initialOptions);
        }
    }

    handleChange = (selected) => {
        const { allOption, field, form, handleChange } = this.props;

        if (handleChange) {
            handleChange(selected);
        }

        let includesAll = false;
        let selectedOptions = [];
        for (let index = 0; index < selected.length && !includesAll; index++) {
            if (selected[index].value === allOption) {
                includesAll = true;
                selectedOptions = [allOption];
            } else {
                selectedOptions = selectedOptions.concat(selected[index].value);
            }
        }

        this.setState({ selectedOptions });

        form.setFieldValue(field.name, selectedOptions);
    };

    options = () => {
        const { selectedOptions } = this.state;
        const { allOption, options, i18nOptionsPrefix, textOptionsMap } = this.props;

        let optionsToShow = [];
        let optionLabel = null;
        options.forEach((option) => {
            if (!textOptionsMap) {
                optionLabel = i18n.get(i18nOptionsPrefix + option);
            } else {
                optionLabel = textOptionsMap.get(option);
            }
            if (selectedOptions && selectedOptions.length) {
                optionsToShow.push({
                    label: optionLabel,
                    value: option,
                    disabled: allOption && option !== allOption && selectedOptions.includes(allOption),
                });
            } else {
                optionsToShow = optionsToShow.concat({ label: optionLabel, value: option });
            }
        });

        return optionsToShow;
    };

    render() {
        const {
            field,
            form: { errors },
            hideLabel,
            hidePlaceholder,
            idForm,
            searchable,
            className,
        } = this.props;
        const { isFocused, selectedOptions } = this.state;
        const hasError = errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--select", {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                {!hideLabel && (
                    <FieldLabel
                        idField={`react-select-${idForm}.${field.name}-input`}
                        labelKey={`${idForm}.${field.name}.label`}
                    />
                )}

                <div className="input-group">
                    <Select
                        id={`${idForm}.${field.name}`}
                        multi
                        className={className}
                        onChange={this.handleChange}
                        options={this.options()}
                        placeholder={hidePlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                        searchable={searchable}
                        value={selectedOptions}
                        clearable={false}
                    />
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default MultiSelectField;
