import * as products from "middleware/products";
import { goBack, replace } from "react-router-redux";
import { actions as creditCardsActions } from "reducers/creditCard";
import { actions as desktopActions } from "reducers/desktop";
import { actions as notificationActions } from "reducers/notification";
import { actions as productActions, types } from "reducers/products";
import { actions as settingsActions } from "reducers/settings";
import { actions as WidgetActions } from "reducers/widgets";
import { call, put, takeLatest, fork } from "redux-saga/effects";
import * as i18n from "util/i18n";
import { adjustIdFieldErrors } from "util/form.js";
import { readCreditCardList, readCreditLineList } from "./login";

const sagas = [
    takeLatest(types.CHANGE_PRODUCT_ALIAS, changeProductAlias),
    takeLatest(types.TOGGLE_FAVORITE_REQUEST, toggleFavoriteRequest),
    takeLatest(types.STATE_DOWNLOAD_PRE_REQUEST, getMetadataDownloadState),
    takeLatest(types.STATE_DOWNLOAD_REQUEST, downloadStateProduct),
    takeLatest(types.EXCHANGE_POINTS_REQUEST, exchangePointsProductCash),
    takeLatest(types.EXCHANGE_POINTS_MILES_REQUEST, exchangePointsProductMiles),
    takeLatest(types.SYNC_ENVIROMENT_PRODUCT_REQUEST, syncEnvironmentProduct),
    takeLatest(types.SHOW_EXCHANGE_POINTS_FORM, loadRedeemData),
    takeLatest(types.EXCHANGE_POINTS_VALIDATE_TRAVEL_NUMBER_REQUEST, exchangePointsValidTravelNumber),
];

export default sagas;

function* changeProductAlias({ alias: changedAlias, idProduct, backAfterCall, formikBag }) {
    const response = yield call(products.changeProductAlias, changedAlias, idProduct);
    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(response.data.data.alias, "error", [
                "account/details",
                "creditCardDetails",
                "loans/details",
                "deposit/details",
            ]),
        );
    } else if (response.status === 200) {
        const { alias } = response.data.data;
        yield put({ type: types.CHANGE_PRODUCT_ALIAS_SUCCESS, alias, idProduct });
    }
    if (backAfterCall) {
        yield put(goBack());
    }
    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* toggleFavoriteRequest({ idProduct, productType, favorite, onFinish, fromList = false }) {
    const response = yield call(products.toggleFavorite, idProduct, productType, favorite);
    if (response.type && response.status === 200 && response.type !== "W") {
        const { totalFavorites, completeFavorites } = response.data.data;
        yield put({
            type: types.TOGGLE_FAVORITE_SUCCESS,
            idProduct,
            favorite,
            totalFavorites,
            fromList,
            completeFavorites,
        });
    } else {
        yield put({ type: types.TOGGLE_FAVORITE_FAILURE });
    }

    if (onFinish) {
        onFinish();
    }
}

function* getMetadataDownloadState() {
    const response = yield call(products.getMetadataDownloadState);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.STATE_DOWNLOAD_PRE_FAILURE });
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
            yield put({ type: types.STATE_DOWNLOAD_PRE_FAILURE });
        } else {
            yield put({
                type: types.STATE_DOWNLOAD_PRE_SUCCESS,
                payload: response.data?.data || {},
            });
        }
    }
}

function* downloadStateProduct({ month, year, productId, formikBag }) {
    const response = yield call(products.downloadStateProduct, month, year, productId);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
        } else {
            const responseState = response.data?.data;

            yield put({
                type: types.STATE_DOWNLOAD_SUCCESS,
                payload: responseState.stateProductList,
            });
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* exchangePointsProductCash({ productId, formikBag, points, totalPoints, moneyValue }) {
    const response = yield call(products.exchangePointsProductCash, productId, points, moneyValue, totalPoints);
    if (!response) {
        yield put(productActions.hideExchangePointForm());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditCardDetails",
                "login",
            ]),
        );
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(productActions.hideExchangePointForm());
            yield put(
                notificationActions.showNotification(i18n.get("forms.product.exchange.points.sent.error"), "error", [
                    "creditCardDetails",
                    "desktop",
                ]),
            );
            yield put(replace("/desktop"));
        } else {
            yield put({
                type: types.EXCHANGE_POINTS_SUCCESS,
                changedPoints: totalPoints - points,
            });
            yield put(productActions.hideExchangePointForm());

            yield put(
                notificationActions.showNotification(i18n.get("forms.product.exchange.points.sent.succes"), "success", [
                    "creditCardDetails",
                ]),
            );
            yield put(creditCardsActions.invalidateCache());

            yield put(
                creditCardsActions.detailRequest(
                    productId,
                    {
                        dateFrom: null,
                        dateTo: null,
                    },
                    undefined,
                    undefined,
                    false,
                ),
            );
            yield call(readCreditCardList, productId);
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

export function* syncEnvironmentProduct({ shouldGetLastSynchronization, onFinish, showSnackbar = true }) {
    const response = yield call(products.SyncEnvironmentProducts, shouldGetLastSynchronization);
    const { data } = response;
    if (data?.code === "COR000I") {
        if (!shouldGetLastSynchronization) {
            yield fork(readCreditLineList);
        }
        yield put({
            type: types.SYNC_ENVIROMENT_PRODUCT_SUCCESS,
        });
        yield put(settingsActions.updatePermissionLocal(data?.data?.permissions));
        if (!shouldGetLastSynchronization) {
            yield put(WidgetActions.listRequest("creditCards", false));
            if (showSnackbar) {
                yield put(desktopActions.loadLayoutRequest());
                yield put(
                    notificationActions.showNotification(i18n.get("desktop.synchronization.success"), "success", [
                        "desktop",
                    ]),
                );
            }
        }
        if (response.data?.data?.becameAdult) {
            yield put({
                type: types.OPEN_ADULTHOOD_MODAL,
                show: true,
            });
        }
    } else {
        yield put({
            type: types.SYNC_ENVIROMENT_PRODUCT_FAILURE,
        });
        if (data?.code === "BAK001W" && !shouldGetLastSynchronization && showSnackbar) {
            yield put(
                notificationActions.showNotification(i18n.get("desktop.synchronization.error"), "error", ["desktop"]),
            );
        }
    }
    if (onFinish) {
        onFinish();
    }
}

function* loadRedeemData({ productId }) {
    const response = yield call(products.preLoadRedeemData, productId);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "desktop",
                "login",
                "creditcards",
                "creditCardDetails",
            ]),
        );
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
        } else {
            const { redeemOptions, enabledPoints, milesOptions } = response.data?.data;

            yield put({
                type: types.LOAD_PREDATA_REDEEM_SUCCESS,
                payload: {
                    redeemOptions,
                    enabledPoints,
                    milesOptions,
                },
            });
        }
    }
}

function* exchangePointsProductMiles({ productId, formikBag, points, miles, travelNumber, totalPoints }) {
    try {
        const response = yield call(products.exchangePointsProductMiles, productId, points, miles, travelNumber);
        if (!response) {
            yield put(productActions.hideExchangePointForm());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardDetails",
                    "desktop",
                ]),
            );
        } else {
            const { type } = response;
            if (type === "W") {
                if (response?.data?.code === "COR020W") {
                    formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                } else {
                    yield put(productActions.hideExchangePointForm());
                    if (miles === "Connectmiles") {
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("forms.product.exchange.points.sent.error"),
                                "error",
                                ["creditCardDetails", "desktop"],
                            ),
                        );
                    } else {
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("forms.product.exchange.points.sent.error.lifemiles"),
                                "error",
                                ["creditCardDetails", "desktop"],
                            ),
                        );
                    }
                }
            } else {
                yield put({
                    type: types.EXCHANGE_POINTS_SUCCESS,
                    changedPoints: totalPoints - points,
                });
                yield put(productActions.hideExchangePointForm());
                if (miles === "Connectmiles") {
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("forms.product.exchange.points.sent.succes"),
                            "success",
                            ["creditCardDetails"],
                        ),
                    );
                } else {
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("forms.product.exchange.points.sent.miles.succes"),
                            "success",
                            ["creditCardDetails"],
                        ),
                    );
                }
                yield put(creditCardsActions.invalidateCache());
                yield put(
                    creditCardsActions.detailRequest(
                        productId,
                        {
                            dateFrom: null,
                            dateTo: null,
                        },
                        undefined,
                        undefined,
                        false,
                    ),
                );
                yield call(readCreditCardList, productId);
            }
        }
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    } catch (error) {
        yield put(productActions.hideExchangePointForm());
        if (miles === "Connectmiles") {
            yield put(
                notificationActions.showNotification(i18n.get("forms.product.exchange.points.sent.error"), "error", [
                    "creditCardDetails",
                    "desktop",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("forms.product.exchange.points.sent.error.lifemiles"),
                    "error",
                    ["creditCardDetails", "desktop"],
                ),
            );
        }
    }
}

function* exchangePointsValidTravelNumber({ values, reloadValuesForm }) {
    const response = yield call(products.exchangePointsValidTravelNumber);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditCardDetails",
                "login",
            ]),
        );
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("forms.product.exchange.points.sent.error"), "error", [
                    "creditCardDetails",
                    "desktop",
                ]),
            );
            yield put(replace("/desktop"));
        } else {
            const { data } = response?.data;
            yield put({
                type: types.EXCHANGE_POINTS_VALIDATE_TRAVEL_NUMBER_SUCCESS,
                travelNumber: data?.travelNumber,
            });
            if (reloadValuesForm) {
                reloadValuesForm({ ...values, travelNumber: data?.travelNumber });
            }
        }
    }
}
