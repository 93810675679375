import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import HeaderEnrollment from "pages/enrollment/_components/HeaderEnrollment";
import classNames from "classnames";

import Text from "pages/_components/Text";
import { arrayOf, bool, func, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";

class Summary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creating: false,
        };
    }

    cancel = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment"));
    };

    onFinish = (ok) => {
        this.setState({
            creating: false,
        });

        if (ok) {
            const { dispatch } = this.props;
            dispatch(push("/enrollment/finishAssociate"));
        }
    };

    create = () => {
        this.setState({
            creating: true,
        });

        const { dispatch } = this.props;

        dispatch(enrollmentActions.finishAssociate(this.onFinish));
    };

    summaryCard = () => {
        const {
            userFullName,
            documentCountry,
            documentType,
            documentNumber,
            environments,
            corporate,
            isDesktop,
        } = this.props;
        return (
            <Box
                background="heading-color"
                borderRadius="xxl"
                className={classNames("py-7 px-5", { "mt-12": !isDesktop })}
                fullWidth>
                <Box display="flex" column>
                    <Box className="mb-2">
                        <Text labelKey="enrollment.summary.data.name" color="inverse" />
                        <Text color="inverse">: {userFullName} </Text>
                    </Box>
                    <Box className="mb-2">
                        <Text labelKey="enrollment.summary.data.document" color="inverse" />
                        <Text color="inverse">
                            : {documentNumber} ({documentType}, {documentCountry}){" "}
                        </Text>
                    </Box>
                    <Box display="flex">
                        <Box fullHeight>
                            <Text
                                labelKey={
                                    corporate
                                        ? "enrollment.summary.data.corporateEnvironment"
                                        : "enrollment.summary.data.retailEnvironment"
                                }
                                color="inverse"
                                noWrap
                            />
                            <Text color="inverse" className="mr-2" noWrap>
                                :
                            </Text>
                        </Box>
                        <Box display="flex" fullHeight column>
                            <ul>
                                {environments?.map((environment) => (
                                    <li>
                                        <Text color="inverse">{environment}</Text>
                                    </li>
                                ))}
                            </ul>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    render() {
        const { isDesktop } = this.props;
        const { creating } = this.state;

        return (
            <>
                <HeaderEnrollment title="enrollment.summary.title" subtitle="enrollment.summary.subtitle" />
                <Box className="mb-5 mb-md-8">
                    <Row gapY="8" className="height-auto">
                        <Col xs={12} md={8} mdOffset={2}>
                            {isDesktop ? (
                                <Box background="white" borderRadius="xxl" className="white-boxed">
                                    {this.summaryCard()}
                                </Box>
                            ) : (
                                this.summaryCard()
                            )}
                        </Col>
                    </Row>
                </Box>
                <Box {...(!isDesktop && { className: "mt-auto" })}>
                    <Row gapY={isDesktop ? "8" : "3"}>
                        <Col xs={12} md={3} mdOffset={3}>
                            <Button
                                label="global.cancel"
                                type="button"
                                className="btn-outline"
                                onClick={this.cancel}
                                block
                            />
                        </Col>
                        <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                            <Button
                                bsStyle="primary"
                                label="global.confirm"
                                loading={creating}
                                type="button"
                                onClick={this.create}
                                block
                            />
                        </Col>
                    </Row>
                </Box>
            </>
        );
    }
}

Summary.propTypes = {
    dispatch: func.isRequired,
    corporate: bool.isRequired,
    userFullName: string.isRequired,
    documentCountry: string.isRequired,
    documentType: string.isRequired,
    documentNumber: string.isRequired,
    environments: arrayOf(string).isRequired,
    isDesktop: bool.isRequired,
};

const mapStateToProps = (state) => ({
    userFullName: enrollmentSelectors.getUserFullName(state),
    documentCountry: enrollmentSelectors.getDocumentCountry(state),
    documentType: enrollmentSelectors.getDocumentType(state),
    documentNumber: enrollmentSelectors.getDocumentNumber(state),
    environments: enrollmentSelectors.getEnvironments(state),
    corporate: enrollmentSelectors.isCorporate(state),
});

export default connect(mapStateToProps)(Summary);
