import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, string, func, bool, arrayOf } from "prop-types";
import { selectors as restrictionSelectors, actions as restrictionActions } from "reducers/administration/restrictions";
import { selectors as sessionSelectors } from "reducers/session";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import Link from "react-router-dom/Link";

const RESTRICTION_TYPE_CALENDAR = "Calendar";
const RESTRICTION_TYPE_IP = "IP";

class RestrictionsUserProfile extends Component {
    static propTypes = {
        restrictions: arrayOf(shape({})),
        activeEnvironment: shape({}).isRequired,
        idUser: string.isRequired,
        dispatch: func.isRequired,
        calendarEnabled: bool.isRequired,
        iPEnabled: bool.isRequired,
    };

    static defaultProps = {
        restrictions: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(restrictionActions.getRestrictionsAvailability());
    }

    renderNotRestrictionsApplied = () => <I18n id="administration.restrictions.noRestrictions" />;

    getCalendarSection = (data) => {
        if (data && data.length > 0) {
            const listDays = data[0].listDays.map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase());
            if (data[0].perpetual) {
                return <I18n id="administration.restrictions.perpetual.detail" />;
            }

            return (
                data.length > 0 && (
                    <div>
                        <div>
                            <I18n id="administration.restrictions.timeZone" /> {data[0].timeZone}
                        </div>
                        <div>{listDays.join(", ")}</div>
                        <div>
                            <I18n id="administration.restrictions.from" />
                            {` ${data[0].startTime} `}
                            <I18n id="administration.restrictions.to" />
                            {` ${data[0].endTime} `}
                            <I18n id="administration.restrictions.hrs" />
                        </div>
                    </div>
                )
            );
        }
        return <></>;
    };

    getIPSection = (ipsData) =>
        ipsData.length > 0 && (
            <div>
                <div>
                    <I18n id="administration.restrictions.IP" /> {ipsData.map((i) => i.ip).join(", ")}
                </div>
            </div>
        );

    renderEnvironmentSection = (restrictions) => {
        const { activeEnvironment, calendarEnabled, iPEnabled } = this.props;
        if (
            !restrictions ||
            restrictions.length === 0 ||
            restrictions.filter((i) => i.idEnvironment && !i.idUser).length === 0 ||
            (!calendarEnabled && !iPEnabled)
        ) {
            return this.renderNotRestrictionsApplied();
        }

        const calendarRestrictions = restrictions.filter(
            (i) => i.idEnvironment && !i.idUser && i.type === RESTRICTION_TYPE_CALENDAR,
        );
        const ipsRestrictions = restrictions.filter(
            (i) => i.idEnvironment && !i.idUser && i.type === RESTRICTION_TYPE_IP,
        );

        const thereIsCalendarRestrByUser =
            restrictions.filter((i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_CALENDAR).length > 0;
        const thereIsIPRestrByUser =
            restrictions.filter((i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_IP).length > 0;

        const calendarOverwritten = calendarRestrictions.length > 0 && thereIsCalendarRestrByUser;
        const ipsOverwritten = ipsRestrictions.length > 0 && thereIsIPRestrByUser;
        return (
            <>
                <div
                    className={`environment-IconName ${
                        calendarOverwritten || ipsOverwritten ? "section-disabled" : ""
                    }`}>
                    <div className="environment-Icon">
                        <Image src={`images/${activeEnvironment.type}.svg`} className="svg-icon" />
                    </div>
                    <p>{activeEnvironment.name}</p>
                </div>
                <div className="section-disabled">
                    {calendarOverwritten && this.getCalendarSection(calendarRestrictions)}
                    {ipsOverwritten && this.getIPSection(ipsRestrictions)}
                </div>
                {(calendarOverwritten || ipsOverwritten) && (
                    <div className="restrictions-warning">
                        <Image src="images/warningIcon.svg" className="svg-icon" />{" "}
                        <span>
                            <I18n id="administration.restrictions.overwritten" />
                        </span>
                    </div>
                )}
                <div className="section-enabled">
                    {!calendarOverwritten && this.getCalendarSection(calendarRestrictions)}
                    {!ipsOverwritten && this.getIPSection(ipsRestrictions)}
                </div>
            </>
        );
    };

    renderUserSection = (restrictions) => {
        const { calendarEnabled, iPEnabled } = this.props;
        if (
            !restrictions ||
            restrictions.length === 0 ||
            restrictions.filter((i) => i.idEnvironment && i.idUser).length === 0 ||
            (!calendarEnabled && !iPEnabled)
        ) {
            return this.renderNotRestrictionsApplied();
        }

        const calendarRestrictions = restrictions.filter(
            (i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_CALENDAR,
        );
        const ipsRestrictions = restrictions.filter(
            (i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_IP,
        );

        return (
            <>
                {calendarRestrictions.length > 0 && this.getCalendarSection(calendarRestrictions)}
                {ipsRestrictions.length > 0 && this.getIPSection(ipsRestrictions)}
            </>
        );
    };

    render() {
        const { restrictions, idUser, calendarEnabled, iPEnabled } = this.props;
        const thereIsCalendarOrIpByUser =
            restrictions.filter(
                (i) => i.idUser && (i.type === RESTRICTION_TYPE_CALENDAR || i.type === RESTRICTION_TYPE_IP),
            ).length > 0;

        return (
            <>
                <div className="col-User">
                    <p className="data-label">
                        <I18n id="administration.restrictions.environment" />
                    </p>
                </div>
                {this.renderEnvironmentSection(restrictions)}
                <br />

                <div className="col-User">
                    <p className="data-label">
                        <I18n id="administration.restrictions.user" />
                    </p>
                    {(iPEnabled || calendarEnabled) && (
                        <>
                            {thereIsCalendarOrIpByUser && (
                                <Link
                                    className="btn btn-quiet"
                                    to={`/administration/restrictions/delete/${idUser || ""}`}>
                                    <I18n id="administration.restrictions.remove" />
                                </Link>
                            )}
                            <Link className="btn btn-quiet" to={`/administration/restrictions/${idUser || ""}`}>
                                <I18n
                                    id={`administration.restrictions.${
                                        thereIsCalendarOrIpByUser ? "edit" : "createNew"
                                    }`}
                                />
                            </Link>
                        </>
                    )}
                </div>
                {this.renderUserSection(restrictions)}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    restrictions: restrictionSelectors.getRestrictions(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    calendarEnabled: restrictionSelectors.getCalendarEnabled(state),
    iPEnabled: restrictionSelectors.getIPEnabled(state),
});

export default connect(mapStateToProps)(RestrictionsUserProfile);
