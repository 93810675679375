import { createReducer, makeActionCreator } from "util/redux";

export const authenticateHandlerTypes = {
    GET_TOKEN_STATUS_REQUEST: "authenticateHandler/GET_TOKEN_STATUS_REQUEST",
    GET_TOKEN_STATUS_SUCCESS: "authenticateHandler/GET_TOKEN_STATUS_SUCCESS",
    GET_PASSWORD_STATUS_REQUEST: "authenticateHandler/GET_PASSWORD_STATUS_REQUEST",
    GET_PASSWORD_STATUS_SUCCESS: "authenticateHandler/GET_PASSWORD_STATUS_SUCCESS",
    DESACTIVATE_TOKEN_REQUEST: "authenticateHandler/DESACTIVATE_TOKEN_REQUEST",
    DESACTIVATE_TOKEN_SUCCESS: "authenticateHandler/DESACTIVATE_TOKEN_SUCCESS",
    DESACTIVATE_TOKEN_FAILED: "authenticateHandler/DESACTIVATE_TOKEN_FAILED",
    GET_TOKEN_INFO_REQUEST: "authenticateHandler/GET_TOKEN_INFO_REQUEST",
    GET_TOKEN_INFO_SUCCESS: "authenticateHandler/GET_TOKEN_INFO_SUCCESS",
    SHOW_DESACTIVATION_TOKEN_MODAL: "authenticateHandler/SHOW_DESACTIVATION_TOKEN_MODAL",
    HIDE_DESACTIVATION_TOKEN_MODAL: "authenticateHandler/HIDE_DESACTIVATION_TOKEN_MODAL",
    UNLOCK_AUTHENTICATOR_REQUEST: "authenticateHandler/UNLOCK_AUTHENTICATOR_REQUEST",
    UNLOCK_AUTHENTICATOR_SUCCESS: "authenticateHandler/UNLOCK_AUTHENTICATOR_SUCCESS",
    DELETE_TOKEN_PIN_REQUEST: "authenticateHandler/DELETE_TOKEN_PIN_REQUEST",
    DELETE_TOKEN_PIN_SUCCES: "authenticateHandler/DELETE_TOKEN_PIN_SUCCES",
    VALIDATE_TOKEN_PIN_REQUEST: "authenticateHandler/VALIDATE_TOKEN_PIN_REQUEST",
    VALIDATE_TOKEN_PIN_SUCCES: "authenticateHandler/VALIDATE_TOKEN_PIN_SUCCES",
    SHOW_VALIDATION_PIN_MODAL: "authenticateHandler/SHOW_VALIDATION_PIN_MODAL",
    HIDE_VALIDATION_PIN_MODAL: "authenticateHandler/HIDE_VALIDATION_PIN_MODAL",
    PRE_FORM_READY: "authenticateHandler/PRE_FORM_READY",
    GET_TOKEN_INFO_STATUS_REQUEST: "authenticateHandler/GET_TOKEN_INFO_STATUS_REQUEST",
    GET_TOKEN_INFO_STATUS_SUCCESS: "authenticateHandler/GET_TOKEN_INFO_STATUS_SUCCESS",
    GET_TOKEN_INFO_STATUS_FAILURE: "authenticateHandler/GET_TOKEN_INFO_STATUS_FAILURE",
};

export const INITIAL_STATE = {
    fetching: false,
    unlockingAuthenticator: false,
    tokenStatus: "",
    isDesactivating: false,
    showDesactivationTokenModal: false,
    authenticatorType: "",
    authenticatorStatus: "",
    redirect: "",
    isFromMigrateScreen: false,
    pinCode: null,
    pinTries: null,
    isPinCodeValid: false,
    showPinValidationModal: false,
    isPinProtectionValidated: false,
    isPinProtectionValidatedCount: 0,
    fetchingTokenValidateStatus: false,
    tokenValidateStatus: undefined,
    serialToken: "",
};

export default createReducer(INITIAL_STATE, {
    [authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [authenticateHandlerTypes.GET_TOKEN_STATUS_SUCCESS]: (state, action) => ({
        ...state,
        tokenStatus: action.tokenStatus,
        pinCode: action.pinCode,
        pinTries: action.pinTries,
        serialToken: action.serialToken,
        fetching: false,
    }),
    [authenticateHandlerTypes.GET_TOKEN_INFO_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [authenticateHandlerTypes.GET_TOKEN_INFO_SUCCESS]: (state, action) => ({
        ...state,
        pinCode: action.pinCode,
        pinTries: action.pinTries,
        fetching: false,
    }),
    [authenticateHandlerTypes.GET_PASSWORD_STATUS_REQUEST]: (state, authenticatorType) => ({
        ...state,
        authenticatorType,
    }),
    [authenticateHandlerTypes.GET_PASSWORD_STATUS_SUCCESS]: (state, action) => ({
        ...state,
        authenticatorStatus: action.authenticatorStatus,
    }),
    [authenticateHandlerTypes.DESACTIVATE_TOKEN_REQUEST]: (state, redirect, isFromMigrateScreen) => ({
        ...state,
        isDesactivating: true,
        redirect,
        isFromMigrateScreen,
    }),
    [authenticateHandlerTypes.DESACTIVATE_TOKEN_SUCCESS]: (state) => ({
        ...state,
        isDesactivating: false,
        showDesactivationTokenModal: false,
    }),
    [authenticateHandlerTypes.DESACTIVATE_TOKEN_FAILED]: (state) => ({
        ...state,
        isDesactivating: false,
        showDesactivationTokenModal: false,
    }),
    [authenticateHandlerTypes.SHOW_DESACTIVATION_TOKEN_MODAL]: (state) => ({
        ...state,
        showDesactivationTokenModal: true,
    }),
    [authenticateHandlerTypes.HIDE_DESACTIVATION_TOKEN_MODAL]: (state) => ({
        ...state,
        showDesactivationTokenModal: false,
    }),
    [authenticateHandlerTypes.SHOW_VALIDATION_PIN_MODAL]: (state) => ({
        ...state,
        showPinValidationModal: true,
    }),
    [authenticateHandlerTypes.HIDE_VALIDATION_PIN_MODAL]: (state) => ({
        ...state,
        showPinValidationModal: false,
    }),
    [authenticateHandlerTypes.CLEAN]: () => ({
        INITIAL_STATE,
    }),
    [authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_REQUEST]: (state, authenticatorType) => ({
        ...state,
        unlockingAuthenticator: true,
        authenticatorType,
    }),
    [authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_SUCCESS]: (state) => ({
        ...state,
        unlockingAuthenticator: false,
    }),
    [authenticateHandlerTypes.DELETE_TOKEN_PIN_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [authenticateHandlerTypes.DELETE_TOKEN_PIN_SUCCES]: (state) => ({
        ...state,
        fetching: false,
        pinCode: false,
        pinTries: 0,
    }),
    [authenticateHandlerTypes.PRE_FORM_READY]: () => ({
        fetching: false,
    }),
    [authenticateHandlerTypes.VALIDATE_TOKEN_PIN_REQUEST]: (state, pinCode) => ({
        ...state,
        pinCode,
        fetching: false,
    }),
    [authenticateHandlerTypes.VALIDATE_TOKEN_PIN_SUCCES]: (state, action) => ({
        ...state,
        fetching: false,
        isPinProtectionValidated: !action.isPinCodeValid,
        pinTries: action.pinTries,
        showPinValidationModal: false,
        isPinProtectionValidatedCount: state.isPinProtectionValidatedCount + 1,
    }),
    [authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_REQUEST]: (state) => ({
        ...state,
        fetchingTokenValidateStatus: true,
    }),
    [authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingTokenValidateStatus: false,
        tokenValidateStatus: action.tokenValidateStatus,
    }),
    [authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_FAILURE]: (state) => ({
        ...state,
        fetchingTokenValidateStatus: false,
    }),
});

export const actions = {
    getTokenStatusRequest: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST),
    getTokenStatusSuccess: makeActionCreator(
        authenticateHandlerTypes.GET_TOKEN_STATUS_SUCCESS,
        "tokenStatus",
        "pinCode",
        "pinTries",
        "serialToken",
    ),
    getTokenInfoRequest: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_INFO_REQUEST),
    getTokenInfoSuccess: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_INFO_SUCCESS, "pinCode", "pinTries"),
    validateTokenPinRequest: makeActionCreator(authenticateHandlerTypes.VALIDATE_TOKEN_PIN_REQUEST, "pinCode"),
    validateTokenPinSucces: makeActionCreator(
        authenticateHandlerTypes.VALIDATE_TOKEN_PIN_SUCCES,
        "isPinCodeValid",
        "pinTries",
    ),
    deleteTokenPinRequest: makeActionCreator(authenticateHandlerTypes.DELETE_TOKEN_PIN_REQUEST),
    deleteTokenPinSucces: makeActionCreator(authenticateHandlerTypes.DELETE_TOKEN_PIN_SUCCES),
    preFormReady: makeActionCreator(authenticateHandlerTypes.PRE_FORM_READY),
    getPasswordStatusRequest: makeActionCreator(
        authenticateHandlerTypes.GET_PASSWORD_STATUS_REQUEST,
        "authenticatorType",
    ),
    getPasswordStatusSuccess: makeActionCreator(
        authenticateHandlerTypes.GET_PASSWORD_STATUS_SUCCESS,
        "authenticatorStatus",
    ),
    desactivateSoftTokenRequest: makeActionCreator(
        authenticateHandlerTypes.DESACTIVATE_TOKEN_REQUEST,
        "redirect",
        "isFromMigrateScreen",
    ),
    showDesactivationTokenModal: makeActionCreator(authenticateHandlerTypes.SHOW_DESACTIVATION_TOKEN_MODAL),
    hideDesactivationTokenModal: makeActionCreator(authenticateHandlerTypes.HIDE_DESACTIVATION_TOKEN_MODAL),
    showValidationPinModal: makeActionCreator(authenticateHandlerTypes.SHOW_VALIDATION_PIN_MODAL),
    hideValidationPinModal: makeActionCreator(authenticateHandlerTypes.HIDE_VALIDATION_PIN_MODAL),
    unlockAuthenticator: makeActionCreator(authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_REQUEST, "authenticatorType"),
    getTokenInfoStatusRequest: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_REQUEST, "onFinish"),
    getTokenInfoStatusSuccess: makeActionCreator(
        authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_SUCCESS,
        "tokenValidateStatus",
    ),
    getTokenInfoStatusFailure: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_FAILURE),
};

export const selectors = {
    getTokenStatus: ({ authenticateHandler }) => authenticateHandler.tokenStatus,
    getPinCode: ({ authenticateHandler }) => authenticateHandler.pinCode,
    getPinTries: ({ authenticateHandler }) => authenticateHandler.pinTries,
    isPinCodeValid: ({ authenticateHandler }) => authenticateHandler.isPinCodeValid,
    isPinProtectionValidated: ({ authenticateHandler }) => authenticateHandler.isPinProtectionValidated,
    isPinProtectionValidatedCount: ({ authenticateHandler }) => authenticateHandler.isPinProtectionValidatedCount,
    getAuthenticatorStatus: ({ authenticateHandler }) => authenticateHandler.authenticatorStatus,
    isFetching: ({ authenticateHandler }) => authenticateHandler.fetching || false,
    isDesactivating: ({ authenticateHandler }) => authenticateHandler.isDesactivating,
    showDesactivationTokenModal: ({ authenticateHandler }) => authenticateHandler.showDesactivationTokenModal,
    showPinValidationModal: ({ authenticateHandler }) => authenticateHandler.showPinValidationModal,
    isUnlockingAuthenticator: ({ authenticateHandler }) => authenticateHandler.unlockingAuthenticator,
    getTokenValidateStatus: ({ authenticateHandler }) => authenticateHandler?.tokenValidateStatus,
    isFetchingTokenValidateStatus: ({ authenticateHandler }) =>
        authenticateHandler.fetchingTokenValidateStatus || false,
    getSerialToken: ({ authenticateHandler }) => authenticateHandler.serialToken,
};
