import React, { useMemo } from "react";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";

const TRANSACTION_PROCESSING_STATUS_LIST = ["PROCESSING"];
const TRANSACTION_ACCEPTED_STATUS = "ACCEPTED";
const useAsyncTicketProcessing = (transaction) => {
    const tooltipProcessingComponent = useMemo(() => {
        if (
            transaction?.idTransactionStatus &&
            TRANSACTION_PROCESSING_STATUS_LIST.includes(transaction?.idTransactionStatus) &&
            (!transaction?.data?.backendReference || transaction.data.backendReference === "")
        ) {
            return (
                <Box className="mr-auto" display="flex" alignY="center">
                    <Image wrapperClassName="fit-content" className="mr-3 mt-2" src="images/icons/tooltip.svg" />
                    <Text size="7" className="pr-8 my-3 my-md-0" labelKey="transaction.async.processing.tooltip.help" />
                </Box>
            );
        }
        if (transaction.idTransactionStatus === TRANSACTION_ACCEPTED_STATUS) {
            return (
                <Box className="mt-6 mx-7">
                    <Box display="flex" alignY="center" alignX="center">
                        <Image
                            wrapperClassName="fit-content"
                            className="mr-3 mt-2"
                            src="images/icons/warningBlack.svg"
                        />
                        <Text
                            bold
                            color="text-boton"
                            size="5"
                            className="pl-8 my-3 my-md-0"
                            labelKey="transaction.accepted.tooltip.help"
                        />
                    </Box>
                </Box>
            );
        }

        return <div />;
    }, [transaction]);

    return [tooltipProcessingComponent];
};

export default useAsyncTicketProcessing;
