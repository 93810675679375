import classNames from "classnames";
import Box from "pages/_components/Box";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import AssistantAlert from "pages/login/_components/AssistantAlert";
import Logo from "pages/login/_components/Logo";
import Step1Content from "pages/login/_components/Step1Content";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { selectors as assistantSelectors } from "reducers/assistant";
import { selectors as loginSelectors } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import { selectors as revelockSelectors, actions as revelockActions } from "reducers/revelock/revelock.reducer";

const LoginStep1 = (props) => {
    const {
        dispatch,
        hasActiveSession,
        showSuspiciousActivityModal,
        isDesktop,
        isMobile,
        isMobileNative,
        isSubmiting,
    } = props;
    const [showSuspiciousModal, setShowSuspiciousModal] = useState();

    useEffect(() => {
        if (hasActiveSession) {
            dispatch(sessionActions.logout()); // End any previous session open session
        }
    }, []);

    useEffect(() => {
        if (showSuspiciousActivityModal && showSuspiciousActivityModal > 0) {
            setShowSuspiciousModal(true);
        }
    }, [showSuspiciousActivityModal]);

    const handleBack = () => {
        dispatch(routerActions.push("/"));
        dispatch(notificationActions.removeNotification());
    };

    const mobileView = () => (
        <>
            <Step1Content
                className={classNames("app-default-main", {
                    "pointer-events-none": isSubmiting,
                })}
                {...props}
            />
        </>
    );

    const desktopView = () => {
        const { isFromAmazon, isFromGoogle, isFromMessenger, isFromWhatsapp } = props;

        return (
            <>
                {(isFromAmazon || isFromGoogle || isFromMessenger || isFromWhatsapp) && <AssistantAlert />}

                <Step1Content
                    className={classNames({
                        "pointer-events-none": isSubmiting,
                    })}
                    {...props}
                />
            </>
        );
    };

    return (
        <>
            {isDesktop ? (
                <>
                    <BoxErrorNotification isMobile={isMobile} scopeToShow="externalLayout" />
                    <Head logo={<Logo isDesktop={isDesktop} className="svg-image logo mt-10 mb-7" />} />
                    <MainContainer
                        shouldHideOnLoad
                        className="pt-0"
                        {...(!isDesktop && { alignX: "flex-start", columnReverse: true })}>
                        {desktopView()}
                    </MainContainer>
                </>
            ) : (
                <>
                    <Box display="flex" column alignX="between" className="scroll" fullHeight>
                        <Head
                            position="relative"
                            {...(isMobileNative && { onBack: handleBack })}
                            logo={<Logo isDesktop={isDesktop} className="svg-image logo" />}
                        />
                        {mobileView()}
                    </Box>
                </>
            )}
            <ConfirmDialog
                showDialog={showSuspiciousModal}
                title={undefined}
                description={i18n.get("revelock.suspicious.activity.text") || ""}
                onConfirm={() => {
                    setShowSuspiciousModal(false);
                    dispatch(revelockActions.clearSuspiciousActivityModal());
                }}
                confirmLabel="global.accept"
                textClassName="mx-md-5"
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    rememberedUser: loginSelectors.getRememberedUser(state),
    activeRegion: loginSelectors.getRegion(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    isSubmiting: loginSelectors.getSubmiting(state),
    showSuspiciousActivityModal: revelockSelectors.getShowSuspiciousActivityModal(state),
});

LoginStep1.propTypes = {
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    isMobileNative: bool.isRequired,
    dispatch: func.isRequired,
    isFromAmazon: bool,
    isFromGoogle: bool,
    isFromMessenger: bool,
    isFromWhatsapp: bool,
    rememberedUser: shape({}),
    activeRegion: string,
    hasActiveSession: bool.isRequired,
    isSubmiting: bool.isRequired,
    showSuspiciousActivityModal: bool,
    location: shape({}).isRequired,
};

LoginStep1.defaultProps = {
    isFromAmazon: false,
    isFromGoogle: false,
    isFromMessenger: false,
    isFromWhatsapp: false,
    rememberedUser: null,
    showSuspiciousActivityModal: null,
    activeRegion: "",
};

export default connect(mapStateToProps)(LoginStep1);
