import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import HiddenFilters from "pages/transactions/_components/HiddenFilters";
import List from "pages/transactions/_components/List";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import ProductFilters from "pages/_components/ProductFilters";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import TransactionFilterProvider, { PDF_CONTENT_TYPE, XLS_CONTENT_TYPE } from "providers/TransactionFilterProvider";
import * as configUtils from "util/config";

const LAST_TRANSACTIONS = "last";

class TransactionsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        isFirstFetching: bool.isRequired,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        disclaimerDate: string.isRequired,
    };

    state = {
        defaultFilters: null,
        ascending: false,
        filtersValue: null,
    };

    componentDidMount = () => {
        const { dispatch } = this.props;

        const dateFrom = new Date();
        dateFrom.setYear(dateFrom.getFullYear() - 10);
        const dateTo = new Date();
        const defaultFilters = {
            dateFrom,
            dateTo,
            pageNumber: 1,
            filter: LAST_TRANSACTIONS,
        };

        this.setState({ defaultFilters });
        dispatch(transactionsActions.loadListRequest(defaultFilters, false, false, true, true));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(replace("/desktop"));
    };

    handleOrder = () => {
        const { ascending } = this.state;
        this.setState({ ascending: !ascending });
    };

    resetHandleOrder = () => {
        this.setState({ ascending: false });
    };

    render() {
        const { fetching, isDesktop, dispatch, isFirstFetching, activeEnvironment, disclaimerDate } = this.props;
        const { defaultFilters, ascending, filtersValue } = this.state;
        const isCorporateGroup = activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE;
        const documents = [
            {
                label: "accounts.pdfFile",
                onClick: (callback) => {
                    callback(PDF_CONTENT_TYPE, filtersValue);
                },
            },
            {
                label: "accounts.xlsFile",
                onClick: (callback) => {
                    callback(XLS_CONTENT_TYPE, filtersValue);
                },
            },
        ];

        const setFilterValues = (dateFrom, dateTo, channelType, status) => {
            let newFilters = {};
            if (status) {
                newFilters = {
                    status,
                    dateFrom: defaultFilters?.dateFrom,
                    dateTo: defaultFilters?.dateTo,
                    channelType: null,
                };
            } else if (channelType) {
                newFilters = {
                    dateFrom: defaultFilters?.dateFrom,
                    dateTo: defaultFilters?.dateTo,
                    channelType,
                };
            } else if (dateFrom || dateTo) {
                newFilters = {
                    ...filtersValue,
                    dateFrom: dateFrom || (filtersValue?.dateFrom ? filtersValue?.dateFrom : defaultFilters?.dateFrom),
                    dateTo: dateTo || (filtersValue?.dateTo ? filtersValue?.dateTo : defaultFilters?.dateTo),
                    channelType: null,
                };
            }
            this.setState({ filtersValue: newFilters });
        };

        return (
            <TransactionFilterProvider>
                <Notification scopeToShow="transactions" />
                <Head
                    title="transactions.list.title"
                    onBack={this.handleBack}
                    exportList={documents}
                    exportListBsStyle="only-icon"
                />
                <MainContainer showLoader={isFirstFetching} showChildrenWithLoader className="px-0">
                    <ProductFilters
                        alwaysShowChips
                        closeMoreFiltersWhenSiblingClick
                        borderRadiusTop
                        notCollapsedFilters
                        documents={documents}
                        showDisclaimer
                        disclaimerDate={disclaimerDate}
                        notBorderTop
                        moreFilters={
                            <HiddenFilters
                                dispatch={dispatch}
                                isDesktop={isDesktop}
                                fetching={fetching}
                                isTransactionList
                                resetHandleOrder={this.resetHandleOrder}
                                setFilterValues={setFilterValues}
                            />
                        }
                        moreFiltersClosedKeyLabel="global.productFilters.btnFilter.closed.label"
                        moreFiltersOpenedKeyLabel="global.productFilters.btnFilter.opened.label"
                        filterDownloadBtn
                        isDesktop={isDesktop}
                    />
                    <List
                        isDesktop={isDesktop}
                        showSearchButton
                        defaultFilters={defaultFilters}
                        showEnvironment={isCorporateGroup}
                        itemsAreClickeable={!isCorporateGroup}
                        handleOrder={this.handleOrder}
                        ascending={ascending}
                    />
                </MainContainer>
            </TransactionFilterProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    isFirstFetching: transactionsSelectors.getFirstFetch(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    disclaimerDate: configUtils.get("transaction.list.disclaimer.date"),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(TransactionsList);
