import classNames from "classnames";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, number, string, func } from "prop-types";
import React from "react";
import withRouter from "react-router-dom/withRouter";
import { NavLink } from "react-router-dom";
import { actions as desktopActions } from "reducers/desktop";
import { connect } from "react-redux";
import { selectors as i18nSelectors } from "reducers/i18n";

const MenuLink = ({
    childTitleKey,
    className,
    component,
    dispatch,
    exact,
    image,
    linkTo,
    notificationCount,
    notificationCountTextID,
    onClick,
    titleKey,
}) => (
    <Box
        component={component}
        onClick={(e) => {
            e.stopPropagation();
            if (window.innerWidth < 1232) {
                dispatch(desktopActions.changeStatusSidebarCollapsed(true));
            }
            if (onClick) {
                onClick();
            }
        }}
        className={classNames("nav-menu-item-wrapper", className)}>
        <NavLink exact={exact} className="nav-menu-item" to={linkTo} activeClassName="is-active">
            {image && <Image wrapperClassName="nav-menu-item-img" src={image} width="6" />}
            <Text align="left" className="nav-menu-item-text" labelKey={titleKey}>
                {childTitleKey}
            </Text>
            {notificationCount > 0 && (
                <>
                    <Badge count={notificationCount} />
                    <Text labelKey={notificationCountTextID} className="visually-hidden" />
                </>
            )}
        </NavLink>
    </Box>
);

MenuLink.propTypes = {
    childTitleKey: string,
    className: string,
    component: string,
    dispatch: func.isRequired,
    exact: bool,
    image: string,
    linkTo: string,
    notificationCount: number,
    notificationCountTextID: string,
    onClick: func,
    titleKey: string,
};

MenuLink.defaultProps = {
    childTitleKey: null,
    className: null,
    component: "li",
    exact: false,
    image: null,
    linkTo: null,
    notificationCount: 0,
    notificationCountTextID: "",
    onClick: null,
    titleKey: null,
};

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
});

export default withRouter(connect(mapStateToProps)(MenuLink));
