import InfoCard from "pages/_components/InfoCard";
import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import Row from "pages/_components/Row";
import { bool, node, shape, func } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import Slider from "react-slick";
import { CREDIT_CARD_TYPE_CARD } from "util/creditCards";
import { string } from "yup";

class DetailHeadInfo extends Component {
    render() {
        const {
            creditCard,
            children,
            isDesktop,
            isAdditionalCreditCard,
            handlePointsClick,
            showEnableWalletRequest,
            cardWasDigitized,
        } = this.props;

        const {
            artifactType,
            blocked,
            cardStyle,
            description,
            franchise,
            holder,
            idProduct,
            isIssuerAvaibleForPoints,
            isIssuerAvaibleForCashback,
            isPrepaid,
            isCorporative,
            productionStatus,
            productType,
            shortLabel,
            statusCode,
            totalPoints,
            cashBackAmount,
            cashBackAmountCurrency,
            financialRelief,
        } = creditCard;
        const filterOptionList = (optionList, keyType) => {
            const filter = optionList?.filter((item) => item && item[keyType] && item[keyType] === true);
            return filter;
        };

        const getCreditCardArray = (product) => {
            if (isPrepaid) {
                const optionList = [
                    {
                        currency: product.availableBalanceCurrency,
                        quantity: product.availableBalance,
                        title: "creditCard.details.info.availableBalance.prePaid",
                        tooltip: "creditCard.details.info.availableBalance.prePaid.tooltip",
                        showInfAdditional: true,
                        showInfMain: true,
                    },
                    {
                        date: product.lastPaymentDate,
                        title: "creditCard.details.info.expirationDate.prePaid",
                        tooltip: "creditCard.details.info.expirationDate.prePaid.tooltip",
                        showInfAdditional: false,
                        showInfMain: true,
                    },
                ];
                return filterOptionList(
                    optionList,
                    isAdditionalCreditCard && !isCorporative ? "showInfAdditional" : "showInfMain",
                );
            }

            const optionList = [
                {
                    title: `creditCard.detail.condition.credit.limit`,
                    quantity: product.creditLimit,
                    currency: product.creditLimitCurrency,
                    tooltip: product.hasOwnLimit
                        ? `creditCard.detail.condition.credit.limit.own.tooltip`
                        : `creditCard.detail.condition.credit.limit.share.tooltip`,
                    showInfAdditional: true,
                    showInfMain: false,
                },

                {
                    currency: product.stmtBalanceCurrency,
                    quantity: product.stmtBalance,
                    title: "creditCard.details.info.detainedBalance",
                    tooltip: "creditCard.details.info.detainedBalance.tooltip",
                    showInfAdditional: false,
                    showInfMain: true,
                },
                {
                    currency: product.balanceCurrency,
                    quantity: product.balance,
                    title: "creditCard.details.info.currentDue",
                    tooltip: "creditCard.details.info.currentDue.tooltip",
                    showInfAdditional: false,
                    showInfMain: true,
                },
                {
                    currency: product.minimumPaymentCurrency,
                    quantity: product.minimumPayment,
                    title: "creditCard.details.info.minimumPayment",
                    showInfAdditional: false,
                    showInfMain: true,
                },
                {
                    currency: product.availableBalanceCurrency,
                    quantity: product.availableBalance,
                    title: "creditCard.details.info.availableBalance",
                    showInfAdditional: true,
                    showInfMain: true,
                },
                {
                    date: product.closingDate,
                    title: "creditCard.details.info.closingDate",
                    showInfAdditional: false,
                    showInfMain: true,
                },
                {
                    title: "creditCard.details.info.expirationDate",
                    showInfAdditional: false,
                    showInfMain: true,
                },
            ];

            if (product.paymentDeadLineText) {
                optionList[6].message = product.paymentDeadLineText;
            } else {
                optionList[6].date = product.paymentDeadLine;
            }

            return filterOptionList(
                optionList,
                isAdditionalCreditCard && !isCorporative ? "showInfAdditional" : "showInfMain",
            );
        };

        const settings = {
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: 1,
            arrows: false,
        };

        const remarkable = () => (
            <RemarkableProductInfo
                isDesktop={isDesktop}
                productType={productType}
                blocked={blocked}
                holder={holder}
                icon={franchise.toLowerCase()}
                iDCc={idProduct}
                franchise={franchise}
                points={totalPoints}
                isPrepaid={isPrepaid}
                isCorporative={isCorporative}
                statusCode={statusCode}
                financialRelief={financialRelief}
                shortLabel={shortLabel}
                description={description}
                productionStatus={productionStatus}
                artifactType={artifactType}
                isAdditionalCreditCard={isAdditionalCreditCard}
                handlePointsClick={handlePointsClick}
                isIssuerAvaibleForPoints={isIssuerAvaibleForPoints}
                isIssuerAvaibleForCashback={isIssuerAvaibleForCashback}
                numberMask={creditCard?.numberMask || ""}
                {...(cardStyle && { cardStyle })}
                isSwitchVisible={creditCard?.isSwitchVisible}
                cashback={`${cashBackAmountCurrency} ${cashBackAmount}`}
                showEnableWalletRequest={showEnableWalletRequest}
                cardWasDigitized={cardWasDigitized}
            />
        );
        return (
            <div className="detail-head-info head-card">
                {isDesktop ? (
                    <>
                        <Row className="justify-content-center" gapX="5" gapY="3">
                            <Col
                                xs={12}
                                md={isPrepaid || (isAdditionalCreditCard && !isCorporative) ? 4 : 3}
                                className="height-auto">
                                {remarkable()}
                            </Col>
                            <Col
                                xs={12}
                                md={isPrepaid || (isAdditionalCreditCard && !isCorporative) ? 8 : 9}
                                className="height-auto">
                                <Row className="justify-content-center min-height-100" gapX="5" gapY="3">
                                    {getCreditCardArray(creditCard).map((el) => (
                                        <Col
                                            xs={12}
                                            md={isPrepaid || (isAdditionalCreditCard && !isCorporative) ? 6 : 4}
                                            key={el.quantity + el.title}
                                            className="height-auto">
                                            <InfoCard
                                                title={el.title}
                                                {...(el.currency && {
                                                    currency: el.currency ? el.currency : " ",
                                                })}
                                                {...(el !== undefined &&
                                                    el.quantity !== undefined && { quantity: el.quantity })}
                                                {...(el.date && { date: el.date })}
                                                {...(el.message && {
                                                    message: el.message,
                                                    messageClassName: "info-card-adapt-font-size",
                                                })}
                                                tooltip={el.tooltip}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                        {children}
                    </>
                ) : (
                    <div className="slick-slider-wrapper">
                        {remarkable()}
                        <Slider {...settings}>
                            {getCreditCardArray(creditCard)
                                .reduce((acc, el, i) => {
                                    const index = Math.floor(i / 4);

                                    if (!acc[index]) {
                                        acc[index] = [];
                                    }

                                    acc[index].push(el);

                                    return acc;
                                }, [])
                                .map((el) => (
                                    <Row className="d-grid-i px-0" gapX="5" gapY="3" key={el[0].quantity + el[0].title}>
                                        {el.map((oneCreditCard, idx) => (
                                            <Col
                                                xs={isPrepaid ? 12 : 6}
                                                className="height-auto"
                                                key={oneCreditCard.quantity + oneCreditCard.title}>
                                                <InfoCard
                                                    title={oneCreditCard.title}
                                                    {...(oneCreditCard?.currency && {
                                                        currency: oneCreditCard.currency,
                                                    })}
                                                    {...(oneCreditCard !== undefined &&
                                                        oneCreditCard.quantity !== undefined && {
                                                            quantity: oneCreditCard.quantity,
                                                        })}
                                                    tooltipPosition={idx % 2 ? "bottom-right" : "bottom-left"}
                                                    tooltip={oneCreditCard.tooltip}
                                                    {...(oneCreditCard.date && { date: oneCreditCard.date })}
                                                    {...(oneCreditCard.message && { message: oneCreditCard.message })}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                ))}
                        </Slider>
                    </div>
                )}
            </div>
        );
    }
}

DetailHeadInfo.propTypes = {
    children: node,
    creditCard: shape({}),
    isDesktop: bool.isRequired,
    isAdditionalCreditCard: bool,
    artifactType: string,
    handlePointsClick: func.isRequired,
    showEnableWalletRequest: func,
    cardWasDigitized: bool,
};

DetailHeadInfo.defaultProps = {
    creditCard: null,
    children: null,
    isAdditionalCreditCard: false,
    artifactType: CREDIT_CARD_TYPE_CARD,
    showEnableWalletRequest: undefined,
    cardWasDigitized: undefined,
};

export default DetailHeadInfo;
