import { call, put, takeEvery } from "redux-saga/effects";
import { types, actions as transactionalProfileActions } from "reducers/transactionalProfile";
import * as transactionalProfile from "middleware/transactionalProfile";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as form from "middleware/form";
import { adjustIdFieldErrors } from "util/form";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";

const sagas = [
    takeEvery(types.TRANSACTIONAL_COUNTRIES_REQUEST, getTransactionalProfileCountries),
    takeEvery(types.TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST, getTransactionalProfileCountriesSetting),
    takeEvery(types.TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST, getTransactionalProfileCountriesUpdate),
    takeEvery(types.TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST, getcredencialsGroups),
];

function* getTransactionalProfileCountries({ accountId }) {
    const response = yield call(transactionalProfile.getTransactionalProfileCountries, accountId);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const { countries, hasProfile } = response.data.data;
        yield put(transactionalProfileActions.transactionalCountriesRequestSuccess(countries, hasProfile));
    } else {
        yield put(transactionalProfileActions.transactionalCountriesRequestFailed());
    }
}

function* getTransactionalProfileCountriesSetting({ onClose }) {
    const response = yield call(transactionalProfile.getTransactionalProfileCountriesSetting);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const { countries, hasProfile, accounts, account } = response.data.data;
        yield put(
            transactionalProfileActions.transactionalCountriesSettingSuccess(countries, hasProfile, accounts, account),
        );
    } else {
        yield put(transactionalProfileActions.transactionalCountriesSettingFailed());
        if (onClose) {
            onClose();
        }
        if (response?.data?.code === "API601W") {
            yield put(
                notificationActions.showNotification(i18n.get("transactional.profile.not.accounts"), "error", [
                    "settings",
                ]),
            );
        }
    }
}

function* getTransactionalProfileCountriesUpdate({
    accountId,
    countriesList,
    hasProfileState,
    onFinish,
    otp,
    formikBag,
}) {
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "transactional.profile.send");
    const response = yield call(
        transactionalProfile.getTransactionalProfileCountriesUpdate,
        accountId,
        countriesList,
        otp,
        fingerPrintToken,
    );
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const { countries, hasProfile } = response.data.data;
        yield put(transactionalProfileActions.transactionalCountriesUpdateSuccess(countries, hasProfile));
        if (hasProfileState) {
            yield put(
                notificationActions.showNotification(i18n.get("transactional.profile.updated"), "success", [
                    "settings",
                    "form",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("transactional.profile.created"), "success", [
                    "settings",
                    "form",
                ]),
            );
        }
        onFinish();
    } else {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put(transactionalProfileActions.transactionalCountriesUpdateFailed());
    }
}

function* getcredencialsGroups({ data }) {
    const { idActivity } = data;
    const response = yield call(form.listCredentialsGroups, "", idActivity);

    if (response && response.status === 200) {
        yield put({
            type: types.TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS,
            credentialsGroups: response.data.data.groups,
        });
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["transactionalProfile"]));
    }
}

export default sagas;
