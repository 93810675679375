import { types as productsTypes } from "reducers/products";

export const types = {
    DEPOSIT_DETAILS_REQUEST: "deposits/DEPOSIT_DETAILS_REQUEST",
    DEPOSIT_DETAILS_SUCCESS: "deposits/DEPOSIT_DETAILS_SUCCESS",
    DEPOSIT_DOWNLOAD_STATEMENT_REQUEST: "deposits/DEPOSIT_DOWNLOAD_STATEMENT_REQUEST",
    DEPOSIT_DOWNLOAD_STATEMENT_SUCCESS: "deposits/DEPOSIT_DOWNLOAD_STATEMENT_SUCCESS",
    DEPOSIT_FETCH_MORE_MOVEMENTS_REQUEST: "deposits/DEPOSIT_FETCH_MORE_MOVEMENTS_REQUEST",
    DEPOSIT_FETCH_MORE_MOVEMENTS_SUCCESS: "deposits/DEPOSIT_FETCH_MORE_MOVEMENTS_SUCCESS",
    DEPOSIT_MOVEMENT_DETAILS_REQUEST: "deposits/DEPOSIT_MOVEMENT_DETAILS_REQUEST",
    DEPOSIT_MOVEMENT_DETAILS_SUCCESS: "deposits/DEPOSIT_MOVEMENT_DETAILS_SUCCESS",
    DEPOSIT_READ_REQUEST: "deposits/DEPOSIT_READ_REQUEST",
    DEPOSIT_READ_SUCCESS: "deposits/DEPOSIT_READ_SUCCESS",
    CLOSE_OPTIONS: "deposits/CLOSE_OPTIONS",
    DOWNLOAD_MOVEMENTS_REQUEST: "deposits/DOWNLOAD_MOVEMENTS_REQUEST",
    DOWNLOAD_MOVEMENTS_FAILURE: "deposits/DOWNLOAD_MOVEMENTS_FAILURE",
    DOWNLOAD_MOVEMENTS_SUCCESS: "deposits/DOWNLOAD_MOVEMENTS_SUCCESS",
    DOWNLOAD_CERTIFIED_REQUEST: "deposits/DOWNLOAD_CERTIFIED_REQUEST",
    DOWNLOAD_CERTIFIED_FAILURE: "deposits/DOWNLOAD_CERTIFIED_FAILURE",
    DOWNLOAD_CERTIFIED_SUCCESS: "deposits/DOWNLOAD_CERTIFIED_SUCCESS",
    EDIT_MOVEMENT_NOTE_REQUEST: "deposits/EDIT_MOVEMENT_NOTE_REQUEST",
    LIST_DEPOSIT_STATEMENTS_REQUEST: "deposits/LIST_DEPOSIT_STATEMENTS_REQUEST",
    LIST_DEPOSIT_STATEMENTS_SUCCESS: "deposits/LIST_DEPOSIT_STATEMENTS_SUCCESS",
    LIST_DEPOSIT_REQUEST: "deposits/LIST_DEPOSIT_REQUEST",
    LIST_DEPOSIT_SUCCESS: "deposits/LIST_DEPOSIT_SUCCESS",
    RESET_FILTERS: "deposits/RESET_FILTERS",
    SET_MOBILE_FILTER: "deposits/SET_MOBILE_FILTER",
    SET_SELECTED_DEPOSIT: "deposits/SET_SELECTED_DEPOSIT",
    SET_SELECTED_MOVEMENT: "deposits/SET_SELECTED_MOVEMENT",
    SET_UNIQUE_DEPOSIT: "deposits/SET_UNIQUE_DEPOSIT",
    TOGGLE_OPTIONS: "deposits/TOGGLE_OPTIONS",
    DEPOSIT_FETCH_MOVEMENTS_REQUEST: "deposits/DEPOSIT_FETCH_MOVEMENTS_REQUEST",
    DEPOSIT_FETCH_MOVEMENTS_SUCCESS: "deposits/DEPOSIT_FETCH_MOVEMENTS_SUCCESS",
};

export const INITIAL_STATE = {
    deposit: {}, // No encuentro que se utilice, dado que el detalle utiliza selectedDeposit
    deposits: [],
    equivalentTotalBalance: null,
    fetching: false,
    fetchingDownload: false,
    fetchingDownloadCertified: false,
    fetchingMovements: false,
    firstFetched: true,
    idSelectedDeposit: null,
    mobileFilter: null,
    movements: [],
    moreMovements: false,
    pageNumber: 1,
    selectedDeposit: null,
    selectedFilter: null,
    selectedMovement: null,
    statements: null,
    voucher: null,
    isOptionsVisible: false,
    filters: {
        channels: [],
        check: null,
        dateFrom: null,
        dateTo: null,
        minAmount: null,
        maxAmount: null,
        reference: null,
        fullList: false,
    },
    totalCount: 0,
    totalFavorites: 0,
    completeFavorites: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        deposit,
        deposits,
        alias,
        equivalentTotalBalance,
        filters,
        idSelectedDeposit,
        mobileFilter,
        moreStatements,
        pageNumber,
        selectedMovement,
        statement,
        statements,
        statementLines,
        totalCount,
        favorite,
        totalFavorites,
        completeFavorites,
    } = action;
    switch (action.type) {
        case types.CLOSE_OPTIONS:
            return {
                ...state,
                isOptionsVisible: false,
            };
        case types.DEPOSIT_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true,
                filters: filters || state.filters,
                movements: [],
                pageNumber: 1,
            };
        case types.DEPOSIT_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                firstFetched: false,
                moreMovements: moreStatements,
                movements: statements,
                totalCount,
            };
        case types.DEPOSIT_READ_REQUEST:
            return {
                ...state,
                isOptionsVisible: false,
                fetching: true,
                idSelectedDeposit,
            };
        case types.DEPOSIT_READ_SUCCESS:
            return {
                ...state,
                fetching: false,
                selectedDeposit: deposit,
                totalFavorites,
            };
        case types.DEPOSIT_DOWNLOAD_STATEMENT_REQUEST:
            return { ...state, fetching: true };
        case types.DEPOSIT_DOWNLOAD_STATEMENT_SUCCESS:
            return { ...state, fetching: false };
        case types.DEPOSIT_FETCH_MORE_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
            };
        case types.DEPOSIT_FETCH_MORE_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movements: state.movements ? state.movements.concat(statements) : statements,
                moreMovements: moreStatements,
                pageNumber,
            };
        case types.DEPOSIT_MOVEMENT_DETAILS_REQUEST:
            return { ...state, fetching: true };
        case types.DEPOSIT_MOVEMENT_DETAILS_SUCCESS:
            return { ...state, fetching: false, voucher: statement.voucher };
        case types.LIST_DEPOSIT_STATEMENTS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
            };
        case types.LIST_DEPOSIT_STATEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                statements: statementLines,
            };
        case types.LIST_DEPOSIT_REQUEST:
            return {
                ...state,
                fetching: true,
                movements: [],
                moreMovements: false,
                selectedMovement: null,
                pageNumber: 1,
            };
        case types.LIST_DEPOSIT_SUCCESS:
            return {
                ...state,
                deposits,
                equivalentTotalBalance,
                fetching: false,
                isOptionsVisible: false,
                completeFavorites,
            };
        case types.RESET_FILTERS:
            return { ...state, filters: INITIAL_STATE.filters };
        case types.SET_MOBILE_FILTER:
            return {
                ...state,
                mobileFilter,
            };
        case types.SET_SELECTED_MOVEMENT:
            return {
                ...state,
                selectedMovement,
            };
        case types.SET_UNIQUE_DEPOSIT:
            return {
                ...state,
                deposits,
                equivalentTotalBalance,
                selectedDeposit: deposits[0],
            };

        case types.TOGGLE_OPTIONS: {
            return {
                ...state,
                isOptionsVisible: !state.isOptionsVisible,
            };
        }

        case productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            if (state.selectedDeposit) {
                return {
                    ...state,
                    selectedDeposit: {
                        ...state.selectedDeposit,
                        productAlias: alias,
                    },
                };
            }

            return {
                ...state,
            };
        }
        case types.DOWNLOAD_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_MOVEMENTS_FAILURE:
        case types.DOWNLOAD_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };

        case types.DEPOSIT_FETCH_MOVEMENTS_REQUEST:
            return {
                ...state,
                pageNumber: 1,
                filters: action.filters,
                fetchingMovements: true,
            };
        case types.DEPOSIT_FETCH_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movements: action.statements,
                moreMovements: action.moreStatements,
                totalCount: action.totalCount,
            };
        case types.DOWNLOAD_CERTIFIED_REQUEST:
            return {
                ...state,
                fetchingDownloadCertified: true,
            };
        case types.DOWNLOAD_CERTIFIED_FAILURE:
        case types.DOWNLOAD_CERTIFIED_SUCCESS:
            return {
                ...state,
                fetchingDownloadCertified: false,
            };

        case productsTypes.TOGGLE_FAVORITE_SUCCESS: {
            const { fromList, idProduct } = action;
            if (fromList) {
                // eslint-disable-next-line no-shadow
                const { deposits } = state;
                if (!deposits || !deposits.some((dep) => dep.idProduct === idProduct)) {
                    return state;
                }
                const updateAccountArray = deposits.map((dep) => {
                    if (dep.idProduct === idProduct) {
                        return { ...dep, favorite };
                    }
                    return dep;
                });
                return { ...state, deposits: updateAccountArray, completeFavorites };
            }
            return {
                ...state,
                selectedDeposit: {
                    ...state.selectedDeposit,
                    favorite,
                },
                totalFavorites,
            };
        }
        default:
            return state;
    }
};

export const actions = {
    closeOptions: () => ({
        type: types.CLOSE_OPTIONS,
    }),
    details: (idSelectedDeposit, filters, formikBag) => ({
        type: types.DEPOSIT_DETAILS_REQUEST,
        idSelectedDeposit,
        filters,
        formikBag,
    }),
    downloadMovements: (idDeposit, format) => ({
        type: types.DOWNLOAD_MOVEMENTS_REQUEST,
        idDeposit,
        format,
    }),
    downloadCertified: (data) => ({
        type: types.DOWNLOAD_CERTIFIED_REQUEST,
        data,
    }),
    downloadStatement: (idDeposit, idStatement) => ({
        type: types.DEPOSIT_DOWNLOAD_STATEMENT_REQUEST,
        idDeposit,
        idStatement,
    }),
    editMovementNote: (depositId, movementId, note) => ({
        type: types.EDIT_MOVEMENT_NOTE_REQUEST,
        depositId,
        movementId,
        note,
    }),
    fetchMoreMovements: (depositId, filters) => ({
        type: types.DEPOSIT_FETCH_MORE_MOVEMENTS_REQUEST,
        depositId,
        filters,
    }),
    listDeposits: () => ({
        type: types.LIST_DEPOSIT_REQUEST,
    }),
    listStatements: (idDeposit) => ({
        type: types.LIST_DEPOSIT_STATEMENTS_REQUEST,
        idDeposit,
    }),
    movementDetails: (idDeposit, idStatement) => ({
        type: types.DEPOSIT_MOVEMENT_DETAILS_REQUEST,
        idDeposit,
        idStatement,
    }),
    readDeposit: (idDeposit) => ({
        type: types.DEPOSIT_READ_REQUEST,
        idDeposit,
    }),
    resetFilters: () => ({
        type: types.RESET_FILTERS,
    }),
    setMobileFilter: (mobileFilter) => ({
        type: types.SET_MOBILE_FILTER,
        mobileFilter,
    }),
    setSelectedMovement: (selectedMovement) => ({
        type: types.SET_SELECTED_MOVEMENT,
        selectedMovement,
    }),
    toggleOptions: () => ({
        type: types.TOGGLE_OPTIONS,
    }),
    fetchMovements: (depositId, filters) => ({
        type: types.DEPOSIT_FETCH_MOVEMENTS_REQUEST,
        depositId,
        filters,
    }),
};

export const selectors = {
    getDeposit: ({ deposits }) => deposits.deposit,
    getDeposits: ({ deposits }) => deposits.deposits,
    getTotalDeposits: ({ deposits }) => deposits.deposits.length,
    getEquivalentTotalBalance: ({ deposits }) => deposits.equivalentTotalBalance,
    getFetching: ({ deposits }) => deposits.fetching,
    getFetchingDownload: ({ deposits }) => deposits.fetchingDownload,
    getFetchingDownloadCertified: ({ deposits }) => deposits.fetchingDownloadCertified,
    getFetchingMovements: ({ deposits }) => deposits.fetchingMovements,
    getFilters: ({ deposits }) => deposits.filters,
    getFirstFetched: ({ deposits }) => deposits.firstFetched,
    getIdSelectedDeposit: ({ deposits }) => deposits.idSelectedDeposit,
    getIsOptionsVisible: ({ deposits }) => deposits.isOptionsVisible,
    getSelectedDeposit: ({ deposits }) => deposits.selectedDeposit,
    getSelectedFilter: ({ deposits }) => deposits.selectedFilter,
    getSelectedMovement: ({ deposits }) => deposits.selectedMovement,
    getStatements: ({ deposits }) => deposits.statements,
    getPageNumber: ({ deposits }) => deposits.pageNumber,
    getMobileFilter: ({ deposits }) => deposits.mobileFilter,
    getMoreMovements: ({ deposits }) => deposits.moreMovements,
    getMovements: ({ deposits }) => deposits.movements,
    getVoucher: ({ deposits }) => deposits.voucher,
    getTotalCount: ({ deposits }) => deposits.totalCount,
    getFullList: ({ deposits }) => deposits.filters?.fullList,
    getTotalFavorites: ({ deposits }) => deposits.totalFavorites,
    getCompleteFavorites: ({ deposits }) => deposits.completeFavorites,
};
