import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import { getNumberRender, PDFAmountField, PDFAmountFieldError, PDFTextField } from "pages/forms/customForms/PDFTicket";
import InfoExchangeRates from "pages/forms/customForms/_customFields/InfoExchangeRates";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import SwitchToggle from "pages/_components/SwitchToggle/SwitchToggle";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import { calculateShortLabel } from "util/account";
import * as config from "util/config";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import { validationAccount, validationAmount, validationLengthMax } from "util/validationSchemaUtil";
import * as Yup from "yup";
import { statusMap } from "util/general";
import useAsyncTicketProcessing from "hooks/useAsyncTicketProcessing";
import FormTransition from "../_components/FormTransition";
import { SCHEDULER_DATE } from "../_components/_fields/ReadText";
import { generateExchangeRateLabel, useExchangeRateValue } from "./hooks/TransferCustomHooks";
import {
    useCreditAccountDestination,
    useDataAmount,
    useDebitAccountOrigin,
    useGenericProps,
    useIsFromWally,
    useMetaData,
    useSelectorOptions,
} from "./hooks/TransferInternalHooks";

const ID_FORM = "transfers.internal";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = "forms.transferInternal.formName";
const maxLengthReason = config.getInteger("core.password.maxLength");

const TransferInternalForm = (props) => {
    const {
        mode,
        dispatch,
        location,
        preDataForm,
        isDesktop,
        isTablet,
        transaction,
        currentLang,
        fromOneTouchAccess,
    } = props;

    const [TooltipProcessingComponent] = useAsyncTicketProcessing(transaction);

    const [metadata] = useMetaData(preDataForm);
    const [originAccountList, destinationAccountList] = useSelectorOptions(preDataForm);
    const [genericProps] = useGenericProps(props);
    const [isFromWally] = useIsFromWally(location);
    const [debitAccountOrigin] = useDebitAccountOrigin(location);
    const [creditAccountDestination] = useCreditAccountDestination(location);
    const [dataAmount] = useDataAmount(preDataForm);
    const [currencyOrigin, setLastCurrencyOrigin] = useState(null);
    const [currencyDestination, setLastCurrencyDestination] = useState(null);
    const [currencyAmount, setLastCurrencyAmount] = useState(null);
    const [sameCurrency, setSameCurrency] = useState(false);
    const [exchageData, setExchangeData] = useExchangeRateValue(undefined);
    const [amountDebitAccount, setAmountDebitAccount] = useState({ currency: "", quantity: 0 });
    const [forex, setForex] = useState(false);
    const [titleForm, setTitleForm] = useState(TITLE_FORM);
    let setFieldValueCustom;

    useEffect(() => {
        if (mode === "edit" && transaction.idTransactionStatus !== "PENDING") {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE }));
        }
        if (mode === "preview" || mode === "view") {
            setTitleForm(`forms.transferInternal.formName`);
        } else {
            setTitleForm(TITLE_FORM);
        }
    }, [dispatch, mode, location]);

    const [currentAmount, setCurrentAmount] = useState(undefined);
    const [currentDebitAccount, setCurrentDebitAccount] = useState(undefined);
    const [currentCreditAccount, setCurrentCreditAccount] = useState(undefined);

    const calculateRateComponent = () => {
        if (
            !currentAmount ||
            !currentDebitAccount ||
            !currentCreditAccount ||
            !preDataForm ||
            !preDataForm.ExchangeRateList
        ) {
            return;
        }
        const { currency: currencyOriginExchange } = currentDebitAccount;
        const { currency: currencyDestinationExchange } = currentCreditAccount;
        const { currency: currencyAmountExchange, quantity: valueAmount } = currentAmount;

        setExchangeData(
            currencyOriginExchange,
            currencyDestinationExchange,
            currencyAmountExchange,
            valueAmount,
            preDataForm.ExchangeRateList,
        );
    };

    /**
     * Observe changes on amount or debit account or creditaccount and calculates rate.
     */
    useEffect(() => {
        calculateRateComponent();
    }, [currentAmount, currentDebitAccount, currentCreditAccount]);

    /**
     * Observe changes rate and save it on values request.
     */
    useEffect(() => {
        if (exchageData && setFieldValueCustom) {
            setFieldValueCustom("exchangeData", exchageData);
        }
    }, [exchageData]);

    const getLabelExchangeComponent = (exchageDataDef) => {
        if (!exchageDataDef) {
            return "";
        }

        const {
            currencyAmount: currAmount,
            currencyOrigin: currOrigin,
            currencyDestination: currDest,
        } = exchageDataDef;
        if (!currAmount || !currOrigin || !currDest) {
            return "";
        }

        if (currAmount === currOrigin) {
            return "forms.transfers.internal.amount.credit_estimate.label";
        }
        if (currAmount === currDest) {
            return "forms.transfers.internal.amount.debit_estimate.label";
        }

        return "";
    };

    const getDefaultValueDate = () => moment(metadata.firstWorkingDate, "YYYY-MM-DD");

    const validationSchema = () =>
        Yup.object().shape({
            creditReference: validationLengthMax(`forms.${ID_FORM}.message.maxCharacters`, maxLengthReason || 40),
            amount: validationAmount(`forms.${ID_FORM}.amount.required`),
            debitAccount: validationAccount(`forms.${ID_FORM}.debitAccount.invalid`, [
                ...(preDataForm?.originAccountList || []),
            ]),
            creditAccount: validationAccount(`forms.${ID_FORM}.creditAccount.invalid`, [
                ...(preDataForm?.destinationAccountList || []),
            ]),
        });

    const getFieldProduct = (
        type,
        valueDebitAccountData = null,
        valueCreditAccountData = null,
        onDebitAccountChange,
        setFieldValue,
    ) => {
        const valueDebitAcount = valueDebitAccountData?.idProduct || valueDebitAccountData || null;
        const valueCreditAccount = valueCreditAccountData?.idProduct || valueCreditAccountData || null;
        let valueAcount = (type === "debitAccount" && (valueDebitAcount || debitAccountOrigin)) || "";
        valueAcount = (type === "creditAccount" && (valueCreditAccount || creditAccountDestination)) || valueAcount;
        let dataOption =
            type === "creditAccount" && valueDebitAcount
                ? {
                      options: destinationAccountList?.options?.filter(
                          (option) => option.idProduct !== (valueDebitAcount || valueAcount),
                      ),
                  }
                : destinationAccountList;

        dataOption = type === "debitAccount" ? originAccountList : dataOption;
        dataOption =
            type === "debitAccount" && creditAccountDestination
                ? {
                      options: originAccountList?.options?.filter(
                          (option) => option.idProduct !== creditAccountDestination,
                      ),
                  }
                : dataOption;

        if (!dataOption || !dataOption.options || !dataOption.options.length) {
            dataOption = { options: [] };
            if (type === "debitAccount" && valueDebitAccountData != null) {
                dataOption.options.push({
                    id: valueDebitAccountData.idProduct,
                    label: valueDebitAccountData.otherLabel,
                    productType: valueDebitAccountData.productType,
                });
            } else if (type === "creditAccount" && valueCreditAccountData != null) {
                dataOption.options.push({
                    id: valueCreditAccountData.idProduct,
                    label: valueCreditAccountData.otherLabel,
                    productType: valueCreditAccountData.productType,
                });
            }
        }

        return (
            <Field
                {...genericProps}
                component={FormFieldsComponents.ProductselectorCustom}
                data={dataOption}
                key={type}
                name={type}
                renderAs="combo"
                value={valueAcount}
                idField={type}
                labelIdField={`react-select-${type}-input`}
                {...(type === "debitAccount" && { onChange: onDebitAccountChange })}
                customPlaceholder={i18n.get(`forms.${ID_FORM}.placeholder.account`)}
                disabled={type === "creditAccount" && creditAccountDestination}
                isWallyAccount={type === "creditAccount" && creditAccountDestination && isFromWally}
                {...(type === "debitAccount" && {
                    onChangeDataCustom: (dataAccount) =>
                        setFieldValue && setFieldValue("balance", dataAccount?.balance),
                })}
            />
        );
    };

    const renderedInfoTransfer = (values = null, setFieldValue) => (
        <>
            {getFieldProduct("debitAccount", values?.debitAccountData, values?.creditAccountData, setFieldValue)}
            {getFieldProduct("creditAccount", values?.debitAccountData, values?.creditAccountData, setFieldValue)}
        </>
    );

    const renderTicket = (values) => {
        const { idTransactionStatus } = props.transaction;
        const idStatus = statusMap.get(idTransactionStatus) ?? idTransactionStatus;
        const vaucherLabel = idStatus === "REJECTED" ? "Reference" : "";
        const showReference = idStatus === "FINISHED" || idStatus === "REJECTED";
        const ticketFinished = idStatus === "FINISHED";

        return (
            <>
                {ticketFinished ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                ) : (
                    <PDFAmountFieldError idForm={ID_FORM} name="amount" values={values} />
                )}
                {showReference ? (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="backendReference"
                        values={values}
                        label={i18n.get(`forms.transfers.internal.voucher${vaucherLabel}.label`)}
                    />
                ) : null}

                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        transaction?.idParentTransaction
                            ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                            : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                    }
                    label={i18n.get("forms.transfers.internal.date.label_preview")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={`${
                        transaction?.programed ? i18n.get("forms.transaction.ticket.typeOperation.program") : ""
                    } ${i18n.get("forms.transfers.internal.typeOperation")}`}
                    label={i18n.get("forms.transfers.internal.typeOperation.label")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={calculateShortLabel(values?.debitAccountData?.productType, values?.debitAccountData?.number)}
                    name="debitAccount"
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={calculateShortLabel(
                        values?.creditAccountData?.productType,
                        values?.creditAccountData?.number,
                    )}
                    name="creditAccount"
                />

                {values?.propertiesInfoExchange?.exchangeRateEstimate ? (
                    <>
                        <PDFTextField
                            idForm={ID_FORM}
                            value={`${values?.propertiesInfoExchange?.currencyEstimate} ${getNumberRender({
                                quantity: values?.propertiesInfoExchange?.amountEstimate,
                            })}`}
                            label={i18n.get(values?.propertiesInfoExchange.labelAmountEstimate)}
                        />
                        <PDFTextField
                            idForm={ID_FORM}
                            value={values?.propertiesInfoExchange.exchangeRateEstimate}
                            label={i18n.get("forms.transfers.internal.rate_estimate.label")}
                        />
                    </>
                ) : null}

                {values?.creditReference ? (
                    <PDFTextField idForm={ID_FORM} name="creditReference" values={values} />
                ) : null}
            </>
        );
    };

    const currencyIsSame = (debitAccount, creditAccount) => {
        const isSameCurrency = debitAccount?.currency === creditAccount?.currency;
        if (isSameCurrency !== sameCurrency) {
            setSameCurrency(isSameCurrency);
        }
        return isSameCurrency;
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler) => {
        const isSameCurrency = !!currencyIsSame(values?.debitAccountData, values?.creditAccountData);
        setCurrentAmount(values?.amount);
        setCurrentDebitAccount(values?.debitAccountData);
        setCurrentCreditAccount(values?.creditAccountData);
        setFieldValueCustom = setFieldValue;

        if (values?.isFromWally !== isFromWally) {
            setFieldValue("isFromWally", isFromWally, false);
        }
        if (setValues && values?.debitAccount && values?.debitAccount === values?.creditAccount) {
            setFieldValue("creditAccount", "", false);
        }
        if (mode === "edit" && (!preDataForm || !preDataForm.currencyList || !preDataForm.originAccountList)) {
            return <PageLoading loading />;
        }

        if (
            isSameCurrency &&
            values?.amount &&
            values?.debitAccountData &&
            values?.creditAccountData &&
            mode === "edit"
        ) {
            const currencyDebit = values?.debitAccountData?.currency;
            const currentAmountCurrency = values?.amount.currency;
            if (currentAmountCurrency !== currencyDebit) {
                const quantity = values?.amount.quantity;
                setFieldValue("amount", {
                    currency: currencyOrigin,
                    quantity,
                });
            }
        }

        if (values?.creditAccount && mode === "edit") {
            const creditAccount = preDataForm?.destinationAccountList?.find(
                (acc) => acc.idProduct === values?.creditAccount,
            );
            const currency = creditAccount?.currency;
            const changeCurrencyOrigin = currencyDestination !== currency;
            if (changeCurrencyOrigin) {
                setLastCurrencyDestination(currency);
            }
        }

        if (values?.debitAccount && mode === "edit") {
            const debitAccount = preDataForm?.originAccountList?.find((acc) => acc.idProduct === values?.debitAccount);
            const currency = debitAccount?.currency;
            const balance = debitAccount?.balance;
            const changeCurrencyOrigin = currencyOrigin !== currency;
            if (changeCurrencyOrigin && values?.amount?.currency !== currency) {
                setFieldValue("amount", { currency, quantity: values?.amount?.quantity }, false);
                setLastCurrencyOrigin(currency);
            } else if (changeCurrencyOrigin) {
                setLastCurrencyOrigin(currency);
            }

            if (amountDebitAccount?.currency !== currency || amountDebitAccount?.quantity !== balance) {
                setAmountDebitAccount({ currency: debitAccount?.currency, quantity: debitAccount?.balance });
            }
        }

        if (values?.debitAccount && values?.creditAccount && !isSameCurrency && mode === "edit") {
            if (values?.scheduler?.selectedOption !== schedulerUtils.TODAY) {
                setFieldValue("scheduler", {
                    selectedOption: schedulerUtils.TODAY,
                    valueDate: getDefaultValueDate(),
                });
            }
        }

        if (values?.amount?.currency !== currencyAmount && mode === "edit") {
            setLastCurrencyAmount(values?.amount?.currency);
        }

        const onDebitAccountChange = (debitAccount) => {
            const { amount } = values;
            const debitAccountData = preDataForm?.originAccountList.find((da) => da.idProduct === debitAccount);
            if (!forex || !amount?.currency) {
                setFieldValue("amount", { ...(amount || {}), currency: debitAccountData.currency });
            }
            setFieldValue("forex", forex);
        };

        const handleToggleForex = () => {
            if (forex) {
                setFieldValue("amount", { ...(values?.amount || {}), currency: values?.debitAccountData?.currency });
            }
            setFieldValue("forex", !forex);
            setForex(!forex);
        };

        return (
            <>
                {mode === "edit" && (
                    <>
                        <Row
                            className="mb-7"
                            {...(isTablet && { gapX: "8" })}
                            {...(isDesktop && !isTablet && { gapX: "12" })}
                            {...(!isDesktop && { gapY: "0" })}>
                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    className="pl-5 pl-lg-10 pr-5 pr-lg-10 pb-9 pb-md-10 mx-n-5"
                                    borderRadius="default">
                                    {getFieldProduct(
                                        "debitAccount",
                                        values?.debitAccount,
                                        values?.creditAccount,
                                        onDebitAccountChange,
                                        setFieldValue,
                                    )}
                                </Box>
                            </Col>

                            <Box
                                className={classNames({
                                    "center-absolute-xy arrow-transfer": isDesktop,
                                    "arrow-transfer": !isDesktop,
                                })}
                                {...(isDesktop && { position: "absolute", left: "50", top: "50" })}>
                                <Image src="images/icons/transfersArrow.svg" />
                            </Box>

                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    className="pl-5 pl-lg-10 pr-5 pr-lg-10 pb-9 pb-md-10 mx-n-5"
                                    borderRadius="default">
                                    {getFieldProduct(
                                        "creditAccount",
                                        values?.debitAccount,
                                        values.creditAccount,
                                        onDebitAccountChange,
                                    )}
                                </Box>
                            </Col>
                        </Row>

                        <Row {...(isTablet && { gapX: "8" })} {...(isDesktop && !isTablet && { gapX: "12" })}>
                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    className="pt-5 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-8 pb-md-11 mx-n-5"
                                    borderRadius="default">
                                    <Box
                                        display="flex"
                                        gap={isDesktop ? "3" : "2"}
                                        {...(isDesktop ? { alignY: "center" } : { column: true, alignX: true })}>
                                        <Box display="flex" {...(!isDesktop && { className: "align-items-start" })}>
                                            <SwitchToggle
                                                {...(isDesktop && { className: "mt-7" })}
                                                textClassName="ml-3 mr-0"
                                                switchIsOn={forex}
                                                fixLabelKey="forex.label"
                                                color="text"
                                                size={isDesktop ? "5" : "3"}
                                                removeMinHeight
                                                handleToggle={handleToggleForex}
                                                tooltipText="forex.tooltip"
                                            />
                                        </Box>

                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.Amount}
                                            data={dataAmount}
                                            key="amount"
                                            name="amount"
                                            value={values.amount}
                                            idField="amount"
                                            customPlaceholderCurrency=""
                                            customPlaceholderQuantity="0.00"
                                            quantityTextAlign="text-left"
                                            disabledCurrency={!forex}
                                        />
                                    </Box>

                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Text}
                                        key="creditReference"
                                        name="creditReference"
                                        idField="creditReference"
                                        isRequired={false}
                                        validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ]*$"
                                        labelKey="forms.transfers.internal.reference"
                                        optional={i18n.get("form.field.optional")}
                                        maxLength={maxLengthReason || 40}
                                    />
                                    {exchageData && !isDesktop && (
                                        <Box className="mt-5">
                                            <InfoExchangeRates
                                                labelAmountEstimate={getLabelExchangeComponent({ ...exchageData })}
                                                amountEstimate={exchageData.estimatedAmount || 0}
                                                exchangeRateEstimate={generateExchangeRateLabel(exchageData)}
                                                currencyEstimate={exchageData.currencyEstimate}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Col>
                            {renderScheduler && (!values?.debitAccount || !values?.creditAccount || sameCurrency) && (
                                <Col xs={12} md={6}>
                                    {renderScheduler(isFromWally || forex)}
                                </Col>
                            )}
                            {exchageData && isDesktop && (
                                <Col xs={12} md={6} className="height-auto">
                                    <InfoExchangeRates
                                        labelAmountEstimate={getLabelExchangeComponent({ ...exchageData })}
                                        amountEstimate={exchageData.estimatedAmount || 0}
                                        exchangeRateEstimate={generateExchangeRateLabel(exchageData)}
                                        currencyEstimate={exchageData.currencyEstimate}
                                    />
                                </Col>
                            )}
                        </Row>
                    </>
                )}

                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={dataAmount}
                                key="amount"
                                name="amount"
                                value={values.amount}
                                idField="amount"
                                bigDataAmount
                                label_viewMap={
                                    transaction?.idTransactionStatus === "FINISHED" ||
                                    transaction?.idTransactionStatus === "ACCEPTED"
                                        ? { [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label_view`) }
                                        : { [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label`) }
                                }
                            />
                        </Box>
                        {TooltipProcessingComponent}
                    </>
                ) : null}

                {mode === "preview" || mode === "view" ? (
                    <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            value={values?.backendReference}
                            label="forms.transfers.internal.voucher.label"
                            shouldRender={
                                (mode === "view" && transaction && transaction?.idTransactionStatus === "FINISHED") ||
                                transaction?.idTransactionStatus === "ACCEPTED"
                            }
                        />
                        {values && values.backendReference && (
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.backendReference}
                                label="forms.transfers.internal.voucherReference.label"
                                shouldRender={
                                    mode === "view" &&
                                    transaction &&
                                    (transaction?.idTransactionStatus === "PENDING" ||
                                        transaction?.idTransactionStatus === "PROCESSING" ||
                                        transaction?.idTransactionStatus === "REJECTED")
                                }
                            />
                        )}
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            value={
                                // eslint-disable-next-line no-nested-ternary
                                mode === "preview"
                                    ? moment().format("DD/MM/YYYY")
                                    : transaction?.idParentTransaction
                                    ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                                    : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                            }
                            label="forms.transfers.internal.date.label_preview"
                        />
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            value={`${
                                transaction?.programed ? i18n.get("forms.transaction.ticket.typeOperation.program") : ""
                            } ${i18n.get("forms.transfers.internal.typeOperation")}`}
                            label="forms.transfers.internal.typeOperation.label"
                            shouldRender={mode === "view"}
                        />
                        {renderedInfoTransfer(values, setFieldValue)}
                        {!isSameCurrency && (
                            <>
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    type="amount_type"
                                    amount={{
                                        currency: values?.exchangeData?.currencyEstimate,
                                        quantity: values?.exchangeData?.estimatedAmount,
                                    }}
                                    label={getLabelExchangeComponent(values?.exchangeData)}
                                />
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={generateExchangeRateLabel(values?.exchangeData || {})}
                                    label="forms.transfers.internal.rate_estimate.label"
                                />
                            </>
                        )}
                        {values?.creditReference && (
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.creditReference}
                                idField="creditReference"
                            />
                        )}

                        {isSameCurrency &&
                        values?.scheduler?.selectedOption &&
                        values?.scheduler?.selectedOption === schedulerUtils.TODAY &&
                        mode === "preview" ? (
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={i18n.get("scheduler.immediate.radiobutton.label")}
                                label="forms.transfers.internal.maketransfer.label_preview"
                            />
                        ) : null}

                        {isSameCurrency &&
                        values?.scheduler?.selectedOption &&
                        values?.scheduler?.selectedOption !== schedulerUtils.TODAY ? (
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                type={SCHEDULER_DATE}
                                value={values?.scheduler}
                                label="forms.transfers.internal.dateScheduler.label_preview"
                            />
                        ) : null}
                    </Box>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: titleForm,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
        showSchedulerMessage: false,
        topDisclaimer: sameCurrency ? "" : "forms.transfers.internal.rate.disclaimer",
        pathBackEditMode: fromOneTouchAccess !== undefined && fromOneTouchAccess === true ? "/desktop" : undefined,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});

TransferInternalForm.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
    usesJointAccount: bool,
    fromOneTouchAccess: bool,
};
TransferInternalForm.defaultProps = {
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
    usesJointAccount: false,
    fromOneTouchAccess: false,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(TransferInternalForm));
