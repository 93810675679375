/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from "classnames";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import FieldError from "pages/_components/fields/FieldError";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { arrayOf, bool, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";

class Enviroments extends Component {
    static propTypes = {
        environments: arrayOf(arrayOf(oneOfType([string, shape({})]))).isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        activeEnvironment: shape({}),
        fromSetDefaultEnvironmentPage: bool,
        userData: shape({}),
        legendTextID: string,
        noLegendTextId: bool,
        whiteBoxed: bool,
    };

    static defaultProps = {
        activeEnvironment: {},
        fromSetDefaultEnvironmentPage: false,
        userData: {},
        legendTextID: "settings.changeEnvironment",
        noLegendTextId: false,
        whiteBoxed: false,
    };

    handleClick = (value) => {
        const { field, form } = this.props;
        form.setFieldValue(field.name, value);
    };

    renderEnviromentName = ([id, environment]) => {
        const { activeEnvironment, fromSetDefaultEnvironmentPage, userData, whiteBoxed } = this.props;
        const environmentToCheck = fromSetDefaultEnvironmentPage ? userData.idDefaultEnvironment : activeEnvironment.id;
        const allowedToAccess = environment.allowedToAccess.toString() === "true";
        return (
            <>
                <Box
                    display="inline-flex"
                    alignX="flex-start"
                    alignY="center"
                    fullWidth
                    {...(whiteBoxed && { background: "white", borderRadius: "md" })}
                    flex1
                    className={classNames("radio-btn-wrapper", {
                        "env-disabled": !allowedToAccess && !fromSetDefaultEnvironmentPage,
                        "mx-0 mb-3 py-4 px-5": whiteBoxed,
                    })}>
                    <input
                        disabled={!allowedToAccess && !fromSetDefaultEnvironmentPage}
                        defaultChecked={Number(id) === environmentToCheck}
                        id={`environment-${id}`}
                        type="radio"
                        value={environment.name}
                        name="environment"
                        onClick={() => this.handleClick(id)}
                        label={environment.name}
                        className="radio-btn"
                    />
                    <label className="radio-btn-label" htmlFor={`environment-${id}`}>
                        {environment.name}
                    </label>
                </Box>
                {!allowedToAccess && !fromSetDefaultEnvironmentPage && (
                    <div className="environtment-disabled-message">
                        <Image src="images/warningIcon.svg" className="svg-icon" />
                        <I18n id="administration.restrictions.unavailableEnvironment" />
                    </div>
                )}
            </>
        );
    };

    render() {
        const {
            environments,
            legendTextID,
            noLegendTextId,
            form: { errors },
            field,
        } = this.props;

        const enviromentsCorporate = environments.filter(
            (enviroment) => enviroment[1]?.environmentType === "corporate",
        );
        const enviromentsRetail = environments.filter((enviroment) => enviroment[1]?.environmentType === "retail");
        return (
            <>
                {legendTextID && !noLegendTextId && (
                    <Box display="flex" alignX="center">
                        <Text
                            className="mb-9 mt-0"
                            labelKey={legendTextID}
                            component="h3"
                            align="center"
                            size="3"
                            color="heading"
                            bold
                        />
                    </Box>
                )}
                <Box className={classNames("form-group selection-list", { "has-error": errors[field.name] })}>
                    {enviromentsRetail && enviromentsRetail.length > 0 && (
                        <Box className="mb-6">
                            <Box className="enviroment-label-wrapper" display="flex">
                                <Image src="images/icons/menu-enviroment-retail.svg" />
                                <Text component="h4" className="enviroment-label-title" labelKey="enviroment.retail" />
                            </Box>
                            {enviromentsRetail.map(this.renderEnviromentName)}
                        </Box>
                    )}
                    <Box>
                        <Box className="enviroment-label-wrapper" display="flex">
                            <Image src="images/icons/menu-enviroment-corporate.svg" />
                            <Text component="h4" className="enviroment-label-title" labelKey="enviroment.corporate" />
                        </Box>
                        {enviromentsCorporate.map(this.renderEnviromentName)}
                    </Box>

                    {errors[field.name] && (
                        <div className="text-center">
                            <FieldError error={errors[field.name]} />
                        </div>
                    )}
                </Box>
            </>
        );
    }
}

export default Enviroments;
