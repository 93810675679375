import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

class AdministrationChannelsTicket extends Component {
    static propTypes = {
        actions: shape({
            loadSignatureTicketRequest: func.isRequired,
        }).isRequired,
        match: shape({
            params: shape({
                idTransaction: string.isRequired,
            }).isRequired,
        }).isRequired,
        hasSignatureEnabled: bool.isRequired,
        signatureLevel: string,
        user: shape({
            firstName: string.isRequired,
            lastName: string.isRequired,
        }).isRequired,
        schemeName: string.isRequired,
    };

    static defaultProps = { signatureLevel: null };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadSignatureTicketRequest(match.params.idTransaction);
    }

    renderMediumTicket = () => {
        const { hasSignatureEnabled } = this.props;

        return <I18n id={`administration.signature.ticket.change.${!hasSignatureEnabled}`} />;
    };

    renderAdvancedTicket = () => {
        const { signatureLevel } = this.props;

        if (signatureLevel) {
            return <I18n id="administration.signature.ticket.configure" SIGNATURE_LEVEL={signatureLevel} />;
        }

        return <I18n id="administration.signature.ticket.change.true" />;
    };

    render() {
        const { user, schemeName } = this.props;

        return (
            <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper"
                        label="administration.signature.user"
                        data={`${user.firstName} ${user.lastName}`}
                    />
                    <div className="transfer-data data-wrapper">
                        <Container.ColumnBody columnClass="list-wrapper">
                            {schemeName === "medium" ? this.renderMediumTicket() : this.renderAdvancedTicket()}
                        </Container.ColumnBody>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default AdministrationChannelsTicket;
