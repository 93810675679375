import React, { Component, Fragment } from "react";
import { shape, bool, number, string } from "prop-types";
import { connect } from "react-redux";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import { channelsSelectors } from "reducers/administration";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";

class CapsList extends Component {
    static propTypes = {
        activeEnvironment: shape({ type: string }).isRequired,
        caps: shape({
            amount: number,
            frequency: string,
            currency: string,
        }).isRequired,
        currency: shape({
            id: string,
        }).isRequired,
        topAmount: shape({
            amount: number,
            frequency: string,
            maximum: number,
        }),
        hasDefaultLabel: bool,
    };

    static defaultProps = {
        hasDefaultLabel: false,
        topAmount: null,
    };

    renderCaps = () => {
        const {
            activeEnvironment: { type },
            caps,
            hasDefaultLabel,
        } = this.props;

        return (
            <section>
                <h4 className="data-label">
                    <I18n id="administration.channels" />
                </h4>
                {Object.entries(caps).map(([channel, { amount, frequency, currency }]) => (
                    <div key={channel} className="data-wrapper data-wrapper-inline">
                        <span className="data-desc">
                            <I18n id={`channels.${channel}`} />
                        </span>{" "}
                        <FormattedAmount
                            className="data-desc"
                            quantity={
                                amount === undefined ? parseFloat(configUtils.get(`default_cap_user_${type}`)) : amount
                            }
                            frequency={i18nUtils.get(`administration.channels.${frequency}`)}
                            currency={currency}
                        />{" "}
                        {hasDefaultLabel && amount === undefined && (
                            <small className="data-desc text-muted">
                                <I18n id="channels.defaultCap" />
                            </small>
                        )}
                    </div>
                ))}
            </section>
        );
    };

    render() {
        const { currency = {}, topAmount } = this.props;
        const { frequency, maximum, amount } = { ...topAmount };

        return (
            <Fragment>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="administration.channels.topAmount" />
                    </span>
                    <FormattedAmount
                        className="data-desc"
                        quantity={maximum || amount}
                        frequency={i18nUtils.get(`administration.channels.${frequency}`)}
                        currency={currency.id}
                    />
                </div>
                {this.renderCaps()}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    caps: channelsSelectors.getCaps(state),
    currency: channelsSelectors.getCurrencies(state)[0],
    topAmount: channelsSelectors.getTopAmount(state),
});

export default connect(mapStateToProps)(CapsList);
