import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { func, arrayOf, shape, string } from "prop-types";

import { actions, selectors } from "reducers/administration/restrictions";
import { actions as formActions } from "reducers/form";

import * as dateUtils from "util/date";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class RestrictionsManageConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        dataToSave: shape({}).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, dataToSave, idTransaction, idActivity } = this.props;
        if (!idTransaction) {
            dispatch(actions.manageRestrictions(dataToSave, credentials, formikBag));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    renderDays = (bitWiseNumber) => (
        <div>
            {dateUtils
                .getDaysNamesWithBitwiseNumber(bitWiseNumber)
                .map((i) => i)
                .join(", ")}
        </div>
    );

    renderCalendarAndIPSection(onlyEnvironment, dataToSave) {
        return (
            <section className="container--layout flex-grow-1">
                <Row>
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        {/* calendar Detail */}
                        <>
                            {!dataToSave.perpetual && dataToSave.calendarToSave && dataToSave.calendarEnabled && (
                                <div>
                                    <div>
                                        <I18n id="administration.restrictions.timeZone.label" />
                                        {` ${dataToSave.timeZone} `}
                                    </div>
                                    {dataToSave.days !== 127 && this.renderDays(dataToSave.days)}
                                    {dataToSave.days === 127 && <I18n id="administration.restrictions.allDays.label" />}
                                    <div>
                                        <I18n id="administration.restrictions.from" />
                                        {` ${dataToSave.startTime} `}
                                        <I18n id="administration.restrictions.to" />
                                        {` ${dataToSave.endTime} `}
                                        <I18n id="administration.restrictions.hrs" />
                                    </div>
                                </div>
                            )}
                            {dataToSave.perpetual && <I18n id="administration.restrictions.perpetual.detail" />}
                            {onlyEnvironment && !dataToSave.calendarEnabled && (
                                <section className="container--layout flex-grow-1">
                                    <I18n id="administration.restrictions.disableCalendar.label" />
                                </section>
                            )}
                            {!dataToSave.calendarToSave && onlyEnvironment && dataToSave.calendarEnabled && (
                                <section className="container--layout flex-grow-1">
                                    <I18n id="administration.restrictions.enableCalendar.label" />
                                </section>
                            )}
                        </>
                        {/* ip Detail */}
                        {(dataToSave.ipsList && dataToSave.ipsList.length > 0) || dataToSave.anyIP ? (
                            <div>
                                <I18n id="administration.restrictions.IP" />{" "}
                                {dataToSave.anyIP && <I18n id="administration.restrictions.anyIP.label" />}
                                <span>{!dataToSave.anyIP && dataToSave.ipsList.join(", ")}</span>
                            </div>
                        ) : (
                            <>
                                {onlyEnvironment && !dataToSave.iPEnabled && (
                                    <section className="container--layout flex-grow-1">
                                        <I18n id="administration.restrictions.disableIP.label" />
                                    </section>
                                )}
                                {onlyEnvironment && dataToSave.iPEnabled && (
                                    <section className="container--layout flex-grow-1">
                                        <I18n id="administration.restrictions.enableIP.label" />
                                    </section>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </section>
        );
    }

    renderDetail() {
        const { dataToSave } = this.props;
        const action = !dataToSave.calendarRestrictionId ? "Save" : "Modify";
        const onlyEnvironment = typeof dataToSave.idUser === "undefined";
        return (
            <>
                <section className="container--layout flex-grow-1">
                    <Row>
                        <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                            <I18n id={`administration.restrictions.restrictions.before${action}`} />
                        </Col>
                    </Row>
                </section>
                {this.renderCalendarAndIPSection(onlyEnvironment, dataToSave)}
                {!onlyEnvironment && (
                    <section className="container--layout flex-grow-1">
                        <div className="restrictions-warning">
                            <Image src="images/warningIcon.svg" className="svg-icon" />{" "}
                            <span>
                                <I18n id="administration.restrictions.restrictions.beforeSave.userNote" />
                            </span>
                        </div>
                    </section>
                )}
            </>
        );
    }

    render() {
        const { credentialGroups } = this.props;
        if (!credentialGroups) {
            return null;
        }

        return (
            <Fragment>
                <Head
                    title="administration.restrictions.manage"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />
                <MainContainer>
                    <div className="above-the-fold">
                        {this.renderDetail()}
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: selectors.getCredentialGroups(state),
    dataToSave: selectors.getDataToSave(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(RestrictionsManageConfirmation);
