import classNames from "classnames";
import { AccordionContext } from "pages/_components/Accordion/Accordion";
import AccordionItemContent from "pages/_components/Accordion/AccordionItemContent";
import Box from "pages/_components/Box";
import DropdownArrow from "pages/_components/DropdownArrow";
import Text from "pages/_components/Text";
import PropTypes, { bool, node, string } from "prop-types";
import React, { Component } from "react";

class AccordionItem extends Component {
    static propTypes = {
        children: node.isRequired,
        className: string,
        contentClassName: string,
        grayTexts: bool,
        heightMd: bool,
        item: node.isRequired,
        noMargin: bool,
        number: PropTypes.number.isRequired,
        title: string,
        titleAlign: string,
        titleBold: bool,
        titleColor: string,
        titleSize: string,
    };

    static defaultProps = {
        className: null,
        contentClassName: "px-5 mt-4 pb-4",
        grayTexts: false,
        heightMd: false,
        noMargin: false,
        title: null,
        titleAlign: "left",
        titleBold: true,
        titleColor: "primary",
        titleSize: "5",
    };

    render() {
        const {
            item,
            number,
            children,
            title,
            titleSize,
            titleColor,
            titleAlign,
            titleBold,
            className,
            grayTexts,
            noMargin,
            contentClassName,
            heightMd,
            ...props
        } = this.props;

        return (
            <AccordionContext.Consumer>
                {({ itemOpen, onClick, validateActive }) => {
                    const isActive = itemOpen === number;

                    return (
                        <Box
                            component="li"
                            className={classNames("navigational-list-item", className, {
                                "navigational-list-item-gray": grayTexts,
                                "m-0": noMargin,
                            })}
                            border="none">
                            <button
                                className={classNames("collapsable-button", {
                                    "collapse-open": isActive,
                                    "accordion-height-md": heightMd,
                                })}
                                type="button"
                                onClick={() => {
                                    onClick(number);
                                    validateActive(isActive);
                                }}>
                                <Text
                                    className="accordion-title"
                                    component="h4"
                                    labelKey={title}
                                    size={titleSize}
                                    color={titleColor}
                                    align={titleAlign}
                                    bold={titleBold}
                                />

                                {item}
                                <DropdownArrow isActive={isActive} {...props} />
                            </button>

                            <AccordionItemContent isActive={isActive} contentClassName={contentClassName}>
                                {children}
                            </AccordionItemContent>
                        </Box>
                    );
                }}
            </AccordionContext.Consumer>
        );
    }
}

export default AccordionItem;
