import classNames from "classnames";
import { Field, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import PhoneInput from "pages/_components/fields/PhoneInput";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "settings.changePhone";
class ChangePhone extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        mobilePhone: string,
        userData: shape({}),
    };

    static defaultProps = {
        mobilePhone: "",
        userData: undefined,
    };

    state = {
        preferredCountries: ["pa", "ve"],
        areaCodes: { ve: ["412", "414", "416", "424", "426"] },
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(settingsActions.getUserData(true));
    }

    showDisclaimerWally = () => {
        const { userData } = this.props;
        return (
            userData?.isUserWallyEnrolled &&
            (userData.isUserWallyEnrolled === true || userData.isUserWallyEnrolled === "true")
        );
    };

    renderDisclaimerWally = () => (
        <Box
            display="flex"
            alignX="flex-start"
            alignY="center"
            background="info"
            className="p-5"
            borderRadius="top-default">
            <Image wrapperClassName="form-confirmation-info-icon mr-3" src="images/icons/info.svg" />

            <Text size="7" color="text-boton">
                <b> {i18n.get("settings.changePhone.success.disclaimer.wally")}</b>
            </Text>
        </Box>
    );

    handleSubmit = ({ mobilePhone, otp }, formikBag) => {
        const { dispatch } = this.props;
        dispatch(settingsActions.sendMobilePhoneCode(mobilePhone, otp, formikBag));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    handleFormSubmit = (event, { handleSubmit, errors, validateForm }) => {
        validateForm();
        const canSubmit = Object.values(errors).every((error) => error === undefined);
        if (canSubmit) {
            handleSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    validationSchema = (actualPhone) =>
        Yup.object().shape({
            mobilePhone: Yup.object()
                .shape({
                    value: Yup.string().required(i18n.get(`${FORM_ID}.mobilePhone.required`)),
                })
                .test(
                    "valid-phone",
                    { value: i18n.get(`${FORM_ID}.mobilePhone.other`) },
                    (phone) => actualPhone !== phone.prefix + phone.areaCode + phone.value,
                ),
            otp: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
        });

    renderForm = (propsForm) => {
        const { isSubmitting, ...form } = propsForm;
        const { isDesktop, fetching, mobilePhone } = this.props;
        const { preferredCountries, areaCodes } = this.state;
        return (
            <form className="full-height" onSubmit={(event) => this.handleFormSubmit(event, form)}>
                <Box display="flex" column fullWidth fullHeight>
                    <Box
                        background="white"
                        className={classNames("mt-5 mt-md-0 pt-5 pt-md-8 px-5 pb-8 pb-md-12 mb-8", {
                            "mx-n-5 full-height": !isDesktop,
                        })}
                        borderRadius={this.showDisclaimerWally() ? "bottom-default" : "xl"}>
                        <Row fullHeight>
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <Box display="flex" column alignX="between" fullHeight>
                                    <Text
                                        size="5"
                                        color="heading"
                                        component="p"
                                        className="m-0 mb-md-10"
                                        align="center"
                                        labelKey="settings.changePhone.explanation"
                                        {...(!isDesktop && { align: "center" })}
                                    />
                                    <Box display="flex" className="mb-md-11">
                                        {!fetching && (
                                            <Field
                                                component={PhoneInput}
                                                idForm={FORM_ID}
                                                idField={`${FORM_ID}.mobilePhone`}
                                                name="mobilePhone"
                                                isDesktop={isDesktop}
                                                mobilePhone={mobilePhone}
                                                className="remove-flex"
                                                preferredCountries={preferredCountries}
                                                areaCodes={areaCodes}
                                                enableAreaCodes={preferredCountries}
                                            />
                                        )}
                                    </Box>

                                    <Box display="flex" column fullWidth>
                                        <CredentialTokenComponent
                                            credentials={["otp"]}
                                            propsForm={propsForm}
                                            onChangeToken={(tokenCode) => {
                                                if (propsForm?.setFieldValue) {
                                                    propsForm.setFieldValue("otp", tokenCode);
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Box>

                    <Box {...(!isDesktop && { className: "mt-auto" })}>
                        <Row>
                            <Col xs={12} md={8} mdOffset={2} lg={4} lgOffset={4}>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.modify"
                                    loading={isSubmitting}
                                    disabled={fetching}
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Box>
            </form>
        );
    };

    render() {
        const { mobilePhone, fetching } = this.props;
        return (
            <>
                <Head title="settings.changePhone" onBack={this.handleBack} />

                <MainContainer showLoader={fetching}>
                    <Notification scopeToShow="changePhone" />
                    {this.showDisclaimerWally() && this.renderDisclaimerWally()}
                    <Formik
                        initialValues={{ mobilePhone: { prefix: "", areaCode: "", value: "" }, otp: "" }}
                        onSubmit={this.handleSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize
                        validationSchema={this.validationSchema(mobilePhone)}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    mobilePhone: settingsSelectors.getMobilePhone(state) || "",
    fetching: settingsSelectors.isFetching(state),
    userData: settingsSelectors.getUserData(state),
});

export default connect(mapStateToProps)(ChangePhone);
