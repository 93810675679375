import classNames from "classnames";
import { USD_CURRENCY } from "constants.js";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import FieldError from "pages/_components/fields/FieldError";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import withFocus from "pages/_components/withFocus";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import * as schedulerUtils from "util/scheduler";
import Tooltip from "pages/_components/Tooltip";
import FieldWarning from "pages/_components/fields/FieldWarning";
import * as i18nUtils from "util/i18n";
import {
    generateTypePaymentList,
    MINIMUM_PAYMENT,
    OTHER_AMOUNT,
    BALANCE_DUE_DATE,
} from "../../hooks/PayCreditCardHooks";

export const amountDefault = { currency: USD_CURRENCY, quantity: "" };

const AmountPaymentTypeField = (props) => {
    const {
        dataAmount,
        genericProps,
        values,
        optionsPayment,
        form,
        isDesktop,
        onChange,
        warningOtherAmountLabel,
    } = props;

    const [paymentTypeCheck, setPaymentTypeCheck] = useState();
    const [options, setOptions] = useState();

    const deleteError = () => {
        const errors = { ...form.errors };
        delete errors?.amount;
        form.setErrors(errors);
    };

    const setAmountValue = (paymentType, amount) => {
        form.setFieldValue("otherAmount", paymentType === OTHER_AMOUNT && amount ? amount : amountDefault);
        form.setFieldValue("amount", amount || amountDefault);
        if (values?.paymentType !== paymentType) {
            form.setFieldValue("paymentType", paymentType);
        }
        setPaymentTypeCheck(paymentType);
        deleteError();
        if (onChange) {
            onChange(paymentType, amount);
        }
    };

    useEffect(() => {
        const currentOptions =
            optionsPayment?.options?.find((option) => option.idProduct === values?.creditCard)?.types ||
            generateTypePaymentList();
        // const DebFree = currentOptions
        //     ?.map((option) => option.amount?.quantity || 0)
        //     .reduce((acc, curr) => acc && curr === 0, true);
        // setOptions(currentOptions.filter((type) => type.paymentType === OTHER_AMOUNT || !DebFree));
        setOptions(currentOptions);
        if (values?.paymentType && values?.paymentType === OTHER_AMOUNT) {
            setAmountValue(values.paymentType, values?.amount);
        } else {
            const paymentType = currentOptions?.find((type) => type.paymentType === MINIMUM_PAYMENT)
                ? MINIMUM_PAYMENT
                : BALANCE_DUE_DATE;
            const amount = currentOptions?.filter((type) => type.paymentType === paymentType)[0]?.amount;
            setAmountValue(paymentType, amount);
        }
    }, [values.creditCard, optionsPayment]);

    useEffect(() => {
        if (values?.scheduler?.selectedOption && values?.scheduler?.selectedOption !== schedulerUtils.TODAY) {
            setAmountValue(OTHER_AMOUNT, values?.paymentType === OTHER_AMOUNT ? values?.amount : null);
        }
    }, [values?.scheduler?.selectedOption]);

    const setValueAmount = (option) => {
        const amount = option.paymentType !== OTHER_AMOUNT ? option.amount : null;
        setAmountValue(option.paymentType, amount);
    };

    const changeValueAmount = (amount) => {
        setAmountValue(OTHER_AMOUNT, amount);
    };

    const renderLeyendAmount = (option) => {
        const topWarningOtherAmount = "mt-1";

        const displayWarningOtherAmount = option?.paymentType === OTHER_AMOUNT && warningOtherAmountLabel;
        const topErrorAmount = displayWarningOtherAmount ? "mt-7" : "";

        const displayError = form.errors?.amount && option?.paymentType === paymentTypeCheck;
        const topScheduler = displayWarningOtherAmount || displayError ? "mt-8" : "";

        return (
            <>
                {displayWarningOtherAmount && (
                    <Box display="flex" alignX="flex-start" alignY="center" className={topWarningOtherAmount}>
                        <Box display="flex" className={classNames("error-wrapper")}>
                            <FieldWarning warning={i18nUtils.get(warningOtherAmountLabel)} icon="infoIcon.svg" />
                        </Box>
                    </Box>
                )}
                {displayError && (
                    <Box display="flex" alignX="flex-start" alignY="center" className={topErrorAmount}>
                        <Box
                            display="flex"
                            className={classNames("error-wrapper", {
                                "other-mount": !option.amount,
                            })}>
                            <FieldError error={form?.errors?.amount} />
                        </Box>
                    </Box>
                )}

                {values?.scheduler?.selectedOption !== schedulerUtils.TODAY && isDesktop ? (
                    <Box display="flex" alignX="flex-start" alignY="center" className={`ml-7 ${topScheduler}`}>
                        <FieldWarning
                            warning={i18nUtils.get("forms.creditCard.payment.creditCardLocal.disclaimer")}
                            icon="infoIcon.svg"
                        />
                    </Box>
                ) : null}
            </>
        );
    };

    return (
        <Box className="field-amountPaymentType">
            {options?.map((option) => {
                const disabledBool = values?.scheduler?.selectedOption !== schedulerUtils.TODAY && !!option?.amount;

                return (
                    <Box
                        display="flex"
                        column
                        background="white"
                        alignX="center"
                        borderRadius="default"
                        className={classNames("pl-5 pl-md-7 pr-5 pr-md-7 mx-n-5", {
                            "pt-4 pt-md-7 pb-8 pb-md-9 min-height-100": !option.amount,
                            "py-6 py-md-8": option.amount,
                        })}
                        maxContentHeight>
                        {option.amount ? (
                            <Box
                                display="flex"
                                alignY="center"
                                className={classNames("radio-btn-wrapper m-0", {
                                    "disabled-option": disabledBool,
                                })}
                                position="relative">
                                <input
                                    disabled={disabledBool}
                                    defaultChecked={option?.paymentType === paymentTypeCheck}
                                    id={option.paymentType}
                                    type="radio"
                                    value={option.paymentType}
                                    name="paymentType"
                                    onClick={() => setValueAmount(option)}
                                    label={option.paymentType}
                                    className="radio-btn"
                                    checked={option?.paymentType === paymentTypeCheck}
                                />
                                <Box display="flex" className="ml-3" alignX="between" fullWidth>
                                    <Box display="flex">
                                        <Text
                                            labelKey={`forms.creditCard.payment.${option?.paymentType}.label`}
                                            color={disabledBool ? "text-disabled-color" : "heading"}
                                        />
                                        {option?.tooltip && !disabledBool && <Tooltip text={option?.tooltip} />}
                                    </Box>

                                    {values?.scheduler?.selectedOption !== schedulerUtils.TODAY &&
                                    !!option?.amount ? null : (
                                        <FormattedAmount
                                            quantity={option.amount?.quantity}
                                            currency={option.amount?.currency}
                                            color="heading"
                                            bold
                                        />
                                    )}
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                column
                                alignX="center"
                                className="radio-btn-wrapper m-0"
                                position="relative">
                                <Box display="flex" alignY="center" fullWidth>
                                    <Box className="mt-6">
                                        <input
                                            disabled={disabledBool}
                                            defaultChecked={option?.paymentType === paymentTypeCheck}
                                            id={option.paymentType}
                                            type="radio"
                                            value={option.paymentType}
                                            name="paymentType"
                                            onClick={() => setValueAmount(option)}
                                            label={option.paymentType}
                                            className="radio-btn"
                                            checked={option?.paymentType === paymentTypeCheck}
                                        />
                                    </Box>
                                    <Box display="flex" className="ml-3 pr-md-5" alignX="between" fullWidth>
                                        <Box display="flex" fullWidth>
                                            <Field
                                                {...genericProps}
                                                editing={!option.amount}
                                                component={FormFieldsComponents.Amount}
                                                data={dataAmount}
                                                key="otherAmount"
                                                name="otherAmount"
                                                value={values.otherAmount}
                                                onChange={(value) => changeValueAmount(value)}
                                                idField="otherAmount"
                                                customPlaceholderCurrency=""
                                                customPlaceholderQuantity="0.00"
                                                quantityTextAlign="text-left"
                                                disabledCurrency
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                {renderLeyendAmount(option)}
                            </Box>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

AmountPaymentTypeField.propTypes = {
    dataAmount: shape({}),
    genericProps: shape({}),
    values: shape({}),
    optionsPayment: shape({}),
    form: shape({}),
    isDesktop: bool.isRequired,
    onChange: func,
    warningOtherAmountLabel: string,
};

AmountPaymentTypeField.defaultProps = {
    dataAmount: {},
    genericProps: {},
    values: {},
    optionsPayment: {},
    form: {},
    onChange: undefined,
    warningOtherAmountLabel: undefined,
};

export default withFocus(resizableRoute(AmountPaymentTypeField));
