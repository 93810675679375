import classNames from "classnames";
import Text from "pages/_components/Text";
import { string } from "prop-types";
import React, { useEffect, useState } from "react";

const Welcome = (props) => {
    const [momentDay, setMomentDay] = useState("");
    const { className } = props;

    useEffect(() => {
        const hour = new Date().getHours();
        const defineMomentDay =
            (hour > 6 && hour < 12 && "morning") || (hour >= 12 && hour < 18 && "afternoon") || "night";

        setMomentDay(`login.step1.welcome.${defineMomentDay}`);
    }, []);
    return (
        <Text
            component="h1"
            size="1"
            color="heading"
            bold
            id={momentDay}
            labelKey={momentDay}
            className={classNames("my-0", className)}
        />
    );
};

Welcome.propTypes = {
    className: string,
};

Welcome.defaultProps = {
    className: null,
};

export default Welcome;
