import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { any, arrayOf, bool, func, number, objectOf, oneOfType, shape, string } from "prop-types";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Notification from "pages/_components/Notification";

class SignaturesSchemeDelete extends Component {
    static propTypes = {
        actions: shape({
            deleteSignaturesSchemeRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        fetching: bool.isRequired,
        functionalGroups: arrayOf(string),
        masterCurrency: string,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        signature: shape({
            capList: arrayOf(objectOf(any)),
            groupsMap: oneOfType([
                shape({
                    A: number,
                }),
                shape(
                    configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                        const result = res;
                        result[signLevel] = number;

                        return result;
                    }, {}),
                ),
            ]),
            idSignature: number,
        }),
        formActions: objectOf(func).isRequired,
        idActivity: string,
        idTransaction: string,
    };

    static defaultProps = {
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        match: null,
        signature: null,
        idActivity: null,
        idTransaction: null,
    };

    componentDidMount() {
        const {
            actions,
            match: {
                params: { idSignature },
            },
        } = this.props;

        actions.deleteSignaturesSchemePreRequest(idSignature);
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, idTransaction, idActivity, functionalGroups, signature } = this.props;

        if (!idTransaction) {
            actions.deleteSignaturesSchemeRequest(
                {
                    credentials,
                    ...{ ...signature, functionalGroups },
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    renderFunctionalGroups = (functionalGroups) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label="administration.signatures.functionalGroups.label"
                data={functionalGroups.map((functionalGroup) => (
                    <div key={functionalGroup}>
                        <I18n id={`administration.signatures.functionalGroups.${functionalGroup}`} />
                    </div>
                ))}
            />
        </div>
    );

    renderSchemeReference = (administrationScheme) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label={
                    administrationScheme === "medium"
                        ? `administration.signatures.modify.${administrationScheme}.confirm.signersCount`
                        : `administration.signatures.modify.${administrationScheme}.confirm.schemeReference`
                }
                data={this.signersCount()}
            />
        </div>
    );

    renderTopAmount = (masterCurrency, amount, period) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label="administration.signatures.delete.topAmount.label"
                data={
                    <Fragment>
                        <FormattedAmount
                            className="data-amount content-data-strong"
                            currency={masterCurrency}
                            frequency={i18nUtils.get(
                                "administration.signatures.delete.advanced.topAmountPerPeriod",
                                null,
                                {
                                    period: i18nUtils.get(
                                        `administration.signatures.transactions.capFrequency.${period}`,
                                    ),
                                },
                            )}
                            quantity={amount}
                        />
                    </Fragment>
                }
            />
        </div>
    );

    renderTransactionType = (administrationScheme, signatureType) => (
        <div className="transfer-block">
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper"
                label={`administration.signatures.delete.${administrationScheme}.confirm.transactionType`}
                data={i18nUtils.get(`administration.signatures.delete.signatureType.${signatureType}`)}
            />
        </div>
    );

    signersCount = () => {
        const {
            activeEnvironment: { administrationScheme },
            signature: { groupsMap },
        } = this.props;

        return administrationScheme === "medium"
            ? `${groupsMap.A}`
            : Object.keys(groupsMap)
                  .sort()
                  .reduce((result, key) => `${result}${`${key}`.repeat(groupsMap[key])}`, "");
    };

    render() {
        const {
            credentialGroups,
            activeEnvironment: { administrationScheme },
            fetching,
            functionalGroups,
            masterCurrency,
            signature,
        } = this.props;
        const hasTopAmount =
            administrationScheme === "advanced" &&
            signature &&
            signature.signatureType === "AMOUNT" &&
            signature.capList &&
            signature.capList[0];

        return (
            <Fragment>
                <Notification scopeToShow="administrationDeleteSignatureScheme" />
                <Head
                    title={`administration.signatures.delete.${administrationScheme}.confirm.title`}
                    closeLinkTo={`/administration/${administrationScheme}/signaturesSchemes`}
                />
                <MainContainer showLoader={fetching}>
                    <div className="above-the-fold">
                        <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <p className="text-lead">
                                    <span>
                                        <I18n id="administration.forms.confirm.credentials" />
                                    </span>
                                </p>
                                {signature && signature.groupsMap && this.renderSchemeReference(administrationScheme)}
                                {signature &&
                                    signature.signatureType &&
                                    this.renderTransactionType(administrationScheme, signature.signatureType)}
                                {functionalGroups &&
                                    functionalGroups.length > 0 &&
                                    this.renderFunctionalGroups(functionalGroups)}
                                {hasTopAmount &&
                                    this.renderTopAmount(
                                        masterCurrency,
                                        signature.capList[0].maximum,
                                        signature.capList[0].frequency,
                                    )}
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default SignaturesSchemeDelete;
