/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { shape, string, bool, number } from "prop-types";
import React from "react";
import * as i18n from "util/i18n";
import { resizableRoute } from "pages/_components/Resizable";
import FormattedAmount from "../FormattedAmount";

const InfoCard = ({
    className,
    currency,
    date,
    image,
    inlineStyle,
    isDesktop,
    message,
    messageClassName,
    plus,
    quantity,
    status,
    title,
    tooltip,
    tooltipPosition,
}) => (
    <Box display="flex" alignX="between" alignY="flex-start" className={classNames("info-card", className)}>
        <Box
            display="flex"
            alignX={isDesktop ? "between" : "flex-start"}
            alignY="center"
            fullWidth
            flex1
            {...(inlineStyle && { fullHeight: true })}>
            {image && <Image className="info-card-icon" wrapperClassName="info-card-icon-svg-wrapper" src={image} />}
            <Box
                display="flex"
                {...(inlineStyle ? { alignX: "between", alignY: "center", fullWidth: true } : { column: true })}
                className="info-card-info-wrapper mr-auto">
                <Box
                    display="flex"
                    {...(inlineStyle
                        ? { className: "info-card-title-wrapper no-margin-bottom" }
                        : { className: "info-card-title-wrapper" })}>
                    <Text className="info-card-title">{i18n.get(title)}</Text>
                    {tooltip && <Tooltip position={tooltipPosition} text={tooltip} />}
                </Box>
                {currency ? (
                    <FormattedAmount
                        className="data-amount content-data-strong"
                        currency={currency}
                        quantity={quantity}
                    />
                ) : date ? (
                    <FormattedDate bold color="heading" date={date} />
                ) : message ? (
                    <Text bold color="heading" className={classNames("info-card-message", messageClassName)}>
                        {message}
                    </Text>
                ) : null}
            </Box>
            {plus && <Button bsStyle="link" image={plus} className="plus-btn" />}
        </Box>
        {status && <Text className="visually-hidden">{status.shortDescription}</Text>}
    </Box>
);

InfoCard.propTypes = {
    className: string,
    currency: string,
    date: string,
    image: string,
    inlineStyle: bool,
    isDesktop: bool,
    message: string,
    messageClassName: string,
    plus: string,
    quantity: number,
    status: shape({}),
    title: string,
    tooltip: string,
    tooltipPosition: string,
};

InfoCard.defaultProps = {
    className: null,
    currency: null,
    date: null,
    image: null,
    inlineStyle: false,
    isDesktop: false,
    message: null,
    messageClassName: null,
    plus: null,
    quantity: null,
    status: {},
    title: null,
    tooltip: null,
    tooltipPosition: "bottom-centered",
};

export default resizableRoute(InfoCard);
