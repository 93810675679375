import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import InfoCard from "pages/_components/InfoCard";
import { arrayOf, bool, shape, string } from "prop-types";
import React, { Children, Component } from "react";
import { connect } from "react-redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import classNames from "classnames";
import * as i18nUtils from "util/i18n";
import { selectors as loginSelectors } from "reducers/login";

class FooterDesktop extends Component {
    render() {
        const { dashboard, messages, moreOptions, urlFooterBanner, isSubmiting } = this.props;
        if (!messages) {
            return null;
        }

        return dashboard ? (
            <Box component="footer" className="app-dashboard-footer">
                <Button externalHref={urlFooterBanner} rel="noreferrer" className="p-0 border-radius-default">
                    <InfoCard
                        image="images/adornmentCards.svg"
                        title="footer.tittle"
                        message={i18nUtils.get("footer.text")}
                    />
                </Button>
            </Box>
        ) : (
            moreOptions && (
                <Box
                    component="footer"
                    className={classNames("app-default-footer", {
                        "pointer-events-none": isSubmiting,
                    })}>
                    <ul className="legal-nav list-inline">
                        {Children.map(moreOptions, (option, idx) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={idx}>{option}</li>
                        ))}
                    </ul>
                </Box>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    messages: i18nSelectors.getMessages(state),
    isSubmiting: loginSelectors.getSubmiting(state),
});

FooterDesktop.propTypes = {
    dashboard: bool,
    urlFooterBanner: string,
    moreOptions: arrayOf(shape({})),
    messages: shape({}),
    isSubmiting: bool,
};

FooterDesktop.defaultProps = {
    dashboard: false,
    moreOptions: null,
    messages: null,
    urlFooterBanner: null,
    isSubmiting: false,
};

export default connect(mapStateToProps)(FooterDesktop);
