import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { bool, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";

const FieldError = (props) => {
    const { error, relativeStyleError, absoluteStyleError } = props;

    const [showEllipsis, setShowEllipsis] = useState(false);

    const wrapperRef = useRef(null);
    const tooltipRef = useRef(null);

    useEffect(() => {
        if (wrapperRef.current.offsetWidth > tooltipRef.current.offsetWidth) {
            setShowEllipsis(false);
        }
        if (wrapperRef.current.offsetWidth <= tooltipRef.current.offsetWidth) {
            setShowEllipsis(true);
        }
    }, []);

    return (
        error && (
            <Box
                fullWidth
                className={classNames("form-group-error-wrapper", {
                    "form-group-error-wrapper-relative": relativeStyleError,
                    "form-group-error-wrapper-absolute": absoluteStyleError,
                })}>
                <div ref={wrapperRef} className="form-group-error mt-1">
                    <div ref={tooltipRef} {...(showEllipsis && { className: "full-width" })}>
                        {showEllipsis ? (
                            <Tooltip
                                onHover
                                forEllipsis
                                inText={error}
                                position="bottom-left"
                                className="cursor-pointer full-width">
                                <Box display="flex" alignY="center">
                                    <Image
                                        src="images/icons/errorIcon.svg"
                                        className="svg-icon error-icon"
                                        wrapperWidth="6"
                                    />
                                    <Text className="mx-2" ellipsis>
                                        {error}
                                    </Text>
                                </Box>
                            </Tooltip>
                        ) : (
                            <Box display="flex" alignY="center">
                                <Image
                                    src="images/icons/errorIcon.svg"
                                    className="svg-icon error-icon"
                                    wrapperWidth="6"
                                />
                                <Text>{error}</Text>
                            </Box>
                        )}
                    </div>
                </div>
            </Box>
        )
    );
};

FieldError.propTypes = {
    error: string.isRequired,
    relativeStyleError: bool,
    absoluteStyleError: bool,
};

FieldError.defaultProps = {
    relativeStyleError: false,
    absoluteStyleError: false,
};

export default FieldError;
