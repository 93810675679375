import classNames from "classnames";
import Image from "pages/_components/Image";
import Spinner from "pages/_components/Spinner";
import Text from "pages/_components/Text";
import { bool, func, node, shape, string } from "prop-types";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { openLink } from "util/browser";
import { isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";

const Button = ({
    ariaHasPopup,
    ariaLabel,
    black,
    block,
    bold,
    regular,
    bsStyle,
    btnUppercase,
    children,
    className,
    defaultLabelText,
    disabled,
    expandable,
    externalHref,
    href,
    id,
    image,
    imageColor,
    imageMd,
    imageRight,
    imageSm,
    imageXs,
    inverse,
    label,
    labelClassName,
    loading,
    onClick,
    paragraph,
    primaryLink,
    removeMinHeight,
    secondary,
    small,
    style,
    tabIndex,
    to,
    tooltipHover,
    type,
    underlined,
    underlinedIn,
    ...props
}) => {
    const [pressed, setPressed] = useState(false);

    const buttonRef = useRef(null);

    const handleClick = (e) => {
        if (type === "button") {
            e.preventDefault();
        }
        setPressed(!pressed);
        if (externalHref) {
            e.preventDefault();
            if (isMobileNativeFunc()) {
                openLink(externalHref).then();
            } else {
                window.open(externalHref, "_system");
            }
            return false;
        }

        if (buttonRef.current) {
            buttonRef.current.focus();
        }

        if (onClick && typeof onClick === "function") {
            return onClick(e);
        }
        return false;
    };
    const handleFocus = (e) => {
        if (typeof props.onFocus === "function") {
            props.onFocus(e);
        }
    };

    const handleBlur = (e) => {
        setPressed(false);
        if (typeof props.onBlur === "function") {
            props.onBlur(e);
        }
    };

    // eslint-disable-next-line no-nested-ternary
    const Component = href ? Link : externalHref ? "a" : "button";

    const specificID = Math.random()
        .toString(36)
        .substring(2, 7);

    return (
        <Component
            ref={buttonRef}
            {...(id && { id })}
            {...(tabIndex && { tabIndex })}
            {...(ariaLabel && { "aria-label": i18n.get(ariaLabel) })}
            {...((label || defaultLabelText) && { "aria-labelledby": `id-labelledby-${specificID}` })}
            {...(tooltipHover && { tooltipHover })}
            {...(expandable &&
                !href && {
                    "aria-expanded": `${pressed}`,
                    "aria-haspopup": `${ariaHasPopup}`,
                })}
            {...(expandable &&
                !href &&
                id && {
                    "aria-controls": `${id}`,
                })}
            {...(!href && !externalHref && { type })}
            {...(href && { to: href })}
            {...(externalHref && !isMobileNativeFunc() && { href: externalHref, target: "_blank" })}
            className={classNames("btn", loading && "is-loading", className, {
                "btn-block": block,
                "btn-primary": bsStyle === "primary",
                "btn-outline": bsStyle === "outline",
                "btn-link": bsStyle === "link",
                "btn-link-wally": bsStyle === "link-wally",
                "btn-chip": bsStyle === "chip",
                "btn-tab": bsStyle === "tab",
                "btn-only-icon": bsStyle === "only-icon",
                "btn-item": bsStyle === "item",
                "btn-big": bsStyle === "big",
                "btn-social-icon": bsStyle === "socialIcon",
                "btn-big-link": bsStyle === "big-link",
                "btn-menu-synchronization": bsStyle === "menuSynchronization",
                "btn-wally-menu": bsStyle === "wally-menu",
                "btn-a11y": bsStyle === "a11y",
                "tooltip-hover": tooltipHover,
                "btn-black": black,
                "btn-secondary": secondary,
                "btn-primary-link": primaryLink,
                "btn-inverse": inverse,
                "btn-small": small,
                "btn-xs": imageXs,
                "icon-sm": imageSm,
                "icon-md": imageMd,
                "button-underlined": underlined,
                "button-underlined-in": underlinedIn,
                "btn-paragraph": paragraph,
                "btn-bold": bold,
                "btn-regular": regular,
                "btn-remove-min-height": removeMinHeight,
                "btn-uppercase": (btnUppercase && bsStyle === "primary") || (btnUppercase && bsStyle === "outline"),
            })}
            {...(!href && !isMobileNativeFunc() && { onClick: (e) => handleClick(e) })}
            {...(!href && isMobileNativeFunc() && { onTouchEnd: (e) => handleClick(e) })}
            {...(!href && { onBlur: (e) => handleBlur(e) })}
            {...(!href && { onFocus: (e) => handleFocus(e) })}
            style={style}
            disabled={disabled}
            {...(loading && { disabled: true })}
            {...props}>
            {loading ? (
                <>
                    <Spinner />

                    {label && <Text labelKey={label} className="visibility-hidden" />}
                    {!label && defaultLabelText && <Text className="visibility-hidden">{defaultLabelText}</Text>}
                    {children && <div className="visibility-hidden">{children}</div>}
                </>
            ) : (
                <>
                    {image && !imageRight && (
                        <Image src={image} color={imageColor} imageXs={imageXs} imageSm={imageSm} imageMd={imageMd} />
                    )}
                    {label && (
                        <Text
                            id={`id-labelledby-${specificID}`}
                            labelKey={label}
                            className={classNames(labelClassName, { "visually-hidden": bsStyle === "only-icon" })}
                        />
                    )}
                    {!label && defaultLabelText && (
                        <Text
                            id={`id-labelledby-${specificID}`}
                            className={classNames({ "visually-hidden": bsStyle === "only-icon" })}>
                            {defaultLabelText}
                        </Text>
                    )}

                    {children}

                    {image && imageRight && (
                        <Image
                            imageRight
                            src={image}
                            color={imageColor}
                            imageXs={imageXs}
                            imageSm={imageSm}
                            imageMd={imageMd}
                        />
                    )}
                </>
            )}
        </Component>
    );
};

Button.propTypes = {
    ariaHasPopup: string,
    ariaLabel: string,
    black: bool,
    block: bool,
    bold: bool,
    bsStyle: string,
    btnUppercase: bool,
    children: node,
    className: string,
    defaultLabelText: string,
    disabled: bool,
    expandable: bool,
    externalHref: string,
    href: string,
    id: string,
    image: string,
    imageColor: string,
    imageMd: bool,
    imageRight: bool,
    imageSm: bool,
    imageXs: bool,
    inverse: bool,
    label: string,
    labelClassName: string,
    loading: bool,
    onBlur: func,
    onClick: func,
    onFocus: func,
    paragraph: bool,
    primaryLink: bool,
    regular: bool,
    removeMinHeight: bool,
    secondary: bool,
    small: bool,
    style: shape({}),
    tabIndex: string,
    to: string,
    tooltipHover: string,
    type: string,
    underlined: bool,
    underlinedIn: bool,
};

Button.defaultProps = {
    ariaHasPopup: null,
    ariaLabel: null,
    black: false,
    block: false,
    bold: false,
    btnUppercase: true,
    bsStyle: null,
    children: null,
    className: null,
    defaultLabelText: "",
    disabled: false,
    expandable: false,
    externalHref: null,
    href: null,
    id: null,
    image: null,
    imageColor: null,
    imageMd: false,
    imageRight: false,
    imageSm: false,
    imageXs: false,
    inverse: false,
    label: "",
    labelClassName: null,
    loading: false,
    onBlur: undefined,
    onClick: () => {},
    onFocus: undefined,
    paragraph: false,
    primaryLink: false,
    regular: false,
    removeMinHeight: false,
    secondary: false,
    small: false,
    style: null,
    tabIndex: null,
    to: null,
    tooltipHover: null,
    type: "button",
    underlined: null,
    underlinedIn: null,
};

export default Button;
