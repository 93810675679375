import { createReducer } from "util/redux";

export const types = {
    LOAD_THEME: "theme/LOAD_THEME",
};
export const INITIAL_STATE = {
    themeOption: "",
};
export default createReducer(INITIAL_STATE, {
    [types.LOAD_THEME]: (state, action) => ({
        ...state,
        themeOption: action.theme,
    }),
});
export const actions = {
    changeLoadTheme: (theme) => {
        document.documentElement.setAttribute("data-theme", theme);
        return {
            type: types.LOAD_THEME,
            theme,
        };
    },
};
export const selectors = {
    getThemeOption: (state) => state.theme.themeOption,
};
