/* eslint-disable jsx-a11y/label-has-associated-control */
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, number } from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { compose } from "redux";

const BiometricModal = (props) => {
    const { dispatch, fingerPrinterModalShow = false, updateShowModal } = props;
    const [showModal, setShowModal] = useState(false);
    const [showAgain, setShowAgain] = useState(true);
    useEffect(() => {
        dispatch(fingerprintActions.fingerPrintConfigurationModalValidate());
    }, []);

    useEffect(() => {
        const shouldShowModal = fingerPrinterModalShow;
        setShowModal(shouldShowModal);
    }, [updateShowModal, fingerPrinterModalShow]);

    const handleCheckboxChange = (event) => {
        setShowAgain(!event.target.checked);
    };
    const handleClose = () => {
        setShowModal(false);
        dispatch(fingerprintActions.fingerprintHideWidget(showAgain));
    };

    const handleConfigure = () => {
        setShowModal(false);
        dispatch(push("/settings/fingerprintConfiguration"));
    };

    return (
        <Modal show={showModal}>
            <Box display="flex" className="biometric-modal" column alignY="center">
                <Modal.Body>
                    <Box>
                        <Image src="images/bioIcon.svg" className="mb-6 svg-image-biometric-modal" />
                        <Text
                            component="p"
                            color="heading"
                            size="5"
                            align="center"
                            labelKey="widgets.biometricIdentification.title"
                            className="mb-5 mt-0"
                        />
                        <Text
                            component="p"
                            color="heading"
                            size="5"
                            align="center"
                            labelKey="widgets.biometricIdentification.body"
                            className="mb-8 mt-0"
                        />

                        <Box className="c-control c-control--checkbox mb-7 mt-0" display="flex" alignY="left">
                            <input
                                type="checkbox"
                                id="rememberBiometrics"
                                onChange={handleCheckboxChange}
                                className="c-control-input"
                            />
                            <label className="c-control-label" htmlFor="rememberBiometrics">
                                <div className="c-control-icons">
                                    <div className="c-control-mark">
                                        <Image src="images/check.svg" className="svg-icon svg-caret" />
                                    </div>
                                </div>
                                <Text
                                    color="text"
                                    size="5"
                                    align="center"
                                    labelKey="widgets.biometricIdentification.dontShowAgain"
                                />
                            </label>
                        </Box>
                        <Button
                            bsStyle="primary"
                            label="widgets.biometricIdentification.configureNow"
                            onClick={handleConfigure}
                            type="button"
                            block
                        />
                        <Button
                            bsStyle="outline"
                            label="global.close"
                            onClick={handleClose}
                            type="button"
                            block
                            className="mt-3"
                        />
                    </Box>
                </Modal.Body>
            </Box>
        </Modal>
    );
};

BiometricModal.propTypes = {
    dispatch: func.isRequired,
    updateShowModal: number,
    fingerPrinterModalShow: bool,
};

BiometricModal.defaultProps = {
    updateShowModal: 0,
    fingerPrinterModalShow: false,
};
const mapStateToProps = (state) => ({
    fingerPrinterModalShow: fingerprintSelectors.fingerPrinterModalShow(state) || false,
    updateShowModal: fingerprintSelectors.updateShowModal(state),
});

export default compose(connect(mapStateToProps), resizableRoute)(BiometricModal);
