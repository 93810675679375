/* eslint-disable camelcase */
import { format } from "date-fns";
import { View } from "@react-pdf/renderer";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { PDFTextField, styles } from "pages/forms/customForms/PDFTicket";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import TicketRenderCustom from "../TicketRenderCustom";

const TicketBeneficiary = (props) => {
    const { transaction } = props;
    const { activityName: title, idActivity } = transaction;

    const ID_ACTIVITY = `beneficiary.view`;

    const renderItemDetail = (itemTitle, value, isPDF, isCapitalize = true, isUppercase = false, isWrap = false) => {
        if (isPDF) {
            return value ? <PDFTextField value={value} label={itemTitle} /> : null;
        }
        return (
            <Box display={isWrap ? "flex" : "block"} className="mb-4" wrap={isWrap}>
                <Text size="5" color="heading" bold addColon>
                    {itemTitle || ""}
                </Text>
                <Text size="5" color="heading" capitalize={isCapitalize} uppercase={isUppercase}>
                    {(value || "").toLowerCase()}
                </Text>
            </Box>
        );
    };

    const renderDetailBank = (bankName, bankAddress, bankCountryName, bankCity, bankCodeType, swiftCode, isPDF) => {
        const Component = isPDF ? View : Box;

        return (
            <Component {...(!isPDF && { className: "mt-2" })}>
                {swiftCode
                    ? renderItemDetail(
                          i18n.get("forms.transfers.foreign.beneficiary.bankSelector.label_preview"),
                          swiftCode,
                          isPDF,
                          false,
                          true,
                      )
                    : null}
                {bankName
                    ? renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryBankName.label`), bankName, isPDF)
                    : null}
                {bankAddress
                    ? renderItemDetail(
                          i18n.get("forms.transfers.foreign.beneficiary.bankAddress.label_preview"),
                          bankAddress,
                          isPDF,
                      )
                    : null}
                {bankCountryName
                    ? renderItemDetail(
                          i18n.get("forms.transfers.foreign.beneficiary.bankCountry.label_preview"),
                          bankCountryName,
                          isPDF,
                      )
                    : null}
                {bankCity
                    ? renderItemDetail(
                          i18n.get("forms.transfers.foreign.beneficiary.bankCity.label_preview"),
                          bankCity,
                          isPDF,
                      )
                    : null}
                {bankCodeType
                    ? renderItemDetail(
                          `${i18n.get(
                              "forms.transfers.foreign.beneficiary.bankSelector.label_preview",
                          )}<${bankCodeType}>`,
                          bankCodeType,
                          isPDF,
                      )
                    : null}
            </Component>
        );
    };

    const renderGenericData = (
        account,
        activityName,
        address,
        beneficiaryType,
        city,
        country,
        creationDateTimeAsString,
        email,
        isInternational,
        isPDF,
        name,
        number,
        productType,
        relationshipType,
        serviceDescription,
    ) => {
        const Component = isPDF ? View : Box;
        return (
            <Component>
                {renderItemDetail(i18n.get(`transactions.ticket.date.label`), creationDateTimeAsString, isPDF)}
                {renderItemDetail(i18n.get(`transactions.ticket.typeOperation.label`), activityName, isPDF)}
                {renderItemDetail(
                    i18n.get(`${ID_ACTIVITY}.beneficiaryType.label`),
                    i18n.get(`beneficiary.type.${beneficiaryType.toLowerCase()}.label`),
                    isPDF,
                )}
                {!isInternational
                    ? renderItemDetail(
                          i18n.get(`${ID_ACTIVITY}.beneficiaryProductType.label`),
                          i18n.get(
                              // eslint-disable-next-line max-len
                              `beneficiary.productType.${beneficiaryType.toLowerCase()}.${productType.id.toLowerCase()}.label`,
                          ),
                          isPDF,
                      )
                    : renderItemDetail(
                          i18n.get(`${ID_ACTIVITY}.beneficiaryProductType.internationa.label`),
                          productType.label,
                          isPDF,
                      )}
                {isInternational &&
                    renderItemDetail(
                        i18n.get(`${ID_ACTIVITY}.beneficiaryRelationship.label`),
                        relationshipType.label,
                        isPDF,
                    )}
                {!isInternational &&
                    renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryProductNumber.label`), number, isPDF)}
                {renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryName.label`), name, isPDF)}
                {email && renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryEmail.label`), email, isPDF, false)}

                {isInternational &&
                    renderItemDetail(
                        i18n.get(`${ID_ACTIVITY}.beneficiaryAccount.label`),
                        account,
                        isPDF,
                        false,
                        true,
                        true,
                    )}
                {isInternational &&
                    renderItemDetail(
                        i18n.get(`${ID_ACTIVITY}.beneficiaryServiceDescription.label`),
                        serviceDescription,
                        isPDF,
                    )}
                {isInternational &&
                    renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryAddress.label`), address, isPDF)}
                {isInternational && renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryCity.label`), city, isPDF)}
                {isInternational &&
                    renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryCountry.label`), country.label, isPDF)}
            </Component>
        );
    };

    const renderData = (isPDF) => {
        const Component = isPDF ? View : Box;

        if (transaction.idActivity === "beneficiary.delete") {
            return (
                <Component {...(!isPDF && { className: "pt-6 mx-7" })} {...(isPDF && { style: styles.marginTop18pt })}>
                    {renderItemDetail(
                        i18n.get(`transactions.ticket.date.label`),
                        transaction.creationDateTimeAsString,
                        isPDF,
                    )}
                    {renderItemDetail(
                        i18n.get(`transactions.ticket.typeOperation.label`),
                        transaction.activityName,
                        isPDF,
                    )}
                </Component>
            );
        }

        const {
            data: { beneficiaryType, beneficiary },
            activityName,
            creationDateTime,
        } = transaction || {};
        const {
            account,
            addIntermediaryBank,
            address,
            bankAddress,
            bankCity,
            bankCodeType,
            bankCountryName,
            bankName,
            city,
            country,
            email,
            intermediary_bankAddress,
            intermediary_bankCity,
            intermediary_bankCodeType,
            intermediary_bankCountryName,
            intermediary_bankName,
            intermediary_swiftCode,
            name,
            number,
            productType,
            relationshipType,
            serviceDescription,
            swiftCode,
        } = beneficiary;

        const isInternational = beneficiaryType === "FOREIGN_TRANSFER";
        return (
            <Component {...(!isPDF && { className: "pt-6 mx-7" })} {...(isPDF && { style: styles.marginTop18pt })}>
                {renderGenericData(
                    account,
                    activityName,
                    address,
                    beneficiaryType,
                    city,
                    country,
                    format(creationDateTime, "DD/MM/YYYY"),
                    email,
                    isInternational,
                    isPDF,
                    name,
                    number,
                    productType,
                    relationshipType,
                    serviceDescription,
                )}
                {isInternational &&
                    renderDetailBank(bankName, bankAddress, bankCountryName, bankCity, bankCodeType, swiftCode, isPDF)}
                {addIntermediaryBank &&
                    renderDetailBank(
                        intermediary_bankAddress,
                        intermediary_bankCity,
                        intermediary_bankCodeType,
                        intermediary_bankCountryName,
                        intermediary_bankName,
                        intermediary_swiftCode,
                        isPDF,
                    )}
            </Component>
        );
    };

    const renderFields = () => renderData(false);

    const renderTicket = () => renderData(true);

    const formProps = {
        title,
        renderTicket,
        renderFields,
        idActivity,
    };
    return <TicketRenderCustom {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    fetching: formSelectors.getFetching(state),
    id: formSelectors.getId(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    mode: formSelectors.getMode(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    templates: templateSelectors.getTemplateList(state),
    ticketConfirmation: true,
    transaction: formSelectors.getTransaction(state),
});

TicketBeneficiary.propTypes = {
    currentLang: string,
    dispatch: func.isRequired,
    fromBackoffice: bool,
    fromTransaction: bool,
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    location: shape({}),
    mode: string,
    preDataForm: shape({}),
    previewData: shape({}),
    transaction: shape({}),
};
TicketBeneficiary.defaultProps = {
    currentLang: "",
    fromBackoffice: false,
    fromTransaction: false,
    location: {},
    mode: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(TicketBeneficiary));
