import { createReducer, makeActionCreator } from "util/redux";
import formTypes from "reducers/types/form";

const INITIAL_STATE = {
    capFrequencyList: null,
    caps: null,
    currentPage: 1,
    currentSignature: null,
    currentSignatureGroupMap: null,
    defaultSignatureCap: 0,
    enabledChannels: null,
    functionalGroups: null,
    hasMoreData: false,
    isFetching: false,
    fetchingExport: false,
    fetchingMoreSignaturesSchemes: false,
    masterCurrency: null,
    selectedFunctionalGroups: [],
    signatureId: null,
    signatureLevelsCounts: null,
    signaturesSchemes: [],
    signatureType: null,
    signatureTypeList: null,
    topAmount: {
        amount: 0,
        period: "daily",
    },
    totalPages: 0,
    credentialGroups: [],
    idTransaction: null,
    idActivity: null,
    signatureDispatch: false,
};

export const selectors = {
    getCredentialGroups: ({ signaturesSchemes }) => signaturesSchemes.credentialGroups,
    getIdTransaction: ({ signaturesSchemes }) => signaturesSchemes.idTransaction,
    getIdActivity: ({ signaturesSchemes }) => signaturesSchemes.idActivity,
    capFrequencyList: ({ signaturesSchemes }) => signaturesSchemes.capFrequencyList,
    caps: ({ signaturesSchemes }) => signaturesSchemes.caps,
    currentPage: ({ signaturesSchemes }) => signaturesSchemes.currentPage,
    currentSignature: ({ signaturesSchemes }) => signaturesSchemes.currentSignature,
    currentSignatureGroupMap: ({ signaturesSchemes }) => signaturesSchemes.currentSignatureGroupMap,
    defaultSignatureCap: ({ signaturesSchemes }) => signaturesSchemes.defaultSignatureCap,
    enabledChannels: ({ signaturesSchemes }) => signaturesSchemes.enabledChannels,
    fetching: ({ signaturesSchemes }) => signaturesSchemes.isFetching,
    isFetchingExport: ({ signaturesSchemes }) => signaturesSchemes.fetchingExport,
    isFetchingMoreSignaturesSchemes: ({ signaturesSchemes }) => signaturesSchemes.fetchingMoreSignaturesSchemes,
    functionalGroups: ({ signaturesSchemes }) => signaturesSchemes.functionalGroups,
    environmentProducts: ({ signaturesSchemes }) => signaturesSchemes.environmentProducts,
    selectedProducts: ({ signaturesSchemes }) => signaturesSchemes.selectedProducts,
    hasMoreData: ({ signaturesSchemes }) => signaturesSchemes.hasMoreData,
    masterCurrency: ({ signaturesSchemes }) => signaturesSchemes.masterCurrency,
    selectedFunctionalGroups: ({ signaturesSchemes }) => signaturesSchemes.selectedFunctionalGroups,
    signatureId: ({ signaturesSchemes }) => signaturesSchemes.signatureId,
    signatureLevelsCounts: ({ signaturesSchemes }) => signaturesSchemes.signatureLevelsCounts,
    signaturesSchemes: ({ signaturesSchemes }) => signaturesSchemes.signaturesSchemes,
    signatureType: ({ signaturesSchemes }) => signaturesSchemes.signatureType,
    signatureTypeList: ({ signaturesSchemes }) => signaturesSchemes.signatureTypeList,
    topAmount: ({ signaturesSchemes }) => signaturesSchemes.topAmount,
    totalPages: ({ signaturesSchemes }) => signaturesSchemes.totalPages,
    signatureDispatch: ({ signaturesSchemes }) => signaturesSchemes.signatureDispatch,
};

export default (name) => {
    const types = {
        CREATE_SIGNATURES_SCHEME_CONFIRM_PRE: `${name}/CREATE_SIGNATURES_SCHEME_CONFIRM_PRE`,
        CREATE_SIGNATURES_SCHEME_FAILURE: `${name}/CREATE_SIGNATURES_SCHEME_FAILURE`,
        CREATE_SIGNATURES_SCHEME_REQUEST: `${name}/CREATE_SIGNATURES_SCHEME_REQUEST`,
        CREATE_SIGNATURES_SCHEME_SUCCESS: `${name}/CREATE_SIGNATURES_SCHEME_SUCCESS`,
        CREATE_SIGNATURES_SCHEME_PRE_FAILURE: `${name}/CREATE_SIGNATURES_SCHEME_PRE_FAILURE`,
        CREATE_SIGNATURES_SCHEME_PRE_REQUEST: `${name}/CREATE_SIGNATURES_SCHEME_PRE_REQUEST`,
        CREATE_SIGNATURES_SCHEME_PRE_SUCCESS: `${name}/CREATE_SIGNATURES_SCHEME_PRE_SUCCESS`,
        DELETE_SIGNATURES_SCHEME_FAILURE: `${name}/DELETE_SIGNATURES_SCHEME_FAILURE`,
        DELETE_SIGNATURES_SCHEME_REQUEST: `${name}/DELETE_SIGNATURES_SCHEME_REQUEST`,
        DELETE_SIGNATURES_SCHEME_SUCCESS: `${name}/DELETE_SIGNATURES_SCHEME_SUCCESS`,
        DELETE_SIGNATURES_SCHEME_PRE_FAILURE: `${name}/DELETE_SIGNATURES_SCHEME_PRE_FAILURE`,
        DELETE_SIGNATURES_SCHEME_PRE_REQUEST: `${name}/DELETE_SIGNATURES_SCHEME_PRE_REQUEST`,
        DELETE_SIGNATURES_SCHEME_PRE_SUCCESS: `${name}/DELETE_SIGNATURES_SCHEME_PRE_SUCCESS`,
        LIST_SIGNATURES_SCHEMES_FAILURE: `${name}/LIST_SIGNATURES_SCHEMES_FAILURE`,
        LIST_SIGNATURES_SCHEMES_REQUEST: `${name}/LIST_SIGNATURES_SCHEMES_REQUEST`,
        LIST_SIGNATURES_SCHEMES_SUCCESS: `${name}/LIST_SIGNATURES_SCHEMES_SUCCESS`,
        MODIFY_SIGNATURES_SCHEME_CONFIRM_PRE: `${name}/MODIFY_SIGNATURES_SCHEME_CONFIRM_PRE`,
        DELETE_SIGNATURES_SCHEME_CONFIRM_PRE: `${name}/DELETE_SIGNATURES_SCHEME_CONFIRM_PRE`,
        MODIFY_SIGNATURES_SCHEME_FAILURE: `${name}/MODIFY_SIGNATURES_SCHEME_FAILURE`,
        MODIFY_SIGNATURES_SCHEME_REQUEST: `${name}/MODIFY_SIGNATURES_SCHEME_REQUEST`,
        MODIFY_SIGNATURES_SCHEME_SUCCESS: `${name}/MODIFY_SIGNATURES_SCHEME_SUCCESS`,
        MODIFY_SIGNATURES_SCHEME_PRE_FAILURE: `${name}/MODIFY_SIGNATURES_SCHEME_PRE_FAILURE`,
        MODIFY_SIGNATURES_SCHEME_PRE_REQUEST: `${name}/MODIFY_SIGNATURES_SCHEME_PRE_REQUEST`,
        MODIFY_SIGNATURES_SCHEME_PRE_SUCCESS: `${name}/MODIFY_SIGNATURES_SCHEME_PRE_SUCCESS`,
        SIGNATURES_SCHEME_EXPORT_LIST_REQUEST: `${name}/SIGNATURES_SCHEME_EXPORT_LIST_REQUEST`,
        SIGNATURES_SCHEME_EXPORT_LIST_FAILURE: `${name}/SIGNATURES_SCHEME_EXPORT_LIST_FAILURE`,
        SIGNATURES_SCHEME_EXPORT_LIST_SUCCESS: `${name}/SIGNATURES_SCHEME_EXPORT_LIST_SUCCESS`,
        SIGNATURES_SCHEME_LOAD_MORE_REQUEST: `${name}/SIGNATURES_SCHEME_LOAD_MORE_REQUEST`,
        SIGNATURES_SCHEME_LOAD_MORE_SUCCESS: `${name}/SIGNATURES_SCHEME_LOAD_MORE_SUCCESS`,
    };

    const actions = {
        createSignaturesSchemeConfirmPre: makeActionCreator(
            types.CREATE_SIGNATURES_SCHEME_CONFIRM_PRE,
            "data",
            "credentialGroups",
            "idTransaction",
            "idActivity",
        ),
        createSignaturesSchemeFailure: makeActionCreator(types.CREATE_SIGNATURES_SCHEME_FAILURE),
        createSignaturesSchemeRequest: makeActionCreator(
            types.CREATE_SIGNATURES_SCHEME_REQUEST,
            "signatureData",
            "formikBag",
        ),
        createSignaturesSchemeSuccess: makeActionCreator(types.CREATE_SIGNATURES_SCHEME_SUCCESS, "data"),
        createSignaturesSchemePreFailure: makeActionCreator(types.CREATE_SIGNATURES_SCHEME_PRE_FAILURE),
        createSignaturesSchemePreRequest: makeActionCreator(types.CREATE_SIGNATURES_SCHEME_PRE_REQUEST),
        createSignaturesSchemePreSuccess: makeActionCreator(
            types.CREATE_SIGNATURES_SCHEME_PRE_SUCCESS,
            "data",
            "credentialGroups",
        ),
        deleteSignaturesSchemeFailure: makeActionCreator(types.DELETE_SIGNATURES_SCHEME_FAILURE),
        deleteSignaturesSchemeRequest: makeActionCreator(
            types.DELETE_SIGNATURES_SCHEME_REQUEST,
            "signatureData",
            "formikBag",
        ),
        deleteSignaturesSchemeSuccess: makeActionCreator(types.DELETE_SIGNATURES_SCHEME_SUCCESS),
        deleteSignaturesSchemePreFailure: makeActionCreator(types.DELETE_SIGNATURES_SCHEME_PRE_FAILURE),
        deleteSignaturesSchemePreRequest: makeActionCreator(types.DELETE_SIGNATURES_SCHEME_PRE_REQUEST, "idSignature"),
        deleteSignaturesSchemePreSuccess: makeActionCreator(
            types.DELETE_SIGNATURES_SCHEME_PRE_SUCCESS,
            "data",
            "credentialGroups",
        ),
        listSignaturesSchemesFailure: makeActionCreator(types.LIST_SIGNATURES_SCHEMES_FAILURE),
        listSignaturesSchemesRequest: (filters) => ({
            type: types.LIST_SIGNATURES_SCHEMES_REQUEST,
            filters,
        }),
        listSignaturesSchemesSuccess: makeActionCreator(types.LIST_SIGNATURES_SCHEMES_SUCCESS, "data"),
        modifySignaturesSchemeConfirmPre: makeActionCreator(
            types.MODIFY_SIGNATURES_SCHEME_CONFIRM_PRE,
            "data",
            "credentialGroups",
            "idTransaction",
            "idActivity",
        ),
        deleteSignaturesSchemeConfirmPre: makeActionCreator(
            types.DELETE_SIGNATURES_SCHEME_CONFIRM_PRE,
            "idTransaction",
            "idActivity",
        ),
        modifySignaturesSchemeFailure: makeActionCreator(types.MODIFY_SIGNATURES_SCHEME_FAILURE),
        modifySignaturesSchemeRequest: makeActionCreator(
            types.MODIFY_SIGNATURES_SCHEME_REQUEST,
            "signatureData",
            "formikBag",
            "credentialGroups",
        ),
        modifySignaturesSchemeSuccess: makeActionCreator(types.MODIFY_SIGNATURES_SCHEME_SUCCESS, "data"),
        modifySignaturesSchemePreFailure: makeActionCreator(types.MODIFY_SIGNATURES_SCHEME_PRE_FAILURE),
        modifySignaturesSchemePreRequest: (idSignature) => ({
            type: types.MODIFY_SIGNATURES_SCHEME_PRE_REQUEST,
            idSignature,
        }),
        modifySignaturesSchemePreSuccess: makeActionCreator(
            types.MODIFY_SIGNATURES_SCHEME_PRE_SUCCESS,
            "data",
            "credentialGroups",
        ),
        exportListRequest: makeActionCreator(types.SIGNATURES_SCHEME_EXPORT_LIST_REQUEST, "format"),
        exportListSuccess: makeActionCreator(types.SIGNATURES_SCHEME_EXPORT_LIST_SUCCESS),
        exportListFailure: makeActionCreator(types.SIGNATURES_SCHEME_EXPORT_LIST_FAILURE),
        loadMoreRequest: makeActionCreator(types.SIGNATURES_SCHEME_LOAD_MORE_REQUEST, "pageNumber"),
        listMoreSignaturesSchemesSuccess: makeActionCreator(types.SIGNATURES_SCHEME_LOAD_MORE_SUCCESS, "data"),
    };

    return {
        actions,
        reducer: createReducer(INITIAL_STATE, {
            [types.CREATE_SIGNATURES_SCHEME_CONFIRM_PRE]: (
                state,
                {
                    data: {
                        selectedFunctionalGroups,
                        selectedProducts,
                        signatureLevelsCounts,
                        signatureType,
                        topAmount,
                        signatureDispatch,
                    },
                    credentialGroups,
                    idTransaction,
                    idActivity,
                },
            ) => ({
                ...state,
                selectedFunctionalGroups,
                selectedProducts,
                signatureLevelsCounts,
                signatureType,
                topAmount,
                credentialGroups,
                idTransaction,
                idActivity,
                signatureDispatch,
            }),
            [types.CREATE_SIGNATURES_SCHEME_FAILURE]: (state) => ({
                ...state,
                isFetching: false,
            }),
            [types.CREATE_SIGNATURES_SCHEME_SUCCESS]: (state) => ({
                ...state,
                isFetching: false,
            }),
            [types.CREATE_SIGNATURES_SCHEME_PRE_FAILURE]: (state) => ({
                ...state,
                isFetching: false,
            }),
            [types.CREATE_SIGNATURES_SCHEME_PRE_REQUEST]: (state) => ({
                ...state,
                isFetching: true,
            }),
            [types.CREATE_SIGNATURES_SCHEME_PRE_SUCCESS]: (
                state,
                {
                    data: {
                        capFrequencyList,
                        enabledChannels,
                        functionalGroups,
                        environmentProducts,
                        masterCurrency,
                        signatureTypeList,
                        signatureDispatch,
                    },
                    credentialGroups,
                },
            ) => ({
                ...state,
                capFrequencyList,
                enabledChannels,
                functionalGroups,
                environmentProducts,
                isFetching: false,
                masterCurrency,
                signatureTypeList,
                credentialGroups,
                signatureDispatch,
            }),
            [types.DELETE_SIGNATURES_SCHEME_PRE_FAILURE]: (state) => ({
                ...state,
                isFetching: false,
            }),
            [types.DELETE_SIGNATURES_SCHEME_PRE_REQUEST]: (state) => ({
                ...state,
                isFetching: true,
            }),
            [types.DELETE_SIGNATURES_SCHEME_PRE_SUCCESS]: (
                state,
                {
                    data: {
                        capFrequencyList,
                        caps,
                        enabledChannels,
                        masterCurrency,
                        selectedFunctionalGroups,
                        signature,
                        signatureGroupMap,
                        signatureTypeList,
                    },
                    credentialGroups,
                },
            ) => ({
                ...state,
                credentialGroups,
                capFrequencyList,
                caps,
                currentSignature: signature,
                currentSignatureGroupMap: signatureGroupMap,
                enabledChannels,
                isFetching: false,
                masterCurrency,
                selectedFunctionalGroups,
                signatureTypeList,
            }),
            [formTypes.SEND_FORM_SUCCESS]: () => ({
                ...INITIAL_STATE,
            }),
            [types.LIST_SIGNATURES_SCHEMES_FAILURE]: (state) => ({
                ...state,
                isFetching: false,
            }),
            [types.LIST_SIGNATURES_SCHEMES_REQUEST]: (state, { pageNumber }) => ({
                ...state,
                isFetching: true,
                currentPage: pageNumber,
            }),
            [types.LIST_SIGNATURES_SCHEMES_SUCCESS]: (
                state,
                { data: { currentPage, defaultSignatureCap, masterCurrency, signatures, totalPages } },
            ) => ({
                ...state,
                currentPage,
                defaultSignatureCap,
                hasMoreData: currentPage < totalPages,
                isFetching: false,
                masterCurrency,
                signaturesSchemes: signatures,
                totalPages,
            }),
            [types.MODIFY_SIGNATURES_SCHEME_CONFIRM_PRE]: (
                state,
                {
                    data: {
                        selectedFunctionalGroups,
                        selectedProducts,
                        signatureId,
                        signatureLevelsCounts,
                        signatureType,
                        topAmount,
                        signatureDispatch,
                    },
                    credentialGroups,
                    idTransaction,
                    idActivity,
                },
            ) => ({
                ...state,
                selectedFunctionalGroups,
                selectedProducts,
                signatureId,
                signatureLevelsCounts,
                signatureType,
                topAmount,
                idTransaction,
                idActivity,
                credentialGroups,
                signatureDispatch,
            }),
            [types.DELETE_SIGNATURES_SCHEME_CONFIRM_PRE]: (state, { idTransaction, idActivity }) => ({
                ...state,
                idTransaction,
                idActivity,
            }),
            [types.MODIFY_SIGNATURES_SCHEME_FAILURE]: (state) => ({
                ...state,
                isFetching: false,
            }),
            [types.MODIFY_SIGNATURES_SCHEME_SUCCESS]: (state) => ({
                ...state,
                isFetching: false,
            }),
            [types.MODIFY_SIGNATURES_SCHEME_PRE_FAILURE]: (state) => ({
                ...state,
                isFetching: false,
            }),
            [types.MODIFY_SIGNATURES_SCHEME_PRE_REQUEST]: (state) => ({
                ...state,
                isFetching: true,
            }),
            [types.MODIFY_SIGNATURES_SCHEME_PRE_SUCCESS]: (
                state,
                {
                    credentialGroups,
                    data: {
                        capFrequencyList,
                        caps,
                        enabledChannels,
                        functionalGroups,
                        masterCurrency,
                        environmentProducts,
                        signature,
                        signatureGroupMap,
                        signatureTypeList,
                        selectedFunctionalGroups,
                    },
                },
            ) => ({
                ...state,
                capFrequencyList,
                caps,
                credentialGroups,
                environmentProducts,
                currentSignature: signature,
                currentSignatureGroupMap: signatureGroupMap,
                enabledChannels,
                functionalGroups,
                isFetching: false,
                masterCurrency,
                signatureTypeList,
                selectedFunctionalGroups,
            }),
            [types.SIGNATURES_SCHEME_EXPORT_LIST_REQUEST]: (state) => ({
                ...state,
                fetchingExport: true,
            }),
            [types.SIGNATURES_SCHEME_EXPORT_LIST_FAILURE]: (state) => ({
                ...state,
                fetchingExport: false,
            }),
            [types.SIGNATURES_SCHEME_EXPORT_LIST_SUCCESS]: (state) => ({
                ...state,
                fetchingExport: false,
            }),
            [types.SIGNATURES_SCHEME_LOAD_MORE_REQUEST]: (state) => ({
                ...state,
                fetchingMoreSignaturesSchemes: true,
            }),
            [types.SIGNATURES_SCHEME_LOAD_MORE_SUCCESS]: (state, { data }) => ({
                ...state,
                fetchingMoreSignaturesSchemes: false,
                signaturesSchemes: state.signaturesSchemes
                    ? state.signaturesSchemes.concat(data.signatures)
                    : data.signatures,
                hasMoreData: data.currentPage < data.totalPages,
                currentPage: data.currentPage,
            }),
        }),
        types,
    };
};
