import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import Notification from "pages/_components/Notification";
import CalendarRestriction from "pages/administration/restrictions/CalendarRestriction";
import IPRestriction from "pages/administration/restrictions/IPRestriction";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import { Form, withFormik } from "formik";
import { compose } from "redux";
import * as Yup from "yup";
import { number, func, shape, string, arrayOf, bool } from "prop-types";
import * as i18nUtils from "util/i18n";
import * as maskUtils from "util/mask";
import I18n from "pages/_components/I18n";
import {
    actions as restrictionActions,
    selectors as restrictionSelectors,
    INITIAL_STATE,
} from "reducers/administration/restrictions";
import { selectors as sessionSelectors } from "reducers/session";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import EnvironmentTag from "pages/_components/EnvironmentTag";

const isCalendarToSave = (values) =>
    INITIAL_STATE.days !== values.days ||
    INITIAL_STATE.startTime !== values.startTime ||
    INITIAL_STATE.endTime !== values.endTime ||
    INITIAL_STATE.perpetual !== values.perpetual ||
    INITIAL_STATE.timeZone !== values.timeZone;

class Restrictions extends Component {
    static propTypes = {
        days: number.isRequired,
        dispatch: func.isRequired,
        activeEnvironmentId: number.isRequired,
        idUser: string,
        values: shape({}),
        history: shape({}).isRequired,
        timeZones: arrayOf(string),
        fetching: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
        userName: string,
    };

    static defaultProps = {
        idUser: undefined,
        values: {},
        timeZones: [],
        userName: "",
    };

    componentDidMount() {
        this.preLoadInfo();
    }

    componentDidUpdate(prevProps) {
        const { idUser } = this.props;
        if (prevProps.idUser !== idUser) {
            this.preLoadInfo();
        }
    }

    shouldActivateSaveButton = () => {
        const { values } = this.props;
        return !isCalendarToSave(values) && values.idUser && values.ipsList.length === 0 && values.anyIP === false;
    };

    onCancelClick = () => {
        const { history } = this.props;
        history.goBack();
    };

    preLoadInfo() {
        const { dispatch, timeZones, idUser } = this.props;
        dispatch(restrictionActions.setInitialState());

        if (timeZones.length === 0) {
            dispatch(restrictionActions.manageRestrictionsPre());
        }
        dispatch(restrictionActions.getRestrictionsAvailability());
        if (typeof idUser === "undefined") {
            dispatch(restrictionActions.getEnvironmentRestrictionsData());
        } else {
            dispatch(restrictionActions.getUserRestrictionsData(idUser));
        }
    }

    render() {
        const { idUser, fetching, activeEnvironment, userName } = this.props;
        const isEnvironmentRestrictions = typeof idUser === "undefined";
        return (
            <Fragment>
                <Notification scopeToShow="restrictions" />

                <Head
                    title={
                        isEnvironmentRestrictions
                            ? "administration.restrictions.environmentRestrictions.title"
                            : "administration.restrictions.userRestrictions.title"
                    }
                />
                <MainContainer showLoader={fetching}>
                    <Form className="above-the-fold">
                        <Container className="container--layout flex-grow-1">
                            {isEnvironmentRestrictions && (
                                <Col sm={12} md={8} mdOffset={2} lg={6} lgOffset={3} className="environment-IconName">
                                    <EnvironmentTag name={activeEnvironment.name} type={activeEnvironment.type} />
                                </Col>
                            )}
                            {!isEnvironmentRestrictions && (
                                <div>
                                    <Col
                                        sm={12}
                                        md={8}
                                        mdOffset={2}
                                        lg={6}
                                        lgOffset={3}
                                        className="control-label text-uppercase">
                                        <I18n id="administration.users.invite.firstName.label" />
                                    </Col>
                                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                        {userName}
                                    </Col>
                                </div>
                            )}
                        </Container>
                        <Container className="container--layout flex-grow-1">
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <CalendarRestriction {...this.props} idUser={idUser} />
                                <IPRestriction {...this.props} idUser={idUser} />
                            </Col>
                        </Container>
                        <Container className="container--layout flex-grow-1">
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <Button
                                    className="btn btn-primary btn-block"
                                    label="global.continue"
                                    type="submit"
                                    disabled={this.shouldActivateSaveButton()}
                                    block
                                />

                                <Button
                                    className="btn btn-outline btn-block"
                                    label="global.cancel"
                                    onClick={this.onCancelClick}
                                    block
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, { match }) => ({
    days: restrictionSelectors.getDays(state),
    startTime: restrictionSelectors.getStartTime(state),
    endTime: restrictionSelectors.getEndTime(state),
    timeZone: restrictionSelectors.getTimeZone(state),
    perpetual: restrictionSelectors.getPerpetual(state),
    ipsList: restrictionSelectors.getIpsList(state),
    restrictions: restrictionSelectors.getRestrictions(state),
    timeZones: restrictionSelectors.getTimezones(state),
    fetching: restrictionSelectors.getFetching(state),
    anyIP: restrictionSelectors.getAnyIP(state),
    calendarRestrictionId: restrictionSelectors.getCalendarRestrictionId(state),
    activeEnvironmentId: sessionSelectors.getActiveEnvironment(state).id,
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    idUser: match.params.userId,
    calendarEnabled: restrictionSelectors.getCalendarEnabled(state),
    iPEnabled: restrictionSelectors.getIPEnabled(state),
    userName: restrictionSelectors.getUserName(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => {
            const { userId } = props.match.params;
            const allDays = props.days === 127;

            return {
                days: props.days,
                startTime: props.startTime,
                endTime: props.endTime,
                timeZone: props.timeZone,
                perpetual: props.perpetual,
                ipsList: props.ipsList,
                anyIP: props.anyIP,
                calendarEnabled: props.calendarEnabled,
                iPEnabled: props.iPEnabled,
                idUser: userId,
                allDays,
            };
        },
        validationSchema: () => {
            const EMPTY_FIELD_ERROR = "administration.restrictions.field.error.empty";
            return Yup.lazy((values) => {
                const calendarToSave = isCalendarToSave(values);
                const shouldValidateCalendarFields =
                    !values.perpetual && !values.disableCalendar && !values.allDays && calendarToSave;

                return Yup.object().shape({
                    days: shouldValidateCalendarFields
                        ? Yup.number()
                              .moreThan(0, i18nUtils.get(EMPTY_FIELD_ERROR))
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                        : Yup.number(),
                    timeZone: shouldValidateCalendarFields
                        ? Yup.string()
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                              .nullable()
                        : Yup.string().nullable(),
                    startTime: shouldValidateCalendarFields
                        ? Yup.string()
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                              .matches(
                                  new RegExp(maskUtils.timeRegex(), "g"),
                                  i18nUtils.get("administration.restrictions.error.startTime.invalid"),
                              )
                        : Yup.string().notRequired(),
                    endTime: shouldValidateCalendarFields
                        ? Yup.string()
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                              .matches(
                                  new RegExp(maskUtils.timeRegex(), "g"),
                                  i18nUtils.get("administration.restrictions.error.endTime.invalid"),
                              )
                        : Yup.string().notRequired(),
                });
            });
        },
        handleSubmit: (fields, formikBag) => {
            const { dispatch, calendarRestrictionId, activeEnvironmentId } = formikBag.props;
            const calendarToSave = isCalendarToSave(fields);
            const dataToSave = {
                ...fields,
                calendarRestrictionId,
                calendarToSave,
                environmentId: activeEnvironmentId,
                days: fields.allDays ? 127 : fields.days,
            };
            if (dataToSave.idUser) {
                delete dataToSave.calendarEnabled;
                delete dataToSave.iPEnabled;
            } else {
                dataToSave.perpetual = false;
            }
            dispatch(restrictionActions.manageRestrictionsConfirmPre(dataToSave, formikBag));
        },
    }),
)(Restrictions);
