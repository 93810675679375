import EmptyTextBox from "pages/_components/EmptyTextBox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import DepositsList from "pages/deposits/List";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as depositsActions, selectors as depositsSelectors } from "reducers/deposits";
import { actions as productsActions } from "reducers/products";
import { selectors as sessionSelectors } from "reducers/session";

class Deposits extends Component {
    static propTypes = {
        isRequestAvailable: bool,
        deposits: arrayOf({ length: number.isRequired }),
        dispatch: func.isRequired,
        isMobile: bool,
        fetching: bool,
        showProductIcon: bool,
        isDesktop: bool,
        completeFavorites: bool,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        isRequestAvailable: false,
        isMobile: false,
        fetching: false,
        deposits: [],
        showProductIcon: true,
        isDesktop: false,
        completeFavorites: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(depositsActions.listDeposits());
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    renderHeader = () => {
        const { isRequestAvailable, isMobile } = this.props;

        if (!isRequestAvailable) {
            return (
                <Head
                    onBack={this.handleBack}
                    title="deposits.header.title"
                    {...(this.hasPermissionToCreate() && {
                        addLinkTo: "/formCustom/createFixedTermDepositForm",
                        addLinkToLabel: "deposit.new",
                    })}
                    pushRedirect
                />
            );
        }
        if (isMobile) {
            return (
                <Head
                    onBack={this.handleBack}
                    title="deposits.header.title"
                    {...(this.hasPermissionToCreate() && {
                        addLinkTo: "/formCustom/createFixedTermDepositForm",
                    })}
                    pushRedirect
                />
            );
        }

        return (
            <Head
                onBack={this.handleBack}
                title="deposits.header.title"
                {...(this.hasPermissionToCreate() && {
                    addLinkTo: "/formCustom/createFixedTermDepositForm",
                    addLinkToLabel: "deposit.new",
                })}
                pushRedirect
            />
        );
    };

    setFavoriteDeposit = (idProduct, productType, favorite) => {
        const { dispatch } = this.props;
        dispatch(productsActions.toggleFavorite(idProduct, productType, favorite, null, true));
    };

    hasPermissionToCreate = () => {
        const { activeEnvironment } = this.props;
        const { permissions } = activeEnvironment;
        return permissions.depositCreate || false;
    };

    render() {
        const { deposits, fetching, isDesktop, showProductIcon, completeFavorites, dispatch } = this.props;
        const isLoading = fetching && !deposits.length;

        return (
            <Fragment>
                <Notification scopeToShow="deposits" />
                {!isLoading && this.renderHeader()}
                <MainContainer showLoader={isLoading || deposits.length === 1}>
                    <Row className="mt-5 mt-md-0">
                        <Col xs={12} md={8} mdOffset={2}>
                            {deposits && deposits.length > 0 ? (
                                <>
                                    <DepositsList
                                        deposits={deposits}
                                        setFavoriteDeposit={this.setFavoriteDeposit}
                                        showProductIcon={showProductIcon}
                                        completeFavorites={completeFavorites}
                                        isDesktop={isDesktop}
                                        dispatch={dispatch}
                                    />
                                </>
                            ) : (
                                <EmptyTextBox text="product.empty.text.deposits" footerLink />
                            )}
                        </Col>
                    </Row>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    deposits: depositsSelectors.getDeposits(state),
    fetching: depositsSelectors.getFetching(state),
    completeFavorites: depositsSelectors.getCompleteFavorites(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(Deposits);
