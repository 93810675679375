import classNames from "classnames";
import {
    EDIT_MODE,
    PREVIEW_MODE,
    VIEW_MODE,
} from "pages/forms/customForms/fixedTermDeposit/CreateFixedTermDepositForm";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FieldError from "pages/_components/fields/FieldError";
import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import SideBarModal from "pages/_components/modal/SideBarModal";
import Text from "pages/_components/Text";
import { arrayOf, bool, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import * as i18n from "util/i18n";
import PercentageBeneficiaryForm from "./PercentageBeneficiaryForm";

const PercentageBeneficiaryField = (props) => {
    const {
        labelKey,
        form,
        field,
        isDesktop,
        className,
        documentData,
        infoLabel,
        defaultValue,
        mode,
        currentLang,
        beneficiaryRelationshipList,
    } = props;

    const [showAddBeneficiary, setShowAddBeneficiary] = useState(false);
    const [beneficiaryList, setBeneficiaryList] = useState(defaultValue || []);
    const [beneficiarySelected, setBeneficiarySelected] = useState();

    const fieldHasError = () => {
        const { errors } = form;
        return errors[`${field.name}`];
    };

    const renderError = (absolute) => {
        const { errors } = form;

        if (fieldHasError()) {
            return (
                <Box
                    display="flex"
                    alignY="center"
                    {...(absolute
                        ? { position: "absolute", className: "pl-5 pt-5" }
                        : { className: "pl-5 translate-error-center" })}>
                    <FieldError relativeStyleError error={errors[`${field.name}`]} />
                </Box>
            );
        }
        return null;
    };

    const setValueField = () => {
        if (!form?.setFieldValue || !field?.name) {
            return;
        }
        const { setFieldValue } = form;
        setFieldValue(field.name, beneficiaryList);
    };

    const deleteError = () => {
        // borro el error del campo
        const { errors, setErrors } = form;
        const { [field.name]: error, ...rest } = errors;
        setErrors(rest);
    };

    const saveBeneficiary = (beneficiary) => {
        if (!beneficiary?.id) {
            return;
        }

        const updateBeneficiaryList = [...(beneficiaryList || [])].filter(
            (item) => item && item.id && item.id !== beneficiary.id,
        );

        setBeneficiaryList([...updateBeneficiaryList, beneficiary]);
        setShowAddBeneficiary(false);

        deleteError();
    };

    const deleteBeneficiary = (idBeneficiary) => {
        if (!idBeneficiary) {
            return;
        }
        setBeneficiaryList([...(beneficiaryList?.filter((ben) => ben && ben.id !== idBeneficiary) || [])]);

        deleteError();
    };

    const shouldShowAddButton = () => {
        const percentage = beneficiaryList.reduce((acc, item) => acc + (item?.percentage ? +item.percentage : 0), 0);
        return percentage < 100;
    };

    useEffect(() => {
        if (beneficiaryList) {
            setValueField();
        }
    }, [beneficiaryList]);

    const getColumnsTemplate = () => (isDesktop ? "1.1fr 1.1fr 1.8fr" : "1.3fr 1fr 5rem");

    const renderItemListBeneficiary = () =>
        beneficiaryList?.map((beneficiary) => (
            <GridTable.Container
                className={classNames({ "gapX-3": isDesktop })}
                padding={classNames("px-5 py-2", { "py-4": !isDesktop && mode !== EDIT_MODE })}
                columnsTemplate={mode === EDIT_MODE ? getColumnsTemplate() : "2fr 1fr"}
                background="transparent">
                <GridTable.Data columnStart={1} columnWidth={1} alignX="flex-start">
                    <Text size={isDesktop ? "6" : "5"}>{beneficiary?.name || ""}</Text>
                </GridTable.Data>
                <GridTable.Data
                    columnStart={2}
                    columnWidth={1}
                    {...(isDesktop ? { alignX: "flex-end" } : { alignX: "center" })}>
                    <FormattedAmount
                        quantity={beneficiary?.percentage || ""}
                        noCurrency
                        className="mr-2"
                        frequencyInSpan={false}
                        frequency="%"
                        size={isDesktop ? "6" : "5"}
                    />
                </GridTable.Data>
                {mode === EDIT_MODE && (
                    <GridTable.Data className="p-0" columnStart={3} alignX="flex-end">
                        <Button
                            ariaLabel="edit_beneficiary"
                            bsStyle="only-icon"
                            className={isDesktop ? "mr-4" : "mr-1"}
                            image="images/icons/editPen16.svg"
                            onClick={() => {
                                setBeneficiarySelected(beneficiary);
                                setShowAddBeneficiary(true);
                            }}
                        />

                        <Box display="flex" className="table-data table-data-icon">
                            <Button
                                ariaLabel="delete_beneficiary"
                                bsStyle="only-icon"
                                image="images/icons/circleCross16.svg"
                                onClick={() => {
                                    deleteBeneficiary(beneficiary?.id);
                                }}
                            />
                        </Box>
                    </GridTable.Data>
                )}
            </GridTable.Container>
        )) || <div />;

    const renderTableBeneficiary = () => (
        <GridTable>
            <GridTable.Header>
                <GridTable.Container
                    className={classNames({ "gapX-3": isDesktop, "py-4 px-5": !isDesktop })}
                    columnsTemplate={mode === EDIT_MODE ? getColumnsTemplate() : "2fr 1fr"}>
                    <GridTable.Data columnStart={1} columnWidth={1} alignX="flex-center" inHeader>
                        {i18n.get(`${field?.name || ""}.beneficiary.name.label`)}
                    </GridTable.Data>
                    <GridTable.Data
                        columnStart={2}
                        columnWidth={1}
                        {...(isDesktop ? { alignX: "flex-end" } : { alignX: "center" })}
                        inHeader>
                        {i18n.get(`${field?.name || ""}.beneficiary.percentage.label`)}
                    </GridTable.Data>
                    {mode === EDIT_MODE && <GridTable.Data columnStart={3} alignX="flex-end" inHeader />}
                </GridTable.Container>
            </GridTable.Header>
            <GridTable.Body {...(mode === EDIT_MODE && beneficiaryList.length === 0 && { className: "pb-10" })}>
                {renderItemListBeneficiary()}
                {renderError(beneficiaryList.length === 0)}
            </GridTable.Body>
        </GridTable>
    );

    const renderViewModeResume = () =>
        beneficiaryList?.map((beneficiary) => (
            <>
                <FormFieldsComponents.ReadTextCustom
                    {...props}
                    value={beneficiary?.name || ""}
                    labelCustom={i18n.get(`${field?.name || ""}.beneficiary.name.label`)}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...props}
                    value={`${beneficiary?.percentage || ""}%`}
                    labelCustom={i18n.get(`${field?.name || ""}.beneficiary.percentage.label`)}
                />
            </>
        )) || <div />;

    return (
        <>
            {mode === EDIT_MODE && (
                <SideBarModal
                    show={showAddBeneficiary}
                    onClose={() => {
                        setShowAddBeneficiary(false);
                    }}
                    title={`${field?.name || ""}.beneficiary.new.title`}
                    scrollable
                    modalContainerClassName="px-0"
                    modalBodyClassName="px-0 px-md-0"
                    modalHeaderClassName="px-5 px-md-0">
                    <PercentageBeneficiaryForm
                        isDesktop={isDesktop}
                        saveCallback={saveBeneficiary}
                        beneficiaryData={beneficiarySelected}
                        documentData={documentData}
                        beneficiaryList={beneficiaryList}
                        currentLang={currentLang}
                        beneficiaryRelationshipList={beneficiaryRelationshipList}
                    />
                </SideBarModal>
            )}
            <Box
                className={classNames("form-group", className, {
                    "has-error": fieldHasError(),
                })}>
                <Box
                    display="flex"
                    alignX="between"
                    alignY="center"
                    className={classNames("mb-3", {
                        "mt-7  mb-md-6": mode === PREVIEW_MODE,
                        "mt-4": mode === VIEW_MODE,
                    })}>
                    {labelKey && (
                        <Text
                            component="label"
                            color="primary"
                            labelKey={labelKey}
                            {...(mode === EDIT_MODE ? { regular: true } : { bold: true })}
                        />
                    )}

                    {mode === EDIT_MODE && shouldShowAddButton() && (
                        <Button
                            ariaLabel="add_beneficiary"
                            label={`${field?.name || ""}.add.beneficiary.label`}
                            bsStyle="link"
                            image="images/plusCircle.svg"
                            className="px-0"
                            onClick={() => {
                                setShowAddBeneficiary(true);
                                setBeneficiarySelected(undefined);
                            }}
                        />
                    )}
                </Box>

                {(mode === EDIT_MODE || mode === PREVIEW_MODE) && beneficiaryList && (
                    <Box
                        borderRadius="default"
                        border="background-divider-outset"
                        background="component-background"
                        overflow="hidden"
                        {...(mode === EDIT_MODE && { toBorderInMobile: true })}>
                        {renderTableBeneficiary()}
                    </Box>
                )}

                {mode === VIEW_MODE && beneficiaryList && renderViewModeResume()}
                {/* {renderError()} */}
                {infoLabel && mode === EDIT_MODE && (
                    <Box display="flex" alignX="flex-start" alignY="center" gapX={3} className="mb-4 mt-4">
                        <Box display="flex">
                            <Image className={classNames("tooltip-img")} src="images/tooltip-icon.svg" />
                        </Box>
                        <Box display="flex">
                            <Text size={isDesktop ? "7" : "7"} {...(!isDesktop && { color: "heading" })}>
                                {infoLabel}
                            </Text>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
};

PercentageBeneficiaryField.propTypes = {
    labelKey: string,
    name: string.isRequired,
    tooltipText: string,
    form: shape({}).isRequired,
    field: shape({}).isRequired,
    className: bool,
    isDesktop: bool,
    documentData: shape({}),
    infoLabel: string,
    defaultValue: arrayOf(shape({})),
    mode: string,
    currentLang: string,
    beneficiaryRelationshipList: arrayOf(shape({})),
};

PercentageBeneficiaryField.defaultProps = {
    labelKey: undefined,
    tooltipText: null,
    className: false,
    isDesktop: false,
    documentData: undefined,
    infoLabel: undefined,
    defaultValue: [],
    mode: undefined,
    currentLang: undefined,
    beneficiaryRelationshipList: [],
};

export default PercentageBeneficiaryField;
