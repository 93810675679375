import { Field, Form, withFormik } from "formik";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Selector from "pages/_components/fields/formik/Selector";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { actions as transactionsActions } from "reducers/transactions";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "transactions.list.filters.channel";
class ChannelFilter extends Component {
    static propTypes = {
        selectedOperationType: string.isRequired,
        fetching: bool.isRequired,
        setFieldValue: func.isRequired,
        setChannelType: func,
        setFilterValues: func.isRequired,
    };

    static defaultProps = {
        setChannelType: () => {},
    };

    componentDidUpdate(prevProps) {
        if (this.props.selectedOperationType !== prevProps.selectedOperationType) {
            this.props.setFieldValue("operationType", this.props.selectedOperationType);
        }
    }

    render() {
        const { fetching, setFilterValues } = this.props;

        const channelsOptions = [
            {
                value: "frontend",
                label: i18n.get("transactions.list.filter.searchBy.channel.frontend"),
            },
            {
                value: "phonegap",
                label: i18n.get("transactions.list.filter.searchBy.channel.phonegap"),
            },
        ];

        return (
            <Form>
                <Container className="container--layout items-center" gridClassName="form-content">
                    <Col xs={12} md={4}>
                        <Box className="form-group form-group--select">
                            <Field
                                component={Selector}
                                options={channelsOptions}
                                idForm="transactions.list.filters.searchBy"
                                name="channel"
                                placeholder=""
                                handleChange={(e) => {
                                    const { setChannelType } = this.props;
                                    if (setChannelType) {
                                        setChannelType(e);
                                        setFilterValues(undefined, undefined, e);
                                    }
                                }}
                            />
                        </Box>
                    </Col>
                    <Col sm={12} md={4} className="height-auto">
                        <Box display="flex" alignY="flex-end" fullHeight>
                            <Button
                                bsStyle="primary"
                                label="product.filters.filter"
                                block
                                loading={fetching}
                                type="submit"
                            />
                        </Box>
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => ({
        channel: "",
        operationType: props.selectedOperationType,
    }),
    validationSchema: () =>
        Yup.object().shape({
            channel: Yup.string().required(i18n.get(`${FORM_ID}.searchBy.required`)),
        }),
    handleSubmit: ({ ...filters }, formikBag) => {
        const {
            dispatch,
            isDesktop,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            resetHandleOrder,
        } = formikBag.props;

        const { channel, operationType } = filters;
        if (isDesktop) {
            dispatch(
                transactionsActions.loadListRequest(
                    { channelType: channel, operationType },
                    onlyPendings,
                    onlyProcessing,
                    pendingDispatch,
                    false,
                    formikBag,
                ),
            );
        } else {
            dispatch(
                transactionsActions.setFilters({
                    filter: "channel",
                    ...filters,
                }),
            );
            dispatch(push(`/transactions/list/filters/results`));
        }
        resetHandleOrder();
    },
})(withTransactionFilterContext(ChannelFilter));
