import classNames from "classnames";
import { resizableRoute } from "pages/_components/Resizable";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useRef } from "react";
import ReactSelect from "react-select";
import * as i18n from "util/i18n";

const CustomDropdownIndicator = () => (
    <Box display="flex" alignY="center" className="Select-arrow-zone">
        <Box className="Select-arrow" />
    </Box>
);

const CustomNoOptionsMessage = () => (
    <Box display="flex" alignX="center" alignY="center" className="select__menu-notice--no-options">
        <Text labelKey="global.no.options" />
    </Box>
);

const Select = ({
    className,
    clearable,
    containerClassName,
    disabled,
    field,
    form,
    id,
    isDesktop,
    labelKey,
    multi,
    onChange,
    options,
    searchable,
    selectClassName,
    value,
    valueKey,
    ...props
}) => {
    const ref = useRef();

    useEffect(() => {
        if (options && options.length === 1 && !value && onChange) {
            const singleOption = options[0];
            onChange(singleOption);
        }
    }, [options, value, onChange]);

    return (
        <ReactSelect
            ref={ref}
            id={id}
            unstyled
            components={{ DropdownIndicator: CustomDropdownIndicator, NoOptionsMessage: CustomNoOptionsMessage }}
            classNames={{
                container: () => classNames("Select full-width", containerClassName),
                control: () => "Select-control",
                dropdownIndicator: () => "Select-arrow-zone",
                input: () => "Select-input",
                menu: () => "Select-menu-outer",
                menuList: () => "Select-menu",
                option: () => "Select-option",
                placeholder: () => "Select-placeholder",
                valueContainer: () => "Select-multi-value-wrapper",
            }}
            menuPlacement="bottom"
            {...(!isDesktop && { menuShouldBlockScroll: true })}
            classNamePrefix="select"
            instanceId={id}
            isDisabled={disabled}
            isSearchable={searchable}
            isClearable={clearable}
            isMulti={multi}
            options={options}
            // eslint-disable-next-line no-nested-ternary
            {...(!multi
                ? typeof value === "string" || value === null
                    ? {
                          value: options?.find((option) => option[valueKey] === value),
                      }
                    : {
                          value: value && options?.find((option) => option[valueKey] === value[valueKey]),
                      }
                : {})}
            {...(!(!onChange && form && form.setFieldValue) && { controlShouldRenderValue: !!value })}
            getOptionLabel={(option) => option[labelKey]}
            getOptionValue={(option) => option[valueKey]}
            onChange={(option) => {
                if (onChange) {
                    onChange(option);
                } else if (form && form.setFieldValue) {
                    form.setFieldValue(field.name, !option ? "" : option.value);
                }
            }}
            {...{ placeholder: i18n.get("forms.placeholder.select"), ...props }}
        />
    );
};

Select.propTypes = {
    className: string,
    clearable: bool,
    containerClassName: string,
    disabled: bool,
    field: shape({}),
    form: shape({}),
    id: string.isRequired,
    isDesktop: bool.isRequired,
    labelKey: string,
    multi: bool,
    onChange: func,
    options: shape({}),
    searchable: bool,
    selectClassName: string,
    value: shape({}),
    valueKey: string,
};

Select.defaultProps = {
    className: null,
    clearable: false,
    containerClassName: null,
    disabled: false,
    field: {},
    form: {},
    labelKey: "label",
    multi: false,
    onChange: null,
    options: null,
    searchable: false,
    selectClassName: null,
    value: null,
    valueKey: "value",
};

export default resizableRoute(Select);
