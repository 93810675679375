import { call, put, takeLatest } from "redux-saga/effects";
import { routerActions, replace, push } from "react-router-redux";

import { ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE } from "util/responses.js";
import { types, actions } from "reducers/administration/usersInvite";
import * as usersInvite from "middleware/administration/usersInvite";
import * as form from "middleware/form";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { credentialsToUnderscoreFormat } from "util/form.js";

const sagas = [
    takeLatest(types.ADMINISTRATION_USER_INVITE_PRE_REQUEST, userInvitePre),
    takeLatest(types.ADMINISTRATION_USER_INVITE_VERIFY, userInviteVerify),
    takeLatest(types.ADMINISTRATION_USER_INVITE_PREVIEW, userInvitePreview),
    takeLatest(types.ADMINISTRATION_USER_INVITE_CONFIRM, userInviteConfirm),
];

export default sagas;

function* userInvitePre() {
    const response = yield call(usersInvite.inviteUsersPre);

    if (response.type === "W") {
        yield put(actions.userInvitePreFailure());
        yield put(routerActions.replace("/administration/users"));
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        yield put({
            type: types.ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS,
            data: response.data.data,
        });
    }
}

function* userInviteVerify({ document, captcha }) {
    const params = {
        docCountry: document.country,
        docType: document.type,
        docNumber: document.document,
    };
    const response = yield call(usersInvite.inviteUserVerify, params, captcha);

    if (response.type === "W") {
        const responseMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");
        yield put(notificationActions.showNotification(responseMessage, "error", ["administrationUserInvite"]));
    } else {
        const { data } = response.data;
        yield put({ type: types.ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS, data });
        yield put(push("/administration/users/inviteStep2"));
    }
}

function* userInvitePreview({ params, formikBag }) {
    const response = yield call(usersInvite.inviteUserPreview, params);

    if (response.type === "W") {
        if (response.data.data.NO_FIELD) {
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                    "administrationUserInvite",
                ]),
            );
        } else {
            formikBag.setErrors(response.data.data);
            yield put(replace("/administration/users/inviteStep2"));
        }
    } else {
        const responseCredentials = yield call(form.listCredentialsGroups, null, "administration.users.invite.send");
        const credentialGroups = responseCredentials.data.data.groups;
        yield put({ type: types.ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS, params, credentialGroups });
        yield put(push("/administration/users/inviteStep3"));
    }
}

function* userInviteConfirm({ confirmationParams, credentials, formikBag }) {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const response = yield call(usersInvite.inviteUserConfirm, {
        ...confirmationParams,
        ...credentialsWithUnderscore,
    });

    formikBag.setSubmitting(false);
    if (response.type === "W") {
        formikBag.setErrors(response.data.data);
        yield put(replace("/administration/users/inviteStep3"));
    } else {
        yield put(actions.userInviteFinish());
        if (response.data.code && response.data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE) {
            yield put(notificationActions.showNotification(response.data.message, "success", ["administration"]));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("administration.users.invite.success"), "success", [
                    "administration",
                ]),
            );
        }

        yield put(replace("/administration/users"));
    }
}
