import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as restrictionSelectors } from "reducers/administration/restrictions";
import I18n from "pages/_components/I18n";
import { Col } from "react-bootstrap";
import { Field } from "formik";
import Row from "pages/_components/Row";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import TagsInputControl from "pages/_components/fields/TagsInputControl";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import * as i18n from "util/i18n";
import { string, func, shape, bool } from "prop-types";
import Image from "pages/_components/Image";
import { validationRegexIP } from "util/general.js";

const FORM_ID = "administration.restrictions";
const Delimiter = {
    comma: ",",
    space: " ",
};

class IPRestrictions extends Component {
    static propTypes = {
        idUser: string,
        setFieldValue: func.isRequired,
        values: shape({}),
        iPEnabled: bool.isRequired,
    };

    static defaultProps = {
        idUser: null,
        values: {},
    };

    removeAllIps = () => {
        const { setFieldValue } = this.props;
        setFieldValue("ipsList", []);
    };

    render() {
        const { values, idUser, iPEnabled } = this.props;

        const header = (
            <div>
                <div className="form-group-text">
                    <h4 className="table-legend">
                        <I18n id="administration.restrictions.iPEnabled.label" />
                    </h4>
                </div>
            </div>
        );

        if (!iPEnabled && idUser) {
            return (
                <div>
                    {header}
                    <div className="restrictions-warning">
                        <Image src="images/warningIcon.svg" className="svg-icon" />{" "}
                        <span>
                            <I18n id="administration.restrictions.restrictions.disabled" />
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {idUser !== null && header}
                {idUser && <Field name="anyIP" component={Checkbox} idForm={FORM_ID} />}

                {idUser === null && (
                    <Field name="iPEnabled" idForm={FORM_ID} component={SwitchField} formGroup biggerLabel />
                )}

                {values.iPEnabled && !values.anyIP && (
                    <>
                        <Row>
                            <Col className="col-12 col-9">
                                {(!values.ipsList || values.ipsList.length === 0) && (
                                    <I18n id="administration.restrictions.allIPsAvailable" />
                                )}
                                {values.ipsList && values.ipsList.length > 1 && (
                                    <div className="flex-container justify-content-end">
                                        <Button
                                            bsStyle="link"
                                            className="btn-small"
                                            label={`${FORM_ID}.removeAll`}
                                            onClick={this.removeAllIps}
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 col-9">
                                <TagsInputControl
                                    name="ipsList"
                                    placeholder={i18n.get(`${FORM_ID}.addIP.placeholder`)}
                                    customDelimiter={Delimiter}
                                    validationRegex={validationRegexIP}
                                    validateDuplicatedItems
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    restrictions: restrictionSelectors.getRestrictions(state),
});

export default connect(mapStateToProps)(IPRestrictions);
