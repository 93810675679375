import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    ADMINISTRATION_USER_INVITE_PRE_REQUEST: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PRE",
    ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS:
        "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS",
    ADMINISTRATION_USER_INVITE_PRE_FAILURE: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PRE_FAILURE",
    ADMINISTRATION_USER_INVITE_VERIFY: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_VERIFY",
    ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS",
    ADMINISTRATION_USER_INVITE_PREVIEW: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PREVIEW",
    ADMINISTRATION_USER_INVITE_PREVIEW_FAILED: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PREVIEW_FAILED",
    ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS",
    ADMINISTRATION_USER_INVITE_CONFIRM: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_CONFIRM",
    ADMINISTRATION_USER_INVITE_FINISH: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_FINISH",
};

export const INITIAL_STATE = {
    countryList: [],
    documentTypeList: [],
    defaultCountry: "",
    defaultDocumentType: "",
    selectedDocument: null,
    isInvitingNewUser: false,
    existentUserInfo: null,
    groupList: [],
    roleList: [],
    encryptedDocument: null,
    confirmationParams: null,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
};

export default createReducer(INITIAL_STATE, {
    [types.ADMINISTRATION_USER_INVITE_PRE_REQUEST]: (state) => ({
        ...state,
    }),
    [types.ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS]: (state, { data }) => ({
        ...state,
        defaultCountry: data.defaultCountry,
        defaultDocumentType: data.documentTypeList.filter(
            (documentType) => documentType.id_country_code === data.defaultCountry,
        )[0].id_document_type,
        countryList: data.countryList,
        documentTypeList: data.documentTypeList,
    }),
    [types.ADMINISTRATION_USER_INVITE_VERIFY]: (state, { document }) => ({
        ...state,
        selectedDocument: document,
    }),
    [types.ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS]: (state, { data }) => ({
        ...state,
        isInvitingNewUser: data.newUser,
        existentUserInfo: !data.newUser
            ? { firstName: data.firstName, lastName: data.lastName, mobilePhone: data.mobilePhone, email: data.email }
            : null,

        groupList: data.groupList,
        roleList: data.roleList,
        encryptedDocument: data.document,
    }),
    [types.ADMINISTRATION_USER_INVITE_PREVIEW_FAILED]: () => ({
        ...INITIAL_STATE,
    }),
    [types.ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS]: (
        state,
        { params, idTransaction, idActivity, credentialGroups },
    ) => ({
        ...state,
        confirmationParams: params,
        idTransaction,
        idActivity,
        credentialGroups,
    }),
    [types.ADMINISTRATION_USER_INVITE_FINISH]: () => ({
        ...INITIAL_STATE,
    }),
});

export const actions = {
    userInvitePre: makeActionCreator(types.ADMINISTRATION_USER_INVITE_PRE_REQUEST),
    userInvitePreFailure: makeActionCreator(types.ADMINISTRATION_USER_INVITE_PRE_FAILURE),
    userInviteVerify: makeActionCreator(types.ADMINISTRATION_USER_INVITE_VERIFY, "document", "captcha"),
    userInvitePreview: makeActionCreator(types.ADMINISTRATION_USER_INVITE_PREVIEW, "params", "formikBag"),
    userInvitePreviewFailed: makeActionCreator(types.ADMINISTRATION_USER_INVITE_PREVIEW_FAILED),
    userInviteFinish: makeActionCreator(types.ADMINISTRATION_USER_INVITE_FINISH),
    userInviteConfirm: makeActionCreator(
        types.ADMINISTRATION_USER_INVITE_CONFIRM,
        "confirmationParams",
        "credentials",
        "formikBag",
    ),
};

export const selectors = {
    getCountryList: ({ administrationUsersInvite }) => administrationUsersInvite.countryList,
    getDocumentTypeList: ({ administrationUsersInvite }) => administrationUsersInvite.documentTypeList,
    getDefaultCountry: ({ administrationUsersInvite }) => administrationUsersInvite.defaultCountry,
    getDefaultDocumentType: ({ administrationUsersInvite }) => administrationUsersInvite.defaultDocumentType,
    getRoleList: ({ administrationUsersInvite }) => administrationUsersInvite.roleList,
    getGroupList: ({ administrationUsersInvite }) => administrationUsersInvite.groupList,
    getEncryptedDocument: ({ administrationUsersInvite }) => administrationUsersInvite.encryptedDocument,
    getSelectedDocument: ({ administrationUsersInvite }) => administrationUsersInvite.selectedDocument,
    getConfirmationParams: ({ administrationUsersInvite }) => administrationUsersInvite.confirmationParams,
    isInvitingNewUser: ({ administrationUsersInvite }) => administrationUsersInvite.isInvitingNewUser,
    getExistentUserInfo: ({ administrationUsersInvite }) => administrationUsersInvite.existentUserInfo,
    getIdTransaction: ({ administrationUsersInvite }) => administrationUsersInvite.idTransaction,
    getIdActivity: ({ administrationUsersInvite }) => administrationUsersInvite.idActivity,
    getCredentialGroups: ({ administrationUsersInvite }) => administrationUsersInvite.credentialGroups,
};
