import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import { useSelectorOptionsAccounts } from "pages/forms/customForms/hooks/TransferCustomHooks";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    selectors as transactionalProfileSelector,
    actions as transactionalProfileActions,
} from "reducers/transactionalProfile";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as Yup from "yup";
import * as i18n from "util/i18n";
import FieldError from "../fields/FieldError";

const FORM_ID = "transactional.profile";
const ID_ACTIVITY = `${FORM_ID}.send`;

const ListCountriesComponent = (props) => {
    const {
        countriesList,
        isDesktop,
        fetching,
        onChangeCountry,
        isAllInChecked,
        isAllOutChecked,
        handlerAllSelect,
        disabledOutColumn,
        error = null,
    } = props;

    const getColumnsTemplate = () => "1.5fr 1fr 1fr";

    return (
        <GridTable>
            <GridTable.Header>
                <GridTable.Container
                    columnsTemplate={getColumnsTemplate()}
                    className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}
                    padding="py-2 px-5 pl-md-9 pr-md-10">
                    <GridTable.Data columnStart={1} alignX="flex-start" size="7" inHeader>
                        <Text size="6" color="text-boton" labelKey="transactional.all.countries" uppercase />
                    </GridTable.Data>
                    <GridTable.Data columnStart={2} alignX="flex-start" size="7" inHeader>
                        <Box display="flex" alignY="center">
                            <Checkbox
                                name="inAll"
                                className="d-flex"
                                formGroup
                                noLabel
                                checked={isAllInChecked}
                                onChange={(e) => handlerAllSelect("in", e)}
                            />
                            <Text size="6" color="text-boton" labelKey="transactional.in.countries" uppercase />
                        </Box>
                    </GridTable.Data>
                    <GridTable.Data columnStart={3} alignX="flex-start" size="7" inHeader>
                        <Checkbox
                            name="outAll"
                            className="d-flex"
                            formGroup
                            noLabel
                            checked={isAllOutChecked}
                            onChange={(e) => handlerAllSelect("out", e)}
                            disabled={disabledOutColumn}
                        />
                        <Text size="6" color="text-boton" labelKey="transactional.out.countries" uppercase />
                    </GridTable.Data>
                </GridTable.Container>
            </GridTable.Header>
            <GridTable.Body loading={fetching} className="scrollable-content">
                {countriesList.map((country) => (
                    <GridTable.Container
                        columnsTemplate={getColumnsTemplate()}
                        className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}
                        padding="py-2 px-5 px-md-9"
                        key={country.id}
                        removeBottomSpacing>
                        <GridTable.Data columnStart={1} alignX="flex-start">
                            <Box display="flex" alignY="center" fullWidth gap="3">
                                <Box display="flex">
                                    <Image src={`images/Flags/${country.id.toLowerCase()}.svg`} width="5" height="5" />
                                </Box>
                                <Text size="6" ellipsis>
                                    {country.label}
                                </Text>
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={2} alignX="flex-start">
                            <Checkbox
                                name={`${country.id}In`}
                                className="d-flex"
                                noLabel
                                checked={country.in}
                                onChange={(e) => onChangeCountry(e, country.id, "in")}
                            />
                        </GridTable.Data>
                        <GridTable.Data columnStart={3} alignX="flex-start">
                            <Checkbox
                                name={`${country.id}Out`}
                                className="d-flex"
                                noLabel
                                checked={country.out}
                                onChange={(e) => onChangeCountry(e, country.id, "out")}
                                disabled={disabledOutColumn}
                            />
                        </GridTable.Data>
                    </GridTable.Container>
                ))}
            </GridTable.Body>
            {error && (
                <Box className={`form-group has-error py-3 ${isDesktop ? "px-9" : "px-4"}`}>
                    <FieldError error={error} />
                </Box>
            )}
        </GridTable>
    );
};

ListCountriesComponent.propTypes = {
    countriesList: arrayOf(shape({})).isRequired,
    isDesktop: bool.isRequired,
    fetching: bool,
    onChangeCountry: func,
    isAllInChecked: bool,
    isAllOutChecked: bool,
    handlerAllSelect: func,
    disabledOutColumn: bool,
    error: string,
};

ListCountriesComponent.defaultProps = {
    fetching: false,
    onChangeCountry: () => {},
    isAllInChecked: false,
    isAllOutChecked: false,
    handlerAllSelect: () => {},
    disabledOutColumn: true,
    error: null,
};

const ListCountriesComponentMemo = React.memo(
    (props) => <ListCountriesComponent {...props} />,
    (prevProps, nextProps) =>
        JSON.stringify(prevProps.countriesList) === JSON.stringify(nextProps.countriesList) &&
        prevProps.fetching === nextProps.fetching &&
        prevProps.disabledOutColumn === nextProps.disabledOutColumn &&
        prevProps.error === nextProps.error,
);

const TransactionalProfileModal = ({
    countriesListProp,
    currentLang,
    fromBackoffice,
    hideSelector,
    idTransaction,
    isDesktop,
    mode,
    onClose,
    setFieldValue,
    show,
    values,
    fetching,
    account,
    accountList,
    dispatch,
    submitForm,
    credentials,
    resetForm,
}) => {
    const [accountListOptions] = useSelectorOptionsAccounts(accountList);
    const [isValidating, setIsValidating] = useState(false);
    const [countriesList, setCountriesList] = useState([]);
    const [isAllInChecked, setIsAllInChecked] = useState(false);
    const [isAllOutChecked, setIsAllOutChecked] = useState(false);
    const [disabledOutColumn, setDisabledOutColumn] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const genericProps = {
        fromBackoffice,
        idActivity: ID_ACTIVITY,
        idTransactionTicket: idTransaction,
        isRequired: true,
        lang: currentLang,
        mode,
    };

    const verifyAccountSimplified = (productId) => {
        const accountSelected = accountList.find((accountItem) => accountItem.idProduct === productId);
        const isSimplified =
            accountSelected?.simplified || accountSelected?.simplifiedLocal || accountSelected?.simplifiedInternacional;
        setDisabledOutColumn(isSimplified);
    };

    useEffect(() => {
        setErrorMessage(null);
        resetForm();
        if (accountListOptions.options?.length > 0) {
            setFieldValue("account", accountListOptions.options[0].idProduct);
            setFieldValue("accountData", accountListOptions.options[0]);
            verifyAccountSimplified(accountListOptions.options[0].idProduct);
        }
    }, [accountListOptions]);

    useEffect(() => {
        if (!fetching) {
            const isAllInCheckedValue = countriesListProp?.filter((country) => country.in);
            const isAllOutCheckedValue = countriesListProp?.filter((country) => country.out);
            if (isAllInCheckedValue.length === countriesListProp?.length) {
                setIsAllInChecked(true);
            } else {
                setIsAllInChecked(false);
            }
            if (isAllOutCheckedValue.length === countriesListProp?.length) {
                setIsAllOutChecked(true);
            } else {
                setIsAllOutChecked(false);
            }
            setCountriesList(countriesListProp);
            const oneCountrySelected = countriesListProp.filter((country) => country.in || country.out);
            if (oneCountrySelected.length > 0) {
                setErrorMessage(null);
            } else {
                setErrorMessage(i18n.get("transactional.profile.select.oneMore.countries"));
            }
        }
    }, [countriesListProp]);

    useEffect(() => {
        if (values?.account && account?.idProduct !== values?.account) {
            verifyAccountSimplified(values?.account);
            dispatch(transactionalProfileActions.transactionalCountriesRequest(values.account, values.accountData));
        }
    }, [values?.account]);

    useEffect(() => {
        dispatch(transactionalProfileActions.credentialsGroupsRequest({ idActivity: ID_ACTIVITY }));
    }, []);

    const handlerSendForm = () => {
        setIsValidating(true);
    };

    useEffect(() => {
        if (isValidating !== undefined && isValidating === true) {
            const oneCountrySelected = countriesList.filter((country) => country.in || country.out);
            setFieldValue("listSelected", oneCountrySelected);
            if (oneCountrySelected.length > 0) {
                submitForm();
                setIsValidating(false);
                setErrorMessage(null);
            } else {
                setErrorMessage(i18n.get("transactional.profile.select.oneMore.countries"));
                setIsValidating(false);
            }
        }
    }, [isValidating]);

    const hasInitialChanges = useMemo(
        () =>
            countriesList.some((country, index) => {
                const originalCountry = countriesListProp[index];
                return country?.in !== originalCountry?.in || country?.out !== originalCountry?.out;
            }),
        [countriesList, countriesListProp],
    );

    const onChangeCountry = (value, countryId, type) => {
        const newList = countriesList.map((country) => {
            if (country.id === countryId) {
                if (type === "in") {
                    return { ...country, in: value };
                }
                return { ...country, out: value };
            }
            return country;
        });
        setCountriesList(newList);

        const oneCountrySelected = newList.filter((country) => country.in || country.out);
        if (oneCountrySelected.length > 0) {
            setErrorMessage(null);
        } else {
            setErrorMessage(i18n.get("transactional.profile.select.oneMore.countries"));
        }
    };

    const handlerAllSelect = (type, value) => {
        let newList;
        if (type === "in") {
            newList = countriesList.map((country) => ({ ...country, in: value }));
            setIsAllInChecked(value);
        } else {
            newList = countriesList.map((country) => ({ ...country, out: value }));
            setIsAllOutChecked(value);
        }
        const oneCountrySelected = newList.filter((country) => country.in || country.out);
        if (oneCountrySelected.length > 0) {
            setErrorMessage(null);
        } else {
            setErrorMessage(i18n.get("transactional.profile.select.oneMore.countries"));
        }
        setCountriesList(newList);
    };

    return (
        <>
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                onHide={() => {
                    onClose();
                }}
                show={show}
                className={classNames("drawer transactional-profile-modal")}>
                <div className="modal-container px-0">
                    <Modal.Header closeButton className={isDesktop ? "px-9" : "px-5"}>
                        <Box display="flex" column alignY="center" className="mt-3 mt-md-7 mb-0 mb-md-9">
                            <Text
                                id="modalTitleID"
                                component="h4"
                                labelKey="transactional.profile.modal.title"
                                className={classNames("mt-0", { "mb-2": hideSelector }, { "mb-0": !hideSelector })}
                                color="heading-color"
                                size="3"
                                align="center"
                                bold
                            />
                            {account && (
                                <Text
                                    id="modalTitleID"
                                    component="h5"
                                    className="m-0"
                                    color="heading-color"
                                    size="5"
                                    align="center"
                                    regular>
                                    {account?.otherLabel}
                                </Text>
                            )}
                        </Box>
                    </Modal.Header>

                    <Form className="full-width full-height min-height-0">
                        <Box display="flex" column fullWidth fullHeight className="px-0 px-md-0">
                            <Box display="flex" column>
                                {accountList.length > 0 && (
                                    <Box display="flex" className="mb-5 mb-md-7 px-5 px-md-9">
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.ProductselectorCustom}
                                            data={accountListOptions}
                                            key="account"
                                            name="account"
                                            idField="account"
                                            renderAs="combo"
                                            value={values?.account}
                                            labelNoMarginTop
                                            disabled={fetching}
                                            onChangeCustom={() => {
                                                setErrorMessage(null);
                                            }}
                                        />
                                    </Box>
                                )}
                                <Text
                                    component="p"
                                    size="6"
                                    align="center"
                                    className={classNames("mb-5 mb-md-6 mb-md-9 my-0", { "px-5": !isDesktop })}>
                                    {!fetching && hideSelector && i18n.get("transactional.profile.not.created")}
                                    {!fetching && !hideSelector && i18n.get("transactional.profile.create")}
                                </Text>
                            </Box>

                            <Box display="flex" flex1 className="min-height-0">
                                <ListCountriesComponentMemo
                                    countriesList={countriesList}
                                    isDesktop={isDesktop}
                                    fetching={fetching}
                                    onChangeCountry={onChangeCountry}
                                    isAllInChecked={isAllInChecked}
                                    isAllOutChecked={isAllOutChecked}
                                    handlerAllSelect={handlerAllSelect}
                                    disabledOutColumn={disabledOutColumn}
                                    error={errorMessage}
                                />
                            </Box>

                            <Box
                                display="flex"
                                column
                                alignY="center"
                                className="mt-auto pt-7 pt-md-8 px-5 px-md-9 pb-12"
                                gap="7">
                                {!fetching && (
                                    <CredentialTokenComponent
                                        credentials={credentials}
                                        onChangeToken={(token) => {
                                            if (setFieldValue) {
                                                setFieldValue("otp", token);
                                            }
                                        }}
                                        disabled={fetching}
                                        relativeStyleError
                                    />
                                )}

                                <Button
                                    bsStyle="primary"
                                    label="global.confirm"
                                    block
                                    onClick={() => handlerSendForm()}
                                    disabled={isValidating || fetching || !hasInitialChanges || errorMessage}
                                />
                            </Box>
                        </Box>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: transactionalProfileSelector.getFetching(state),
    countriesListProp: transactionalProfileSelector.getCountriesList(state),
    hideSelector: !transactionalProfileSelector.getHasProfile(state),
    account: transactionalProfileSelector.getAccount(state),
    accountList: transactionalProfileSelector.getAccounts(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(transactionalProfileSelector.getCredentialsGroups(state)),
    hasCompleteCredentialGroups: transactionalProfileSelector.hasCompleteCredentialGroups(state),
});

TransactionalProfileModal.propTypes = {
    children: func,
    countriesListProp: arrayOf(shape({})).isRequired,
    accountList: arrayOf(shape({})).isRequired,
    currentLang: string,
    fromBackoffice: bool,
    hideSelector: bool,
    idTransaction: string,
    isDesktop: bool.isRequired,
    mode: string,
    onClose: func,
    setFieldValue: func.isRequired,
    show: bool,
    fetching: bool,
    values: shape({}).isRequired,
    account: shape({}),
    dispatch: func.isRequired,
    submitForm: func.isRequired,
    credentials: shape({}),
    resetForm: func.isRequired,
};

TransactionalProfileModal.defaultProps = {
    children: null,
    currentLang: null,
    fromBackoffice: false,
    hideSelector: false,
    idTransaction: null,
    mode: "edit",
    onClose: () => {},
    show: false,
    account: null,
    fetching: false,
    credentials: {},
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            inAll: false,
            outAll: false,
            otp: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch, hideSelector, onClose } = formikBag.props;
            const { account } = formikBag.props;
            const { otp } = values;

            const newListCountries = values.listSelected;

            dispatch(
                transactionalProfileActions.transactionalCountriesUpdateRequest(
                    account?.idProduct,
                    newListCountries,
                    !hideSelector,
                    onClose,
                    otp,
                    formikBag,
                ),
            );
        },
    }),
)(resizableRoute(TransactionalProfileModal));
