import classNames from "classnames";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { replace } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import Disclaimer from "pages/_components/Disclaimer";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { validationAccount, validationLength, validationText } from "util/validationSchemaUtil";
import * as Yup from "yup";
import FormTransition from "../_components/FormTransition";
import { useOptionLabel, useSelectorOptionsAccounts } from "./hooks/TransferCustomHooks";
import { useDebitAccountOrigin, useGenericProps, useCurrencyOrigin } from "./hooks/TransferInternalHooks";

const ID_FORM = "reference.letter";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = "forms.reference.letter.title";

const ReferenceLetter = (props) => {
    const { mode, dispatch, location, preDataForm, isDesktop, isTablet, loggedUser } = props;

    const [genericProps] = useGenericProps(props, ID_ACTIVITY);
    const [debitAccountOrigin] = useDebitAccountOrigin(location);
    const [currencyOrigin] = useCurrencyOrigin(location);
    const [listCurrency] = useOptionLabel(preDataForm?.currencyList);
    const [accountList] = useSelectorOptionsAccounts(preDataForm?.debitAccountList);
    const [accountListEdit, setAccountListEdit] = useState(accountList);
    const [showAllAccDisclaimer, setShowAllAccDisclaimer] = useState(false);
    const activeStatus = "ACTIVA";

    const maxLengthDestinatary = config.getInteger("form.reference.letter.field.destinatary.maxLength");

    const getAccountWithAll = (list) => {
        let listActive = null;
        if (list && list.options) {
            listActive = { options: list.options.filter((x) => x.status === activeStatus) };
        }

        if (list?.options?.length <= 1) {
            return list;
        }

        let { options } = list;
        if (listActive?.options?.length > 1) {
            options = [
                {
                    id: "all",
                    idProduct: "all",
                    label: i18n.get(`reference.letter.all.acccounts`),
                },
            ].concat(list.options);
        }

        return {
            options,
        };
    };

    useEffect(() => {
        if (mode === "edit") {
            if (!preDataForm?.debitAccountList && !preDataForm?.currencyList) {
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE }));
            }
            if (!accountListEdit || !accountListEdit?.options) {
                setAccountListEdit(getAccountWithAll(accountList));
            }
        }
    }, [dispatch, mode, location]);

    useEffect(() => {
        if (!accountListEdit || !accountListEdit?.options) {
            setAccountListEdit(getAccountWithAll(accountList));
        }
    }, [accountList]);

    const {
        nonWorkingDays = [],
        enabledWeekDays = [true, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        // eslint-disable-next-line react/prop-types
    } = props.preData || {};

    const setClientTypeValue = (setFieldValue) => {
        const { bankType } = loggedUser;
        setFieldValue("clientType", bankType);
    };

    const validationSchema = () =>
        Yup.object().shape({
            destinatary: validationLength(
                "",
                "forms.reference.letter.destinatary.maxCharacters",
                0,
                maxLengthDestinatary,
                true,
                undefined,
            ),
            currency: validationText(),
            debitAccount: validationAccount(null, [...(preDataForm?.debitAccountList || [])]),
        });

    const handleOnCHangeRadioButton = (e, setFieldValue) => {
        setFieldValue("language", e);
        setClientTypeValue(setFieldValue);
    };

    const cancelAction = () => {
        dispatch(
            replace({
                pathname: "/desktop",
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    const onChangeDebitAccount = (e, setFieldValue) => {
        if (listCurrency?.length > 0) {
            if (e !== "all") {
                const accountSelected = preDataForm?.debitAccountList?.find((x) => x.idProduct === e);
                const currencySelected = listCurrency.find((x) => x.id === accountSelected?.currency);
                setFieldValue("currency", [currencySelected]);
                setShowAllAccDisclaimer(false);
            } else {
                setShowAllAccDisclaimer(true);
            }
            setFieldValue("accounts", accountListEdit);
            setClientTypeValue(setFieldValue);
        }
    };

    const onChangeCurrency = (e, setFieldValue) => {
        const accs = accountList.options.filter((x) => x.currency === e[0]?.id);
        setAccountListEdit(getAccountWithAll({ options: accs }));
        setFieldValue("accounts", accountListEdit);
        setFieldValue("debitAccount", "");
        setShowAllAccDisclaimer(false);
        setClientTypeValue(setFieldValue);
    };

    const renderFields = (setFieldValue, values) => {
        const radioOptions = [
            {
                id: "spa",
                label: i18n.get(`reference.letter.language.spanish`),
            },
            {
                id: "eng",
                label: i18n.get(`reference.letter.language.english`),
            },
        ];

        if (!preDataForm || !preDataForm.currencyList || !preDataForm.debitAccountList) {
            return <PageLoading loading />;
        }

        const valueAccount =
            values?.debitAccount || (accountListEdit?.options?.[1]?.idProduct ? debitAccountOrigin : "");
        let valueCurrency = preDataForm.currencyDisabled ? listCurrency[0] : values.currency;
        valueCurrency = valueCurrency || currencyOrigin;

        const boxPadding = "pt-5 pb-9 px-5 pt-md-8 pb-md-11 px-md-8";

        const accountInput = () => (
            <>
                <Field
                    {...genericProps}
                    {...(isDesktop && { labelNoMarginTop: true })}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={accountListEdit}
                    key="debitAccount"
                    name="debitAccount"
                    renderAs="combo"
                    value={valueAccount}
                    onChange={(e) => {
                        onChangeDebitAccount(e, setFieldValue);
                    }}
                    idField="debitAccount"
                />
                {showAllAccDisclaimer && (
                    <Disclaimer
                        alignY="flex-start"
                        className="pt-3 pr-0"
                        padding="p-3 px-md-0 pt-md-3 pb-md-0"
                        labelKey="reference.letter.all.acccounts.disclaimer"
                        textSize="7"
                    />
                )}
            </>
        );

        return (
            <>
                {mode === "edit" && (
                    <>
                        <Row
                            className="mt-3 mt-md-0 mb-3 mb-md-7"
                            {...(!isDesktop && { gapY: "3" })}
                            {...(isTablet && { gapX: "8" })}
                            {...(isDesktop && !isTablet && { gapX: "12" })}>
                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    className={classNames("pt-5 px-5 pt-md-8 pb-md-11 px-md-8 mx-n-5", {
                                        "pb-5": showAllAccDisclaimer,
                                        "pb-9": !showAllAccDisclaimer,
                                    })}
                                    borderRadius="default">
                                    <Field
                                        {...genericProps}
                                        labelNoMarginTop
                                        key="currency"
                                        name="currency"
                                        renderAs="combo"
                                        idField="currency"
                                        labelIdField="react-select-currency-input"
                                        // customPlaceholder={i18n.get(`forms.${ID_FORM}.currency.placeholder`)}
                                        component={FormFieldsComponents.Selector}
                                        optionList={listCurrency}
                                        defaultValue={valueCurrency}
                                        disabled={preDataForm.currencyDisabled}
                                        onChange={(e) => {
                                            onChangeCurrency(e, setFieldValue);
                                        }}
                                    />
                                    {!isDesktop && accountInput()}
                                </Box>
                            </Col>

                            <Col xs={12} md={6}>
                                <Box
                                    alignY="center"
                                    background="white"
                                    borderRadius="default"
                                    className="px-5 px-md-8 py-5 py-md-auto mx-n-5"
                                    display="flex"
                                    fullHeight>
                                    <Box display="flex" alignY="center">
                                        <FieldLabel
                                            labelKey="forms.reference.letter.language.label"
                                            labelClassName="color-heading mt-1 mr-9 mb-0"
                                        />
                                        <Field
                                            {...genericProps}
                                            labelNoMarginTop
                                            component={FormFieldsComponents.RadioButtonGroupCustom}
                                            key="language"
                                            name="language"
                                            className={classNames("d-flex", { "flex-row": true })}
                                            radioLabelClassName="mr-7"
                                            inLineControl={false}
                                            onChange={(e) => {
                                                handleOnCHangeRadioButton(e, setFieldValue);
                                            }}
                                            options={radioOptions}
                                            value={values.language || "spa"}
                                        />
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                        <Row
                            className="mb-7"
                            {...(isTablet && { gapX: "8" })}
                            {...(isDesktop && !isTablet && { gapX: "12" })}>
                            {isDesktop && (
                                <Col xs={12} md={6}>
                                    <Box
                                        background="white"
                                        className={classNames("pt-5 pb-9 px-5 pt-md-8 px-md-8 mx-n-5", {
                                            "pb-md-6": showAllAccDisclaimer,
                                            "pb-md-11": !showAllAccDisclaimer,
                                        })}
                                        borderRadius="default"
                                        position="relative">
                                        {accountInput()}
                                    </Box>
                                </Col>
                            )}

                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    className={classNames(boxPadding, "mx-n-5")}
                                    borderRadius="default"
                                    fullHeight>
                                    <Field
                                        {...genericProps}
                                        labelNoMarginTop
                                        component={FormFieldsComponents.Text}
                                        key="destinatary"
                                        name="destinatary"
                                        idField="destinatary"
                                        isRequired
                                        validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ]*$"
                                        labelKey="forms.transfers.internal.reference"
                                        optional={i18n.get("form.field.optional")}
                                        maxLength={maxLengthDestinatary}
                                        tooltipText={`forms.${ID_FORM}.destinatary.tooltip`}
                                        tooltipPosition={isDesktop ? "left-centered" : "bottom-centered"}
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </>
                )}
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        renderTicket: null,
        useDefaultSubmit: true,
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
        showSchedulerMessage: false,
        topDisclaimer: "",
        showFilterChips: false,
        cancelAction,
        submitButtonLabel: "forms.reference.letter.submit.button",
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    loggedUser: sessionSelectors.getUser(state),
});

ReferenceLetter.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
    loggedUser: shape({
        bankType: string,
    }),
};
ReferenceLetter.defaultProps = {
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    location: {},
    fromTransaction: false,
    loggedUser: null,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(ReferenceLetter));
