/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from "classnames";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { bool, func, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { selectors as desktopSelectors } from "reducers/desktop";
import * as i18n from "util/i18n";

const SwitchToggle = ({
    bold,
    className,
    color,
    disabled,
    fixLabelKey,
    greyStyle,
    handleToggle,
    id,
    isSidebarCollapsed,
    labelOffKey,
    labelOnKey,
    productType,
    size,
    switchColor,
    switchIsOn,
    removeMinHeight,
    tabIndex,
    textClassName,
    tooltipText,
}) => {
    const [checked, setChecked] = React.useState(switchIsOn);
    useEffect(() => {
        setChecked(switchIsOn);
    }, [switchIsOn]);

    const toggle = () => {
        if (!disabled) {
            handleToggle(id);
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter" || e.key === "space") {
            toggle();
        }
    };

    return (
        <Box display="flex" key={`switch-${(Math.floor(Math.random() * 1000000) + 1000000).toString()}`}>
            <Box
                tabIndex={tabIndex}
                role="switch"
                className={classNames("switch-toggle", className, {
                    "grey-style": greyStyle,
                    [`switch-color-${switchColor}`]: switchColor,
                })}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggle();
                }}
                onKeyDown={(e) => {
                    e.stopPropagation();
                    handleKeyPress(e);
                }}
                aria-checked={checked}
                aria-label={i18n.get(fixLabelKey || (checked ? labelOnKey : labelOffKey))}>
                <label
                    className={classNames("react-switch-label", {
                        "react-switch-label-on": checked,
                    })}
                    htmlFor={`react-switch-new-${id}`}>
                    <input
                        checked={checked}
                        onChange={(e) => e.preventDefault()}
                        className="react-switch-checkbox"
                        type="checkbox"
                    />
                    <span className="react-switch-button" />
                    <Text hidden>{i18n.get(fixLabelKey || (checked ? labelOnKey : labelOffKey))}</Text>
                </label>
            </Box>
            <Box
                display="flex"
                className={classNames("switch-toggle", className, {
                    "grey-style": greyStyle,
                })}>
                <Text
                    color={color}
                    size={size}
                    bold={bold}
                    ellipsis
                    className={classNames("switch-toggle-text", textClassName, {
                        "switch-toggle-text-hidden": !isSidebarCollapsed && productType === "TC",
                        "switch-toggle-text-min-height": !removeMinHeight,
                    })}
                    labelKey={fixLabelKey || (checked ? labelOnKey : labelOffKey)}
                />
                {tooltipText && <Tooltip forEllipsis text={tooltipText} />}
            </Box>
        </Box>
    );
};

SwitchToggle.propTypes = {
    bold: string,
    className: string,
    color: string,
    disabled: bool,
    fixLabelKey: string,
    greyStyle: bool,
    handleToggle: func,
    id: string,
    isSidebarCollapsed: bool.isRequired,
    labelOffKey: string,
    labelOnKey: string,
    productType: string,
    size: string,
    switchColor: string,
    switchIsOn: bool.isRequired,
    removeMinHeight: bool,
    tabIndex: string,
    textClassName: string,
    tooltipText: string,
};

SwitchToggle.defaultProps = {
    bold: null,
    className: null,
    color: null,
    disabled: false,
    fixLabelKey: null,
    greyStyle: false,
    handleToggle: (e) => e,
    id: null,
    labelOffKey: null,
    labelOnKey: null,
    productType: null,
    size: null,
    switchColor: null,
    removeMinHeight: false,
    tabIndex: "0",
    textClassName: "mr-3 ml-3",
    tooltipText: null,
};

const mapStateToProps = (state) => ({
    isSidebarCollapsed: desktopSelectors.isSidebarCollapsed(state),
});

export default connect(mapStateToProps)(SwitchToggle);
