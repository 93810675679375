import classNames from "classnames";
import FormSignatures from "pages/forms/_components/FormSignatures";
import TransactionTicketCancelConfirmation from "pages/forms/_components/TransactionTicketCancelConfirmation";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, instanceOf, shape, string, node as nodeProp } from "prop-types";
import React, { Component, createRef } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import { actions as formsActions, selectors as formsSelectors } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";
import * as schedulerUtils from "util/scheduler";
import { getTransactionKind } from "util/transaction";
import { statusMap } from "util/general";
import * as i18n from "util/i18n";
import Disclaimer from "pages/_components/Disclaimer";

class TransactionTicket extends Component {
    state = {
        showDetails: false,
    };

    transactionTicketCancelSection = createRef();

    static propTypes = {
        // fromBackoffice: bool,
        isCancelling: bool,
        transaction: shape({
            programed: bool,
            idTransaction: string,
            submitDateTimeAsString: string,
            idTransactionStatus: string,
        }),
        childrenTransactions: arrayOf(
            shape({
                valueDateTime: instanceOf(Date),
                idTransaction: string,
                idTransactionStatus: string,
            }),
        ),
        formActions: shape({}),
        parentTransaction: shape({
            idTransaction: string,
            creationDateTime: instanceOf(Date),
        }),
        children: shape({}),
        // tells if we are rendering to confirm a recent transaction
        // ticketConfirmation: bool,
        dispatch: func,
        isDesktop: bool,
        // activeEnvironment: shape({
        //     permissions: shape({
        //         requestTransactionCancellation: bool,
        //     }),
        // }).isRequired,
        showSchedulerMessage: bool,
        generalHandleClick: func,
        handleCancel: func,
        isMobileNative: bool.isRequired,
        wally: bool,
        usesJointAccount: bool,
        extraContent: nodeProp,
    };

    static defaultProps = {
        // fromBackoffice: false,
        isCancelling: false,
        // ticketConfirmation: false,
        transaction: {},
        childrenTransactions: null,
        formActions: null,
        parentTransaction: null,
        children: null,
        dispatch: () => {},
        isDesktop: undefined,
        showSchedulerMessage: true,
        generalHandleClick: null,
        handleCancel: null,
        wally: false,
        usesJointAccount: false,
        extraContent: undefined,
    };

    componentDidUpdate() {
        if (this.transactionTicketCancelSection && this.transactionTicketCancelSection.scrollIntoView) {
            this.transactionTicketCancelSection.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(formsActions.formClosed());
    }

    handleClick = () => {
        this.setState(({ showDetails }) => ({ showDetails: !showDetails }));
    };

    handleCancelTransaction = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(push(`/form/requestTransactionCancellation?referenceToCancel=${idTransaction}`));
    };

    render() {
        const {
            childrenTransactions,
            formActions,
            // fromBackoffice,
            isCancelling,
            parentTransaction,
            // ticketConfirmation,
            transaction,
            children,
            isDesktop,
            // activeEnvironment,
            showSchedulerMessage,
            generalHandleClick,
            handleCancel,
            isMobileNative,
            wally,
            usesJointAccount,
            extraContent,
        } = this.props;
        const { idTransaction, idParentTransaction } = transaction;
        const selectedOption = transaction?.data?.scheduler ? transaction.data.scheduler.selectedOption : null;

        const scheduled = selectedOption ? selectedOption !== schedulerUtils.TODAY : false;

        const statusIcon = () => {
            let icon = "";
            if (
                transaction.idTransactionStatus === "FINISHED" || transaction.idTransactionStatus === "ACCEPTED" &&
                transaction.idActivity !== "requestTransfers.wally.send"
            ) {
                icon = "success";
            } else if (transaction.idTransactionStatus === "PARCIAL") {
                icon = "partial";
            } else if (transaction.idTransactionStatus === "SCHEDULED") {
                icon = "success-scheduled";
            } else if (
                transaction.idTransactionStatus === "PROCESSING" ||
                transaction.idTransactionStatus === "DRAFT" ||
                transaction.idActivity === "requestTransfers.wally.send"
            ) {
                icon = "processing";
            } else if (transaction.idTransactionStatus === "PENDING") {
                icon = "pending";
            } else {
                icon = "error";
            }
            return icon;
        };

        let titleKind = getTransactionKind(transaction?.idActivity);

        if (transaction?.idActivity === "redeem.miles.connect.send" && transaction?.data?.miles === "Lifemiles") {
            titleKind = getTransactionKind(`${transaction?.idActivity}.lifemiles`);
        }

        const idStatus = statusMap.get(transaction.idTransactionStatus) ?? transaction.idTransactionStatus;

        let labelKeyHeader = `forms.transaction.ticket.status.${idStatus}.${titleKind}`;

        const labelCancelDetail = "forms.transfers.confirmation.cancel.detail";

        if (isCancelling) {
            labelKeyHeader = "forms.transfers.confirmation.cancel.title";
        }

        return (
            <Box className={classNames("ticket-wrapper", { wally })}>
                <Row gapY="7">
                    <Col xs={12}>
                        <Box component="article" className="ticket">
                            <Box
                                component="header"
                                display="flex"
                                alignX="center"
                                alignY="center"
                                className={classNames(`ticket-header  ${statusIcon()}`, { wally })}>
                                <Box display="flex" alignY="center" className="ticket-header-info">
                                    <Image
                                        src={`images/icons/tickets/${statusIcon()}.svg`}
                                        wrapperClassName="ticket-header-image"
                                    />

                                    <Text className="ticket-header-text ml-4" labelKey={labelKeyHeader} />
                                </Box>
                            </Box>

                            <Box
                                className={classNames("default-ticket-content zig-zag-border", {
                                    "success-ticket":
                                        statusIcon() === "success" || statusIcon() === "success-scheduled",
                                    "partial-ticket": statusIcon() === "partial",
                                })}
                                fullWidth
                                background="white"
                                position="relative">
                                {children ? (
                                    <Box className="data-children display-contents mb-7">
                                        {children}
                                        {showSchedulerMessage && scheduled && (
                                            <div className="data-wrapper mx-7">
                                                <I18n
                                                    id="forms.confirmation.scheduler"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <div>
                                                    <Message value={transaction.data.scheduler} />
                                                </div>
                                            </div>
                                        )}
                                    </Box>
                                ) : null}

                                <Box className="ticket-info-wrapper-secondary">
                                    {transaction?.idActivity !== "historic.transaction" &&
                                        (transaction?.signatures?.length > 1 || usesJointAccount) && (
                                            <Box display="flex" fullWidth className="mb-5">
                                                <FormSignatures
                                                    transaction={transaction}
                                                    parentTransaction={parentTransaction}
                                                    usesJointAccount={usesJointAccount}
                                                />
                                            </Box>
                                        )}

                                    {parentTransaction && !usesJointAccount && (
                                        <>
                                            <Text component="h4" className="data-title" labelKey="scheduler.title" />

                                            <Box display="flex" alignX="between" alignY="center">
                                                <Box display="flex">
                                                    <Text
                                                        className="data-label mr-3"
                                                        labelKey="forms.transaction.ticket.createdDate.label"
                                                    />
                                                    <FormattedDate size="7" date={parentTransaction.creationDateTime} />
                                                </Box>
                                                <Button
                                                    href={`/transaction/${parentTransaction.idTransaction}`}
                                                    bsStyle="link"
                                                    label="forms.transaction.ticket.detail.label"
                                                />
                                            </Box>
                                        </>
                                    )}

                                    {childrenTransactions && childrenTransactions.length > 0 && (
                                        <Row>
                                            <Col xs={12}>
                                                <Text
                                                    component="h4"
                                                    labelKey="forms.transaction.ticket.scheduled.children.title"
                                                    className="data-title"
                                                />
                                                {childrenTransactions.map((child) => (
                                                    <>
                                                        <Box className="link-wrapper" fullWidth>
                                                            <Link to={`/transaction/${child.idTransaction}`}>
                                                                <Box
                                                                    display="flex"
                                                                    alignY="baseline"
                                                                    alignX="between"
                                                                    fullWidth>
                                                                    <Box
                                                                        display="flex"
                                                                        className="mr-3"
                                                                        alignY="baseline"
                                                                        fullWidth>
                                                                        <FormattedDate
                                                                            color="heading"
                                                                            size="7"
                                                                            bold
                                                                            date={child.valueDateTime}
                                                                        />
                                                                        <Box className="d-grid" withEllipsis>
                                                                            <Text
                                                                                className="id-ticket ml-2"
                                                                                size="small"
                                                                                ellipsis>
                                                                                {child.idTransaction}
                                                                            </Text>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        display="flex"
                                                                        alignY="center"
                                                                        alignX="center"
                                                                        borderRadius="sm"
                                                                        className={classNames("state-wrapper px-3", {
                                                                            "background-error":
                                                                                child.idTransactionStatus ===
                                                                                    "FAILED" ||
                                                                                child.idTransactionStatus ===
                                                                                    "CANCELLED" ||
                                                                                child.idTransactionStatus ===
                                                                                    "DELETED" ||
                                                                                child.idTransactionStatus ===
                                                                                    "RETURNED",

                                                                            "background-warning-border-color":
                                                                                child.idTransactionStatus ===
                                                                                "PROCESSING",
                                                                            "background-warning":
                                                                                child.idTransactionStatus ===
                                                                                    "PENDING" ||
                                                                                child.idTransactionStatus === "DRAFT" ||
                                                                                child.idTransactionStatus ===
                                                                                    "SCHEDULED",
                                                                            "background-success":
                                                                                child.idTransactionStatus ===
                                                                                "FINISHED" || child.idTransactionStatus === "ACCEPTED",
                                                                            "background-partial":
                                                                                child.idTransactionStatus === "PARCIAL",
                                                                        })}>
                                                                        <Text
                                                                            color="text-boton"
                                                                            size="7"
                                                                            bold
                                                                            ellipsis
                                                                            labelKey={`transaction.status.${child.idTransactionStatus}`}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Link>
                                                        </Box>
                                                    </>
                                                ))}
                                            </Col>
                                        </Row>
                                    )}
                                </Box>
                                {idTransaction && (
                                    <Box className="px-7 pb-7">
                                        <Text
                                            size="7"
                                            color="heading"
                                            labelKey="forms.transaction.ticket.scheduled.idTransaction.label"
                                        />
                                        <Text size="7" color="heading">
                                            {usesJointAccount && idParentTransaction
                                                ? idParentTransaction
                                                : idTransaction}
                                        </Text>
                                    </Box>
                                )}

                                {transaction.idActivity === "transfers.foreign.send" && (
                                    <Box className="px-3 pb-7">
                                        <Disclaimer labelKey="transferForeing.tooltip.message" textColor="secondary" />
                                    </Box>
                                )}

                                {statusIcon() !== "processing" &&
                                statusIcon() !== "pending" &&
                                transaction?.idTransactionStatus !== "SCHEDULED" &&
                                transaction?.data?.typeTransaction !== "PARENT" &&
                                !isMobileNative ? (
                                    <Box display="flex" alignX="center" className="mt-auto">
                                        <Button
                                            className="px-5"
                                            bsStyle="link"
                                            image="images/icons/download.svg"
                                            label="global.download"
                                            onClick={() => generalHandleClick("downloadTicketPdf")}
                                        />
                                    </Box>
                                ) : null}
                                {extraContent &&
                                    transaction?.idTransactionStatus &&
                                    transaction.idTransactionStatus !== "SCHEDULED" &&
                                    extraContent(transaction)}
                                <Box display="flex" alignX="center" alignY="center" className="pt-8 pb-9" fullWidth>
                                    <Button
                                        bsStyle="socialIcon"
                                        image="images/socialIcons/linkedin.svg"
                                        externalHref="https://www.linkedin.com/company/banesco-panama"
                                        ariaLabel={i18n.get("transactions.ticket.social.linkedin")}
                                    />
                                    <Button
                                        bsStyle="socialIcon"
                                        image="images/socialIcons/facebook.svg"
                                        externalHref="https://www.facebook.com/BanescoPanama"
                                        ariaLabel={i18n.get("transactions.ticket.social.facebook")}
                                    />
                                    <Button
                                        bsStyle="socialIcon"
                                        image="images/socialIcons/twitter.svg"
                                        externalHref="https://twitter.com/BanescoPanama"
                                        ariaLabel={i18n.get("transactions.ticket.social.twitter")}
                                    />
                                    <Button
                                        bsStyle="socialIcon"
                                        image="images/socialIcons/instagram.svg"
                                        externalHref="https://www.instagram.com/banescopanama/"
                                        ariaLabel={i18n.get("transactions.ticket.social.instagram")}
                                    />
                                    <Button
                                        bsStyle="socialIcon"
                                        image="images/socialIcons/youtube.svg"
                                        externalHref="https://www.youtube.com/user/BanescoPanama"
                                        ariaLabel={i18n.get("transactions.ticket.social.youtube")}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        {isCancelling ? (
                            <section
                                ref={(node) => {
                                    this.transactionTicketCancelSection = node;
                                }}>
                                <Box display="flex" alignX="center" fullWidth>
                                    <TransactionTicketCancelConfirmation
                                        idTransaction={idTransaction}
                                        handleCancelPreview={handleCancel}
                                        labelConfirm={labelCancelDetail}
                                        scheduled={scheduled}
                                    />
                                </Box>
                            </section>
                        ) : (
                            <div className="d-flex-justify-content-center">
                                <div className={isDesktop && "ml-1"}>{formActions}</div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isCancelling: formsSelectors.getIsCancellingTransaction(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(resizableRoute(TransactionTicket));
