import { Formik, Form, Field } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import PinInput from "pages/_components/fields/PinInput";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React from "react";
import { Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";

const TokenAndButtonsModal = (props) => {
    const { isDesktop, acceptFunction, cancelFunction, headingText, modalShow, confirmLabel } = props;

    const handleAccept = (pinValue) => {
        acceptFunction(pinValue);
    };

    return (
        <div className="modal-container">
            <Modal show={modalShow}>
                <Box className="pt-7 px-6 pb-9">
                    <Box display="flex" fullWidth alignX="center">
                        <Text size="2" color="heading" bold>
                            {headingText}
                        </Text>
                    </Box>
                    <Box display="flex" alignX="center" className="mt-9 mx-md-12">
                        <Formik
                            initialValues={{ pinCode: "" }}
                            validate={(values) => {
                                const errors = {};
                                if (!values?.pinCode) {
                                    errors.pinCode = i18n.get(DEFAULT_REQUIRED);
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                setSubmitting(false);
                                if (handleAccept) {
                                    handleAccept(values.pinCode);
                                }
                                resetForm({
                                    pinCode: "",
                                });
                            }}>
                            {() => (
                                <Form>
                                    <Field
                                        name="pinCode"
                                        component={PinInput}
                                        placeholder="*"
                                        maxLength={1}
                                        labelNoMarginTop
                                        showLabel={false}
                                        type="number"
                                        noMarginBottom
                                    />

                                    <Box display="flex" column fullWidth>
                                        {isDesktop ? (
                                            <Row className="px-0">
                                                <Col xs={6}>
                                                    <Button
                                                        label="global.cancel"
                                                        bsStyle="outline"
                                                        onClick={cancelFunction}
                                                        block
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <Button
                                                        label={confirmLabel || "global.accept"}
                                                        bsStyle="primary"
                                                        block
                                                        type="submit"
                                                    />
                                                </Col>
                                            </Row>
                                        ) : (
                                            <>
                                                <Button
                                                    id="btnConfirm"
                                                    label={confirmLabel || "global.accept"}
                                                    bsStyle="primary"
                                                    block
                                                    type="submit"
                                                />
                                                <Button
                                                    label="global.cancel"
                                                    bsStyle="outline"
                                                    onClick={cancelFunction}
                                                    block
                                                />
                                            </>
                                        )}
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

TokenAndButtonsModal.propTypes = {
    isDesktop: bool.isRequired,
    acceptFunction: func.isRequired,
    cancelFunction: func.isRequired,
    modalShow: bool.isRequired,
    headingText: string.isRequired,
    confirmLabel: string,
};
TokenAndButtonsModal.defaultProps = {
    confirmLabel: "global.accept",
};

export default connect()(resizableRoute(TokenAndButtonsModal));
