import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Document from "pages/_components/fields/Document";
import HeadSimple from "pages/_components/HeadSimple";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { actions as loginActions } from "reducers/login";
import { actions as recoveryUserActions, selectors as recoveryUserSelectors } from "reducers/recoveryUser";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as utilIdentification from "util/recoveryUser";
import * as Yup from "yup";

const FORM_ID = "recoverUser.step1";

const RecoverUserStep1 = (props) => {
    const { dispatch, fetching, preData, isMobile } = props;
    const [countryList, setCountryList] = useState([]);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [idNumberMap1, setIdNumberMap1] = useState([]);
    const [idNumberMap2, setIdNumberMap2] = useState({});

    useEffect(() => {
        dispatch(recoveryUserActions.preForm());
    }, [dispatch]);

    useEffect(() => {
        setCountryList(preData?.countryList || []);
        setDocumentTypeList(preData?.documentTypeList || []);
        setIdNumberMap1(preData?.idNumberMap1 || []);
        setIdNumberMap2(preData?.idNumberMap2 || []);
    }, [preData]);

    const handlerHeaderClose = () => {
        dispatch(recoveryUserActions.reset());
        dispatch(loginActions.reset());
    };

    return (
        <PageLoading loading={fetching} {...(isMobile && { classicStyle: false })}>
            <Box
                display="flex"
                column
                background="white"
                fullHeight
                className="px-5 px-md-7 pt-7 pt-md-0 pb-10 pb-md-7">
                <HeadSimple
                    onRightClick={handlerHeaderClose}
                    title="recoverUser.step1.header"
                    className="mb-2 mb-md-0"
                />
                <MainContainer>
                    <Form className="content-recoveryUser-wrapper full-height">
                        <Box
                            display="flex"
                            className="pb-md-0 pt-md-5 px-md-5"
                            column
                            fullWidth
                            {...(isMobile && { fullHeight: true })}>
                            <Box display="flex mb-12">
                                <Text
                                    component="p"
                                    color="text"
                                    size={isMobile ? "5" : "6"}
                                    align="center"
                                    labelKey="recoverUser.step1.title"
                                />
                            </Box>

                            <Box display="flex" className="mb-8">
                                <Field
                                    countries={countryList}
                                    documentCountryMap={documentTypeList}
                                    idNumberMap={idNumberMap1}
                                    idNumber2Map={idNumberMap2}
                                    component={Document}
                                    name="documentUser"
                                    reducedColumnGap
                                    {...(isMobile && { idNumberStyle2: true })}
                                />
                            </Box>
                            <Box display="flex mt-auto">
                                <Button type="submit" bsStyle="primary" label="global.continue" block />
                            </Box>
                        </Box>
                    </Form>
                </MainContainer>
            </Box>
        </PageLoading>
    );
};

RecoverUserStep1.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    preData: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    fetching: recoveryUserSelectors.getFetching(state),
    preData: recoveryUserSelectors.getPreData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            documentUser: {
                documentCountry: "",
                documentType: "",
                documentNumber1: "",
                documentNumber2: "",
                idNumber1: "",
                idNumber2: "",
            },
        }),
        validationSchema: () =>
            Yup.object().shape({
                documentUser: Yup.object().shape({
                    documentCountry: Yup.string().required(i18n.get(`${FORM_ID}.documentCountry.required`)),
                    documentType: Yup.string().required(i18n.get(`${FORM_ID}.documentType.required`)),
                    documentNumber1: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    documentNumber2: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    }),
                    idNumber1: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) =>
                            documentCountry === "PA" || (documentCountry === "VE" && documentType === "CED"),
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    }),
                    idNumber2: Yup.string().when("documentCountry", {
                        is: "PA",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    }),
                }),
            }),
        handleSubmit: ({ documentUser }, formikBag) => {
            const { documentCountry, documentType } = documentUser;
            const documentNumberToShow = utilIdentification.generateIdentificationToShow(documentUser);
            const documentNumber = utilIdentification.generateIdentificationToSend(documentUser);
            const dataForm = { documentCountry, documentType, documentNumber, documentNumberToShow };
            formikBag.props.dispatch(recoveryUserActions.saveForm(dataForm));
            formikBag.props.dispatch(replace("/recoverUser/step2"));
        },
    }),
)(resizableRoute(RecoverUserStep1));
