import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions } from "reducers/enrollment";
import * as config from "util/config";
import { isMobileNativeFunc } from "util/device";
import { openLink } from "util/browser";

class Start extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(enrollmentActions.clean());
    }

    handleGoClick = (corporate) => {
        const { dispatch } = this.props;
        if (!corporate) {
            dispatch(enrollmentActions.saveEnrollmentType(corporate));
            dispatch(push("/enrollment/document"));
        } else {
            // Activo o inactivo enrolamiento para empresas BANIMPBPE-4402
            const isActiveCorporate = config.getBoolean("enrollment.corporate.isActive", true);
            if (isActiveCorporate) {
                dispatch(enrollmentActions.saveEnrollmentType(corporate));
                dispatch(push("/enrollment/document"));
            } else {
                const externalHref = config.get("enrollment.corporate.url", "https://www.banesco.com.pa/");
                if (isMobileNativeFunc()) {
                    openLink(externalHref).then();
                } else {
                    window.open(externalHref, "_blank");
                }
            }
        }
    };

    render() {
        const { isDesktop } = this.props;
        return (
            <>
                <Box
                    component="header"
                    display="flex"
                    {...(!isDesktop && { alignY: "center" })}
                    className="py-7"
                    fullWidth
                    column>
                    <Text component="h1" color="heading" bold labelKey="enrollment.start.title" />
                    <Box display="flex" alignX="center" fullWidth>
                        <Text
                            component="h2"
                            className="m-0"
                            labelKey="enrollment.start.subtitle"
                            size="3"
                            bold
                            color="primary"
                            align="center"
                        />
                    </Box>
                </Box>

                <Row>
                    <Col xs={12} md={10} mdOffset={1}>
                        <Row>
                            <Col xs={12} md={6}>
                                <Box
                                    component="article"
                                    display="flex"
                                    column
                                    alignY="center"
                                    alignX="between"
                                    className="py-9 px-7"
                                    background="white"
                                    borderRadius="default"
                                    fullWidth
                                    fullHeight>
                                    <Text
                                        component="h3"
                                        className="mb-5 mb-md-8"
                                        size={isDesktop ? "4" : "3"}
                                        bold
                                        align="center"
                                        labelKey="enrollment.start.natural"
                                    />
                                    <Box display="flex" alignY="flex-end" className="mb-4 mb-md-8 access-image-wrapper">
                                        <Image src="images/newUser.svg" />
                                    </Box>
                                    <Text
                                        component="p"
                                        className="mb-6 mb-md-11 mt-0"
                                        size="6"
                                        align="center"
                                        labelKey="enrollment.start.natural.description"
                                    />
                                    <Button
                                        bsStyle="outline"
                                        className="px-11"
                                        label="enrollment.start.select"
                                        onClick={() => this.handleGoClick(false)}
                                    />
                                </Box>
                            </Col>
                            <Col xs={12} md={6}>
                                <Box
                                    component="article"
                                    display="flex"
                                    column
                                    alignY="center"
                                    alignX="between"
                                    className="py-9 px-7"
                                    background="white"
                                    borderRadius="default"
                                    fullWidth
                                    fullHeight>
                                    <Text
                                        component="h3"
                                        className="mb-5 mb-md-8"
                                        size={isDesktop ? "4" : "3"}
                                        bold
                                        align="center"
                                        labelKey="enrollment.start.corporate"
                                    />
                                    <Box display="flex" alignY="flex-end" className="mb-4 mb-md-8 access-image-wrapper">
                                        <Image src="images/newUserCorp.svg" />
                                    </Box>
                                    <Text
                                        component="p"
                                        className="mb-6 mb-md-11 mt-0"
                                        size="6"
                                        align="center"
                                        labelKey="enrollment.start.corporate.description"
                                    />
                                    <Button
                                        bsStyle="outline"
                                        className="px-11"
                                        label="enrollment.start.select"
                                        onClick={() => this.handleGoClick(true)}
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

Start.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
};

export default connect()(resizableRoute(Start));
