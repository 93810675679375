import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import GeneralMsg from "pages/_components/GeneralMsg";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Table from "rc-table";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";

class SignaturesSchemes extends Component {
    static propTypes = {
        actions: shape({
            exportListRequest: func,
            listSignaturesSchemesRequest: func,
            loadMoreRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
            type: string,
        }).isRequired,
        currentPage: number,
        fetching: bool,
        fetchingExport: bool,
        fetchingMoreSignaturesSchemes: bool,
        hasMoreData: bool.isRequired,
        routerActions: shape({
            push: func,
        }).isRequired,
        signaturesSchemes: arrayOf(
            shape({
                capList: arrayOf(shape({ maximum: number })),
                idSignature: number,
                signatureType: string,
            }),
        ).isRequired,
        totalPages: number.isRequired,
    };

    static defaultProps = {
        currentPage: 1,
        fetching: false,
        fetchingExport: false,
        fetchingMoreSignaturesSchemes: false,
    };

    componentDidMount() {
        const { actions } = this.props;

        actions.listSignaturesSchemesRequest();
    }

    getSigners = (administrationScheme, signatureScheme) => {
        const { groupsMap } = signatureScheme;

        return administrationScheme === "medium"
            ? groupsMap.A
            : Object.keys(groupsMap).reduce((result, key) => result + key.toString().repeat(groupsMap[key]), "");
    };

    generateTableColumns = () => {
        const {
            activeEnvironment: { administrationScheme },
            routerActions,
        } = this.props;

        let result = [
            {
                key: "signers",
                dataIndex: "signers",
                title: i18n.get("administration.signatures.list.signers"),
                width: 75,
            },
            {
                key: "transactions",
                dataIndex: "transactions",
                title: i18n.get("administration.signatures.list.transactions"),
                width: 250,
            },
            {
                key: "functionalGroups",
                dataIndex: "functionalGroups",
                title: i18n.get("administration.signatures.list.functionalGroups"),
                width: 250,
            },
        ];

        if (administrationScheme === "advanced") {
            result = [
                ...result,
                {
                    align: "left",
                    key: "amount",
                    dataIndex: "amount",
                    title: i18n.get("administration.signatures.list.amountPerPeriod"),
                    width: 150,
                },
            ];
        }

        return [
            ...result,
            {
                key: "actions",
                dataIndex: "actions",
                title: "",
                width: 25,
                className: "text-right",
                onCell: (record) => ({
                    onClick: (e) => {
                        routerActions.push(
                            `/administration/${administrationScheme}/signaturesSchemes/remove/${record.idSignature}`,
                        );

                        e.stopPropagation();
                    },
                }),
            },
        ];
    };

    handleClickDownload = (format) => {
        const { actions } = this.props;

        actions.exportListRequest(format);
    };

    handleMoreDataClick = () => {
        const { actions, currentPage } = this.props;

        actions.loadMoreRequest({ pageNumber: currentPage + 1 });
    };

    btnHandlerOnClick = () => {
        const {
            routerActions,
            activeEnvironment: { administrationScheme },
        } = this.props;
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/create`);
    };

    onRowClick = (record) => {
        const {
            activeEnvironment: { administrationScheme },
            routerActions,
        } = this.props;

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/${record.idSignature}`);
    };

    populateSignaturesData = () => {
        const {
            activeEnvironment: { administrationScheme, type },
            signaturesSchemes,
        } = this.props;

        return (
            signaturesSchemes &&
            signaturesSchemes.map((signatureScheme) => {
                const { capList, signatureFeatures: functionalGroups, idSignature, signatureType } = signatureScheme;

                let result = {
                    idSignature,
                    key: idSignature,
                    signers: this.getSigners(administrationScheme, signatureScheme),
                };

                if (administrationScheme === "advanced" || administrationScheme === "medium") {
                    result = { transactions: i18n.get(`signatures.type.${signatureType}`), ...result };
                    if (functionalGroups && functionalGroups.length) {
                        let textFunctionalGroups = functionalGroups
                            .slice(0, 2)
                            .map((functionalGroup) =>
                                i18n.get(`administration.signatures.functionalGroups.${functionalGroup}`),
                            )
                            .join(", ");
                        if (functionalGroups.length > 2) {
                            textFunctionalGroups += ` (+${functionalGroups.length - 2})`;
                        }

                        result = { functionalGroups: textFunctionalGroups, ...result };
                    } else {
                        result = { functionalGroups: "-", ...result };
                    }
                }

                if (administrationScheme === "advanced") {
                    if (signatureType === "AMOUNT" && capList.length > 0) {
                        result = {
                            amount: (
                                <FormattedAmount
                                    className="data-amount"
                                    frequencyInSpan={false}
                                    currency={configUtils.get("core.masterCurrency")}
                                    quantity={
                                        capList[0].maximum === -1
                                            ? configUtils.get(`default_cap_signature_${type}`)
                                            : capList[0].maximum
                                    }
                                    frequency={i18n.get(
                                        "administration.signatures.create.advanced.topAmountPerPeriod",
                                        null,
                                        {
                                            period: i18n.get(
                                                `administration.signatures.transactions.capFrequency.${capList[0].frequency}`,
                                            ),
                                        },
                                    )}
                                />
                            ),
                            ...result,
                        };
                    } else {
                        result = { amount: "-", ...result };
                    }
                }

                return {
                    actions: <Button bsStyle="link" image="images/delete-message.svg" className="btn-circle-bg" />,
                    ...result,
                };
            })
        );
    };

    renderPageHeader = () => {
        const {
            activeEnvironment: { administrationScheme },
            fetchingExport,
            signaturesSchemes,
        } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="administrationSignaturesSchemes" />
                {signaturesSchemes.length ? (
                    <Head
                        addLinkTo={`/administration/${administrationScheme}/signaturesSchemes/create`}
                        addLinkToLabel="administration.signatures.new.title"
                        exportList
                        handleClick={this.handleClickDownload}
                        isFetchingExport={fetchingExport}
                        title="administration.signatures.list.title"
                    />
                ) : (
                    <Head title="administration.signatures.list.title" />
                )}
            </Fragment>
        );
    };

    renderTableFooter = () => {
        const { totalPages, hasMoreData, fetchingMoreSignaturesSchemes } = this.props;

        return (
            <div>
                {totalPages > 1 &&
                    (hasMoreData ? (
                        <div className="text-center no-more-data" key="noMoresignatures">
                            <Button
                                className="btn btn-link"
                                onClick={this.handleMoreDataClick}
                                loading={fetchingMoreSignaturesSchemes}
                                image="images/show.svg"
                                label="administration.signatures.list.more"
                            />
                        </div>
                    ) : (
                        <div className="text-center no-more-data" key="noMoreSignatures">
                            <p className="text-lead">
                                <I18n id="administration.signatures.list.more.noMoreData" />
                            </p>
                        </div>
                    ))}
            </div>
        );
    };

    renderSignaturesSchemesTable = () => {
        const { totalPages } = this.props;

        return (
            <Fragment>
                <div className="flex-container">
                    <Fragment>
                        <h4>{i18n.get("administration.signatures.index.title")}</h4>
                    </Fragment>
                </div>

                <Table
                    columns={this.generateTableColumns()}
                    data={this.populateSignaturesData()}
                    rowKey={(record) => record.key}
                    emptyText={i18n.get("administration.signatures.list.noRecords")}
                    onRow={(record) => ({
                        onClick: () => this.onRowClick(record),
                    })}
                    footer={totalPages > 1 ? this.renderTableFooter : null}
                />
            </Fragment>
        );
    };

    render() {
        const { fetching, signaturesSchemes } = this.props;

        return (
            <Fragment>
                {this.renderPageHeader()}
                <MainContainer className="main-container" showLoader={fetching}>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout container--scrollable-table flex-grow-1 items-center"
                            gridClassName="form-content">
                            <Col className="col-12">
                                {signaturesSchemes.length ? (
                                    this.renderSignaturesSchemesTable()
                                ) : (
                                    <GeneralMsg
                                        imagePath="images/coloredIcons/transfer.svg"
                                        description="administration.signatures.list.noRecords"
                                        callToAction={
                                            <Button
                                                className="btn btn-primary btn-block"
                                                onClick={this.btnHandlerOnClick}
                                                label="administration.signatures.list.addGroup"
                                            />
                                        }
                                    />
                                )}
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default resizableRoute(SignaturesSchemes);
