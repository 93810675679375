import classNames from "classnames";
import DetailHeadInfo from "pages/creditCards/_components/DetailHeadInfo";
import CreditCardMovements from "pages/creditCards/_components/Movements";
import CreditCardOtherFilters from "pages/creditCards/_components/OtherFilters";
import CreditCardPeriodFilter from "pages/creditCards/_components/_filters/PeriodFilter";
import DownloadAccoutDetailForm from "pages/forms/customForms/DownloadAccoutDetailForm";
import ExchangePointsForm from "pages/forms/customForms/ExchangePointsForm";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import SideBarModal from "pages/_components/modal/SideBarModal";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import { bool, func, number as int, shape, string, arrayOf } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as productActions, selectors as productSelectors } from "reducers/products";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";

import { CREDIT_CARD_RELATION_TYPE_ADDITIONAL, generatePinAssigmentData, MAP_CREDIT_CARD_TYPE } from "util/creditCards";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import { selectors as sessionSelectors } from "reducers/session";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import EnabledWalletCardModal from "pages/wallet/EnabledWalletCardModal";
import {
    applePayCardWasDigitized,
    applePayRemovePassButton,
    CREDIT_CARD_WALLET_TYPE,
    MINIMUM_VERSION_IOS_SUPPORTED,
    PROVISION_SUCCESS_CODE,
    START_EVENTS_CODE,
} from "util/wallet/wallet.util";
import * as configUtils from "util/config";
import { isIOSPlatform, isMobileNativeFunc, isSupportVersion } from "util/device";
import StatementsCreditCardActions from "./StatementsActions";
import AdditionalsCards from "./_components/AdditionalsCardsList";
import Conditions from "./_components/Conditions";
import MovementsTransit from "./_components/MovementsTransit";
import { CreditCardStatus, CreditCardFiantialReliefStatus } from "../_components/CreditCard/CreditCard";

const TRANSFER_INTERNAL_PERMISSION = "transferInternal";
const TRANSFER_LOCAL_PERMISSION = "transferLocal";
const TRANSFER_FOREIGN_PERMISSION = "transferForeign";

const PAGE_IDENTIFIER = "creditCards.read";
class CreditCardDetails extends Component {
    periods = {
        lastMonth: dateUtils.getLastMonthPeriod(),
        secondLastMonth: dateUtils.getSecondLastMonthPeriod(),
        currentPeriod: dateUtils.getCurrentPeriod(),
    };

    state = {
        selectedFilter: "currentPeriod",
        resetDateFilters: false,
        showSideBarDownloadDetail: false,
        downloadingStatements: false,
        showSideBarSearchStatements: false,
        transitMovementSize: 0,
        showEnabledWallet: false,
        showWallet: false,
        isCardWasDigitized: undefined,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(productActions.resetChangedPoints());
        dispatch(productActions.hideExchangePointForm());
        this.loadCreditCars();
        this.setRevelockLocation();
    }

    componentDidUpdate(prevProps) {
        const { dispatch, creditCards, getSelectProduct, isMobile } = this.props;

        if (prevProps.creditCards !== creditCards && creditCards.length > 0) {
            const tmpCreditCard = creditCards.filter((card) => card.idProduct === getSelectProduct?.idProduct);
            if (tmpCreditCard.length > 0) {
                dispatch(creditCardActions.setExtraDataDetail(tmpCreditCard[0]));
            }
        }
        if (
            prevProps.match.params.id !== this.props.match.params.id &&
            prevProps.match.path === "/creditCardAdditional/:id"
        ) {
            this.loadCreditCars();
        }
        if (this.props.match.params.id !== getSelectProduct?.idProduct && !isMobile) {
            const tmpCreditCard = creditCards.filter((card) => card.idProduct === this.props.match.params.id);
            if (tmpCreditCard.length > 0) {
                dispatch(creditCardActions.setExtraDataDetail(tmpCreditCard[0]));
                dispatch(
                    productActions.setSelectProduct({
                        idProduct: this.props.match.params.id,
                        description: tmpCreditCard[0].description,
                        statusCode: tmpCreditCard[0].statusCode,
                        type: tmpCreditCard[0].relationType,
                    }),
                );
            }
        }
        if (!this.props.history.location?.pathname.includes(getSelectProduct?.idProduct) && isMobile) {
            const url = this.props.history.location?.pathname;
            const splitUrl = url.split("/");
            if (splitUrl.length > 2) {
                const tmpCreditCard = creditCards.filter((card) => card.idProduct === splitUrl[2]);
                if (tmpCreditCard.length > 0) {
                    dispatch(creditCardActions.setExtraDataDetail(tmpCreditCard[0]));
                    dispatch(
                        productActions.setSelectProduct({
                            idProduct: tmpCreditCard[0].idProduct,
                            description: tmpCreditCard[0].description,
                            statusCode: tmpCreditCard[0].statusCode,
                            type: tmpCreditCard[0].relationType,
                        }),
                    );
                }
            }
        }

        if (this.props.creditCard && this.props.creditCard !== prevProps.creditCard) {
            this.validateCreditCardWallet();
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(creditCardActions.clearOffsetMovements());
    }

    validateEnrolledWallet = async () => {
        const { creditCard } = this.props;
        if (!creditCard?.panSuffix) {
            this.setState({ showWallet: false });
            return;
        }

        const cardWasDigitizedResp = await applePayCardWasDigitized(creditCard.panSuffix);

        const isCardWasDigitized =
            cardWasDigitizedResp !== undefined && (cardWasDigitizedResp === true || cardWasDigitizedResp === "true");
        this.setState({ showWallet: true, isCardWasDigitized });
    };

    validateCreditCardWallet = () => {
        if (!isMobileNativeFunc() || !isIOSPlatform()) {
            this.setState({ showWallet: false });
            return;
        }

        const versionResult = isSupportVersion(window.device.version, MINIMUM_VERSION_IOS_SUPPORTED);
        if (versionResult === undefined || versionResult === false || versionResult === "false") {
            this.setState({ showWallet: false });
            return;
        }

        /**
         * if config key not exists, get default value true for show only certain users
         */
        const testEnabled = configUtils.getBoolean("applePay.test.enabled", true);
        if (testEnabled === false) {
            this.validateEnrolledWallet();
            return;
        }

        const { loggedUser } = this.props;
        const userTestEnabled = configUtils.getArray("applePay.test.users");
        if (userTestEnabled.some((item) => item && loggedUser?.userId && item === loggedUser.userId)) {
            this.validateEnrolledWallet();
        }
    };

    /**
     * Set position revelock
     */

    getLocationBasePath = () => {
        const { match } = this.props;
        const basePathSplit = match?.path?.split(":");
        return basePathSplit && basePathSplit.length > 0 ? basePathSplit[0] : "";
    };

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(this.getLocationBasePath(), PAGE_IDENTIFIER);
        }
    };

    loadCreditCars = () => {
        const { match, dispatch, extraDataDetail } = this.props;
        const [dateFrom, dateTo] = [null, null];

        if (!extraDataDetail) {
            dispatch(
                routerActions.replace({ pathname: "/creditCards", state: { transition: "transition-flow-close" } }),
            );
            return;
        }

        const selectedFilter = "currentPeriod";
        this.setState({ selectedFilter });

        dispatch(creditCardActions.listRequest());

        dispatch(
            creditCardActions.detailRequest(match.params.id, {
                dateFrom,
                dateTo,
            }),
        );
    };

    showAdditional = (creditCard) => {
        if (!creditCard) {
            return;
        }

        const { dispatch, isMobile } = this.props;
        const { idProduct, description, statusCode } = creditCard;

        dispatch(creditCardActions.setExtraDataDetail(creditCard));
        dispatch(productActions.setSelectProduct({ idProduct, description, statusCode, type: "N" }));
        if (!isMobile) {
            dispatch(creditCardActions.detailRequest(idProduct, {}));
        }
        this.props.history.push({ pathname: `/creditCardAdditional/${idProduct}` });
    };

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.showOptions());
    };

    handleFilterButtonsClick = (selectedFilter, idCreditCard) => {
        const { dispatch } = this.props;
        const [dateFrom, dateTo] = this.periods[selectedFilter];

        this.setState({ selectedFilter });
        this.handleResetDateFilters(true);
        dispatch(creditCardActions.movementsRequest(idCreditCard, { dateFrom, dateTo }));
    };

    onFinishDownload = () => {
        this.setState({
            downloadingStatements: false,
        });
    };

    handleClickDownload = (format) => {
        const { dispatch, match, filters } = this.props;
        this.setState({
            downloadingStatements: true,
        });
        dispatch(
            creditCardActions.downloadMovements(
                match.params.id,
                { dateFrom: filters?.dateFrom, dateTo: filters?.dateTo },
                format,
                this.onFinishDownload,
            ),
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.hideOptions());
        dispatch(routerActions.goBack());
    };

    handleResetDateFilters = (resetDateFilters) => this.setState({ resetDateFilters });

    renderFilters = () => {
        const { creditCard } = this.props;
        const { selectedFilter } = this.state;
        const [date] = this.periods.secondLastMonth;
        return (
            <>
                <Button
                    label="creditCards.movements.filters.currentPeriod"
                    className={classNames("btn btn-outline", {
                        "is-active": selectedFilter === "currentPeriod",
                    })}
                    key="currentPeriod"
                    onClick={() => this.handleFilterButtonsClick("currentPeriod", creditCard.idProduct)}
                />
                <Button
                    label="creditCards.movements.filters.lastMonth"
                    className={classNames("btn btn-outline", {
                        "is-active": selectedFilter === "lastMonth",
                    })}
                    key="lastMonth"
                    onClick={() => this.handleFilterButtonsClick("lastMonth", creditCard.idProduct)}
                />
                <Button
                    className={classNames("btn btn-outline", {
                        "is-active": selectedFilter === "secondLastMonth",
                    })}
                    key="secondLastMonth"
                    onClick={() => this.handleFilterButtonsClick("secondLastMonth", creditCard.idProduct)}
                    label="accounts.movements.filters.secondLastMonth"
                    replace={{
                        SECOND_LAST_MONTH: `${
                            i18nUtils.getArray("global.months")[date.getMonth()]
                        } ${date.getFullYear().toString()}`,
                    }}
                />
            </>
        );
    };

    isAdditionalCreditCard = () => {
        const { extraDataDetail } = this.props;
        if (!extraDataDetail) {
            return false;
        }
        const { relationType } = extraDataDetail;
        return relationType && relationType === CREDIT_CARD_RELATION_TYPE_ADDITIONAL;
    };

    isShowConditions = (isCorporative) => {
        if (isCorporative) {
            return true;
        }
        return !this.isAdditionalCreditCard();
    };

    hasAdditionalCreditCardList = () => {
        const { extraDataDetail } = this.props;
        if (!extraDataDetail) {
            return false;
        }

        const { additionalCreditCardList } = extraDataDetail;
        return (
            additionalCreditCardList &&
            additionalCreditCardList?.filter((creditCard) => creditCard.isVisibleInWidget).length > 0
        );
    };

    getAdditionalCreditCardList = () => {
        const { extraDataDetail } = this.props;
        if (!extraDataDetail) {
            return [];
        }
        const { additionalCreditCardList } = extraDataDetail;
        return additionalCreditCardList
            ?.filter((creditCard) => creditCard.isVisibleInWidget)
            ?.map((item) => {
                const artifactType = MAP_CREDIT_CARD_TYPE.get(item?.artifactType || "");
                return {
                    ...item,
                    iconType: artifactType?.icon || "",
                    type: artifactType?.title || "",
                    brand: (item?.franchise || "").toLowerCase(),
                    alias: item?.label || "",
                    avaible: item?.availableBalance || 0,
                };
            });
    };

    getOptionsByRelationType = (optionList, relationType) =>
        optionList?.filter((item) => item && item[relationType] && item[relationType] === true);

    handlePinAssigment = () => {
        const { dispatch, creditCard } = this.props;
        const values = generatePinAssigmentData({
            idProduct: creditCard.idProduct,
            numberMask: creditCard.numberMask,
            shortLabel: creditCard.shortLabel,
            franchise: creditCard.franchise,
            expirationDate: creditCard.expirationDateMask,
            submitAction: creditCardActions.assignPinRequest,
            parentBasePath: `${this.getLocationBasePath()}${PAGE_IDENTIFIER}`,
        });

        dispatch(changeStatusProductActions.modalShow(values));
    };

    filterOptionListByPermission = (optionList) => {
        const { activeEnvironment } = this.props;
        if (!activeEnvironment?.permissions) {
            return [];
        }
        return (
            optionList?.filter((item) => {
                if (!item || !item.permissionList) {
                    return false;
                }
                return item.permissionList.some(
                    (itemP) =>
                        itemP === "*" ||
                        (Object.keys(activeEnvironment.permissions).includes(itemP) &&
                            activeEnvironment.permissions[itemP] &&
                            activeEnvironment.permissions[itemP] === true),
                );
            }) || []
        );
    };

    handlePointsClick = () => {
        const { dispatch, getSelectProduct, creditCard } = this.props;

        if (getSelectProduct?.statusCode === CreditCardStatus.Blocked) {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("forms.product.exchange.points.text.blocked.error"),
                    "error",
                    ["creditcards", "creditCardDetails"],
                ),
            );
        } else if (getSelectProduct?.statusCode === CreditCardStatus.Inactived) {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("forms.product.exchange.points.text.inactive.error"),
                    "error",
                    ["creditcards", "creditCardDetails"],
                ),
            );
        } else if (getSelectProduct?.statusCode !== CreditCardStatus.Actived) {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("forms.product.exchange.points.text.nonactive.error"),
                    "error",
                    ["creditcards", "creditCardDetails"],
                ),
            );
        } else {
            dispatch(productActions.showExchangePointForm(creditCard.idProduct));
        }
    };

    showEnableWalletRequest = (creditCardId) => {
        if (!creditCardId) {
            return;
        }

        this.setState({ showEnabledWallet: true });
    };

    callbackProvision = (resultCode) => {
        if (!resultCode || resultCode === START_EVENTS_CODE) {
            return;
        }
        this.setState({ showEnabledWallet: false });
        applePayRemovePassButton();

        if (resultCode === PROVISION_SUCCESS_CODE) {
            this.setState({ isCardWasDigitized: true });
        }
    };

    render() {
        const {
            creditCard,
            isDesktop,
            dispatch,
            isFetching,
            totalFavorites,
            getSelectProduct,
            currentLang,
            extraDataDetail,
            activeEnvironment,
            showExchangePointForm,
            changedPoints,
            history,
            creditCardInList,
        } = this.props;

        const {
            transitMovementSize,
            downloadingStatements,
            showSideBarSearchStatements,
            showSideBarDownloadDetail,
        } = this.state;

        const secondaryBtn = () => {
            const secondaryBtnArr = [];
            if (!creditCard?.financialRelief || creditCard?.financialRelief === CreditCardFiantialReliefStatus.N) {
                secondaryBtnArr.push({
                    bsStyle: "outline",
                    label: "creditCard.buttons.statements",
                    onClick: () => {
                        this.setState({ showSideBarDownloadDetail: true });
                    },
                    showInfAdditional: true,
                    showInfMain: true,
                    permissionList: ["*"],
                });
            }

            if (isDesktop) {
                const btnPayCard = {
                    bsStyle: "primary",
                    label: creditCard?.isPrepaid
                        ? "creditCard.buttons.reloadCard.contextMenu"
                        : "creditCard.buttons.payCard",
                    href: creditCard?.isPrepaid
                        ? "/formCustom/rechargeCreditCardLocal?creditCard="
                        : `/formCustom/payCreditCardLocal?creditCard=`,
                    showInfAdditional: true,
                    showInfMain: true,
                    permissionList: ["*"],
                };
                secondaryBtnArr.push(btnPayCard);
            }
            return secondaryBtnArr;
        };

        const contextOptions = () => {
            const contextOptionsBtnArr = [];
            if (creditCard?.financialRelief === CreditCardFiantialReliefStatus.N) {
                contextOptionsBtnArr.push({
                    label: "creditCard.buttons.pin",
                    showInfAdditional: true,
                    showInfMain: true,
                    onClick: () => {
                        if (getSelectProduct.statusCode === CreditCardStatus.Actived) {
                            this.handlePinAssigment();
                        } else {
                            dispatch(
                                notificationActions.showNotification(
                                    i18nUtils.get("creditCards.snackbar.assignPin.disabled"),
                                    "error",
                                    ["creditcards", "creditCardDetails"],
                                ),
                            );
                        }
                    },
                    permissionList: ["*"],
                });
                if (creditCardInList?.showReplaceOption && creditCardInList.productionStatus !== "Inactive") {
                    contextOptionsBtnArr.push({
                        label: "creditCard.buttons.report.replace.card",
                        showInfAdditional: true,
                        showInfMain: true,
                        permissionList: ["*"],
                        href: "/formCustom/reportReplaceCard/",
                    });
                }
                if (creditCardInList?.showRenewOption) {
                    contextOptionsBtnArr.push({
                        label: "creditCard.buttons.report.renew.card",
                        showInfAdditional: true,
                        showInfMain: true,
                        permissionList: ["*"],
                        href: "/formCustom/renewCard/",
                    });
                }
            }
            contextOptionsBtnArr.push({
                label: "creditCard.buttons.financing.plans",
                showInfAdditional: false,
                showInfMain: true,
                permissionList: ["*"],
                href: `/creditCards/financingplans/`,
            });
            /* contextOptionsBtnArr.push({
                label: "creditCard.buttons.lostCard",
                showInfAdditional: true,
                showInfMain: true,
                permissionList: ["*"],
            });
            contextOptionsBtnArr.push({
                label: creditCard?.isPrepaid ? "creditCard.buttons.payServices" : "creditCard.buttons.buyBalance",
                showInfAdditional: false,
                showInfMain: true,
                permissionList: ["*"],
            }); */
            if (creditCard?.financialRelief === CreditCardFiantialReliefStatus.N) {
                contextOptionsBtnArr.push({
                    label: "creditCard.buttons.payServices",
                    showInfAdditional: true,
                    showInfMain: true,
                    permissionList: ["*"],
                    onClick: () => {
                        history.push("/servicePayments");
                    },
                });
            }
            contextOptionsBtnArr.push({
                label: "creditCard.buttons.transfer",
                showInfAdditional: true,
                showInfMain: false,
                permissionList: [TRANSFER_INTERNAL_PERMISSION, TRANSFER_LOCAL_PERMISSION, TRANSFER_FOREIGN_PERMISSION],
                onClick: () => {
                    if (!activeEnvironment?.permissions) {
                        return;
                    }
                    const permissionsList = Object.keys(activeEnvironment.permissions);
                    let pathTransfer;
                    if (permissionsList.includes(TRANSFER_INTERNAL_PERMISSION)) {
                        pathTransfer = "/formCustom/transferInternal";
                    } else if (permissionsList.includes(TRANSFER_LOCAL_PERMISSION)) {
                        pathTransfer = "/formCustom/transferLocal";
                    } else if (permissionsList.includes(TRANSFER_FOREIGN_PERMISSION)) {
                        pathTransfer = "/formCustom/transferForeign";
                    }

                    if (pathTransfer) {
                        dispatch(
                            routerActions.replace({
                                pathname: pathTransfer,
                                state: { transition: "transition-flow-close" },
                            }),
                        );
                    }
                },
            });

            return contextOptionsBtnArr;
        };

        let contextOptionList = [];
        if (isDesktop) {
            contextOptionList = this.getOptionsByRelationType(
                contextOptions(),
                this.isAdditionalCreditCard() ? "showInfAdditional" : "showInfMain",
            );
        } else {
            contextOptionList = this.getOptionsByRelationType(
                secondaryBtn(),
                this.isAdditionalCreditCard() ? "showInfAdditional" : "showInfMain",
            ).concat(
                this.getOptionsByRelationType(
                    contextOptions(),
                    this.isAdditionalCreditCard() ? "showInfAdditional" : "showInfMain",
                ),
            );
        }

        contextOptionList = this.filterOptionListByPermission(contextOptionList);

        let secondaryButtonList = this.getOptionsByRelationType(
            secondaryBtn(),
            this.isAdditionalCreditCard() ? "showInfAdditional" : "showInfMain",
        );

        secondaryButtonList = this.filterOptionListByPermission(secondaryButtonList);

        return (
            <>
                <Notification scopeToShow="creditCardDetails" />
                <PageLoading loading={isFetching || Object.keys(extraDataDetail).length === 0} classicStyle={false}>
                    {!isFetching && creditCard && (
                        <>
                            <ProductDetail>
                                <ProductDetail.Header
                                    contextOptions={contextOptionList}
                                    dispatch={dispatch}
                                    handleOptionsClick={this.handleClick}
                                    isDesktop={isDesktop}
                                    onBack={this.handleBack}
                                    product={creditCard}
                                    idProduct={creditCard?.idProduct}
                                    secondaryBtn={secondaryButtonList}
                                    totalFavorites={totalFavorites}
                                    selectProduct={getSelectProduct}>
                                    {isDesktop ? (
                                        <DetailHeadInfo
                                            {...this.props}
                                            creditCard={{
                                                ...creditCard,
                                                description: getSelectProduct?.description,
                                                statusCode: getSelectProduct?.statusCode,
                                                totalPoints: changedPoints || creditCard?.totalPoints,
                                                cardStyle: extraDataDetail?.cardStyle,
                                                isSwitchVisible: extraDataDetail?.isSwitchVisible,
                                            }}
                                            isAdditionalCreditCard={this.isAdditionalCreditCard()}
                                            artifactType={extraDataDetail?.artifactType}
                                            handlePointsClick={this.handlePointsClick}
                                        />
                                    ) : null}
                                </ProductDetail.Header>
                                {isDesktop && !this.isAdditionalCreditCard() && this.hasAdditionalCreditCardList() && (
                                    <AdditionalsCards
                                        additionals={this.getAdditionalCreditCardList()}
                                        onClickItemCustom={(creditCardObj) => {
                                            this.showAdditional(creditCardObj);
                                        }}
                                    />
                                )}

                                <ProductDetail.Body
                                    isDesktop={isDesktop}
                                    filters={this.renderFilters()}
                                    filtersKeyLabel="accounts.movements"
                                    handleResetDateFilters={this.handleResetDateFilters}
                                    {...(creditCardInList?.isCloseToExpiration && {
                                        disclaimerStyled: "warning",
                                        disclaimerLabelkey: "forms.report.card.disclaimer.toExpire",
                                        disclaimerTextParams: {
                                            CARD_BRAND: creditCardInList?.franchise,
                                            CARD_NUMBER: creditCardInList?.numberMask,
                                        },
                                    })}
                                    {...(creditCardInList?.hasExpirated && {
                                        disclaimerStyled: "error",
                                        disclaimerLabelkey: "forms.report.card.disclaimer.expired",
                                        disclaimerTextParams: {
                                            CARD_BRAND: creditCardInList?.franchise,
                                            CARD_NUMBER: creditCardInList?.numberMask,
                                        },
                                    })}
                                    moreFilters={
                                        <CreditCardOtherFilters
                                            isDesktop={isDesktop}
                                            dispatch={dispatch}
                                            productId={creditCard.idProduct}
                                            currency={creditCard.availableBalanceCurrency}
                                            // eslint-disable-next-line react/destructuring-assignment
                                            resetFilters={this.state.resetDateFilters}
                                            handleResetDateFilters={this.handleResetDateFilters}
                                        />
                                    }
                                    moreFiltersClosedKeyLabel="accounts.movements.filters.more"
                                    moreFiltersOpenedKeyLabel="accounts.movements.filters.less"
                                    {...(!isDesktop && {
                                        detailHeadInfo: (
                                            <>
                                                <DetailHeadInfo
                                                    {...this.props}
                                                    creditCard={{
                                                        ...creditCard,
                                                        description: getSelectProduct?.description,
                                                        statusCode: getSelectProduct?.statusCode,
                                                        cardStyle: extraDataDetail?.cardStyle,
                                                        isSwitchVisible: extraDataDetail?.isSwitchVisible,
                                                    }}
                                                    isAdditionalCreditCard={this.isAdditionalCreditCard()}
                                                    artifactType={extraDataDetail?.artifactType}
                                                    cardWasDigitized={this.state.isCardWasDigitized}
                                                    handlePointsClick={this.handlePointsClick}
                                                    {...(this.state.showWallet &&
                                                        this.state.showWallet === true && {
                                                            showEnableWalletRequest: () =>
                                                                this.showEnableWalletRequest(creditCard?.idProduct),
                                                        })}
                                                />
                                                {!this.isAdditionalCreditCard() && this.hasAdditionalCreditCardList() && (
                                                    <AdditionalsCards
                                                        additionals={this.getAdditionalCreditCardList()}
                                                        onClickItemCustom={(creditCardObj) => {
                                                            this.showAdditional(creditCardObj);
                                                        }}
                                                    />
                                                )}
                                            </>
                                        ),
                                    })}>
                                    <Box
                                        isDesktop={isDesktop}
                                        iconLabel="images/last-movements.svg"
                                        keyLabel="creditCard.statements">
                                        <StatementsCreditCardActions
                                            documentDownload={{
                                                documents: [".pdf", ".xls"],
                                                downloading: downloadingStatements,
                                                onDownload: this.handleClickDownload,
                                            }}
                                            modalActions={{
                                                showModalSearch: () => {
                                                    this.setState({ showSideBarSearchStatements: true });
                                                },
                                            }}
                                        />
                                        <CreditCardMovements
                                            idProduct={creditCard.idProduct}
                                            keyLabel="creditCard.statements"
                                            isDesktop={isDesktop}
                                            creditCardParams={{
                                                creditCardfranchise: creditCard.franchise,
                                                creditCardShortLabel: creditCard.shortLabel,
                                            }}
                                        />
                                    </Box>
                                    {creditCard?.idProduct && (
                                        <Box
                                            isDesktop={isDesktop}
                                            customKeyLabel
                                            borderRadius="bottom-xl"
                                            {...(isDesktop && { iconLabel: "images/movTransit.svg" })}
                                            keyLabel={`${i18nUtils.get("creditcard.movements.transit.tabTitle")} ${
                                                transitMovementSize ? `(${transitMovementSize})` : ""
                                            }`}>
                                            <MovementsTransit
                                                productId={creditCard.idProduct}
                                                setMovementTransitSize={(quantity) =>
                                                    this.setState({ transitMovementSize: quantity || 0 })
                                                }
                                            />
                                        </Box>
                                    )}
                                    {this.isShowConditions(creditCard?.isCorporative) && (
                                        <Box
                                            isDesktop={isDesktop}
                                            {...(isDesktop && { iconLabel: "images/creditCardWarning.svg" })}
                                            keyLabel="creditcard.conditions.tabTitle">
                                            <Conditions isDesktop={isDesktop} creditCard={creditCard} />
                                        </Box>
                                    )}
                                </ProductDetail.Body>
                            </ProductDetail>
                        </>
                    )}
                </PageLoading>
                <SideBarModal
                    show={showSideBarDownloadDetail}
                    onClose={() => {
                        this.setState({ showSideBarDownloadDetail: false });
                    }}
                    title="forms.product.state.download.title">
                    <DownloadAccoutDetailForm
                        isDesktop={isDesktop}
                        account={creditCard}
                        currentLang={currentLang}
                        dispatch={dispatch}
                    />
                </SideBarModal>
                <SideBarModal
                    show={showSideBarSearchStatements}
                    onClose={() => {
                        this.setState({ showSideBarSearchStatements: false });
                    }}
                    title="creditCards.movements.filters.searchBy">
                    <CreditCardPeriodFilter
                        isDesktop
                        productId={creditCard?.idProduct}
                        dispatch={dispatch}
                        onCloseModal={() => {
                            this.setState({ showSideBarSearchStatements: false });
                        }}
                    />
                </SideBarModal>
                <SideBarModal
                    show={showExchangePointForm}
                    onClose={() => {
                        dispatch(productActions.hideExchangePointForm());
                    }}
                    title="forms.product.exchange.points.title"
                    modalId="/forms.product.exchange.points">
                    <ExchangePointsForm
                        isDesktop={isDesktop}
                        idProduct={getSelectProduct?.idProduct}
                        creditCardNumber={creditCard?.shortLabel}
                        currentLang={currentLang}
                        dispatch={dispatch}
                    />
                </SideBarModal>
                {this.state.showEnabledWallet && creditCard?.idProduct && (
                    <EnabledWalletCardModal
                        show={this.state.showEnabledWallet}
                        cardId={creditCard.idProduct}
                        cardType={CREDIT_CARD_WALLET_TYPE}
                        callbackError={() => {
                            this.setState({ showEnabledWallet: false });
                            applePayRemovePassButton();
                        }}
                        scopeError="creditCardDetails"
                        activityId="enabled.wallet.creditCard.send"
                        callbackProvision={this.callbackProvision}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;

    return {
        creditCard: creditCardSelectors.getDetail(state, id),
        creditCardInList: creditCardSelectors.getDetailInList(state, id),
        isFetching: creditCardSelectors.getFetching(state),
        filters: creditCardSelectors.getFilters(state),
        totalFavorites: creditCardSelectors.getTotalFavorites(state),
        getSelectProduct: productSelectors.getSelectProduct(state),
        currentLang: i18nSelectors.getLang(state),
        extraDataDetail: creditCardSelectors.getExtraDataDetail(state),
        creditCards: creditCardSelectors.getList(state),
        activeEnvironment: sessionSelectors.getActiveEnvironment(state),
        showExchangePointForm: productSelectors.getShowExchangePointForm(state),
        changedPoints: productSelectors.getChangedPoints(state),
        loggedUser: sessionSelectors.getUser(state),
    };
};

CreditCardDetails.propTypes = {
    creditCard: shape({}),
    creditCardInList: shape({}),
    match: shape({}).isRequired,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    filters: shape({}).isRequired,
    totalFavorites: int.isRequired,
    getSelectProduct: shape({}),
    currentLang: string,
    extraDataDetail: shape({}),
    creditCards: arrayOf(shape({})),
    activeEnvironment: shape({}),
    showExchangePointForm: bool.isRequired,
    changedPoints: int,
    setLocationCustomPath: string,
    isMobile: bool,
    history: shape({}),
    loggedUser: shape({}),
};

CreditCardDetails.defaultProps = {
    creditCard: {},
    creditCardInList: {},
    getSelectProduct: undefined,
    currentLang: "",
    extraDataDetail: {},
    creditCards: [],
    activeEnvironment: { permissions: [] },
    changedPoints: undefined,
    setLocationCustomPath: "",
    isMobile: false,
    history: {},
    loggedUser: undefined,
};

export default connect(mapStateToProps)(withRevelockPositionContext(CreditCardDetails));
