import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { string, bool } from "prop-types";
import Image from "pages/_components/Image";

import { selectors as i18nSelectors } from "reducers/i18n";
import * as i18n from "util/i18n";

class LanguageSelectionLink extends Component {
    static propTypes = {
        activeLanguage: string,
        disabled: bool,
    };

    static defaultProps = {
        activeLanguage: "en",
        disabled: false,
    };

    handleClick = (event) => {
        const { disabled } = this.props;

        if (disabled) {
            event.preventDefault();
        }
    };

    render() {
        const { activeLanguage } = this.props;
        const a11yMessage1 = i18n.get("settings.changeLanguage.link.a11y");
        const a11yMessage2 = i18n.get(`settings.changeLanguage.label.${activeLanguage}.a11y`);
        if (activeLanguage) {
            return (
                <Link onClick={this.handleClick} style={{ display: "flex" }} to="/languageSelection">
                    <Image src={`images/${activeLanguage}-flag.svg`} />
                    <span className="visually-hidden">{`${a11yMessage1}, ${a11yMessage2}`}</span>
                    <span className="pl-2" aria-hidden="true">{`${activeLanguage.toUpperCase()}`}</span>
                </Link>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(LanguageSelectionLink);
