import React, { Component } from "react";
import { connect } from "react-redux";
import { string, shape, objectOf } from "prop-types";
import { selectors } from "reducers/administration/common/administrationTicket";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import * as dateUtils from "util/date";

class RestrictionsManageTicketData extends Component {
    static propTypes = {
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
    };

    renderDays = (bitWiseNumber) => (
        <div>
            {dateUtils
                .getDaysNamesWithBitwiseNumber(bitWiseNumber)
                .map((i) => i)
                .join(", ")}
        </div>
    );

    renderCalendarAndIPSection(onlyEnvironment, dataToSave) {
        return (
            <>
                {/* calendar Detail */}
                {!dataToSave.calendarToSave && onlyEnvironment && (
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="administration.restrictions.restrictions.byCalendar" />
                        </div>
                        {dataToSave.disableCalendar && (
                            <div className="data-desc">
                                {" "}
                                <I18n id="administration.restrictions.disableCalendar.label" />
                            </div>
                        )}
                        {!dataToSave.disableCalendar && (
                            <div className="data-desc">
                                {" "}
                                <I18n id="administration.restrictions.enableCalendar.label" />
                            </div>
                        )}
                    </div>
                )}
                {!dataToSave.perpetual && dataToSave.calendarToSave && !dataToSave.disableCalendar && (
                    <>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="administration.restrictions.timeZone.label" />
                            </div>
                            <div className="data-desc"> {dataToSave.timeZone}</div>
                        </div>

                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="administration.restrictions.restrictions.days" />
                            </div>
                            <div className="data-desc">
                                {dataToSave.days !== 127 && this.renderDays(dataToSave.days)}
                                {dataToSave.days === 127 && <I18n id="administration.restrictions.allDays.label" />}
                            </div>
                        </div>

                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="administration.restrictions.restrictions.time" />
                            </div>
                            <div className="data-desc">
                                <I18n id="administration.restrictions.from" />
                                {` ${dateUtils.timeinMinToString(dataToSave.startTime)} `}
                                <I18n id="administration.restrictions.to" />
                                {` ${dateUtils.timeinMinToString(dataToSave.endTime)} `}
                                <I18n id="administration.restrictions.hrs" />
                            </div>
                        </div>
                    </>
                )}
                {dataToSave.perpetual && (
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="administration.restrictions.restrictions.byCalendar" />
                        </div>
                        <div className="data-desc">
                            {" "}
                            <I18n id="administration.restrictions.perpetual.detail" />
                        </div>
                    </div>
                )}
                {/* ip Detail */}
                {(dataToSave.ipsList && dataToSave.ipsList.length > 0) || dataToSave.anyIP ? (
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="administration.restrictions.IP" />
                        </div>
                        <div className="data-desc">
                            {dataToSave.anyIP && <I18n id="administration.restrictions.anyIP.label" />}
                            <span>{!dataToSave.anyIP && dataToSave.ipsList.join(", ")}</span>
                        </div>
                    </div>
                ) : (
                    <>
                        {onlyEnvironment && (
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="administration.restrictions.restrictions.byIP" />
                                </div>
                                {dataToSave.disableIP && (
                                    <div className="data-desc">
                                        {" "}
                                        <I18n id="administration.restrictions.disableIP.label" />
                                    </div>
                                )}
                                {!dataToSave.disableIP && (
                                    <div className="data-desc">
                                        {" "}
                                        <I18n id="administration.restrictions.enableIP.label" />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </>
        );
    }

    renderDetail(data) {
        const onlyEnvironment = typeof data.idUser === "undefined";
        return (
            <>
                <div className="transfer-data data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.restrictions.restrictions.type" />
                    </div>
                    <div className="data-desc">
                        {onlyEnvironment ? (
                            <I18n id="administration.restrictions.restrictions.environment" />
                        ) : (
                            <I18n id="administration.restrictions.restrictions.user" />
                        )}
                    </div>
                </div>
                {this.renderCalendarAndIPSection(onlyEnvironment, data)}
            </>
        );
    }

    render() {
        const { transactionData } = this.props;

        if (transactionData.data) {
            return (
                <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        {this.renderDetail(transactionData.data)}
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(RestrictionsManageTicketData);
