import classNames from "classnames";
import { string, bool, oneOf } from "prop-types";
import React from "react";
import * as i18n from "util/i18n";

const Text = ({
    align,
    ariaLabel,
    bold,
    breakWord,
    capitalize,
    children,
    className,
    component,
    color,
    addColon,
    defaultValue,
    dotts,
    ellipsis,
    ellipsisShow,
    htmlFor,
    id,
    italic,
    labelKey,
    hidden,
    light,
    lowercase,
    noWrap,
    prefix,
    quote,
    underlined,
    uppercase,
    regular,
    role,
    size,
    suffix,
    wrap,
    wordSpacing,
    textParams,
    ...rest
}) => {
    const getFinalMessage = () => {
        const excludedProps = ["dispatch"];

        const params = Object.keys(rest).reduce((map, key) => {
            const mapParam = map;
            const propsParam = rest;

            if (excludedProps.indexOf(key) < 0) {
                mapParam[key] = propsParam[key];
            }
            return mapParam;
        }, {});

        let finalMessage = i18n.get(labelKey, defaultValue, textParams || params);

        if (quote) {
            finalMessage = `"${finalMessage}"`;
        }

        return finalMessage;
    };

    const finalMessage = getFinalMessage();

    const Component = component;
    return (
        <>
            <Component
                {...(ariaLabel && { "aria-label": ariaLabel })}
                {...(htmlFor && { htmlFor })}
                {...(id && { id })}
                {...(role && { role })}
                {...(ellipsisShow && labelKey
                    ? { [`tooltip-text`]: `${finalMessage} ${children}` }
                    : ellipsisShow && { [`tooltip-text`]: children })}
                {...((className ||
                    capitalize ||
                    color ||
                    addColon ||
                    size ||
                    align ||
                    bold ||
                    regular ||
                    light ||
                    ellipsis ||
                    ellipsisShow ||
                    italic ||
                    light ||
                    uppercase ||
                    underlined ||
                    wrap ||
                    breakWord ||
                    hidden ||
                    dotts ||
                    wordSpacing ||
                    noWrap) && {
                    className: classNames(className, {
                        [`color-${color}`]: color && color,
                        [`size-${size}`]: size && size,
                        [`align-${align}`]: align && align,
                        [`word-spacing-${wordSpacing}`]: wordSpacing,
                        "text-bold": bold,
                        "text-regular": regular,
                        "text-light": light,
                        "text-ellipsis": ellipsis,
                        "text-ellipsis-show": ellipsisShow,
                        "text-italic": italic,
                        "text-uppercase": uppercase,
                        "text-lowercase": lowercase,
                        "text-capitalize": capitalize,
                        "text-underlined": underlined,
                        "text-wrap": wrap,
                        "text-no-wrap": noWrap,
                        "word-break-word": breakWord,
                        "add-dotts": dotts,
                        "add-colon": addColon,
                        "visually-hidden": hidden,
                    }),
                })}
                {...((labelKey || defaultValue) && { dangerouslySetInnerHTML: { __html: finalMessage } })}>
                {children}
            </Component>
        </>
    );
};

Text.propTypes = {
    addColon: bool,
    align: string,
    ariaLabel: string,
    bold: bool,
    capitalize: bool,
    children: string,
    className: string,
    component: oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "span", "label", "legend"]),
    color: string,
    breakWord: bool,
    defaultValue: string,
    dotts: bool,
    ellipsis: bool,
    ellipsisShow: bool,
    hidden: bool,
    htmlFor: string,
    id: string,
    italic: bool,
    labelKey: string,
    light: string,
    lowercase: bool,
    noWrap: string,
    prefix: string,
    quote: bool,
    underlined: bool,
    uppercase: bool,
    regular: bool,
    role: string,
    size: oneOf(["small", "1", "2", "3", "4", "5", "6", "7", "big"]),
    suffix: string,
    wrap: bool,
    wordSpacing: string,
    textParams: string,
};

Text.defaultProps = {
    addColon: false,
    align: null,
    ariaLabel: null,
    bold: false,
    capitalize: false,
    children: null,
    className: null,
    component: "span",
    color: null,
    breakWord: false,
    defaultValue: null,
    dotts: false,
    ellipsis: false,
    ellipsisShow: false,
    hidden: false,
    htmlFor: null,
    id: null,
    italic: false,
    labelKey: null,
    light: null,
    lowercase: false,
    noWrap: null,
    prefix: null,
    quote: false,
    underlined: false,
    uppercase: false,
    regular: false,
    role: null,
    size: null,
    suffix: null,
    wrap: false,
    wordSpacing: null,
    textParams: null,
};

export default Text;
