import * as API from "middleware/api";
import * as restAPI from "middleware/apiRESTful";
import * as OAUTH from "middleware/oauth";
import * as deviceUtils from "util/device";

export const loginStep1 = (email, password, recaptchaResponse, credentialType, otp, fingerPrintToken) =>
    API.executeAnonymous("/session.login.legacy.step1", {
        userEmail: email,
        _password: password,
        _captcha: recaptchaResponse,
        credentialType,
        _otp: otp,
        fingerPrintToken,
    });

export const oauth = (email, exchangeToken = "") => OAUTH.executePasswordGrant(email, `${exchangeToken}`);

export const thirdPartyOauth = (
    email,
    password,
    thirdPartyToken,
    redirectUri,
    exchangeToken = "",
    recaptchaResponse = "",
) =>
    OAUTH.executeImplicit(
        "assistant-client",
        redirectUri,
        thirdPartyToken,
        email,
        `${password}#${exchangeToken}#${recaptchaResponse}`,
    );

export const fingerprintOauth = (email, fingerprintAuthToken) =>
    OAUTH.executePasswordGrant(
        email,
        `fingerprint#${fingerprintAuthToken}#${deviceUtils.getDeviceId()}#${deviceUtils.getDeviceModel()}`,
    );

export const listEnvironments = () => API.executeWithAccessToken("session.login.oauth.step2b");

export const loginStep3 = (exchangeToken, environment, setAsDefault, location, idDevice, assistant, fingerPrintToken) =>
    API.executeWithExchangeToken(
        "/session.login.legacy.step3",
        {
            environment,
            setAsDefault,
            idDevice,
            ...location,
            ...assistant,
            fingerPrintToken,
        },
        exchangeToken,
    );

export const loginStep4 = (exchangeToken, acceptConditions, fingerPrintToken) =>
    API.executeWithExchangeToken(
        "/session.login.legacy.step4",
        {
            acceptConditions,
            fingerPrintToken,
        },
        exchangeToken,
    );

export const logout = () => {
    const response = API.executeWithAccessToken("/session.logout");

    API.setAuthToken(null);
    restAPI.setAuthToken(null);
    return response;
};

export const setAuthToken = (token) => {
    API.setAuthToken(token);
    restAPI.setAuthToken(token);
};

export const changeEnvironment = (idEnvironmentToChange, setAsDefault) =>
    API.executeWithAccessToken("/session.changeEnvironment", {
        idEnvironmentToChange,
        setAsDefault,
    });

export const check = () => API.executeWithAccessToken("/session.get");

export const extend = () => API.executeWithAccessToken("/session.extend");

export const checkFingerprintSession = (_accessToken) => {
    API.setAuthToken(_accessToken);
    restAPI.setAuthToken(_accessToken);

    return API.executeWithAccessToken("/fingerprint.sessionExist");
};

export const fingerprintLogin = (location, deviceInfo, fingerPrintToken) =>
    API.executeWithAccessToken("/login.fingerprint", { ...location, ...(deviceInfo || {}), fingerPrintToken });

export const registerUserDevice = (exchangeToken, idDevice, pushToken, extraInfo) =>
    API.executeWithExchangeToken(
        "/session.login.legacy.registerUserDevice",
        {
            idDevice,
            pushToken,
            extraInfo,
        },
        exchangeToken,
    );

export const getClientCountry = () => API.executeAnonymous("/session.getUserCountry", null, 2000);
export const updateMaskAmount = (maskAmount) =>
    API.executeWithAccessToken("/desktop.config.maskamount", { maskAmount });

export const fingerPrinterFourthStepRequest = (acceptConditions, fingerPrintToken) =>
    API.executeWithAccessToken("/session.login.fingerPrinter.step4", { acceptConditions, fingerPrintToken });

export const validateAccess = (fingerPrintToken) =>
    API.executeWithAccessToken("/monitor.session.validate", { fingerPrintToken });
