import { Field, Form, withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, string } from "prop-types";
import { go } from "react-router-redux";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import { actions as recoveryPasswordActions, selectors } from "reducers/recoveryPassword";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import TextField from "pages/_components/fields/TextField";
import Captcha from "pages/_components/fields/credentials/Captcha";

const FORM_ID = "recoveryPassword.step3";

class Step3Content extends Component {
    render() {
        const { isMobile, isSubmitting, showCaptcha, contact, idTransaction, notificationType, dispatch } = this.props;
        return (
            <Form>
                <Box display="flex" column fullWidth {...(isMobile ? { fullHeight: true } : { fullHeight: false })}>
                    <Box display="flex" column className="pb-7">
                        <Text
                            labelKey={
                                contact === "" ? "recoveryPassword.step3.title" : "recoveryPassword.step3.title.contact"
                            }
                            align="center"
                            size={`${isMobile ? "5" : "6"}`}
                            component="p"
                            className="mt-0 mb-5"
                            color="text"
                        />

                        <Text
                            defaultValue={contact}
                            align="center"
                            size={`${isMobile ? "5" : "6"}`}
                            component="p"
                            className="mt-0 mb-5"
                            color="text"
                        />
                    </Box>
                    {isMobile && <BoxErrorNotification isMobile={isMobile} scopeToShow="recoveryPassword" />}
                    <Box display="flex" column className="my-auto pb-7">
                        <Field
                            name="verificationCode"
                            idForm={FORM_ID}
                            component={TextField}
                            placeholder=""
                            autoCapitalize="characters"
                        />

                        <Box display="flex" fullWidth className="mt-5">
                            {showCaptcha && <Field idForm={FORM_ID} name="captcha" component={Captcha} />}
                        </Box>
                    </Box>
                    <Box display="flex" column className="pb-7">
                        <Text
                            labelKey="recoveryPassword.step3.title3"
                            textParams={{
                                NOTIFICATION_TYPE: i18n.get(
                                    `recoveryPassword.step3.notificationType.${notificationType}`,
                                ),
                                ID_TRANSACTION: `${idTransaction}`,
                            }}
                            align="center"
                            size={`${isMobile ? "5" : "6"}`}
                            component="p"
                            className="mt-0 mb-5"
                            color="text"
                        />
                        <Text
                            labelKey="recoveryPassword.step3.title4"
                            textParams={{
                                NOTIFICATION_TYPE: i18n.get(
                                    `recoveryPassword.step3.notificationType.${notificationType}.title4`,
                                ),
                                ID_TRANSACTION: `${idTransaction}`,
                            }}
                            align="center"
                            size={`${isMobile ? "5" : "6"}`}
                            component="p"
                            className="my-0"
                            color="text"
                        />
                    </Box>
                    <Box display="flex" column className="mb-7 mb-md-0 mt-auto">
                        <Button label="global.continue" type="submit" bsStyle="primary" loading={isSubmitting} block />
                        <Button
                            label="recoveryPassword.step3.resendEmail"
                            type="button"
                            bsStyle="outline"
                            onClick={() => {
                                dispatch(go(-2));
                            }}
                            block
                        />
                    </Box>
                </Box>
            </Form>
        );
    }
}

Step3Content.propTypes = {
    isSubmitting: bool,
    isMobile: bool.isRequired,
    showCaptcha: bool.isRequired,
    dispatch: func.isRequired,
    contact: string,
    idTransaction: string,
    notificationType: string,
};

Step3Content.defaultProps = {
    isSubmitting: false,
    contact: "",
    idTransaction: "",
    notificationType: "mail",
};

const mapStateToProps = (state) => ({
    showCaptcha: selectors.getShowCaptcha(state),
    contact: selectors.getContact(state),
    idTransaction: selectors.getIdTransaction(state),
    notificationType: selectors.getNotificationType(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ verificationCode: "", captcha: "" }),
        validationSchema: ({ showCaptcha }) =>
            Yup.object().shape({
                verificationCode: Yup.string().required(i18n.get(`${FORM_ID}.verificationCode.required`)),
                captcha: showCaptcha ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.required`)) : Yup.string(),
            }),
        handleSubmit: (values, formikBag) => {
            const {
                props: { exchangeToken },
            } = formikBag;

            const { verificationCode, captcha } = values;
            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryPassStep3(verificationCode, captcha, exchangeToken, formikBag),
            );
        },
    }),
)(Step3Content);
