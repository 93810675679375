import React from "react";
import { string, bool } from "prop-types";

import I18n from "pages/_components/I18n";

const SectionTitle = ({ title, subTitle, hasLine }) => (
    <div className="form-section-title">
        <I18n component="h3" id={title} />
        {hasLine && <hr />}
        <I18n component="p" id={subTitle} />
    </div>
);

SectionTitle.propTypes = {
    title: string.isRequired,
    subTitle: string,
    hasLine: bool,
};

SectionTitle.defaultProps = {
    subTitle: "",
    hasLine: true,
};

export default SectionTitle;
