import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelectors,
} from "reducers/authenticateHandler";
import Button from "pages/_components/Button";
import { push } from "react-router-redux";
import { actions as statusActions } from "reducers/status";
import { TOKEN_STATUS_AS_ACTIVE_LIST, TOKEN_STATUS_AS_PENDING_LIST } from "util/userToken.util";
import { ENT000, hasRegisteredIdentity } from "util/softToken.util";
import { bool, func, string } from "prop-types";
import TokenButton from "./TokenButton";

const TokenButtonWrapper = ({ dispatch, isFetching, isStep0, tokenValidateStatus }) => {
    const [hasSofttokenRegister, setHasSofttokenRegister] = useState(false);
    const [hasSofttokenRegisterLoading, setHasSofttokenRegisterLoading] = useState(true);
    const [showToken, setShowToken] = useState(true);

    const validateRegisteredIdentity = async () => {
        try {
            setHasSofttokenRegisterLoading(true);
            const hasRegisteredIdentityResponse = await hasRegisteredIdentity();

            if (!hasRegisteredIdentityResponse) {
                setHasSofttokenRegister(false);
                setHasSofttokenRegisterLoading(false);
                return;
            }

            const { code, data } = JSON.parse(hasRegisteredIdentityResponse);
            if (!code || code !== ENT000 || !data || data !== "true") {
                setHasSofttokenRegister(false);
                setHasSofttokenRegisterLoading(false);
                return;
            }

            setHasSofttokenRegister(true);
        } catch (error) {
            setHasSofttokenRegisterLoading(false);
        }
    };

    useEffect(() => {
        validateRegisteredIdentity();
    }, []);

    useEffect(() => {
        if (hasSofttokenRegister) {
            dispatch(authenticateHandlerActions.getTokenInfoStatusRequest(() => setHasSofttokenRegisterLoading(false)));
        }
    }, [hasSofttokenRegister]);

    const handleClickActiveToken = () => {
        dispatch(push("/loginStep1"));
        dispatch(statusActions.saveLastHref("/tokenActivationStep1"));
    };

    const renderLoading = () => <Button className="btn-login-action btn-login-action-dashed" block loading />;

    const renderActiveTokenButton = () => (
        <Button
            className="btn-login-action btn-login-action-dashed"
            label="login.step0.active.token"
            onClick={handleClickActiveToken}
            block
        />
    );

    const renderButtonShowToken = () => (
        <Button
            className="btn-login-action"
            bsStyle="outline"
            onClick={() => {
                setShowToken(false);
            }}
            block
            label="token.show.button"
        />
    );

    const renderTokenButton = () => {
        if (isStep0 && showToken && hasSofttokenRegister) {
            return renderButtonShowToken();
        }
        if (hasSofttokenRegisterLoading || isFetching) {
            return renderLoading();
        }
        if (!tokenValidateStatus) {
            return renderActiveTokenButton();
        }

        if (TOKEN_STATUS_AS_PENDING_LIST.some((item) => item === tokenValidateStatus)) {
            return (
                <Button
                    className="btn-login-action btn-login-action-dashed"
                    label="login.step0.pending.token"
                    disabled
                    block
                />
            );
        }

        if (TOKEN_STATUS_AS_ACTIVE_LIST.some((item) => item === tokenValidateStatus)) {
            return <TokenButton isStep0={isStep0} loadingPrimaryBackground />;
        }

        return renderActiveTokenButton();
    };

    const renderContent = () => (hasSofttokenRegister ? renderTokenButton() : renderActiveTokenButton());

    if (isStep0) {
        return renderContent();
    }

    return hasSofttokenRegisterLoading || isFetching ? renderLoading() : renderContent();
};

const mapStateToProps = (state) => ({
    isFetching: authenticateHandlerSelectors.isFetchingTokenValidateStatus(state),
    tokenValidateStatus: authenticateHandlerSelectors.getTokenValidateStatus(state),
});

TokenButtonWrapper.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool,
    isStep0: bool,
    tokenValidateStatus: string,
};
TokenButtonWrapper.defaultProps = {
    isFetching: false,
    isStep0: false,
    tokenValidateStatus: undefined,
};

export default connect(mapStateToProps)(TokenButtonWrapper);
