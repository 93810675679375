import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import { actions as settingsActions } from "reducers/settings";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "settings.changeEmail";

class ChangeEmail extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        dispatch: func.isRequired,
        setFieldValue: func,
    };

    static defaultProps = {
        setFieldValue: undefined,
    };

    componentDidMount() {
        // obtain user's current email
        this.props.dispatch(settingsActions.getUserData());
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    render() {
        const { isDesktop, isSubmitting, setFieldValue } = this.props;

        return (
            <>
                <Head title="settings.changeEmail" onBack={this.handleBack} />
                <MainContainer>
                    <Notification scopeToShow="changeEmail" />
                    <Form className="full-height" noValidate="novalidate">
                        <Box display="flex" column fullWidth fullHeight>
                            <Box
                                background="white"
                                className={classNames("mt-5 mt-md-0 pt-5 pt-md-8 px-5 pb-8 pb-md-12 mb-8", {
                                    "mx-n-5 full-height": !isDesktop,
                                })}
                                borderRadius="xl">
                                <Row fullHeight>
                                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                        <Box display="flex" column alignX="between" fullHeight>
                                            <Text
                                                size="5"
                                                color="heading"
                                                component="p"
                                                className="m-0 mb-md-10"
                                                align="left"
                                                labelKey="settings.changeEmail.explanation"
                                                {...(!isDesktop && { align: "center" })}
                                            />
                                            <Field
                                                idForm={FORM_ID}
                                                name="mail"
                                                type="email"
                                                labelNoMarginTop
                                                component={TextField}
                                                autoFocus={isDesktop}
                                                className="mb-md-11 remove-flex"
                                            />

                                            <Box display="flex" column alignX="flex-end">
                                                <CredentialTokenComponent
                                                    credentials={["otp"]}
                                                    onChangeToken={(tokenCode) => {
                                                        if (setFieldValue) {
                                                            setFieldValue("otp", tokenCode);
                                                        }
                                                    }}
                                                    relativeStyleError
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                            <Box {...(!isDesktop && { className: "mt-auto" })}>
                                <Row>
                                    <Col xs={12} md={8} mdOffset={2} lg={4} lgOffset={4}>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            loading={isSubmitting}
                                            block
                                        />
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = () => ({});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            mail: "",
            otp: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mail: Yup.string()
                    .email(i18n.get(`${FORM_ID}.mail.invalid`))
                    .required(i18n.get(`${FORM_ID}.mail.required`)),
                otp: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ mail, otp }, formikBag) => {
            formikBag.props.dispatch(settingsActions.sendMailCode(mail, otp, formikBag));
        },
    }),
)(ChangeEmail);
