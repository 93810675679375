import React, { Component } from "react";
import { string, shape, instanceOf, bool } from "prop-types";
import { WithContext as ReactTags } from "react-tag-input";
import { Field } from "formik";

class TagsInputControl extends Component {
    input = React.createRef();

    static propTypes = {
        name: string.isRequired,
        placeholder: string,
        customDelimiter: shape({
            comma: string,
            space: string,
        }).isRequired,
        validationRegex: instanceOf(RegExp),
        validateDuplicatedItems: bool,
    };

    static defaultProps = {
        placeholder: "",
        validationRegex: null,
        validateDuplicatedItems: false,
    };

    handleClick = () => {
        this.input.current.ref.current.textInput.focus();
    };

    handleAddition = (item, field, form) => {
        const { validationRegex, validateDuplicatedItems } = this.props;
        if (validationRegex && !validationRegex.test(item.text)) {
            return false;
        }
        if (validateDuplicatedItems && field.value.filter((i) => i === item.text).length > 0) {
            return false;
        }
        form.setFieldValue(field.name, [...field.value, item.text]);
        return true;
    };

    handleInputBlur = (field, form) => {
        const currentVal = this.input.current.ref.current.state.query;
        if (this.handleAddition({ id: currentVal, text: currentVal }, field, form)) {
            this.resetReactTagsInput();
        }
    };

    handleChange = (tag, field, form) => {
        const { customDelimiter } = this.props;
        if ([customDelimiter.comma, customDelimiter.space].includes(tag.charAt(tag.length - 1))) {
            const currentVal = tag.slice(0, -1);
            if (this.handleAddition({ id: currentVal, text: currentVal }, field, form)) {
                this.resetAndFocusReactTagsInput();
            }
        }
    };

    handleDelete = (i, field, form) => {
        form.setFieldValue(
            field.name,
            field.value.filter((x) => x !== field.value[i]),
        );
    };

    resetReactTagsInput() {
        this.input.current.ref.current.setState({ query: "" });
        this.input.current.ref.current.textInput.value = "";
    }

    resetAndFocusReactTagsInput() {
        this.input.current.ref.current.setState({ query: "" });
        this.input.current.ref.current.textInput.value = "";
        this.input.current.ref.current.textInput.focus();
    }

    render() {
        const { placeholder, name } = this.props;
        return (
            <Field name={name}>
                {({ field, form }) => {
                    const ipListTagValue = field.value.map((e) => ({ id: e, text: e }));
                    return (
                        <div className="input-group">
                            <ReactTags
                                ref={this.input}
                                autofocus={false}
                                tags={ipListTagValue}
                                handleInputBlur={() => {
                                    this.handleInputBlur(field, form);
                                }}
                                inputFieldPosition="inline"
                                allowDragDrop={false}
                                handleInputChange={(tag) => {
                                    this.handleChange(tag, field, form);
                                }}
                                handleDelete={(i) => {
                                    this.handleDelete(i, field, form);
                                }}
                                placeholder={placeholder}
                                allowUnique
                                classNames={{
                                    tags: "tagsClass",
                                    tagInput: "tagInputClass",
                                    tagInputField: "tagInputFieldClass",
                                    selected: "selectedClass",
                                    tag: "tagClass",
                                    remove: "removeClass",
                                    suggestions: "suggestionsClass",
                                    activeSuggestion: "activeSuggestionClass",
                                }}
                            />
                        </div>
                    );
                }}
            </Field>
        );
    }
}
export default TagsInputControl;
