import classNames from "classnames";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";

import Box from "pages/_components/Box";
import I18n from "pages/_components/I18n";
import FieldError from "pages/_components/fields/FieldError";

class SwitchField extends Component {
    static propTypes = {
        name: string,
        onChange: func,
        onBlur: func,
        error: string,
        label: string,
        hideLabel: bool,
        idForm: string,
        readOnly: bool,
        value: bool,
        formGroup: bool,
        biggerLabel: bool,
    };

    static defaultProps = {
        name: "",
        error: "",
        label: "",
        hideLabel: false,
        idForm: "",
        readOnly: false,
        value: false,
        onChange: () => {},
        onBlur: null,
        formGroup: false,
        biggerLabel: false,
    };

    handleChange = ({ target, ...event }) => {
        const { onChange } = this.props;

        onChange({
            ...event,
            target: {
                ...target,
                value: target.value === "true",
            },
        });
    };

    render() {
        const { idForm, error, hideLabel, label, value, formGroup, biggerLabel, ...props } = this.props;

        return (
            <div
                className={classNames("c-control c-control-block c-control--switch", {
                    "form-group": formGroup,
                    "has-error": error,
                })}>
                <Box display="flex" alignY="center">
                    <input
                        id={props.name}
                        className="c-control-input"
                        type="checkbox"
                        {...props}
                        onChange={this.handleChange}
                        value={!value}
                        checked={value}
                        readOnly
                    />
                    <label
                        className={classNames("c-control-label", { disabled: props.disabled })}
                        htmlFor={props.name}
                    />

                    {!hideLabel && !biggerLabel && (
                        <div className="form-group-text">{label || <I18n id={`${idForm}.${props.name}.label`} />}</div>
                    )}
                    {!hideLabel && biggerLabel && (
                        <h4 className="table-legend">{label || <I18n id={`${idForm}.${props.name}.label`} />}</h4>
                    )}
                </Box>

                {error && <FieldError error={error} />}
            </div>
        );
    }
}

export default SwitchField;
